import React, { useEffect, useState } from "react";
import './style.css'
// Icons 
import NewCourseHeadIcon from '../../../../../assets/icons/ActivityContentIcons/New Course head icon.png'
import NewCourseIcon from '../../../../../assets/icons/ActivityContentIcons/New Course icon.png'
import CurrentCourseIcon from '../../../../../assets/icons/ActivityContentIcons/Current Courses icon.png'
import CurrentCourseHeadIcon from '../../../../../assets/icons/ActivityContentIcons/Current Courses head icon.png'
import { Link, useNavigate } from "react-router-dom";
import LoginHeading from "../../../../LoginHeading/LoginHeading";
import MetaTags from "../../../../GlobalLoading/MetaData";
import { Card, CircularProgress } from "@mui/material";
import Joyride from "react-joyride";
import axiosInstance from "../../../../../axios";


const Activity = ({setMode, mode}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const [courses, setCourses] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };
    const navigate = useNavigate();
    const HandleAddCourseInfo = (type) => {
        navigate('/Dashboard/newcourse', { state: { courseType: type, courses:courses } });
    }




    useEffect(() => {
        const getCourses = async () => {
            setLoading(true);
            var instructor_id = parseInt(localStorage.getItem("instructor_id"));
            const response = await axiosInstance.get(`${process.env.REACT_APP_ENDPOINT}/instructors/${instructor_id}/courses`);
            setCourses(response.data);
            setOnboarding(response.data.length);
            setLoading(false)
        }
        getCourses();

        const handleOutsideClick = (e) => {
            if (!e.target.closest('.dropdown')) {
                setIsOpen(false);
            }
        };

        document.addEventListener('click', handleOutsideClick);
        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, []);
    const handleSelect = (course) => {
        const selectedCourse = course;
        const activity = selectedCourse?.stages[selectedCourse.stages.length - 1].activities[selectedCourse.stages[selectedCourse.stages.length - 1].activities.length - 1];
        navigate('/Dashboard/gameDone', { state: { courseType: 'single', course: selectedCourse, activity: activity, courses:courses } });
    }
    // onboarding
    const [Onboarding, setOnboarding] = useState(null);
    const steps = Onboarding === 0  ?  [
        {
            content: <h2>Select course.</h2>,
            placement: 'left',
            target: '#selc',
            title: 'Select course'
        },
        {
            content: <h2>Select course single or journey you want create single stage course or multi satges course.</h2>,
            placement: 'left',
            target: '#selcet',
            title: 'Select course'
        },
        {
            content: <h2>Single stage course but you can add more than one activity.</h2>,
            placement: 'right',
            target: '#single',
            title: 'Create single stage'
        },
        {
            content: <h2>Joueney course this type of course you can add multiple stage in course also you can add Pdf, Image, Video, Audio and Url type activities.</h2>,
            placement: 'right',
            target: '#journey',
            title: 'Create Journey course'
        },
    ] : [
        {
            content: <h2>Select course.</h2>,
            placement: 'left',
            target: '#selc',
            title: 'Select course'
        },
        {
            content: <h2>Your current Courses are here.</h2>,
            placement: 'right',
            target: '#CurrentCourses',
            title: 'Current Courses'
        },
        {
            content: <h2>Select course single or journey you want create single stage course or multi satges course.</h2>,
            placement: 'left',
            target: '#selcet',
            title: 'Select course'
        },
        {
            content: <h2>Single stage course but you can add more than one activity.</h2>,
            placement: 'right',
            target: '#single',
            title: 'Create single stage'
        },
        {
            content: <h2>Joueney course this type of course you can add multiple stage in course also you can add Pdf, Image, Video, Audio and Url type activities.</h2>,
            placement: 'right',
            target: '#journey',
            title: 'Create Journey course'
        },
    ]
    const [{ run }, setState] = useState({ run: true, })
    

    return (
        <Card>
        <div className={`w-full ${mode === 'light' ? 'BodyBg' :'' }`}>
            <MetaTags title='Create New Course' description='Create New Course page--' />
            <div className={`flex relative px-5 py-2 mb-2  ${mode === 'light' ? 'ContentHeadingBg' :'' }`}>
                <h3 className="text-xl">Create New Activity</h3>
                <LoginHeading objects={setOnboarding} setMode={setMode}/>
            </div>
            <div className={`flex flex-wrap justify-center gap-5 m-7 contentSides ${mode === 'light' ? 'bg-white' :'' }`}>
            {!loading && Onboarding <= 1  &&<Joyride callback={() => { }} continuous showProgress
                    run={run} steps={steps} hideCloseButton scrollToFirstStep showSkipButton
                    styles={{
                        buttonNext: { backgroundColor: '#E5F2E3', borderRadius: '5px',color:'black', },
                        buttonBack: { backgroundColor: '#E5F2E3',  borderRadius: '5px', color:'black', }
                      }} />}
                <div id="selcet" className="flex-none">
                    {/* New courses Dropdown */}
                    <div className="relative inline-block text-left mt-20">
                        <img className="ml-10" src={NewCourseHeadIcon} alt="" />
                        {/* Dropdown button */}
                        <div className="group">
                            <button className={`p-3 flex ${mode === 'light' ? 'NewCourseBg' :'border rounded-xl w-48' }`}><img className="pr-3 " src={NewCourseIcon} alt="NewIcon" /><p className="mt-2   "><b>New</b> Course</p></button>
                            {/* Dropdown panel */}
                            <div className={`origin-top-right absolute right-0 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5  group-hover:visible ${!loading && Onboarding > 1 ? 'invisible' : 'visible'}`}>
                                <div className="py-1">
                                    {/* Option 1 */}
                                    <li onClick={() => HandleAddCourseInfo('single')}
                                        id="single" className="flex justify-center cursor-pointer block px-4 py-2 text-base text-gray-700 hover:bg-gray-100 hover:text-gray-900">
                                        Single Course
                                    </li>

                                    {/* Option 2 */}
                                    <li onClick={() => HandleAddCourseInfo('journey')}
                                        id="journey" className="flex justify-center cursor-pointer block px-4 py-2 text-base text-gray-700 hover:bg-gray-100 hover:text-gray-900">
                                        Journey Course
                                    </li>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*  Dropdown Menu of Current Course Button is Here */}
                <div id="CurrentCourses" className="flex-none relative inline-block text-left dropdown">
                    <div className="mt-20">
                        <img className="ml-10" src={CurrentCourseHeadIcon} alt="" />
                        <button
                            type="button"
                            className={`inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm  py-3 text-base  hover:bg-blue-200 ${mode === 'light' ? 'CurrentCourseBg' :'border rounded-xl w-48' }`}
                            onClick={handleToggle}>
                            <img className="pr-3" src={CurrentCourseIcon} alt="CurrentIcon" /><p className="mt-2"><b>Current</b> Courses</p>
                        </button>
                    </div>

                    {(isOpen || Onboarding == 1) && (
                        <div className="origin-top-right absolute right-0 overflow-y-auto max-h-64 w-full py-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                            {loading ? <div className="flex justify-center"><CircularProgress className="text-blue-800" /> </div>
                                : <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                                    {courses.length > 0 ? (
                                        courses.map((course, index) => (
                                            <li
                                                key={index}
                                                onClick={() => handleSelect(course)}
                                                className={`block px-4 py-2 text-base cursor-pointer ${selectedOption === course ? 'text-blue-200' : 'text-gray-700'} hover:bg-blue-200 hover:text-gray-900`}
                                                role="menuitem"
                                            >
                                                {course.name}
                                            </li>
                                        ))
                                    ) : (
                                        <p>No courses available</p>
                                    )}

                                </div>}

                        </div>
                    )}
                </div>
            </div>
        </div>
        </Card>
    )
};

export default Activity;