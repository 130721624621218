import React, { useEffect, useState } from 'react';
import '../../../style.css';
import IconGamizignWorld from '../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/The Gamizign World off icon.png'
// Normal State Sidebar Icons 
import BackIcon from '../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/Back icon panel.png'
import BackIconOff from '../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/Back icon panel off.png'
import AnalyticsIcon from '../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/Analytics panel icon.png'
import AnalyticsIconOff from '../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/Analytics panel icon off.png'
import HomeworkIcon from '../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/Homework panel icon.png'
import HomeworkIconOff from '../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/Homework panel icon off.png'
import SettingIcon from '../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/Settings panel icon.png'
import SettingIconOff from '../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/Settings panel icon off.png';
import ViewCourseIcon from '../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/View Course icon.png';
import ViewCourseIconOff from '../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/Test the Course icon.png';
import PublishCourseIcon from '../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/Publish Course icon.png';
import LogoPic from '../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/Gamizign-logo.png';
import { Link, Outlet, useLocation } from 'react-router-dom';
import axios from 'axios';
import axiosInstance from '../../../../../../axios';




const CourseBar = () => {
  const [dynamicUrl, setDynamicUrl] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get(`${process.env.REACT_APP_ENDPOINT}/webgl`);
        setDynamicUrl(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const course_id = queryParams.get('course_id');
  var instructor_id = parseInt(localStorage.getItem("instructor_id"));
  const [selectedTab, setSelectedTab] = useState(2);
  const [selectedTabName, setSelectedTabName] = useState('Analytics');
  const menuItems = [
    { id: 11, icon: IconGamizignWorld, iconOff: IconGamizignWorld, label: 'The Gamizign World', style: 'font-bold text-xl', link: '/Dashboard' },
    { id: 1, icon: BackIcon, iconOff: BackIconOff, label: 'Back', style: 'font-bold text-xl', link: '/Dashboard/Courses' },
    { id: 2, icon: AnalyticsIcon, iconOff: AnalyticsIconOff, label: 'Analytics', style: '', link: `/Course?course_id=${course_id}`, },
    { id: 3, icon: HomeworkIcon, iconOff: HomeworkIconOff, label: 'Homework', style: '', link: `/Course/HomeWork?course_id=${course_id}`, },
    // { id: 4, icon: SettingIcon, iconOff: SettingIconOff, label: 'Settings', style: '', link: `/Course/CourseSetting?course_id=${course_id}`, },
    { id: 5, icon: ViewCourseIcon, iconOff: ViewCourseIconOff, label: 'View Course', style: 'mx-8 bg-white mt-6', link: `/Course/ViewCourse?course_id=${course_id}`, },
    // { id: 6, icon: PublishCourseIcon, iconOff: PublishCourseIcon, label: 'View Gamizign Courses', style: 'mx-8 bg-white mt-2', targrte:'_blank', link: `${dynamicUrl?.url}?port=8000&id=${instructor_id}&ip=gamizign.co&secured=true`, },
  ];

  const [isNavOpen, setIsNavOpen] = useState(false);

  const handleTabClick = (tabId, tabLabel) => {
    setSelectedTab(tabId);
    setSelectedTabName(tabLabel);
  };
  return (
    <div>
      <div className="flex">
        {/* Sidebar Tabs*/}
        <div className="SIDEMENU flex-none FixedWidth256 hidden lg:block">
          <div className="SidbarBgColor textcolor">
            <div className="mb-8">
              {/* <div className='flex py-2 px-4'><img className='mr-2' src={IconGamizignWorld} alt="" /><h3 className='font-bold text-xl'>The Gamizign World</h3>
              </div> */}
              {menuItems.map((item) => (
                <div className={item.style}>
                  <Link to={item.link} target={item.targrte}> <div
                    key={item.id}
                    className={`flex items-center py-2 px-4 border cursor-pointer hover:bg-blue-100 ${selectedTab === item.id ? 'SlectTabColor' : ''}`}
                    onClick={() => handleTabClick(item.id, item.label)}>
                    <div className="mr-2"><img src={selectedTab === item.id ? item.iconOff : item.icon} alt="" /></div>
                    <span>{item.label}</span>
                  </div>
                  </Link>
                </div>
              ))}
            </div>
            <div className=" justify-center items-center">
              {/*Sidebar Footer */}
              <div className='pb-7'>
                <span className='flex justify-center'><img src={LogoPic} alt="Logo" className="w-20 h-20 mr-2" /></span>
                <Link className='flex justify-center' to='https://gamizign.com/privacy-policy/' ><p >Privacy Policy & Terms of Use Subscription Agreement</p></Link>
              </div>

            </div>
          </div>
          {/* Content Area of Sidebar Tabs*/}
          {/* <div className="flex-grow">
        <div className='px-5 py-2 mb-2 ContentHeadingBg'><h3 className="text-xl  ">{selectedTabName}</h3></div>
        <div className="flex  m-7 ContentAreaBg">
          {headerOptions[selectedTab] &&
            headerOptions[selectedTab].map((option, index) => (
              <div key={index} className="px-4 py-2 rounded">
                {option}
              </div>
            ))}
        </div>
      </div> */}
        </div>
        <div className='MAINMENU flex-1'>
          <Outlet></Outlet>
        </div>
        <div className="HAMBURGER-ICON lg:hidden absolute top-0 right-0 px-3 py-3" onClick={() => setIsNavOpen((prev) => !prev)}>
          <span className="block h-0.5 w-8 bg-gray-600"></span>
          <span className="block h-2 w-8"></span>
          <span className="block h-0.5 w-8 bg-gray-600"></span>
          <span className="block h-2 w-8"></span>
          <span className="block h-0.5 w-8 bg-gray-600"></span>
        </div>
        <div className="MOBILE-MENU">
          <div className={isNavOpen ? "showMenuNav" : "hideMenuNav"}>
            <div className="CLOSE-ICON absolute top-0 right-0 px-3 py-2" onClick={() => setIsNavOpen(false)}>
              <svg
                className="h-8 w-8 text-gray-600"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <line x1="18" y1="6" x2="6" y2="18" />
                <line x1="6" y1="6" x2="18" y2="18" />
              </svg>
            </div>
            <div className="MENU-ITEMS absolute top-10 w-full">
              {menuItems.map((item) => (
                <div className={item.style}>
                  <Link to={item.link}> <div
                    key={item.id}
                    className={`flex items-center py-2 px-4 border cursor-pointer hover:bg-blue-100 ${selectedTab === item.id ? 'SlectTabColor' : ''}`}
                    onClick={() => {
                      handleTabClick(item.id, item.label);
                      setIsNavOpen(false);
                    }}>
                    <div className="mr-2"><img src={selectedTab === item.id ? item.iconOff : item.icon} alt="" /></div>
                    <span>{item.label}</span>
                  </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
          <style>{`
                .hideMenuNav {
                  display: none;
                }
                .showMenuNav {
                  display: block;
                  position: absolute;
                  width: 100%;
                  height: 100vh;
                  top: 0;
                  left: 0;
                  background: white;
                  z-index: 10;
                  display: flex;
                  flex-direction: column;
                  justify-content: space-evenly;
                  align-items: center;
                }
            `}</style>
        </div>
      </div>
    </div>
  );
};

export default CourseBar;

