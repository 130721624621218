import './App.css';
import './index.css';

import Dashboard from './components/pages/sidebar/sidebarMenu';
import SignInPage from './components/pages/signin/signin';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import NewCourse from './components/pages/sidebar/sidbarOptionsContent/Activity/Newcourse/newCourse';
import Activity from './components/pages/sidebar/sidbarOptionsContent/Activity/activity';
import Adding from './components/pages/sidebar/sidbarOptionsContent/Activity/Newcourse/adding';
import QuestionList from './components/pages/sidebar/sidbarOptionsContent/QuestionList/questionList';
import Gamizign from './components/pages/sidebar/sidbarOptionsContent/Gamizign/gamizign';
import Insights from './components/pages/sidebar/sidbarOptionsContent/Insights/insights';
import Instructors from './components/pages/sidebar/sidbarOptionsContent/Instructors/instructors';
import LearnerList from './components/pages/sidebar/sidbarOptionsContent/LearnerList/learnerList';
import Users from './components/pages/sidebar/sidbarOptionsContent/Users/users';
import Rewards from './components/pages/sidebar/sidbarOptionsContent/Rewards/rewards';
import Settings from './components/pages/sidebar/sidbarOptionsContent/Settings/settings';
import Help from './components/pages/sidebar/sidbarOptionsContent/Help/help';
import CreatingPptx from './components/pages/sidebar/sidbarOptionsContent/Activity/Newcourse/CreatingPptx';
import CreatingPdf from './components/pages/sidebar/sidbarOptionsContent/Activity/Newcourse/CreatingPdf';
import Customizing from './components/pages/sidebar/sidbarOptionsContent/Activity/Newcourse/customizing';
import SavingQuestions from './components/pages/sidebar/sidbarOptionsContent/Activity/Newcourse/Saving/savingQuestion';
import ChooseGame from './components/pages/sidebar/sidbarOptionsContent/Activity/Newcourse/Saving/chooseGame';
import GameDone from './components/pages/sidebar/sidbarOptionsContent/Activity/Newcourse/Saving/gameDone';
// Patch 3
import AddingActivityType from './components/pages/sidebar/sidbarOptionsContent/Activity/JourneyActivity/activityType';
import MultiActivy from './components/pages/sidebar/sidbarOptionsContent/Activity/JourneyActivity/MultiActivity/mutiActivity';
import QuestionListEdit from './components/pages/sidebar/sidbarOptionsContent/QuestionList/components/EditQuestionList';
import EditReferencedQList from './components/pages/sidebar/sidbarOptionsContent/QuestionList/editReferencedQList';
import PdfDownloadQuestionList from './components/pages/sidebar/sidbarOptionsContent/QuestionList/components/PdfDownloadQuestionList';
import CourseBar from './components/pages/sidebar/sidbarOptionsContent/Gamizign/Components/CourseBar';
import CourseAnalytics from './components/pages/sidebar/sidbarOptionsContent/Gamizign/Components/Analytics';
import HomeWork from './components/pages/sidebar/sidbarOptionsContent/Gamizign/Components/HomeWork/HomeWork';
import CourseSetting from './components/pages/sidebar/sidbarOptionsContent/Gamizign/Components/CourseSetting';
import ViewCourse from './components/pages/sidebar/sidbarOptionsContent/Gamizign/Components/ViewCourse';
import PublishCourse from './components/pages/sidebar/sidbarOptionsContent/Gamizign/Components/PublishCourse';
import GamizignWorld from './components/pages/sidebar/sidbarOptionsContent/The Gamizign World/gamizignWorld';
import ActivityEvaluation from './components/pages/sidebar/sidbarOptionsContent/Gamizign/Components/HomeWork/ActivityEva';
import LearnerEva from './components/pages/sidebar/sidbarOptionsContent/Gamizign/Components/HomeWork/LearnerEva';
import Admin from './components/Admin';
import { ThemeProvider } from '@emotion/react';
import { createTheme } from '@mui/material/styles'; 
import RouteProtection from './components/RouteProtection/RouteProtection';
import AdminRouteProtection from './components/RouteProtection/AdminRouteProtection';
import { HelmetProvider } from 'react-helmet-async';
import { useState } from 'react';

function App() {
  const getInitialElement = () => {
    const user_type = localStorage.getItem("user_type");
    const id = localStorage.getItem("instructor_id");
    return user_type === 'Instructor' ? <div className='flex'><Dashboard /><GamizignWorld /></div> : user_type === 'Admin' ? <Admin/> :<SignInPage />;
    // return id === null ? <SignInPage /> : <Dashboard />;
  };
  const [mode, setMode] = useState('light');
  const theme = mode === 'light' ? createTheme({
    typography: {
      allVariants: {
        fontFamily: 'Calibri',
        textTransform: 'none',
        fontSize: 16,
      },
    },
  }):createTheme({
    typography: {
      allVariants: {
        fontFamily: 'Calibri',
        textTransform: 'none',
        fontSize: 16,
      },
    },
    palette: {
      mode: 'dark',
      primary: {
        main: '#ff5252',
      },
    },
  });
  
  let router = createBrowserRouter([
    {
      path: "/",
      element: getInitialElement()
    },
    {
      path: "/Dashboard",
      element: <RouteProtection />,
      children: [
        {
          path: "/Dashboard/",
          element: <Dashboard />,
          children: [
            {path: "/Dashboard/", element: <GamizignWorld setMode={setMode} mode={mode} />},
            {path: "/Dashboard/CreateCourse", element: <Activity setMode={setMode} mode={mode} />},
            // Tabs routes here
            {path: "/Dashboard/questionList", element: <QuestionList setMode={setMode} mode={mode} />},
            {path: "/Dashboard/refQuestionList", element: <EditReferencedQList setMode={setMode} mode={mode} />},
            {path: "/Dashboard/Courses", element: <Gamizign setMode={setMode} mode={mode} />},
            {path: "/Dashboard/insights", element: <Insights setMode={setMode} mode={mode} />},
            {path: "/Dashboard/instructors", element: <Instructors />},
            {path: "/Dashboard/learnerList", element: <LearnerList />},
            {path: "/Dashboard/users", element: <Users />},
            {path: "/Dashboard/rewards", element: <Rewards />},
            {path: "/Dashboard/settings", element: <Settings />},
            {path: "/Dashboard/help", element: <Help />},
            // Create New Activity 
            {path: "/Dashboard/newcourse", element: <NewCourse setMode={setMode} mode={mode} />},
            {path: "/Dashboard/adding", element: <Adding setMode={setMode} mode={mode} />},
            {path: "/Dashboard/creatingPptx", element: <CreatingPptx setMode={setMode} mode={mode} />},
            {path: "/Dashboard/creatingPdf", element: <CreatingPdf setMode={setMode} mode={mode} />},
            {path: "/Dashboard/customizing", element: <Customizing setMode={setMode} mode={mode} />},
            {path: "/Dashboard/saveQuestions", element: <SavingQuestions setMode={setMode} mode={mode} />},
            {path: "/Dashboard/chooseGame", element: <ChooseGame setMode={setMode} mode={mode} />},
            {path: "/Dashboard/gameDone", element: <GameDone setMode={setMode} mode={mode} />},
            // Patch 3 Journey course
            {path: "/Dashboard/activityType", element: <AddingActivityType setMode={setMode} mode={mode} />},
            // Multi Activity Journey course
              {path: "/Dashboard/multiActivities", element: <MultiActivy setMode={setMode} mode={mode} />},
            // Question List 
            {path: "/Dashboard/DownloadQuestionsList", element: <PdfDownloadQuestionList setMode={setMode} mode={mode} />},
            {path: "/Dashboard/EditQuestionsList", element: <QuestionListEdit setMode={setMode} mode={mode} />},
    
          ]
        },
      ]},

    
    // Gamizign your courses
    {
      path: "/Course",
      element: <RouteProtection />, // Wrap the parent route with RouteProtection
      children: [
        {
          path: "/Course/",
          element: <CourseBar />,
          children: [
            {path: "/Course/", element: <CourseAnalytics setMode={setMode} mode={mode} />,},
            {path: "/Course/HomeWork", element: <HomeWork setMode={setMode} mode={mode} />,},
            {path: "/Course/CourseSetting", element: <CourseSetting setMode={setMode} mode={mode} />,},
            {path: "/Course/ViewCourse", element: <ViewCourse setMode={setMode} mode={mode} />,},
            {path: "/Course/PublishCourse", element: <PublishCourse />,},
            {path: "/Course/ActivityEvaluation", element: <ActivityEvaluation setMode={setMode} mode={mode} />,},
            {path: "/Course/LearnerEvaluation", element: <LearnerEva setMode={setMode} mode={mode} />,},
          ]
        },
      ]},
  
    // Admin Client routing
    {
      path: "/Admin",
      element:<AdminRouteProtection/>,
      children:[
        {
          path: "/Admin",
          element:<Admin setMode={setMode} mode={mode}/>
        },
      ]
    },
    
  ])
  return (
    <ThemeProvider theme={theme}>
      <div>
      <HelmetProvider>
        <RouterProvider router={router} />
        </HelmetProvider>
        {/* <SignInPage/>  */}
      </div>
    </ThemeProvider>
  );
}

export default App;
