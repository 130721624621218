import React, { useState } from "react";
import userIcon from '../../assets/icons/userIcon.png'
import { Button, Dialog, DialogContent, Alert, Tooltip, } from '@mui/material';
import HelpIcon from '../../assets/icons/helpIcon.png'


const AdminLoginHeading = ({objects, setMode}) => {
    var adminName = localStorage.getItem("adminName");
    var adminEmail = localStorage.getItem("adminEmail");

    const [showDropdown, setShowDropdown] = useState(false);
    const [IsLogout, setIsLogout] = useState(false);

    const HandllogoutCancel = () =>{
        setIsLogout(false);
    };
    const logout = () =>{
        localStorage.clear(); window.location.href = "/";
        setIsLogout(false);
    };

    return (
        <div>
            <div className="text-xl absolute  right-5 lg:right-5 flex">
               <div className="hidden sm:block text-gray-700"> {adminEmail}
                ({adminName})</div>
                <div className="relative">
                    {/*  */}
                    <Dialog className="lg:ms-56" open={IsLogout} onClose={HandllogoutCancel}>
                    <DialogContent>
                        <Alert
                            severity= 'warning'
                            >
                                Are you sure you want to logout?
                        </Alert>
                    </DialogContent>
                        <div className='flex justify-center'>
                            <Button onClick={logout} color="primary">
                                Yes
                            </Button>
                            <Button onClick={HandllogoutCancel} color="primary">
                                No
                            </Button>
                        </div>
                </Dialog>
                    {/* Button with dropdown toggle */}
                    <button
                        className="flex items-center justify-center w-10 h-10 -mt-1 hover:bg-gray-300 rounded-full focus:outline-none"
                        onMouseEnter={() => setShowDropdown(true)}
                        onMouseLeave={() => setShowDropdown(false)}
                    >
                        <img width={30} src={userIcon} alt="" />
                    </button>

                    {/* Dropdown menu */}
                    {showDropdown && (
                        <div className="absolute text-black right-0 z-10 w-40 py-2 bg-white border border-gray-200 rounded shadow-lg top-full" onMouseEnter={() => setShowDropdown(true)} onMouseLeave={() => setShowDropdown(false)}>
                            {/* Option 1 */}
                            <button className="flex items-center w-full px-4 py-2 hover:bg-gray-100 focus:outline-none">
                                Profile
                            </button>

                            {/* Option 2 */}
                            <button className="flex items-center w-full px-4 py-2 hover:bg-gray-100 focus:outline-none">

                                Settings
                            </button>

                            {/* Option 3 */}
                            <button className="flex items-center w-full px-4 py-2 hover:bg-gray-100 focus:outline-none"
                                onClick={() => {setIsLogout(true)}}>
                                Logout
                            </button>
                        </div>
                    )}
                </div>
                <Tooltip title='Help'><button type="button" className="cursor-pointer flex items-center justify-center w-8 h-8 hover:bg-gray-300 rounded-full"
                 onClick={()=>objects((prevImage) => (prevImage === 1 ? 5 : 1))}>
                    <img width={30} src={HelpIcon} alt="help" /></button></Tooltip>
                    {/* <Tooltip title='ViewMode'><button type="button" className="cursor-pointer flex items-center justify-center h-8 hover:bg-gray-300 rounded-full"
                 onClick={()=>setMode((prevImage) => (prevImage === 'light' ? 'dark' : 'light'))}>
                    View</button></Tooltip> */}
            </div>
        </div>
    )
};
export default AdminLoginHeading;
