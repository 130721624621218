import React, { useEffect } from "react";
import { Tooltip } from '@mui/material';
import CourseLock from '../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/course locked icon.png'
import CourseUnLock from '../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/course open icon.png'
import CourseActive from '../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/course active icon.png'
import CourseNotActive from '../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/course not active icon.png'
import LearnerTotal from '../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/total learners number icon.png'
import LearnerDone from '../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/learners done number icon.png'
import EditQuestoin from '../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/edit icon.png'
import DeletQuestoin from '../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/delete icon.png'
import CourseDoneIcon from '../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/course done icon.png'
import { Link } from "react-router-dom";


const GroupeCourses = ({ filteredCourses, setCourseName, openBotModal, DeleteDialogOpen, setCourseIndex, setCourseStatus, getUniqueStudentCount, handleHideCourse, setCourseId }) => {
    // Initialize arrays for each mainTopic
    const languageCourses = [];
    const Unknown = [];
    const mathCourses = [];
    const scienceCourses = [];
    const socialCourses = [];
    const NotopicCourses = [];

    // Iterate through filteredCourses and sort into respective arrays
    if (filteredCourses && filteredCourses.length > 0) {
        filteredCourses.forEach(course => {
            switch (course.mainTopic) {
                case 'Language Arts':
                    languageCourses.push(course);
                    break;
                case 'Math':
                    mathCourses.push(course);
                    break;
                case 'Science':
                    scienceCourses.push(course);
                    break;
                case 'Social':
                    socialCourses.push(course);
                    break;
                default:
                    Unknown.push(course)
                    // Handle case if mainTopic does not match any expected value
                    console.warn(`Unexpected mainTopic: ${course.mainTopic}`);
                    break;
            }
        });
    }
    const ActivityCourseColors = ['#DBE6F5', '#E5F2E3', '#F5F9E6', '#FEFAE2', '#FEF7E2', '#FEF1DD', '#FDE4DC', '#EFE1EE'];

    return (
        <div>
            {/* Language Arts Courses */}
            <div>
                <p className="border-b-2 mx-7 px-2 text-2xl flex justify-center sm:justify-start font-bold">Language arts</p>
                <div className="grid grid-cols-1 sm:grid sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-5 gap-5 2xl:px-40 sm:px-5 px-5 mt-5">
                    {languageCourses.length > 0 ? (
                        languageCourses.map((course, CourseIndex) => (
                            <div key={CourseIndex}>
                                {course.view ? (
                                    <div>
                                        <p className="flex justify-center pt-20 pb-3 sm:text-sm"><b>{course.name}</b></p>
                                        <p className="flex justify-center"><Tooltip title="Show Course"><img className="cursor-pointer" onClick={() => { setCourseName(course.name); handleHideCourse(CourseIndex) }} src={course.view ? CourseNotActive : CourseActive} alt="" /></Tooltip></p>
                                    </div>
                                ) : (
                                    <div>
                                        <div className={`border relative ${course.view ? 'hidden' : ''}`} style={{ borderRadius: '20px', height: "250px", backgroundColor: ActivityCourseColors[CourseIndex % ActivityCourseColors.length] }}>
                                            <div className="" style={{
                                                backgroundImage: course.background ? `url(${process.env.REACT_APP_ENDPOINT}/uploads/${course.background})` : 'none',
                                                backgroundSize: 'cover', borderRadius: '20px', height: "200px", backgroundPosition: 'center', backgroundRepeat: 'no-repeat',
                                            }}></div>
                                            <div className="bg-slate-600 text-white  absolute inset-x-0 bottom-0 shadow-xl" style={{ borderRadius: '20px', height: "" }}>
                                                <div className="flex justify-center" >
                                                    <Tooltip title={getUniqueStudentCount(course) === course.students.length ? 'All Players Played' : course.unlock ? 'Lock Course' : 'Unlock Course'}><img className="cursor-pointer" onClick={() => { openBotModal(); setCourseName(course.name); setCourseIndex(CourseIndex); setCourseStatus(course.unlock) }}
                                                        src={getUniqueStudentCount(course) === course.students.length ? CourseDoneIcon : course.unlock ? CourseUnLock : CourseLock} alt="" style={{ marginTop: "-25px" }} /></Tooltip>
                                                </div>
                                                <Tooltip title="Analysis"><Link to={`/Course?course_id=${course.course_id}`}>
                                                    <p className="flex justify-center pt-5 pb-3 sm:text-sm text-center"><b>{course.name}</b></p>
                                                    <p className="flex justify-center mt-3 sm:text-sm"></p>
                                                </Link></Tooltip>
                                            </div>
                                        </div>
                                        <div className="flex gap-9 justify-center mt-2 border py-2" style={{ borderRadius: "10px" }}>
                                            <Tooltip title="Hide Course"><img className="cursor-pointer" onClick={() => { setCourseName(course.name); handleHideCourse(CourseIndex) }} src={course.view ? CourseNotActive : CourseActive} alt="" /></Tooltip>
                                            <Tooltip title="Edit"><img src={EditQuestoin} alt="" /></Tooltip>
                                            <Tooltip title="Delete"><img className="cursor-pointer" src={DeletQuestoin} alt="" onClick={() => { DeleteDialogOpen('course'); setCourseName(course.name); setCourseId(course.course_id) }} /></Tooltip>
                                        </div>
                                        <div className="flex gap-5 justify-center border py-2" style={{ borderRadius: "10px" }}>
                                            <Tooltip title="Players"><span className="relative"><img src={LearnerTotal} alt="" /><span className={`absolute inset-0 top-0.5 ${getUniqueStudentCount(course) <= 9 ? 'left-2.5' : 'left-1'}`}>{getUniqueStudentCount(course)}</span> </span></Tooltip>
                                            <p>Learners</p>
                                            <Tooltip title="Enrolled Students"><span className="relative"><img src={LearnerDone} alt="" /><span className={`absolute inset-0 top-0.5 ${course.students.length <= 9 ? 'left-2.5' : 'left-1'}`}>
                                                {course.students.length}
                                            </span> </span></Tooltip>
                                        </div>
                                    </div>)}
                            </div>
                        ))
                    ) : (
                        <div className="text-gray-500">No courses available in this subject.</div>
                    )}
                </div>
            </div>
            {/* Math Courses */}
            <div>
                <p className="border-b-2 mx-7 px-2 text-2xl pt-10 flex justify-center sm:justify-start font-bold">Math</p>
                <div className="grid grid-cols-1 sm:grid sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-5 gap-5 2xl:px-40 sm:px-5 px-5 mt-5">
                    {mathCourses.length > 0 ? (
                        mathCourses.map((course, CourseIndex) => (
                            <div key={CourseIndex}>
                                {course.view ? (
                                    <div>
                                        <p className="flex justify-center pt-20 pb-3 sm:text-sm"><b>{course.name}</b></p>
                                        <p className="flex justify-center"><Tooltip title="Show Course"><img className="cursor-pointer" onClick={() => { setCourseName(course.name); handleHideCourse(CourseIndex) }} src={course.view ? CourseNotActive : CourseActive} alt="" /></Tooltip></p>
                                    </div>
                                ) : (
                                    <div>
                                        <div className={`border relative ${course.view ? 'hidden' : ''}`} style={{ borderRadius: '20px', height: "250px", backgroundColor: ActivityCourseColors[CourseIndex % ActivityCourseColors.length] }}>
                                            <div className="" style={{
                                                backgroundImage: course.background ? `url(${process.env.REACT_APP_ENDPOINT}/uploads/${course.background})` : 'none',
                                                backgroundSize: 'cover', borderRadius: '20px', height: "200px", backgroundPosition: 'center', backgroundRepeat: 'no-repeat',
                                            }}></div>
                                            <div className="bg-slate-600 text-white  absolute inset-x-0 bottom-0 shadow-xl" style={{ borderRadius: '20px', height: "" }}>
                                                <div className="flex justify-center" >
                                                    <Tooltip title={getUniqueStudentCount(course) === course.students.length ? 'All Players Played' : course.unlock ? 'Lock Course' : 'Unlock Course'}><img className="cursor-pointer" onClick={() => { openBotModal(); setCourseName(course.name); setCourseIndex(CourseIndex); setCourseStatus(course.unlock) }}
                                                        src={getUniqueStudentCount(course) === course.students.length ? CourseDoneIcon : course.unlock ? CourseUnLock : CourseLock} alt="" style={{ marginTop: "-25px" }} /></Tooltip>
                                                </div>
                                                <Tooltip title="Analysis"><Link to={`/Course?course_id=${course.course_id}`}>
                                                    <p className="flex justify-center pt-5 pb-3 sm:text-sm text-center"><b>{course.name}</b></p>
                                                    <p className="flex justify-center mt-3 sm:text-sm"></p>
                                                </Link></Tooltip>
                                            </div>
                                        </div>
                                        <div className="flex gap-9 justify-center mt-2 border py-2" style={{ borderRadius: "10px" }}>
                                            <Tooltip title="Hide Course"><img className="cursor-pointer" onClick={() => { setCourseName(course.name); handleHideCourse(CourseIndex) }} src={course.view ? CourseNotActive : CourseActive} alt="" /></Tooltip>
                                            <Tooltip title="Edit"><img src={EditQuestoin} alt="" /></Tooltip>
                                            <Tooltip title="Delete"><img className="cursor-pointer" src={DeletQuestoin} alt="" onClick={() => { DeleteDialogOpen('course'); setCourseName(course.name); setCourseId(course.course_id) }} /></Tooltip>
                                        </div>
                                        <div className="flex gap-5 justify-center border py-2" style={{ borderRadius: "10px" }}>
                                            <Tooltip title="Players"><span className="relative"><img src={LearnerTotal} alt="" /><span className={`absolute inset-0 top-0.5 ${getUniqueStudentCount(course) <= 9 ? 'left-2.5' : 'left-1'}`}>{getUniqueStudentCount(course)}</span> </span></Tooltip>
                                            <p>Learners</p>
                                            <Tooltip title="Enrolled Students"><span className="relative"><img src={LearnerDone} alt="" /><span className={`absolute inset-0 top-0.5 ${course.students.length <= 9 ? 'left-2.5' : 'left-1'}`}>
                                                {course.students.length}
                                            </span> </span></Tooltip>
                                        </div>
                                    </div>)}
                            </div>
                        ))
                    ) : (
                        <div className="text-gray-500">No courses available in this subject.</div>
                    )}
                </div>
            </div>
            {/* Science Courses */}
            <div>
                <p className="border-b-2 mx-7 px-2 text-2xl pt-10 flex justify-center sm:justify-start font-bold">Science</p>
                <div className="grid grid-cols-1 sm:grid sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-5 gap-5 2xl:px-40 sm:px-5 px-5 mt-5">
                    {scienceCourses.length > 0 ? (
                        scienceCourses.map((course, CourseIndex) => (
                            <div key={CourseIndex}>
                                {course.view ? (
                                    <div>
                                        <p className="flex justify-center pt-20 pb-3 sm:text-sm"><b>{course.name}</b></p>
                                        <p className="flex justify-center"><Tooltip title="Show Course"><img className="cursor-pointer" onClick={() => { setCourseName(course.name); handleHideCourse(CourseIndex) }} src={course.view ? CourseNotActive : CourseActive} alt="" /></Tooltip></p>
                                    </div>
                                ) : (
                                    <div>
                                        <div className={`border relative ${course.view ? 'hidden' : ''}`} style={{ borderRadius: '20px', height: "250px", backgroundColor: ActivityCourseColors[CourseIndex % ActivityCourseColors.length] }}>
                                            <div className="" style={{
                                                backgroundImage: course.background ? `url(${process.env.REACT_APP_ENDPOINT}/uploads/${course.background})` : 'none',
                                                backgroundSize: 'cover', borderRadius: '20px', height: "200px", backgroundPosition: 'center', backgroundRepeat: 'no-repeat',
                                            }}></div>
                                            <div className="bg-slate-600 text-white  absolute inset-x-0 bottom-0 shadow-xl" style={{ borderRadius: '20px', height: "" }}>
                                                <div className="flex justify-center" >
                                                    <Tooltip title={getUniqueStudentCount(course) === course.students.length ? 'All Players Played' : course.unlock ? 'Lock Course' : 'Unlock Course'}><img className="cursor-pointer" onClick={() => { openBotModal(); setCourseName(course.name); setCourseIndex(CourseIndex); setCourseStatus(course.unlock) }}
                                                        src={getUniqueStudentCount(course) === course.students.length ? CourseDoneIcon : course.unlock ? CourseUnLock : CourseLock} alt="" style={{ marginTop: "-25px" }} /></Tooltip>
                                                </div>
                                                <Tooltip title="Analysis"><Link to={`/Course?course_id=${course.course_id}`}>
                                                    <p className="flex justify-center pt-5 pb-3 sm:text-sm text-center"><b>{course.name}</b></p>
                                                    <p className="flex justify-center mt-3 sm:text-sm"></p>
                                                </Link></Tooltip>
                                            </div>
                                        </div>
                                        <div className="flex gap-9 justify-center mt-2 border py-2" style={{ borderRadius: "10px" }}>
                                            <Tooltip title="Hide Course"><img className="cursor-pointer" onClick={() => { setCourseName(course.name); handleHideCourse(CourseIndex) }} src={course.view ? CourseNotActive : CourseActive} alt="" /></Tooltip>
                                            <Tooltip title="Edit"><img src={EditQuestoin} alt="" /></Tooltip>
                                            <Tooltip title="Delete"><img className="cursor-pointer" src={DeletQuestoin} alt="" onClick={() => { DeleteDialogOpen('course'); setCourseName(course.name); setCourseId(course.course_id) }} /></Tooltip>
                                        </div>
                                        <div className="flex gap-5 justify-center border py-2" style={{ borderRadius: "10px" }}>
                                            <Tooltip title="Players"><span className="relative"><img src={LearnerTotal} alt="" /><span className={`absolute inset-0 top-0.5 ${getUniqueStudentCount(course) <= 9 ? 'left-2.5' : 'left-1'}`}>{getUniqueStudentCount(course)}</span> </span></Tooltip>
                                            <p>Learners</p>
                                            <Tooltip title="Enrolled Students"><span className="relative"><img src={LearnerDone} alt="" /><span className={`absolute inset-0 top-0.5 ${course.students.length <= 9 ? 'left-2.5' : 'left-1'}`}>
                                                {course.students.length}
                                            </span> </span></Tooltip>
                                        </div>
                                    </div>)}
                            </div>
                        ))
                    ) : (
                        <div className="text-gray-500">No courses available in this subject.</div>
                    )}
                </div>
            </div>
            {/* Social Study Courses */}
            <div>
                <p className="border-b-2 mx-7 px-2 text-2xl pt-10 flex justify-center sm:justify-start font-bold">Social</p>
                <div className="grid grid-cols-1 sm:grid sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-5 gap-5 2xl:px-40 sm:px-5 px-5 mt-5">
                    {socialCourses.length > 0 ? (
                        socialCourses.map((course, CourseIndex) => (
                            <div key={CourseIndex}>
                                {course.view ? (
                                    <div>
                                        <p className="flex justify-center pt-20 pb-3 sm:text-sm"><b>{course.name}</b></p>
                                        <p className="flex justify-center"><Tooltip title="Show Course"><img className="cursor-pointer" onClick={() => { setCourseName(course.name); handleHideCourse(CourseIndex) }} src={course.view ? CourseNotActive : CourseActive} alt="" /></Tooltip></p>
                                    </div>
                                ) : (
                                    <div>
                                        <div className={`border relative ${course.view ? 'hidden' : ''}`} style={{ borderRadius: '20px', height: "250px", backgroundColor: ActivityCourseColors[CourseIndex % ActivityCourseColors.length] }}>
                                            <div className="" style={{
                                                backgroundImage: course.background ? `url(${process.env.REACT_APP_ENDPOINT}/uploads/${course.background})` : 'none',
                                                backgroundSize: 'cover', borderRadius: '20px', height: "200px", backgroundPosition: 'center', backgroundRepeat: 'no-repeat',
                                            }}></div>
                                            <div className="bg-slate-600 text-white  absolute inset-x-0 bottom-0 shadow-xl" style={{ borderRadius: '20px', height: "" }}>
                                                <div className="flex justify-center" >
                                                    <Tooltip title={getUniqueStudentCount(course) === course.students.length ? 'All Players Played' : course.unlock ? 'Lock Course' : 'Unlock Course'}><img className="cursor-pointer" onClick={() => { openBotModal(); setCourseName(course.name); setCourseIndex(CourseIndex); setCourseStatus(course.unlock) }}
                                                        src={getUniqueStudentCount(course) === course.students.length ? CourseDoneIcon : course.unlock ? CourseUnLock : CourseLock} alt="" style={{ marginTop: "-25px" }} /></Tooltip>
                                                </div>
                                                <Tooltip title="Analysis"><Link to={`/Course?course_id=${course.course_id}`}>
                                                    <p className="flex justify-center pt-5 pb-3 sm:text-sm text-center"><b>{course.name}</b></p>
                                                    <p className="flex justify-center mt-3 sm:text-sm"></p>
                                                </Link></Tooltip>
                                            </div>
                                        </div>
                                        <div className="flex gap-9 justify-center mt-2 border py-2" style={{ borderRadius: "10px" }}>
                                            <Tooltip title="Hide Course"><img className="cursor-pointer" onClick={() => { setCourseName(course.name); handleHideCourse(CourseIndex) }} src={course.view ? CourseNotActive : CourseActive} alt="" /></Tooltip>
                                            <Tooltip title="Edit"><img src={EditQuestoin} alt="" /></Tooltip>
                                            <Tooltip title="Delete"><img className="cursor-pointer" src={DeletQuestoin} alt="" onClick={() => { DeleteDialogOpen('course'); setCourseName(course.name); setCourseId(course.course_id) }} /></Tooltip>
                                        </div>
                                        <div className="flex gap-5 justify-center border py-2" style={{ borderRadius: "10px" }}>
                                            <Tooltip title="Players"><span className="relative"><img src={LearnerTotal} alt="" /><span className={`absolute inset-0 top-0.5 ${getUniqueStudentCount(course) <= 9 ? 'left-2.5' : 'left-1'}`}>{getUniqueStudentCount(course)}</span> </span></Tooltip>
                                            <p>Learners</p>
                                            <Tooltip title="Enrolled Students"><span className="relative"><img src={LearnerDone} alt="" /><span className={`absolute inset-0 top-0.5 ${course.students.length <= 9 ? 'left-2.5' : 'left-1'}`}>
                                                {course.students.length}
                                            </span> </span></Tooltip>
                                        </div>
                                    </div>)}
                            </div>
                        ))
                    ) : (
                        <div className="text-gray-500">No courses available in this subject.</div>
                    )}
                </div>
            </div>
            {/* Without topic Courses */}
            <div>
                <p className="border-b-2 mx-7 px-2 text-2xl pt-10 flex justify-center sm:justify-start font-bold">No topic courses</p>
                <div className="grid grid-cols-1 sm:grid sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-5 gap-5 2xl:px-40 sm:px-5 px-5 mt-5">
                    {Unknown.length > 0 ? (
                        Unknown.map((course, CourseIndex) => (
                            <div key={CourseIndex}>
                                {course.view ? (
                                    <div>
                                        <p className="flex justify-center pt-20 pb-3 sm:text-sm"><b>{course.name}</b></p>
                                        <p className="flex justify-center"><Tooltip title="Show Course"><img className="cursor-pointer" onClick={() => { setCourseName(course.name); handleHideCourse(CourseIndex) }} src={course.view ? CourseNotActive : CourseActive} alt="" /></Tooltip></p>
                                    </div>
                                ) : (
                                    <div>
                                        <div className={`border relative ${course.view ? 'hidden' : ''}`} style={{ borderRadius: '20px', height: "250px", backgroundColor: ActivityCourseColors[CourseIndex % ActivityCourseColors.length] }}>
                                            <div className="" style={{
                                                backgroundImage: course.background ? `url(${process.env.REACT_APP_ENDPOINT}/uploads/${course.background})` : 'none',
                                                backgroundSize: 'cover', borderRadius: '20px', height: "200px", backgroundPosition: 'center', backgroundRepeat: 'no-repeat',
                                            }}></div>
                                            <div className="bg-slate-600 text-white  absolute inset-x-0 bottom-0 shadow-xl" style={{ borderRadius: '20px', height: "" }}>
                                                <div className="flex justify-center" >
                                                    <Tooltip title={getUniqueStudentCount(course) === course.students.length ? 'All Players Played' : course.unlock ? 'Lock Course' : 'Unlock Course'}><img className="cursor-pointer" onClick={() => { openBotModal(); setCourseName(course.name); setCourseIndex(CourseIndex); setCourseStatus(course.unlock) }}
                                                        src={getUniqueStudentCount(course) === course.students.length ? CourseDoneIcon : course.unlock ? CourseUnLock : CourseLock} alt="" style={{ marginTop: "-25px" }} /></Tooltip>
                                                </div>
                                                <Tooltip title="Analysis"><Link to={`/Course?course_id=${course.course_id}`}>
                                                    <p className="flex justify-center pt-5 pb-3 sm:text-sm text-center"><b>{course.name}</b></p>
                                                    <p className="flex justify-center mt-3 sm:text-sm"></p>
                                                </Link></Tooltip>
                                            </div>
                                        </div>
                                        <div className="flex gap-9 justify-center mt-2 border py-2" style={{ borderRadius: "10px" }}>
                                            <Tooltip title="Hide Course"><img className="cursor-pointer" onClick={() => { setCourseName(course.name); handleHideCourse(CourseIndex) }} src={course.view ? CourseNotActive : CourseActive} alt="" /></Tooltip>
                                            <Tooltip title="Edit"><img src={EditQuestoin} alt="" /></Tooltip>
                                            <Tooltip title="Delete"><img className="cursor-pointer" src={DeletQuestoin} alt="" onClick={() => { DeleteDialogOpen('course'); setCourseName(course.name); setCourseId(course.course_id) }} /></Tooltip>
                                        </div>
                                        <div className="flex gap-5 justify-center border py-2" style={{ borderRadius: "10px" }}>
                                            <Tooltip title="Players"><span className="relative"><img src={LearnerTotal} alt="" /><span className={`absolute inset-0 top-0.5 ${getUniqueStudentCount(course) <= 9 ? 'left-2.5' : 'left-1'}`}>{getUniqueStudentCount(course)}</span> </span></Tooltip>
                                            <p>Learners</p>
                                            <Tooltip title="Enrolled Students"><span className="relative"><img src={LearnerDone} alt="" /><span className={`absolute inset-0 top-0.5 ${course.students.length <= 9 ? 'left-2.5' : 'left-1'}`}>
                                                {course.students.length}
                                            </span> </span></Tooltip>
                                        </div>
                                    </div>)}
                            </div>
                        ))
                    ) : (
                        <div className="text-gray-500">No courses available in this subject.</div>
                    )}
                </div>
            </div>
        </div>
    )
};
export default GroupeCourses;