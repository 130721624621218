import React, { useState } from "react";
import './style.css'
import GamizignLogo from '../../../assets/images/SigninPageImages/Gamizign-logo.png'
import LoginIcon from '../../../assets/images/SigninPageImages/login_icons.png'
import HeartIcon from '../../../assets/images/SigninPageImages/kindly icon.png'
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import CircularProgress from '@mui/material/CircularProgress';
import { Link, useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Alert, Dialog, DialogContent, InputAdornment } from "@mui/material";
import AccountCircle from '@mui/icons-material/AccountCircle';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import KeyIcon from '@mui/icons-material/Key';
import EmailIcon from '@mui/icons-material/Email';
import axios from "axios";
import setAuthToken from "../../Auth/auth";


const SignIn = () => {
    const steps = ['Organization info', 'Account info', 'Activate your account'];
    function HorizontalNonLinearStepper() {
        const [activeStep, setActiveStep] = React.useState(0);
        const [completed, setCompleted] = React.useState({});
        const [OrgName, setOrgName] = useState('');
        const [OrgNameError, setOrgNameError] = useState('');
        const [FirstName, setFirstName] = useState('');
        const [FirstNameError, setFirstNameError] = useState('');
        const [LastName, setLastName] = useState('');
        const [LastNameError, setLastNameError] = useState('');
        const [Email, setEmail] = useState('');
        const [EmailError, setEmailError] = useState('');
        const [Password, setPassword] = useState('');
        const [PasswordError, setPasswordError] = useState('');
        const [Progrees, setProgrees] = useState(false);
        // create admin and org request
        const CreteAminHandler = async () => {
          setProgrees(true);
          try {
            const organizationResponse = await axios.post(`${process.env.REACT_APP_ENDPOINT}/organization/`, {
              name: OrgName,
            });
        
            const { organization_id } = organizationResponse.data;
        
            if (organization_id) {
              console.log('org created, Organization ID:', organization_id);
        
              // Second POST request to admin URL with organization_id
              const adminResponse = await axios.post(`${process.env.REACT_APP_ENDPOINT}/admin`, {
                first_name: FirstName ,
                last_name: LastName ,
                email:Email ,
                organization_id: organization_id,
                hashed_password : Password
              });
              if (adminResponse.data) {
                console.log('Admin created successfully');
                setProgrees(false);
                setIsSignUp(false);
                setPymentError(true);
                setErrorMessage('Congratulations your email has been activited, Now you can login with your email.');
              }else{
                console.log('error while creating admin'); 
                setProgrees(false);
                setPymentError(true);
                setErrorMessage('An error occured while activiting your email please try again later.');
              }
              console.log('Admin Response:', adminResponse.data);
        
            } else {
              console.error('Error creating new Org.');
              setProgrees(false);
              setPymentError(true);
              setErrorMessage('An error occured while activiting your email please try again later.');
            }
          } catch (error) {
            console.error('Error occurred during the requests:', error);
          }
        };
        
      
        const totalSteps = () => {
          return steps.length;
        };
      
        const completedSteps = () => {
          return Object.keys(completed).length;
        };
      
        const isLastStep = () => {
          return activeStep === totalSteps() - 1;
        };
      
        const allStepsCompleted = () => {
          return completedSteps() === totalSteps();
        };
      
        const handleNext = () => {
          const newActiveStep =
            isLastStep() && !allStepsCompleted()
              ? // It's the last step, but not all steps have been completed,
                // find the first step that has been completed
                steps.findIndex((step, i) => !(i in completed))
              : activeStep + 1;
          setActiveStep(newActiveStep);
        };
      
        const handleBack = () => {
          setActiveStep((prevActiveStep) => prevActiveStep - 1);
          const newCompleted = completed;
          delete newCompleted[activeStep -1] ;
          setCompleted(newCompleted);
        };
      
      
        const handleComplete = () => {
          let error = false;
          if (activeStep === 0) {
            if (!OrgName) {
              error= true;
              setOrgNameError('Please fill the Organization name.')
            }
          }else if (activeStep === 1) {
            if (!FirstName) {
              error= true;
              setFirstNameError('Please fill your first name.')
            }
            if (!LastName) {
              error= true;
              setLastNameError('Please fill your last name.')
            }
            if (!Email) {
              error= true;
              setEmailError('Please fill the Email.')
            }
            if (!Password) {
              error= true;
              setPasswordError('Please enter password.')
            }
            
          }
          
         
          if(!error && completedSteps() !== totalSteps() - 1){
            const newCompleted = completed;
            newCompleted[activeStep] = true;
            setCompleted(newCompleted);
            handleNext();
          }
        };
      
      
        return (
          <Box sx={{ width: '100%' }}>
            <Stepper nonLinear activeStep={activeStep}>
              {steps.map((label, index) => (
                <Step key={label} completed={completed[index]}>
                  <StepButton color="inherit">
                    {label}
                  </StepButton>
                </Step>
              ))}
            </Stepper>
            <div>
                <React.Fragment>
                  <div className="flex justify-center mt-5 px-5 max-w-xl">
                  {activeStep === 0 ? (
                    <div className='drop-shadow-xl'>
                      <h1 className="flex justify-center text-xl">Organization info</h1>
                      <TextField 
                      value={OrgName}
                      placeholder="Enter Organization name"
                      onChange={(e)=>{{setOrgName(e.target.value);setOrgNameError('')}}}
                       margin="dense" id="Orgnization Name" label="Enter Orgnization Name" variant="outlined"
                      sx={{
                        width:'100%',
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                              border: '2px solid',
                            },
                        },
                    }}
                      InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <CorporateFareIcon style={{color:'#4183d9'}} />
                            </InputAdornment>
                        ),
                        style: {
                          borderRadius: "10px",
                          color:'#4183d9',
                          fontSize:'20px',
                          background:"white"
                        }
                    }}
                     />
                      {OrgNameError && <p style={{ color: 'red' }}>{OrgNameError}</p> }
                    </div>
                    ) : (activeStep === 1 ? (
                    <div className='drop-shadow-xl'>
                      <h1 className="flex justify-center text-xl">Account info</h1>
                      <TextField
                                    autoFocus
                                    margin="dense"
                                    label="First Name"
                                    name="firstName"
                                    type="text"
                                    placeholder="Enter your first name"
                                    fullWidth
                                    sx={{
                                      width:'100%',
                                      '& .MuiOutlinedInput-root': {
                                          '& fieldset': {
                                              border: 'none',
                                          },
                                          '&.Mui-focused fieldset': {
                                            border: '2px solid',
                                          },
                                      },
                                  }}
                                    value={FirstName}
                                    onChange={(e)=>{setFirstName(e.target.value);setFirstNameError('')}}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <AccountCircle style={{color:'#4183d9'}} />
                                            </InputAdornment>
                                        ),
                                        style: {
                                          borderRadius: "10px",
                                          color:'#4183d9',
                                          fontSize:'20px',
                                          background:"white"
                                        }
                                    }}
                                />
                                {FirstNameError && <p style={{ color: 'red' }}>{FirstNameError}</p> }
                                <TextField
                                    margin="dense"
                                    label="Last Name"
                                    name="lastName"
                                    type="text"
                                    placeholder="Enter your last name"
                                    fullWidth
                                    sx={{
                                      width:'100%',
                                      '& .MuiOutlinedInput-root': {
                                          '& fieldset': {
                                              border: 'none',
                                          },
                                          '&.Mui-focused fieldset': {
                                            border: '2px solid',
                                          },
                                      },
                                  }}
                                    value={LastName}
                                    onChange={(e)=>{setLastName(e.target.value);setLastNameError('')}}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <AccountCircle style={{color:'#4183d9'}} />
                                            </InputAdornment>
                                        ),
                                        style: {
                                          borderRadius: "10px",
                                          color:'#4183d9',
                                          fontSize:'20px',
                                          background:"white"
                                        }
                                    }}
                                />
                                {LastNameError && <p style={{ color: 'red' }}>{LastNameError}</p> }
                                <TextField
                                    margin="dense"
                                    label="Email"
                                    name="email"
                                    type="email"
                                    placeholder="Enter email"
                                    fullWidth
                                    value={Email}
                                    sx={{
                                      width:'100%',
                                      '& .MuiOutlinedInput-root': {
                                          '& fieldset': {
                                              border: 'none',
                                          },
                                          '&.Mui-focused fieldset': {
                                            border: '2px solid',
                                          },
                                      },
                                  }}
                                    onChange={(e)=>{setEmail(e.target.value);setEmailError('')}}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <EmailIcon style={{color:'#4183d9'}} />
                                            </InputAdornment>
                                        ),
                                        style: {
                                          borderRadius: "10px",
                                          color:'#4183d9',
                                          fontSize:'20px',
                                          background:"white"
                                        }
                                    }}
                                />
                                {EmailError && <p style={{ color: 'red' }}>{EmailError}</p> }
                                <TextField
                                    margin="dense"
                                    label="Password"
                                    name="hashed_password"
                                    type="password"
                                    fullWidth
                                    placeholder="Enter password"
                                    value={Password}
                                    sx={{
                                      width:'100%',
                                      '& .MuiOutlinedInput-root': {
                                          '& fieldset': {
                                              border: 'none',
                                          },
                                          '&.Mui-focused fieldset': {
                                            border: '2px solid',
                                          },
                                      },
                                  }}
                                    onChange={(e)=>{setPassword(e.target.value);setPasswordError('')}}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <KeyIcon style={{color:'#4183d9'}} />
                                            </InputAdornment>
                                        ),
                                        style: {
                                          borderRadius: "10px",
                                          color:'#4183d9',
                                          fontSize:'20px',
                                          background:"white"
                                        }
                                    }}

                                />
                                {PasswordError && <p style={{ color: 'red' }}>{PasswordError}</p> }
                    </div>
                    ) : (
                    <div className='drop-shadow-xl'>
                      <h1 className="flex justify-center text-xl">Activate your account</h1>
                      {Progrees && (
                                <div className="absolute inset-0 flex justify-center items-center bg-white bg-opacity-75 z-50">
                                  <CircularProgress className="text-blue-800" />
                                </div>
                              )}
                      <Button disabled={Progrees} onClick={CreteAminHandler} sx={{ mr: 1, border:'#4183d9 2px solid', borderRadius:'10px', background:'white' }}>
                      Activate your email
                    </Button>
                    </div>
                    ))}
                  </div>
                  <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                    <Button
                      color="inherit"
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      sx={{ mr: 1 }}
                    >
                      Back
                    </Button>
                    <Box sx={{ flex: '1 1 auto' }} />
                        <Button onClick={handleComplete}>
                          {completedSteps() === totalSteps() - 1
                            ? 'Finished'
                            : 'Next'}
                        </Button>
                  </Box>
                </React.Fragment>
            </div>
          </Box>
        );
      }
    // const history = useHistory(); // Use useHistory hook for navigation
    const [isSignUp , setIsSignUp] = useState(false)
    const navigate = useNavigate();
    const [state, setState] = useState({
        showPassword: false,
        password: '',
        error: '',
        isLoading: false,
    });

    const handleSubmit = async (event) => {
      event.preventDefault();
      setState((prevState) => ({ ...prevState, error: '', isLoading: true }));
      handleLoginResult(true);
      const authData = {
        username: event.target.email.value,
        password: event.target.password.value
      };
      let status = 0;
      
      
    
      try {
          const response = await axios.post(`${process.env.REACT_APP_ENDPOINT}/token`, authData, {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/x-www-form-urlencoded'
            }
          });
          axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.access_token}`;
          status = response.status
          const responseData = response.data;
          if(response.status === 402){
            setPymentError(true);
            setErrorMessage('License expired please contact your administrator.')
          }
          if(response.status === 401){
            setErrorMessage('Something error had happened try loggin in again');
          }
          console.log(responseData);
                    
          if (responseData.user_type === "Instructor") {
              localStorage.setItem("instructor_id", responseData.user.instructor_id);
              localStorage.setItem("name", responseData.user.firstName);
              localStorage.setItem("lastname", responseData.user.lastName);
              localStorage.setItem("email", responseData.user.email);
              localStorage.setItem("organization", responseData.user.organization_id);
              localStorage.setItem("user_type", responseData.user_type);
              sessionStorage.setItem("token", `Bearer ${responseData.access_token}`);
              window.location.href = `/Dashboard`;
          } else {
              localStorage.setItem("admin_id", responseData.user.admin_id);
              localStorage.setItem("adminName", responseData.user.first_name);
              localStorage.setItem("adminEmail", responseData.user.email);
              localStorage.setItem("organization", responseData.user.organization_id);
              localStorage.setItem("user_type", responseData.user_type);
              sessionStorage.setItem("token", `Bearer ${responseData.access_token}`);
              window.location.href = `/Admin`;
          }
  
          handleLoginResult(true);
          setState((prevState) => ({ ...prevState, error: '', isLoading: false }));
      } catch (error) {
          const errorMessage = status !== 402 && 'Invalid email or password';
          setState((prevState) => ({ ...prevState, error: errorMessage, isLoading: false }));
          handleLoginResult(false);
          console.error('Error during login:', error);
      }
  };

    const togglePasswordVisibility = () => {
        setState((prevState) => ({ ...prevState, showPassword: !prevState.showPassword }));
    };

    const handleLoginResult = (isCorrectLogin) => {
        const inputs = document.querySelectorAll("input");

        inputs.forEach((input) => {
            input.readOnly = isCorrectLogin;
        });
    }
    const handleGoogleLogin = async (credentialResponse) => {
        var jwtToken = credentialResponse.credential.toString();
        setState((prevState) => ({ ...prevState, error: '', isLoading: true }));
        fetch(`${process.env.REACT_APP_ENDPOINT}/handle_token`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: jwtToken,
            }),
        })
            .then(response => response.json())
            .then(decodedData => {
                    localStorage.setItem("instructor_id", decodedData.instructor_id);
                localStorage.setItem("name", decodedData.firstName);
                localStorage.setItem("email", decodedData.email);
                localStorage.setItem("organization", decodedData.organization_id);
                sessionStorage.setItem("token", `Bearer ${decodedData.access_token}`);
                setState((prevState) => ({ ...prevState, error: '', isLoading: false }));

                navigate("/Dashboard");
                // setData(decodedData);
            })
            .catch(error => {
                const errorMessage = 'Error logging in with Google';
                setState((prevState) => ({ ...prevState, error: errorMessage, isLoading: false }));
                console.error('Error decoding JWT on the server:', error);
            });
    }
    const [pymentError, setPymentError] = useState(false);
    const [ErrorMessage, setErrorMessage] = useState('');
    return (
        <div>
        <Dialog open={pymentError} onClose={!pymentError}>
          <DialogContent>
            <Alert severity={ErrorMessage === 'Congratulations your email has been activited, Now you can login with your email.' ? 'success' : 'error'}>{ErrorMessage}</Alert>
          </DialogContent>
          <div className='flex justify-center'>
            <Button onClick={() => { setPymentError(false); setIsSignUp(false) }} color="primary">
              Okay
            </Button>

          </div>
        </Dialog>
            {isSignUp ? (
            <div className="flex justify-center items-center">
              <div className="flex-1"></div>
                <div className="px-0 py-0">
                 <div className="flex justify-center"><img className="logo" src={GamizignLogo} width={200} alt="LogoImage" /></div>
                    <HorizontalNonLinearStepper/>
                    <div className="flex justify-end"><button className="text-sky-600" onClick={()=>{setIsSignUp(false)}}>have an account?</button></div>
                </div>
                <div className="flex-1"></div>
            </div>
            ):(
                 <div className="flex justify-center items-center">
                 <div className="flex-1"></div>
                 <div className="flex-none SignIn text-center justify-center px-0 py-0" >
                     <div className="flex justify-center"><img className="logo" src={GamizignLogo} width={200} alt="LogoImage" /></div>
                     <div className="flex justify-center"><img className="img-fluid" src={LoginIcon} alt="LoginImage" /></div>
                     <div className="drop-shadow-xl rounded-b-3xl bg-transparent relative">
                         {state.isLoading && (
                             <div className="absolute inset-0 py-40 flex justify-center items-center bg-transparent bg-opacity-75 z-50">
                                 <CircularProgress className="text-blue-800" />
                             </div>
                         )}
                         <form className="container p-3 px-5" action="post" onSubmit={handleSubmit}>
                         <TextField
                                    margin="dense"
                                    label="Email"
                                    name="email"
                                    type="email"
                                    required 
                                    placeholder="mohd@example.com"
                                    fullWidth
                                    sx={{
                                      marginTop: '20px',
                                      '& .MuiOutlinedInput-root': {
                                          '& fieldset': {
                                              border: 'none',
                                          },
                                          '&.Mui-focused fieldset': {
                                            border: '2px solid',
                                          },
                                      },
                                  }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <EmailIcon  style={{color:'#4183d9'}}/>
                                            </InputAdornment>
                                        ),
                                        style: {
                                          borderRadius: "10px",
                                          color:'#4183d9',
                                          fontSize:'20px',
                                          background:"white"
                                        }
                                    }}
                                />
                         <TextField
                                    margin="dense"
                                    label="Password"
                                    name="password"
                                    type={state.showPassword ? 'text' : 'password'}
                                    required 
                                    placeholder="Enter your password"
                                    fullWidth
                                    className=""
                                    sx={{
                                      marginTop: '20px',
                                      '& .MuiOutlinedInput-root': {
                                          '& fieldset': {
                                              border: 'none',
                                          },
                                          '&.Mui-focused fieldset': {
                                            border: '2px solid',
                                          },
                                      },
                                  }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <span onClick={togglePasswordVisibility} className="cursor-pointer">{state.showPassword ? <FaEye style={{color:'#4183d9'}} size={24}/> : <FaEyeSlash style={{color:'#4183d9'}} size={24}/>}</span>
                                            </InputAdornment>
                                        ),
                                        style: {
                                          borderRadius: "10px",
                                          color:'#4183d9',
                                          fontSize:'20px',
                                          background:"white", 
                                        }
                                    }}
                                />
                             {state.error && <p style={{ color: 'red' }}>{state.error}</p>}
                             <div className="flex justify-center items-center gap-1 py-1 mb-5"><img src={HeartIcon} alt="HeartIcon" /><p>Kindly!</p></div>
                             <button className="btn GameOn hover:scale-110 text-2xl py-2 w-full" type="submit" disabled={state.isLoading}>
                                 <label><b>GAME ON!</b></label>
                             </button>
 
                             <GoogleOAuthProvider clientId="1071328084227-056krbch512vrpup6fgevl6j8i55g7ic.apps.googleusercontent.com">
                                 <GoogleLogin
                                     onSuccess={handleGoogleLogin}
                                     shape="circle"
                                     type="standard"
                                     logo_alignment="left"
                                     text="continue_with"
                                     theme="filled_blue"
                                     size="large"
                                     disabled={state.isLoading} // Disable the button when loading
                                 />
                             </GoogleOAuthProvider>
                    <button onClick={() => { setIsSignUp(true) }} className="btn transition-transform transform text-black hover:scale-110 py-2 w-full" type="button">
                      Create a new account
                    </button>
                         </form>
                     </div>
                     <p className="mt-4"><Link className="text-black" target="_blank" to="https://gamizign.com/privacy-policy/">Privacy Policy & Terms of Use</Link></p>
                 </div>
                 <div className="flex-1"></div>
             </div>
            )}
           
        </div>
    );

};
export default SignIn;
