import React, { useState } from 'react';
import { Card, CardContent, CardHeader, List, ListItem, ListItemText, ListItemIcon, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, InputAdornment, IconButton, Collapse, Slide, Alert, CircularProgress } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import SchoolIcon from '@mui/icons-material/School';
import ClassIcon from '@mui/icons-material/Class';
import PeopleIcon from '@mui/icons-material/People';
import AccountCircle from '@mui/icons-material/AccountCircle';
import KeyIcon from '@mui/icons-material/Key';
import EmailIcon from '@mui/icons-material/Email';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import EditIcon from '@mui/icons-material/Edit';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import axios from 'axios';
import Papa from 'papaparse';
import { Link } from 'react-router-dom';
import axiosInstance from '../axios';

const ReusableListCard = ({ title, titleCard, items, onAdd, selectedIndex, classes, handleUpdateItem, NetworkResp, RespMessage, setNetworkResp, setRespMessage, handleDelete, NewRecord, setNewRecord, className }) => {
    const [open, setOpen] = useState(false);
    const [Delete, setDelete] = useState(false);
    const [progress, setProgress] = useState(false);
    const [newItem, setNewItem] = useState({});
    const [expanded, setExpanded] = useState({});
    const [actionType, setActionType] = useState('');
    const [DeleteId, setDeleteId] = useState('');
    // const [MainError, setMainError] = useState(false);
    const [FirstName, setFirstName] = useState('');
    const [LastName, setLastName] = useState('');
    const [Email, setEmail] = useState('');
    const [Grade, setGrade] = useState('');
    const [Class, setClass] = useState('');
    const [Paswoord, setPaswoord] = useState('');
    // errors
    const [FirstNameError, setFirstNameError] = useState('');
    const [LastNameError, setLastNameError] = useState('');
    const [EmailError, setEmailError] = useState('');
    const [GradeError, setGradeError] = useState('');
    const [ClassError, setClassError] = useState('');
    const [PaswoordError, setPaswoordError] = useState('');
    var organization_id = localStorage.getItem("organization");
    // var organization_id = 2;
    const rewardCategories = ['Electronics', 'Entertainment', 'Food & Drinks', 'House Furnishing', 'Kids', 'Ladies', 'Men', 'Renting', 'Services', 'Travelling', 'Watches'];
    const handleClickOpen = () => {
        let limitError = false;
        if (!organization_id == 1 || !organization_id == 6) {
            if (selectedIndex === 0 && items.length >= 2) {
                limitError = true;
                setDelete(true);
                setActionType('limit');
                setRespMessage('Thank you for using Gamizign the AI ultimate gamification platform! You have reached the maximum number of instructors allowed in your trial account. To add more users, please consider upgrading to one of our premium plans.');
            }
            if (selectedIndex === 2 && items.length >= 40) {
                limitError = true;
                setDelete(true);
                setActionType('limit');
                setRespMessage('Thank you for using Gamizign the AI ultimate gamification platform! You have reached the maximum number of students allowed in your trial account. To add more users, please consider upgrading to one of our premium plans.');
            }
        }
        if (!limitError) {

            setOpen(true);
        }
    };

    const handleClose = () => {
        setOpen(false);
        setNewItem({});
        setFirstNameError('');
        setLastNameError('');
        setEmailError('');
        setGradeError('');
        setClassError('');
        setPaswoordError('');
        setFirstName('');
        setLastName('');
        setEmail('');
        setPaswoord('');
        setGrade('');
        setClass('');
    };
    const handleResp = () => {
        setNetworkResp(false);
        setNewRecord(null);
    };
    // delete dialog and funtions here
    const handleCloseDelete = () => {
        setDelete(false);
    };
    const DeleteDateHandle = () => {
        handleCloseDelete();
        handleDelete(DeleteId)

    }
    // Add new inst, student, class or rewars
    const handleAdd = () => {
        let mainError = false;
        if (selectedIndex === 0) {
            if (!FirstName) {
                setFirstNameError('Please fill the First Name.');
                mainError = true;
            }
            if (!LastName) {
                setLastNameError('Please fill the Last Name.')
                mainError = true;

            }
            if (!Email) {
                setEmailError('Please fill the Email.')
                mainError = true;

            }
            if (Email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(Email)) {
                setEmailError('Please fill the Email in correct format like')
                mainError = true;

            }
            if (Email && Email === localStorage.getItem("adminEmail")) {
                setEmailError('You can not use Admin email for Instructor.')
                mainError = true;

            }
            if (!Paswoord) {
                setPaswoordError('Please enter the Password.')
                mainError = true;

            }
        } else if (selectedIndex === 1) {
            if (!FirstName) {
                setFirstNameError('Please fill the Class Name.');
                mainError = true;
            }
        } else if (selectedIndex === 2) {
            if (!FirstName) {
                setFirstNameError('Please fill the First Name.');
                mainError = true;
            }
            if (!LastName) {
                setLastNameError('Please fill the Last Name.')
                mainError = true;

            }
            if (!Email) {
                setEmailError('Please fill the Email.')
                mainError = true;

            }
            if (Email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(Email)) {
                setEmailError('Please fill the Email in correct format like')
                mainError = true;

            }
            if (!Grade) {
                setGradeError('Please fill the Grade.')
                mainError = true;

            }
            if (!Class) {
                setClassError('Please select the Class.')
                mainError = true;

            }
            if (!Paswoord) {
                setPaswoordError('Please enter the Password.')
                mainError = true;

            }
        } else {
            if (!FirstName) {
                setFirstNameError('Please fill the Reward Name.');
                mainError = true;
            }
            if (!LastName) {
                setLastNameError('Please select the Category.')
                mainError = true;

            }
            if (!Email) {
                setEmailError('Please fill the Description.')
                mainError = true;

            }
            if (!Grade) {
                setGradeError('Please fill the Quantity.')
                mainError = true;

            }
            if (!Class) {
                setClassError('Please fill the Score.')
                mainError = true;

            }
        }


        if (!mainError) {
            if (selectedIndex === 3) {
                const updatedItem = {
                    ...newItem, organization_id: localStorage.getItem("organization"), admin_id: localStorage.getItem("admin_id"),
                    claimed: true, level: 0, rewardedScore: newItem.score, rewardType: 1,
                };
                onAdd(updatedItem);
            } else {
                const updatedItem = selectedIndex === 2
                    ? [{ ...newItem, organization_id: localStorage.getItem("organization"), admin_id: localStorage.getItem("admin_id"), st_class: newItem.grade, avatar_id: 1 }]
                    : { ...newItem, organization_id: localStorage.getItem("organization"), admin_id: localStorage.getItem("admin_id") };
                onAdd(updatedItem);
            }
            setNewItem({});
            handleClose();
        }
    };
    // add students in bulk/ upload CSV 
    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (event) => {
                const csvData = event.target.result;
                addColumnsToCSV(csvData, file);
            };
            reader.readAsText(file);
        }
    };
    const addColumnsToCSV = (csvData, originalFile) => {
        // Parse the CSV data
        const parsedData = Papa.parse(csvData, { header: true });

        // Add new columns with fixed values
        const modifiedData = parsedData.data.map(row => ({
            ...row,
            avatar_id: 1,
            organization_id: localStorage.getItem("organization"),
            class_id: newItem.class_id,
            admin_id: localStorage.getItem("admin_id"),
        }));
        // Convert back to CSV
        const updatedCSVString = Papa.unparse(modifiedData);

        // Create a Blob from the updated CSV string
        const blob = new Blob([updatedCSVString], { type: 'text/csv;charset=utf-8;' });

        // Create a new File object with the same properties as the original file
        const updatedFile = new File([blob], originalFile.name, {
            type: originalFile.type,
            lastModified: originalFile.lastModified
        });

        setGrade(updatedFile);
    };

    const handleUploadCsv = async () => {
        setProgress(true);
        var org = localStorage.getItem("organization");
        var admin_id = localStorage.getItem("admin_id");
        let endpoint = `${process.env.REACT_APP_ENDPOINT}/organizations/${org}/classes/${newItem.class_id}/students/csv/?admin_id=${admin_id}`;

        try {
            let formData = new FormData();
            formData.append('file', Grade);

            const response = await axiosInstance.postForm(endpoint, formData);

            if (response.status === 200) {
                if (selectedIndex === 2) {
                    setNewRecord(response.data);
                }
                setRespMessage('Created successfully');
                setNetworkResp(true);
            } else {
                console.log('Request status is', response.statusText);
            }
        } catch (error) {
            console.error('Error adding item:', error);
            setRespMessage('The new record was not added.');
            setNetworkResp(true);
        }
        handleClose();
        setProgress(false);
    };

    // update inst, student, class or rewars
    const updateHandle = () => {
        if (selectedIndex === 0) {
            const updatedItem = { ...newItem, organization_id: localStorage.getItem("organization"), };
            handleUpdateItem(updatedItem);
        } else {
            handleUpdateItem(newItem);
        }
        setNewItem({});
        handleClose();
    }
    const handleChange = (event) => {
        const { name, value } = event.target;
        setNewItem((prevState) => ({ ...prevState, [name]: value }));
    };
    const handleToggleExpand = (className) => {
        setExpanded((prevState) => ({
            ...prevState,
            [className]: !prevState[className],
        }));
    };
    const categorizedStudents = items.reduce((acc, item) => {
        const className = item.grade;
        if (!acc[className]) {
            acc[className] = [];
        }
        acc[className].push(item);
        return acc;
    }, {});
    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="down" ref={ref} {...props} />;
    });

    return (
        <Card>
            <CardHeader title={titleCard} />
            <CardContent>
                <List>
                    {selectedIndex === 2 ? (
                        classes.map((className, idx) => (
                            <div className='border-b-2 border-sky-300 sm:border-none' key={idx}>
                                <ListItem button onClick={() => handleToggleExpand(className.class_name)}>
                                    <ListItemIcon>
                                        <ClassIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={`${idx + 1}.  ${className.class_name}`} />
                                    {expanded[className.class_name] ? <ExpandLess /> : <ExpandMore />}
                                </ListItem>
                                <Collapse in={expanded[className.class_name]} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        {className.students.length > 0 ? className.students.map((student, index) => (
                                            <ListItem className='border-b-2 sm:border-none' key={index} sx={{ pl: 4 }} >
                                                <ListItemIcon>
                                                    <PeopleIcon />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={`${index + 1}. ${student.firstName} ${student.lastName} - Grade: ${student.grade} - Id: ${student.student_id}`}
                                                />
                                                <ListItemIcon>
                                                    <IconButton id='Edit' onClick={() => { handleClickOpen(); setNewItem(student); setActionType('edit') }} edge="end" sx={{ margin: '5px' }} aria-label="edit">
                                                        <EditIcon />
                                                    </IconButton>
                                                    <IconButton id='Delete' onClick={() => {
                                                        setDelete(true); setActionType('delete'); setRespMessage(student.firstName + " " + student.lastName);
                                                        setDeleteId(student.student_id);
                                                    }} edge="end" sx={{ margin: '0px' }} aria-label="delete">
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </ListItemIcon>
                                            </ListItem>
                                        )) :<div className='ms-10 text-xl'>No student avaiable in the <b> {className.class_name}</b> class.</div>}
                                    </List>
                                </Collapse>
                            </div>
                        ))
                    ) : (
                        items.map((item, index) => (
                            <ListItem className='border-b-2 sm:border-none' key={index} sx={{ display: 'flex', alignItems: 'center' }} >
                                <ListItemIcon>
                                    {selectedIndex === 0 && <SchoolIcon />}
                                    {selectedIndex === 1 && <ClassIcon />}
                                    {selectedIndex === 3 && <EmojiEventsIcon />}
                                </ListItemIcon>
                                <ListItemText
                                    primary={
                                        `${index + 1}. ` +
                                        (selectedIndex === 0
                                            ? `${item.firstName} ${item.lastName}`
                                            : selectedIndex === 1
                                                ? item.class_name
                                                : selectedIndex === 2
                                                    ? `${item.firstName} ${item.lastName} - Grade: ${item.grade}, Class: ${item.st_class}`
                                                    : `${item.name} , Description: ${item.description} - Quantity: ${item.qty} - Score: ${item.score}`
                                        )
                                    }
                                />
                                <ListItemIcon className='mx-0'>
                                    <IconButton id='Edit' onClick={() => { handleClickOpen(); setNewItem(item); setActionType('edit') }} edge="end" sx={{ margin: '5px' }} aria-label="edit">
                                        <EditIcon />
                                    </IconButton>
                                    <IconButton id='Delete' onClick={() => {
                                        setDelete(true); setActionType('delete');
                                        setRespMessage(selectedIndex === 0 || selectedIndex === 2 ? item.firstName + " " + item.lastName : selectedIndex === 1 ? item.class_name : item.name);
                                        setDeleteId(selectedIndex === 0 ? item.instructor_id : selectedIndex === 1 ? item.class_id : selectedIndex === 2 ? item.student_id : item.reward_id);
                                    }} edge="end" sx={{ margin: '0px' }} aria-label="delete">
                                        <DeleteIcon />
                                    </IconButton>
                                </ListItemIcon>
                            </ListItem>
                        ))
                    )}
                </List>
                <Button id='AddNew' variant="contained" color="primary" startIcon={<AddIcon />} onClick={() => { setActionType('add');handleClickOpen()}} sx={{ marginTop: 2 }}>
                    Add New {title}
                </Button>
                {selectedIndex === 2 && <Button id='AddNewCsv' variant="contained" color="primary" startIcon={<AddIcon />} onClick={() => { setActionType('addCSV');handleClickOpen()}} sx={{ marginTop: 2, marginLeft: 2 }}>
                    Import CSV {title}
                </Button>}
                {/* Dialog for nework response */}
                <Dialog open={NetworkResp || Delete} onClose={handleResp || handleCloseDelete}>
                    <DialogContent>
                        <Alert
                            severity={
                                RespMessage === 'Created successfully' ||
                                    RespMessage === 'Updated successfully'
                                    ? 'success'
                                    : actionType === 'delete' ? 'warning' : 'error'
                            }>
                            {actionType === 'delete' ? 'Are you sure to want delete the ' : ''}
                             <span className={actionType === 'delete' ? 'font-bold text-xl' : ''}>
                                {RespMessage === 'Created successfully'? <>New {title} has been added successfully.</> : ''}  
                                {RespMessage ===  'Updated successfully' ? <>{title} Record has been updated successfully.</> : ''} </span> 
                                {RespMessage === 'An error occurred while Creating' || RespMessage === 'An error occurred while Updating' ? <>{RespMessage} {title}, Please try again later</> : '' } 
                                {actionType === 'delete' ? <span className='font-bold'>{RespMessage}</span> : ''} 
                                {actionType === 'delete' ? ' from ' : ''} 
                             {actionType === 'delete' ? titleCard : ''}
                             {actionType === 'delete' ? '?' : '.'}
                        </Alert>
                        {NewRecord && Array.isArray(NewRecord) ? (
                            <>
                                {NewRecord.map((record, index) => (
                                    <p key={index}>
                                        <span className="font-bold">{record.firstName} {record.lastName} </span>
                                        with student ID : <span className="font-bold">{record.student_id} </span>
                                        in <span className="border-b border-sky-300">{className}</span> class.
                                    </p>
                                ))}
                            </>
                        ) : (NewRecord &&
                            <p>
                                <span className="font-bold">{NewRecord.firstName} {NewRecord.lastName} </span>
                                with student ID : <span className="font-bold">{NewRecord.student_id} </span>
                                in <span className="border-b border-sky-300">{className}</span> class.
                            </p>
                        )}
                        {/* {NewRecord && <>{NewRecord.map((NewRecord, index)=>(<p><span className='font-bold'>{NewRecord.firstName} {NewRecord.lastName}</span> with student ID : <span className='font-bold'>{NewRecord.student_id}</span> in <span className='border-b border-sky-300'>{className}</span> class.</p>))}</>} */}
                    </DialogContent>
                    {actionType === 'delete' ?
                        <div className='flex justify-center'>
                            <Button onClick={DeleteDateHandle} color="primary">
                                Yes
                            </Button>
                            <Button onClick={handleCloseDelete} color="primary">
                                No
                            </Button>
                        </div>
                        : organization_id > 1 ?
                            <div className='flex justify-center'>
                                <Button onClick={() => { setDelete(false); setRespMessage('') }} color="primary">
                                    Okay
                                </Button>
                                <Button onClick={() => { setDelete(false); setRespMessage('') }} color="primary">
                                    Upgrade
                                </Button>
                            </div>

                            : <Button onClick={()=>{handleResp();setDelete(false)}} color="primary">
                                Okay
                            </Button>
                    }
                </Dialog>
                <Dialog open={open} onClose={handleClose} disabled={progress}>
                    <DialogTitle>{actionType === 'edit' ? 'Update' : 'Add New'} {title}</DialogTitle>
                    <DialogContent>
                        {progress && (
                            <div className="absolute inset-0 flex justify-center items-center bg-white bg-opacity-75 z-50">
                                <CircularProgress className="text-blue-800" />
                            </div>
                        )}
                        {actionType === 'add' &&
                            <DialogContentText>
                                Please enter the details for the new {title}.
                            </DialogContentText>
                        }
                        {selectedIndex === 0 && (
                            <>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    label="First Name"
                                    name="firstName"
                                    type="text"
                                    fullWidth
                                    value={newItem.firstName}
                                    onChange={(e) => { handleChange(e); setFirstName(e.target.value); setFirstNameError('') }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <AccountCircle />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                {FirstNameError && <p className="text-red-500">{FirstNameError}</p>}
                                <TextField
                                    margin="dense"
                                    label="Last Name"
                                    name="lastName"
                                    type="text"
                                    fullWidth
                                    value={newItem.lastName}
                                    onChange={(e) => { handleChange(e); setLastName(e.target.value); setLastNameError('') }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <AccountCircle />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                {LastNameError && <p className="text-red-500">{LastNameError}</p>}
                                <TextField
                                    margin="dense"
                                    label="Email"
                                    name="email"
                                    type="email"
                                    fullWidth
                                    value={newItem.email}
                                    onChange={(e) => { handleChange(e); setEmail(e.target.value); setEmailError('') }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <EmailIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                {EmailError && <p ><span className="text-red-500">{EmailError}</span>{EmailError === 'Please fill the Email in correct format like' && ' example@gamizign.com'}</p>}
                                <TextField
                                    margin="dense"
                                    label="Password"
                                    name="hashed_password"
                                    type="password"
                                    fullWidth
                                    value={newItem.hashed_password}
                                    onChange={(e) => { handleChange(e); setPaswoord(e.target.value); setPaswoordError('') }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <KeyIcon />
                                            </InputAdornment>
                                        ),
                                    }}

                                />
                                {PaswoordError && <p className="text-red-500">{PaswoordError}</p>}
                            </>
                        )}
                        {selectedIndex === 1 && (
                            <>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    label="Class Name"
                                    name="class_name"
                                    type="text"
                                    fullWidth
                                    value={newItem.class_name}
                                    onChange={(e) => { handleChange(e); setFirstName(e.target.value); setFirstNameError('') }}
                                />
                                {FirstNameError && <p className="text-red-500">{FirstNameError}</p>}
                            </>
                        )}
                        {selectedIndex === 2 && (
                            <>
                                {actionType === 'edit' &&
                                    <TextField
                                        autoFocus
                                        margin="dense"
                                        label="Student Id"
                                        name="student_id"
                                        type="text"
                                        title='Student Id'
                                        fullWidth
                                        value={newItem.student_id}
                                        disabled
                                    />}
                                {actionType !== 'addCSV' && <TextField
                                    autoFocus
                                    margin="dense"
                                    label="First Name"
                                    name="firstName"
                                    type="text"
                                    fullWidth
                                    value={newItem.firstName}
                                    onChange={(e) => { handleChange(e); setFirstName(e.target.value); setFirstNameError('') }}
                                />}
                                {FirstNameError && <p className="text-red-500">{FirstNameError}</p>}
                                {actionType !== 'addCSV' && <TextField
                                    margin="dense"
                                    label="Last Name"
                                    name="lastName"
                                    type="text"
                                    fullWidth
                                    value={newItem.lastName}
                                    onChange={(e) => { handleChange(e); setLastName(e.target.value); setLastNameError('') }}
                                />}
                                {LastNameError && <p className="text-red-500">{LastNameError}</p>}
                                {actionType !== 'addCSV' && <TextField
                                    margin="dense"
                                    label="Email"
                                    name="email"
                                    type="email"
                                    fullWidth
                                    value={newItem.email}
                                    onChange={(e) => { handleChange(e); setEmail(e.target.value); setEmailError('') }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <EmailIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />}
                                {EmailError && <p ><span className="text-red-500">{EmailError}</span>{EmailError === 'Please fill the Email in correct format like' && ' example@gamizign.com'}</p>}
                                {actionType !== 'addCSV' && <TextField
                                    margin="dense"
                                    label="Grade"
                                    name="grade"
                                    type="number"
                                    fullWidth
                                    value={newItem.grade}
                                    onChange={(e) => { handleChange(e); setGrade(e.target.value); setGradeError('') }}
                                />}
                                {GradeError && <p className="text-red-500">{GradeError}</p>}
                                <FormControl margin="dense" fullWidth>
                                    <InputLabel>Class</InputLabel>
                                    <Select
                                        labelId="class_id"
                                        name="class_id"
                                        value={newItem.class_id}
                                        label="Class"
                                        onChange={(e) => { handleChange(e); setClass(e.target.value); setClassError('') }}
                                    >
                                        {classes.map((classItem) => (
                                            <MenuItem key={classItem.class_id} value={classItem.class_id}>
                                                {classItem.class_name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                {actionType === 'addCSV' && <TextField
                                    margin="dense"
                                    label="Upload CSV"
                                    name="Csv_File"
                                    type="file"
                                    accept=".csv"
                                    fullWidth
                                    // value={newItem.grade}
                                    onChange={(e) => { handleChange(e); setGrade(e.target.files[0]); setGradeError(''); handleFileUpload(e) }}
                                />}
                                {ClassError && <p className="text-red-500">{ClassError}</p>}
                                {actionType !== 'addCSV' && <TextField
                                    margin="dense"
                                    label="Password"
                                    name="hashed_password"
                                    type="password"
                                    fullWidth
                                    value={newItem.hashed_password}
                                    onChange={(e) => { handleChange(e); setPaswoord(e.target.value); setPaswoordError('') }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <KeyIcon />
                                            </InputAdornment>
                                        ),
                                    }}

                                />}
                                {PaswoordError && <p className="text-red-500">{PaswoordError}</p>}

                            </>
                        )}
                        {selectedIndex === 3 && (
                            <>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    label="Name"
                                    name="name"
                                    type="text"
                                    fullWidth
                                    value={newItem.name}
                                    onChange={(e) => { handleChange(e); setFirstName(e.target.value); setFirstNameError('') }}
                                />
                                {FirstNameError && <p className="text-red-500">{FirstNameError}</p>}
                                <FormControl margin="dense" fullWidth>
                                    <InputLabel>Category</InputLabel>
                                    <Select
                                        labelId="Category"
                                        name="category"
                                        value={newItem.category}
                                        label="Category"
                                        onChange={(e) => { handleChange(e); setLastName(e.target.value); setLastNameError('') }}
                                    >
                                        {rewardCategories.map((Item, index) => (
                                            <MenuItem key={Item} value={index + 1}>
                                                {Item}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                {LastNameError && <p className="text-red-500">{LastNameError}</p>}
                                <TextField
                                    margin="dense"
                                    label="Description"
                                    name="description"
                                    type="description"
                                    fullWidth
                                    value={newItem.description}
                                    onChange={(e) => { handleChange(e); setEmail(e.target.value); setEmailError('') }}
                                />
                                {EmailError && <p className="text-red-500">{EmailError}</p>}
                                <TextField
                                    margin="dense"
                                    label="Quantity"
                                    name="qty"
                                    type="number"
                                    fullWidth
                                    value={newItem.qty}
                                    onChange={(e) => { handleChange(e); setGrade(e.target.value); setGradeError('') }}
                                />
                                {GradeError && <p className="text-red-500">{GradeError}</p>}
                                <TextField
                                    margin="dense"
                                    label="Score"
                                    name="score"
                                    type="text"
                                    fullWidth
                                    value={newItem.score}
                                    onChange={(e) => { handleChange(e); setClass(e.target.value); setClassError('') }}
                                />
                                {ClassError && <p className="text-red-500">{ClassError}</p>}

                            </>
                        )}
                    </DialogContent>
                    <DialogActions>
                        {actionType === 'addCSV' &&<Link to={`${process.env.REACT_APP_ENDPOINT}/uploads/Student_import_sample.csv`}>
                            <Button onClick={handleClose} color="primary">
                            View Sample
                        </Button>
                        </Link>}
                        <Button onClick={handleClose} color="primary">
                            Cancel
                        </Button>
                        {actionType === 'edit' ?
                            <Button onClick={updateHandle} color="primary">
                                Update
                            </Button> : actionType === 'add' ?
                                <Button onClick={handleAdd} color="primary">
                                    Add
                                </Button> :
                                <Button onClick={handleUploadCsv} color="primary">
                                    Upload
                                </Button>
                        }
                    </DialogActions>
                </Dialog>
            </CardContent>
        </Card>
    );
};

export default ReusableListCard;
