import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import '../style.css'
import axios from "axios";
import ReUseablePdf from "../ReUseablePdf";
import MetaTags from "../../../../../GlobalLoading/MetaData";
import Joyride from "react-joyride";
import axiosInstance from "../../../../../../axios";

const CreatingPdf = ({ onSelectStart, onSelectEnd }) => {
  var [outline_data, setOutline] = useState('DFG');
  const [isGenerating, setIsGenerating] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const {courses, courseType, course, stageIndex, courseFormatDate, stratingDate, finishingDate } = useLocation().state;
  const navigate = useNavigate();


  var selectedDifficulty = "Medium & Hard";
  var questionsRange = "5 to 10";
  const location = useLocation();
  const [file, setFile] = useState('');
  const [slides, setSlides] = useState([]);
  const [start_page, setStartPage] = useState('');
  const [end_page, setEndPage] = useState('');
  const [EmptySlice, setEmptySlice] = useState(false);
  const [ErrorMesg, setErrorMesg] = useState('');

  const submitPages = async () => {
    setEmptySlice(false)
    if (end_page != '' && file != null) {
      setIsGenerating(true);
      setIsDisabled(true);
      try {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("start_page", JSON.stringify(start_page));
        formData.append("end_page", JSON.stringify(end_page));
        formData.append("difficulty_level", JSON.stringify(selectedDifficulty));
        formData.append("range", JSON.stringify(questionsRange));
        formData.append("course_type", JSON.stringify(course.mainTopic));
        const response = await axiosInstance.postForm(`${process.env.REACT_APP_ENDPOINT}/question_pdf_chat_gpt/`, formData, {

        });
        if (response.data) {
          setIsGenerating(false);
          setIsDisabled(false);
          var questions = response.data;
          navigate('/Dashboard/customizing', { state: {courses:courses, courseType: courseType, course: course, questions: questions, selectedFile: selectedFile, stageIndex:stageIndex, courseFormatDate:courseFormatDate, stratingDate: stratingDate, finishingDate: finishingDate } });
        }

        else {
          setIsGenerating(false);
          setIsDisabled(false);
          console.log('Error in fetching questions');
        }
      }
      catch (error) {
        setIsGenerating(false);
        setIsDisabled(false);
        console.error('Fetch error:', error);
        setEmptySlice(true);
        setErrorMesg('An error occured in generating questions! try again.')
      }
    }else{
      setEmptySlice(true);
    setErrorMesg('You must select end page before submit');
    }
  }
  const { selectedFile } = location.state || {};
  useEffect(() => {
    // Access the state from the previous page
    if (selectedFile) {
      setFile(selectedFile);
    }
  }, [location.state]);
    // onboarding
    const [Onboarding, setOnboarding] = useState(courses.length);
    const [{ run, steps }, setState] = useState({
      run: true,
      steps: [
        {
          content: <h2>Select.</h2>,
          placement: 'left',
          target: '#QT',
          title: 'Questions Difficulty'
        },
        {
          content: <h2>Want to challenge your students, select how much difficulty you want your questions to be Green colored is selected.</h2>,
          placement: 'left',
          target: '#QType',
          title: 'Questions Difficulty'
        },
        {
          content: <h2>Selcet question range how may question do you want generate by default range is 5 to 10.</h2>,
          placement: 'left',
          target: '#QRange',
          title: 'Question range'
        },
        {
          content: <h2>Select starting page from which page you want create question bu default starting is 1 .</h2>,
          placement: 'left',
          target: '#start_page',
          title: 'Starting page'
        },
        {
          content: <h2>You Should must select ending page for create questions.</h2>,
          placement: 'left',
          target: '#end_page',
          title: 'Ending page'
        },
        {
          content: <h2>Click submit to generate questions from your selected range of pages and wait till generating.</h2>,
          placement: 'top',
          target: '#submitPages',
          title: 'Generate questions'
        },
  
      ]
    })

  return (
    <div>
      <MetaTags title='Upload Pages' description='Gamizign Upload Pages page--' />
      {Onboarding <= 1  && <Joyride callback={() => { }} continuous showProgress
        run={run} steps={steps} hideCloseButton scrollToFirstStep showSkipButton 
        styles={{
          buttonNext: { backgroundColor: '#E5F2E3', borderRadius: '5px',color:'black', },
          buttonBack: { backgroundColor: '#E5F2E3',  borderRadius: '5px', color:'black', }
        }}/>}
      <ReUseablePdf selectedDifficulty={selectedDifficulty} questionsRange={questionsRange} submitPages={submitPages}
        isGenerating={isGenerating} isDisabled={isDisabled} file={file} start_page={start_page} setStartPage={setStartPage} setOnboarding={setOnboarding}
        end_page={end_page} setEndPage={setEndPage} EmptySlice={EmptySlice} setEmptySlice={setEmptySlice} ErrorMesg={ErrorMesg}
      />
    </div>
  )
};

export default CreatingPdf;