import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ViewHIcon from '../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/ViewCourse/view course head icon.png'
import EditIcon from '../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/ViewCourse/edit icon.png'
import DeleteIcon from '../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/ViewCourse/delete icon.png'
import TestCourse from '../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/ViewCourse/Test the Course icon.png'
// Activity type
import PdfImage from '../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/ViewCourse/PDF icon hover off color.png'
import GameImage from '../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/ViewCourse/game icon hover off color.png'
import VideoImage from '../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/ViewCourse/video icon hover off color.png'
import ImageImage from '../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/ViewCourse/image icon hover off color.png'
import AudioImage from '../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/ViewCourse/Audio icon hover off color.png'
import urlImage from '../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/ViewCourse/URL icon hover off color.png'
import MultiAcrivitylImage from '../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/ViewCourse/multiactivity  icon hover off.png'
import SaveIcon from '../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/ViewCourse/save icon.png'
import AlertIcon from '../../../../../../assets/icons/Alert-icon.png'
import RadioOffIcon from '../../../../../../assets/icons/ActivityContentIcons/NewCourse/Creating/radio button off.png'
import RadioOnIcon from '../../../../../../assets/icons/ActivityContentIcons/NewCourse/Creating/radio button on.png'
import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Button, CircularProgress, FormControl, InputLabel, MenuItem, Select, TextField, Tooltip, Typography } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LoginHeading from "../../../../../LoginHeading/LoginHeading";
import Modal from 'react-modal'
import LoadingComponent from "../../../../../GlobalLoading/GlobalLoading";
import MetaTags from "../../../../../GlobalLoading/MetaData";
import axiosInstance from "../../../../../../axios";


const ViewCourse = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const course_id = queryParams.get('course_id');


  const [SelectedCourse, setSelectedCourse] = useState(null)
  const [questionsLists, setQuestionsLists] = useState(null)
  const [loading, setLoading] = useState(true);
  const AuthToken = `${sessionStorage.getItem('token')}`
  var instructor_id = parseInt(localStorage.getItem("instructor_id"));
  const [Progress, setProgress] = useState(false);
  useEffect(() => {
    const getCourse = async () => {
      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      };

      try {
        setLoading(true);

        const response = await axiosInstance.get(`${process.env.REACT_APP_ENDPOINT}/courses/${course_id}/`, requestOptions);
        var instructor_id = parseInt(localStorage.getItem("instructor_id"));
        const responseQlist = await axiosInstance.get(`${process.env.REACT_APP_ENDPOINT}/instructors/${instructor_id}/question-lists`, requestOptions);

        setLoading(false);
        // setCourses(data);
        setSelectedCourse(response.data);
        setQuestionsLists(responseQlist.data);
      } catch (error) {
        console.error('Error fetching courses:', error);
        // Handle the error if needed
      } finally {
        setLoading(false);
      }
    };
    getCourse();
  }, []);
  // Delete satge from course and enpoint
  const [courseName, setCourseName] = useState()
  const [stageId, setStageId] = useState()
  const [stageName, setStageName] = useState()
  const [stageIndex, setStageIndex] = useState()
  const [activtyId, setActivtyId] = useState()
  const [activityName, setActivityName] = useState()
  const [stageDelete, setStageDelete] = useState(false)
  const [activityDelete, setActivityDelete] = useState(false)
  const DeleteStage = () => {
    setProgress(true);
    fetch(`${process.env.REACT_APP_ENDPOINT}/stages/${stageId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': AuthToken,
      },
    })
      .then(response => {
        if (response.ok) {
          const selectedCourseCopy = { ...SelectedCourse };
          selectedCourseCopy.stages = selectedCourseCopy.stages.filter(stage => stage.stage_id !== stageId);
          setSelectedCourse(selectedCourseCopy);
          setProgress(false);
          console.log('Stage has been deleted successfully.');
          
        }
        else {
          setProgress(false);
          throw new Error(`Failed to delete stage : ${stageId}`);
        }
      })
      .catch(error => {
        console.error('Error deleting course:', error);
      });

  };
  // Delete Activity from satge/course and enpoint
  const DeleteActivity = () => {
    setProgress(true);
    var req = {
      "activity_id": activtyId,
      "instructor_id": instructor_id,
    };
    fetch(`${process.env.REACT_APP_ENDPOINT}/activities/${activtyId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': AuthToken,
      },
      body: JSON.stringify(req),
    })
      .then(response => {
        if (response.ok) {
          const selectedCourseCopy = { ...SelectedCourse };
          selectedCourseCopy.stages[stageIndex].activities = selectedCourseCopy.stages[stageIndex].activities.filter(activity => activity.activity_id !== activtyId);
          setSelectedCourse(selectedCourseCopy);
          setProgress(false);
          console.log('Activity has been deleted successfully.');
        }
        else {
          setProgress(false);
          throw new Error(`Failed to delete activity : ${activtyId}`);
        }
      })
      .catch(error => {
        console.error('Error deleting course:', error);
      });
  };

  // Stages and Activities Header Color
  const StageColors = ['#ACCCE5', '#BCE2BB', '#DDE6C0', '#FFF3C6', '#FEE7C3', '#FCDCBD', '#F6C1C1', '#DFBFDD'];
  const ActivityColors = ['#DBE6F5', '#E5F2E3', '#F5F9E6', '#FEFAE2', '#FEF7E2', '#FEF1DD', '#FDE4DC', '#EFE1EE'];

  // Culculation of stages duation 
  const parseDate = (dateStr) => {
    const [day, month, year] = dateStr.split("/");
    // JavaScript Date object uses months starting from 0, so subtract 1 from the month
    return new Date(year, month - 1, day);
  };
  const calculateRemainingDays = (startingDate, finishingDate) => {
    const startDate = parseDate(startingDate);
    const endDate = parseDate(finishingDate);
    const duration = Math.abs(endDate - startDate);
    const daysRemaining = Math.ceil(duration / (1000 * 60 * 60 * 24));

    return (daysRemaining / 7).toFixed(0);
  };

  // Formation of date
  const today = new Date();
  const yyyy = today.getFullYear();
  let mm = today.getMonth() + 1; // Months start at 0!
  let dd = today.getDate();
  let hh = today.getHours();
  let min = today.getMinutes();
  let ss = today.getSeconds();

  if (dd < 10) dd = '0' + dd;
  if (mm < 10) mm = '0' + mm;

  const formattedToday = dd + '/' + mm + '/' + yyyy +'  ' + hh + ':' + min + ':' + ss  
  // Change Activit Type 

  const ChangActivityType = (stageId, activityId, newType , activity) => {
    // activity.lastModified  = formattedToday;
    activity.questionList_id = null;
    activity.gameType = null;
    activity.url = null;
    setSelectedCourse((prevCourse) => {
      const updatedStages = prevCourse.stages.map((stage) => {
        if (stage.stage_id === stageId) {
          return {
            ...stage,
            activities: stage.activities.map((activity) =>
              activity.activity_id === activityId
                ? { ...activity, activityType: newType }
                : activity
            ),
          };
        }
        return stage;
      });
      return { ...prevCourse, stages: updatedStages };
    });
    
  };
  // Change game type array 
  const ChangeGame = [
    'Squirrlo', 'Jupitario', 'Deepmarine', 'SpaceX', 'Freesign', 'KanwaKango', 'Lexileague',
     'Trainaid', 'Loopylizer', 'DinerDish', 'SugarSmash', 'StreetSprint', 'CanalTour', 'AntBattel', 'BeastBasket', 'HexMatch',
  ]
  const changeGameType = (stageId, activityId, newGameType) => {
    setSelectedCourse((prevCourse) => {
      const updatedStages = prevCourse.stages.map((stage) => {
        if (stage.stage_id === stageId) {
          return {
            ...stage,
            activities: stage.activities.map((activity) =>
              activity.activity_id === activityId
                ? { ...activity, gameType: Number(newGameType) }
                : activity
            ),
          };
        }
        return stage;
      });
      return { ...prevCourse, stages: updatedStages };
    });
  };
  // Change Question list in activity
  const changeQuestionList = (stageId, activityId, newGameType) => {
    setSelectedCourse((prevCourse) => {
      const updatedStages = prevCourse.stages.map((stage) => {
        if (stage.stage_id === stageId) {
          return {
            ...stage,
            activities: stage.activities.map((activity) =>
              activity.activity_id === activityId
                ? { ...activity, questionList_id: Number(newGameType) }
                : activity
            ),
          };
        }
        return stage;
      });
      return { ...prevCourse, stages: updatedStages };
    });
  };
  // upload file for activity
  const [selectedFile , setSelectedFile] = useState (null)
  const handleFileChange = (files , activity) => {
    const file = files;
    setSelectedFile(file);
    // activity.activity_file_name  = file.name;
    // activity.lastModified  = formattedToday;
    // activity.questionList_id = null;
    // activity.gameType = null;

};

  const UpdateActivityhandle = async (activity) => {
    // e.preventDefault();
    if (selectedFile) {
      uploadFile(selectedFile).then(fileName => {
        activity.activity_file_name = fileName;
      });
    }
    if (activity.activityType === 'game') {
      activity.actionType = 0;
    }else{
      activity.actionType = 1;
      delete activity.analyticsList; 
      activity.activity_case  = (activity.activity_case === null || activity.activity_case === '') && "Task" ;
      activity.answer_exact   = (activity.answer_exact === null || activity.answer_exact === '') && "is exactly" ;
    }

    try {
      const response = await axiosInstance.post(`${process.env.REACT_APP_ENDPOINT}/activity/${activity.activity_id}`, activity);

      if (response.status !== 200) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      window.location.reload();
      // Handle the response data as needed
    } catch (error) {
      console.error('Error:', error);
      // Handle the error as needed
    }
  };
  const ActivityType = (type) => {
    switch (type) {
      case 'pdf':
        return PdfImage;
      case 'game':
        return GameImage;
      case 'image':
        return ImageImage;
      case 'audio':
        return AudioImage;
      case 'video':
        return VideoImage;
      case 'url':
        return urlImage;
      case 'multiActivity':
        return MultiAcrivitylImage;
      default:
        return PdfImage;
    }
  };
  const ActivtyTypeArray = ['game', 'pdf', 'url', 'video', 'audio', 'image', 'multiActivity' ]
  // Url input change
  const [validationError, setValidationError] = useState('');
  const UrlInput = (stageId, activityId, event)=>{
    const inputValue = event;
    const urlRegex = /^https?:\/\//;

    if (urlRegex.test(inputValue) || inputValue === '') {
      setSelectedCourse((prevCourse) => {
        const updatedStages = prevCourse.stages.map((stage) => {
          if (stage.stage_id === stageId) {
            return {
              ...stage,
              activities: stage.activities.map((activity) =>
                activity.activity_id === activityId
                  ? { ...activity, url: inputValue }
                  : activity
              ),
            };
          }
          return stage;
        });
        return { ...prevCourse, stages: updatedStages };
      });
        setValidationError('');
    } else {
        setValidationError('URL must start with "http://".');
    }
  };
  // Uploade file
  const uploadFile = async (file) => {
    return new Promise((resolve, reject) => {

        // Prepare the request
        const url = `${process.env.REACT_APP_ENDPOINT}/attachment?type=activity`;
        const formData = new FormData();
        formData.append('file', file);
        const req = {
            method: 'POST',
            body: formData,
        };

        // Send the request
        fetch(url, req)
            .then(function (response) {
                if (response.ok) {
                    return response.json(); // Extract the file name from the response
                } else {
                    // setUploading(false);
                    throw new Error('Error uploading file');
                }
            })
            .then(data => {
                // setUploading(false);
                resolve(data.message); // Resolve with the file name
            })
            .catch(error => {
                // setUploading(false);
                reject(error);
            });
    });
};

  return (
    <div className='w-full'>
      <MetaTags title={`View the ${SelectedCourse?.name} Course`} description='Gamizign View Course page--' />
      <div className='flex relative px-5 py-2 mb-2 ContentHeadingBg'>
        <h3 className="text-xl">Course Structure</h3>
        <LoginHeading />
      </div>
      <div className="sm:m-7 my-7 mx-4 bg-white border border-2" disabled={Progress}>
      {Progress && (
                    <div className="absolute inset-0 flex justify-center items-center bg-white bg-opacity-75 z-50 h-full">
                        <CircularProgress className="text-blue-800" />
                    </div>
                )}
        <div className='flex justify-center static'><img className='absolute top-6' src={ViewHIcon} alt="" /></div>
        {/* loading */}
        {loading ? (
         <LoadingComponent/>
        ) : (
          <div>
            <div className="bg-gray-100 sm:grid grid-cols-3 px-5 py-2">
            <Tooltip title='Course Name'><div className="bg-white  px-5 py-2 border border-2">{SelectedCourse?.name}</div></Tooltip>
              <div></div>
              <div className="flex">
                <p className="bg-white  px-5 py-2 border border-2">{`Date Created ${SelectedCourse.dateCreated}`}</p>
                {/* <div className="flex gap-2 mt-2.5 ms-5">
                  <span><img src={EditIcon} alt="" /></span>
                  <span><img src={DeleteIcon} alt="" /></span>
                </div> */}
              </div>
            </div>
            <div>
              {/* Stages of Course */}
              <p className={`${SelectedCourse.stages.length === 0 ? "text-xl flex justify-center text-red-500 font-bold mt-5" : "hidden"}`}>No any Stage in this Course</p>
              {SelectedCourse.stages.map((stage, StageIndex) => (
                <div key={StageIndex}>
                  <div className="sm:mx-20 mx-3 my-5 flex">
                    <Accordion className="grow" defaultExpanded>
                      <div style={{ backgroundColor: StageColors[StageIndex % StageColors.length] }}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel3-content"
                          id="panel3-header"
                          sx={{ justifyItems:'center' , display:'flex'}}
                        >
                          <Typography sx={{ color: 'text.secondary' ,width: '20%'}}>{stage.name}</Typography>
                          <Typography sx={{ color: 'text.secondary', width: '80%' }}>
                            <p className="bg-white p-2 max-w-80">{`Date Created ${stage.startingDate}`} {`Duration ${calculateRemainingDays(stage.startingDate, stage.finishingDate)} ${calculateRemainingDays < 2 ? "week" : "weeks"}`}</p>
                            </Typography>
                        </AccordionSummary>
                      </div>
                      {/* Activities Of stage */}
                      <div>
                        <AccordionDetails>
                          <p className={`${stage.activities.length === 0 ? "text-xl flex justify-center text-red-500 font-bold" : "hidden"}`}>No any activity in this Stage</p>
                          {stage.activities && Array.isArray(stage.activities) ? (<div>
                            {stage.activities.map((activity, ActivitiesIndex) => (
                              <div className=" -mt-4 py-2 w-full sm:ms-9 flex" key={ActivitiesIndex}>
                                <Accordion className="grow">
                                  <div className="mb-0.5" style={{ backgroundColor: ActivityColors[StageIndex % ActivityColors.length] }}>

                                    <AccordionSummary
                                      expandIcon={<ExpandMoreIcon />}
                                      aria-controls="panel1-content"
                                      id="panel1-header"
                                    >
                                      <div className="flex relative w-full gap-5">
                                        <p>{activity.name}</p>
                                        {/* <span className=""><img src={EditIcon} alt="" /></span> */}
                                        <Tooltip title={`${activity.activityType} Activity`}><span><img width={30} src={ActivityType(activity.activityType)} alt="" /></span></Tooltip>
                                      </div>
                                    </AccordionSummary>
                                  </div>
                                  <AccordionDetails>
                                    <div>
                                      <div>
                                      <FormControl  fullWidth
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    '& fieldset': {
                                                        border:'2px solid #F3F6F6',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        border: '2px solid #DBE6F5',
                                                    },
                                                    '&:hover fieldset': {
                                                        border: '2px solid #DBE6F5',
                                                    },
                                                },
                                            }}>
                                            <InputLabel>Activity Type</InputLabel>
                                            <Select
                                                labelId="Activity Type"
                                                name="Activity Type"
                                                label="Activity Type"
                                                value={activity.activityType}
                                                onChange={(e) => {ChangActivityType(stage.stage_id, activity.activity_id, e.target.value , activity)}}
                                                style={{ borderRadius: '10px', color: '#2E4EB5', background: '#F3F6F6'}}
                                            >
                                                {ActivtyTypeArray.map((ActivityType) => (
                                                    <MenuItem key={ActivityType} value={ActivityType}>
                                                        {ActivityType}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                      </div>
                                      {/* Url input */}
                                      {activity.activityType === 'url'  && <div>
                                        <TextField
                                          margin="dense"
                                          label="URL"
                                          name="URL"
                                          type="text"
                                          placeholder='http://...........'
                                          fullWidth
                                          defaultValue={activity.url}
                                          onChange={(e) => {UrlInput(stage.stage_id, activity.activity_id, e.target.value);}}
                                          sx={{
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    border:'2px solid #F3F6F6',
                                                },
                                                '&.Mui-focused fieldset': {
                                                    border: '2px solid #DBE6F5',
                                                },
                                                '&:hover fieldset': {
                                                    border: '2px solid #DBE6F5',
                                                },
                                            },
                                        }}
                                        />
                                        {validationError && (<p className="text-red-500">{validationError}</p>)}
                                      </div>}
                                      {(activity.activityType != 'game' && activity.activityType != 'url') && <div className="sm:flex">
                                        <TextField
                                          margin="dense"
                                          label="Activity File"
                                          name="Activity File"
                                          type="file"
                                          // accept={activity.activityType === 'image' ? 'image/*' : activity.activityType === 'video' ? '.mp4, .webm' : activity.activityType === 'audio' ? 'audio/*' : '.pdf'}
                                          fullWidth
                                          // value={activity.activity_file_name}
                                          onChange={(e) => handleFileChange(e.target.files[0], activity)}
                                          sx={{
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    border:'2px solid #F3F6F6',
                                                },
                                                '&.Mui-focused fieldset': {
                                                    border: '2px solid #DBE6F5',
                                                },
                                                '&:hover fieldset': {
                                                    border: '2px solid #DBE6F5',
                                                },
                                            },
                                        }}
                                        />
                                      </div>}
                                      {activity.activityType !== "game" && (
                                        <div>
                                          <TextField
                                          margin="dense"
                                          label="Activity question\description"
                                          name="Activity question\description"
                                          type="text"
                                          fullWidth
                                          defaultValue={activity.description}
                                          onChange={(e) =>  (activity.description = e.target.value)}
                                          sx={{
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    border:'2px solid #F3F6F6',
                                                },
                                                '&.Mui-focused fieldset': {
                                                    border: '2px solid #DBE6F5',
                                                },
                                                '&:hover fieldset': {
                                                    border: '2px solid #DBE6F5',
                                                },
                                            },
                                        }}
                                        />
                                          <TextField
                                          margin="dense"
                                          label="Student answer requirement"
                                          name="Student answer requirement"
                                          type="text"
                                          fullWidth
                                          defaultValue={activity.answer_requirement}
                                          onChange={(e) =>  (activity.answer_requirement = e.target.value)}
                                          sx={{
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    border:'2px solid #F3F6F6',
                                                },
                                                '&.Mui-focused fieldset': {
                                                    border: '2px solid #DBE6F5',
                                                },
                                                '&:hover fieldset': {
                                                    border: '2px solid #DBE6F5',
                                                },
                                            },
                                        }}
                                        />
                                        </div>)}
                                      {activity.activityType === "game" && (
                                        <div>
                                      <FormControl margin="dense" fullWidth
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    '& fieldset': {
                                                        border:'2px solid #F3F6F6',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        border: '2px solid #DBE6F5',
                                                    },
                                                    '&:hover fieldset': {
                                                        border: '2px solid #DBE6F5',
                                                    },
                                                },
                                            }}>
                                            <InputLabel>Game Type</InputLabel>
                                            <Select
                                                labelId="Game Type"
                                                name="Game Type"
                                                label="Game Type"
                                                value={activity.gameType}
                                                onChange={(e) =>changeGameType(stage.stage_id, activity.activity_id, e.target.value)}
                                                style={{ borderRadius: '10px', color: '#2E4EB5', background: '#F3F6F6'}}
                                            >
                                                {ChangeGame.map((game, index) => (
                                                    <MenuItem key={game} value={index}>
                                                        {game}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                      <FormControl margin="dense" fullWidth
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    '& fieldset': {
                                                        border:'2px solid #F3F6F6',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        border: '2px solid #DBE6F5',
                                                    },
                                                    '&:hover fieldset': {
                                                        border: '2px solid #DBE6F5',
                                                    },
                                                },
                                            }}>
                                            <InputLabel>Questions list</InputLabel>
                                            <Select
                                                labelId="Questions list"
                                                name="Questions list"
                                                label="Questions list"
                                                value={activity.questionList_id}
                                                onChange={(e) => changeQuestionList(stage.stage_id, activity.activity_id, e.target.value)}
                                                style={{ borderRadius: '10px', color: '#2E4EB5', background: '#F3F6F6'}}
                                            >
                                                {questionsLists.map((questionsList, index) => (
                                                    <MenuItem sx={{ display: `${questionsList.mainTopic === SelectedCourse.mainTopic ? '' : 'none'}` }}
                                                    key={questionsList} value={questionsList.questionList_id}>
                                                        {questionsList.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                      </div>)}
                                      <div className="flex justify-center mt-10">
                                      <button onClick={()=>UpdateActivityhandle(activity)} className='BackBtn flex p-2 px-7 hover:bg-blue-200'><b> Save Activity </b><img className='ml-5 mt-1' src={SaveIcon} alt="Back" /></button>

                                      </div>


                                    </div>
                                  </AccordionDetails>
                                </Accordion>
                                <Tooltip title='Delete Activity'><img className="w-6 h-6 flrx-none ms-3 sm:me-5 mt-5 cursor-pointer" src={DeleteIcon} alt="DeleteActivity" onClick={() => { setActivtyId(activity.activity_id); setActivityName(activity.name); setStageIndex(StageIndex); setCourseName(SelectedCourse.name); setActivityDelete(true) }} /></Tooltip>

                              </div>
                            ))}
                          </div>) : (<div>NO any Activity in This Stage  </div>)}


                        </AccordionDetails>
                      </div>
                      <AccordionActions>

                      </AccordionActions>
                    </Accordion>
                    <Tooltip title='Delete Stage'><img className="w-6 h-6 flrx-none ms-3 mt-7 cursor-pointer" src={DeleteIcon} alt="DeleteStage" onClick={() => { setStageId(stage.stage_id); setStageName(stage.name); setCourseName(SelectedCourse.name); setStageDelete(true) }} /></Tooltip>
                  </div>

                </div>
              ))}

              {/* <div className="flex justify-center my-10 gap-5">
                <img src={TestCourse} alt="" />
                <p >Test the Course</p>
              </div> */}
            </div>
          </div>
        )}
        {/* Delete Stage pop up */}
        <Modal
          isOpen={stageDelete}
          onRequestClose={() => setStageDelete(false)}
          className="Modal flex justify-center mt-40"
        // overlayClassName="Overlay"
        >
          {/* Modal Content */}
          <div className="flex sm:ms-20" >
            <div className="sm:ms-20 mx-20 sm:mx-0 drop-shadow-xl bg-gray-100 flex justify-center px-5" style={{ width: "", borderRadius: "20px 20px" }}>
              <div className="mt-5">
                <div className="flex justify-center"><img width={50} src={AlertIcon} alt="" /></div>
                <h1 className={`sm:text-2xl `}>Are you sure you want to <b> Delete </b> the <b>{stageName}</b> from <b>{courseName}</b>?</h1>
                <div className="flex justify-center gap-8 my-4">
                  <button className="hover:bg-red-200 px-2" onClick={() => { setStageDelete(false); DeleteStage() }}>Yes</button>
                  <button onClick={() => setStageDelete(false)}>No</button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        {/* Delete Activity pop up */}
        <Modal
          isOpen={activityDelete}
          onRequestClose={() => setActivityDelete(false)}
          className="Modal flex justify-center mt-40"
        // overlayClassName="Overlay"
        >
          {/* Modal Content */}
          <div className="flex sm:ms-20" >
            <div className="sm:ms-20 mx-20 sm:mx-0 drop-shadow-xl bg-gray-100 flex justify-center px-5" style={{ width: "", borderRadius: "20px 20px" }}>
              <div className="mt-5">
                <div className="flex justify-center"><img width={50} src={AlertIcon} alt="" /></div>
                <h1 className={`sm:text-2xl `}>Are you sure you want to <b> Delete </b> the <b>{activityName}</b> from <b>{stageName} of {courseName}</b>?</h1>
                <div className="flex justify-center gap-8 my-4">
                  <button className="hover:bg-red-200 px-2" onClick={() => { DeleteActivity(); setActivityDelete(false) }}>Yes</button>
                  <button onClick={() => setActivityDelete(false)}>No</button>
                </div>
              </div>
            </div>
          </div>
        </Modal>

      </div>
    </div>
  )
};

export default ViewCourse;
