import React from "react";
import { useLocation } from "react-router-dom";
import LoginHeading from "../../../../../LoginHeading/LoginHeading";
import MetaTags from "../../../../../GlobalLoading/MetaData";


const CourseSetting = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const course_id = queryParams.get('course_id');
    return(
        <div className='w-full'>
          <MetaTags title='Course Setting' description='Gamizign Course Setting page--' />
        <div className='flex relative px-5 py-2 mb-2 ContentHeadingBg'>
          <h3 className="text-xl">Course Settings</h3>
          <LoginHeading/>
          </div>
        <div className="m-7 bg-white contentSide">
        <h1 className="text-2xl">You can change course settings here</h1>
        </div>
    </div>
    )
};

export default CourseSetting;
