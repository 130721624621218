import React, { useState, useEffect } from "react";
import Addfolder from '../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/create group icon.png'
import GridIconOn from '../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/grid icon on.png'
import GridIconOff from '../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/grid icon off.png'
import ListIconOn from '../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/list icon on.png'
import ListIconOff from '../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/list icon off.png'
import CheckBoxOn from '../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/PPTX check box on.png'
import CheckBoxOff from '../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/PPTX check box off.png'
import GamizignHIcon from '../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Gamizign head icon.png'
import CourseDoneIcon from '../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/course done icon.png'
import CourseLock from '../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/course locked icon.png'
import CourseUnLock from '../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/course open icon.png'
import CourseActive from '../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/course active icon.png'
import CourseNotActive from '../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/course not active icon.png'
import LearnerTotal from '../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/total learners number icon.png'
import LearnerDone from '../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/learners done number icon.png'
import EditQuestoin from '../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/edit icon.png'
import DeletQuestoin from '../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/delete icon.png'
import PdfImage from '../../../../../assets/icons/ActivityContentIcons//Gamizign_Courses/PDF icon hover off color.png'
import GameImage from '../../../../../assets/icons/ActivityContentIcons//Gamizign_Courses/game icon hover off color.png'
import VideoImage from '../../../../../assets/icons/ActivityContentIcons//Gamizign_Courses/video icon hover off color.png'
import ImageImage from '../../../../../assets/icons/ActivityContentIcons//Gamizign_Courses/image icon hover off color.png'
import AudioImage from '../../../../../assets/icons/ActivityContentIcons//Gamizign_Courses/Audio icon hover off color.png'
import urlImage from '../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/ViewCourse/URL icon hover off color.png'
import MultiAcrivitylImage from '../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/ViewCourse/multiactivity  icon hover off.png'
import NextIcon from '../../../../../assets/icons/ActivityContentIcons//Gamizign_Courses/Next icon.png'
import BackIcon from '../../../../../assets/icons/ActivityContentIcons//Gamizign_Courses/back iconB.png'
import CreateNewQuestion from '../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/New Course icon.png'
import RadioOnIcon from '../../../../../assets/icons/ActivityContentIcons/NewCourse/Patch4-MultiActivity/radio button on.png'
import RadioOffIcon from '../../../../../assets/icons/ActivityContentIcons/NewCourse/Patch4-MultiActivity/radio button off.png'
import AlertIcon from '../../../../../assets/icons/Alert-icon.png'
import { Link } from "react-router-dom";
import Modal from 'react-modal'
import LoginHeading from "../../../../LoginHeading/LoginHeading";
import { Button, Dialog, Alert, DialogContent, Skeleton, Tooltip } from '@mui/material';
import LoadingComponent from "../../../../GlobalLoading/GlobalLoading";
import CoursesSkeleton from "./Components/CoursesSkeleton";
import MetaTags from "../../../../GlobalLoading/MetaData";
import GroupeCourses from "./Components/GroupesCourses";
import GroupActivities from "./Components/GroupesActivities";
import Joyride from "react-joyride";
import axiosInstance from "../../../../../axios";


const Gamizign = () => {
  // Grid and List view icon toggle
  const AuthToken = `${sessionStorage.getItem('token')}`;
  const [currentImageGrid, setCurrentImageGrid] = useState(GridIconOff);
  const toggleImageView = () => {
    setCurrentImageGrid((prevImage) => (prevImage === GridIconOff ? GridIconOn : GridIconOff));
  };
  // Courses checkBox in or out icon toggle
  const [coursesCheckbox, setCoursesCheckbox] = useState(RadioOnIcon);
  const toggleCeoursesCheckbox = () => {
    setCoursesCheckbox((prevImage) => (prevImage === RadioOnIcon ? RadioOffIcon : RadioOnIcon));
  };
  // Activities check in or out icon toggle
  const [activitiesCheckbox, setActivitiesCheckbox] = useState(CheckBoxOn);
  const toggleActivitiesCheckbox = () => {
    setActivitiesCheckbox((prevImage) => (prevImage === CheckBoxOn ? CheckBoxOff : CheckBoxOn));
  };

  // Course Status icon toggle
  const [CourseIndex, setCourseIndex] = useState(0);
  const [CourseStatus, setCourseStatus] = useState();
  const toggleCourseStatus = () => {
    const updatatedcourses = [...filteredCourses];
    updatatedcourses[CourseIndex].unlock = !updatatedcourses[CourseIndex].unlock;
    setFilteredCourses(updatatedcourses);
    // setCourseStatus((prevImage) => (prevImage === CourseLock ? CourseUnLock : CourseLock));
    setBotmodalIsOpen(false);
  };
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  var instructor_id = parseInt(localStorage.getItem("instructor_id"));
  var token = sessionStorage.getItem("token");

  useEffect(() => {
    const getCourses = async () => {
      try {
        setLoading(true);

        const response = await axiosInstance.get(`${process.env.REACT_APP_ENDPOINT}/instructors/${instructor_id}/courses`);

        setLoading(false);
        setCourses(response.data);
        setFilteredCourses(response.data);
      } catch (error) {
        console.error('Error fetching courses:', error);
        localStorage.clear(); window.location.href = "/";
        alert("Session expired please loggin again!");
        // Handle the error if needed
      } finally {
        setLoading(false);
      }
    };

    getCourses();
  }, []);
  // Search Courses
  const [filteredCourses, setFilteredCourses] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');


  const handleSearch = (e) => {
    setSearchTerm(e.target.value);

    const lowercasedSearchTerm = e.target.value.toLowerCase();

    const filteredLists = courses.filter((courses) => {
      return courses.name.toLowerCase().includes(lowercasedSearchTerm);
    });

    setFilteredCourses(filteredLists);
  };
  // PopUp here Course Lock and unlock
  const [CourseName, setCourseName] = useState('');
  const [botmodalIsOpen, setBotmodalIsOpen] = useState(false);
  const openBotModal = () => {
    // setBotmodalIsOpen(true);
  };
  const BotcloseModal = () => {
    setBotmodalIsOpen(false);
  };
  // Bg color of course
  const ActivityCourseColors = ['#DBE6F5', '#E5F2E3', '#F5F9E6', '#FEFAE2', '#FEF7E2', '#FEF1DD', '#FDE4DC', '#EFE1EE'];

  // Delete course Request to API
  const [courseId, setCourseId] = useState();
  const handleDeleteCourse = () => {
    const updatedCourses = filteredCourses.filter(course => course.course_id !== courseId);
    setFilteredCourses(updatedCourses);
    setDeleteDialog(false);
    var req = {
      "course_id": courseId,
      "instructor_id": instructor_id,
    };
    fetch(`${process.env.REACT_APP_ENDPOINT}/courses/${courseId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': AuthToken,
      },
      body: JSON.stringify(req),
    })
      .then(response => {
        if (response.ok) {
          console.log(CourseName, 'course has been deleted successfully');
        }
        else {
          throw new Error(`Failed to delete course ${courseId}`);
        }
      })
      .catch(error => {
        console.error('Error deleting course:', error);
      });
  };
  // hide course
  const handleHideCourse = (CourseIndex) => {
    // const updatedCourses = filteredCourses.filter(course => course.course_id !== course_id);
    const updatatedcourses = [...filteredCourses];
    // updatatedcourses.splice(CourseIndex, 1)
    updatatedcourses[CourseIndex].view = !updatatedcourses[CourseIndex].view;
    setFilteredCourses(updatatedcourses);
    // setCoursebotmodalIsOpen(false);
  };
  // lock course based on finishing date of last stage
  const parseDate = (dateString) => {
    const [day, month, year] = dateString.split('/').map(Number);
    return new Date(year, month - 1, day);
  };

  useEffect(() => {
    const updatedCourses = courses.map((course) => {
      let isUnlock = false
      if (course.stages.length > 0) {
        const lastStage = course.stages[course.stages.length - 1];
        isUnlock = parseDate(lastStage.finishingDate) > new Date();
      }
      return { ...course, unlock: isUnlock };
    });
    setFilteredCourses(updatedCourses);
  }, [courses])
  // Delete Course PopUp
  const [DeleteDialog, setDeleteDialog] = useState(false);
  const [deleteType, setDeleteType] = useState('');
  const DeleteDialogOpen = (type) => {
    setDeleteType(type);
    setDeleteDialog(true);
  };
  const DeleteDialogClose = () => {
    setDeleteDialog(false);
  };
  // Delete Activity PopUp
  // const [ActivitybotmodalIsOpen, setActivitybotmodalIsOpen] = useState(false);
  // const openActivityBotModal = () => {
  //   setActivitybotmodalIsOpen(true);
  // };
  // const ActivityBotcloseModal = () => {
  //   setActivitybotmodalIsOpen(false);
  // };
  // delete Activities
  const [Activity_id, setActivity_id] = useState(0);
  const [ActivityName, setActivityName] = useState('');
  const deleteActivities = () => {
    setDeleteDialog(false);
    const updatedActivities = activities.filter(activity => activity.activity_id !== Activity_id);
    setActivities(updatedActivities);
    var req = {
      "activity_id": Activity_id,
      "instructor_id": instructor_id,
    };
    fetch(`${process.env.REACT_APP_ENDPOINT}/activities/${Activity_id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': AuthToken,
        // You might need to include additional headers like authorization token
      },
      body: JSON.stringify(req),
    })
      .then(response => {
        if (response.ok) {
          console.log(ActivityName, 'has been deleted successfully.');
        } else {
          throw new Error(ActivityName, 'Failed to delete.');
        }
        return response.json();
      })
      .then(data => {
        // Handle the successful response data here
      })
      .catch(error => {
        console.error('Error deleting activities:', error);
        // Handle errors here
      });
  }
  // seprate array for Activites
  const [activities, setActivities] = useState([]);
  const extractActivities = () => {
    const allActivities = filteredCourses.flatMap(course => {
      return course.stages.flatMap(stage =>
        stage.activities.map(activity => ({
          ...activity,
          stageName: stage.name,
          courseName: course.name,
          lerners: course.students.length,
          background: course.background,
          mainTopic: course.mainTopic,
          unlock: course.unlock
        }))
      );
    });

    setActivities(allActivities);
  };


  // Call extractActivities when filteredCourses changes or when needed
  useEffect(() => {
    extractActivities();
  }, [filteredCourses]);
  // players of each course
  const getUniqueStudentCount = (course) => {
    const uniqueStudentIds = new Set();

    course.stages.forEach((stage) => {
      stage.activities.forEach((activity) => {
        activity.analyticsList.forEach((analytics) => {
          uniqueStudentIds.add(analytics.student_id);
        });
      });
    });

    return uniqueStudentIds.size;
  };
  // Make array for Pigination  of Activities table
  const [currentPageactivities, setCurrentPage] = useState(1);
  const rowsPerPageactivities = 5;

  const indexOfLastactivities = currentPageactivities * rowsPerPageactivities;
  const indexOfFirstactivities = indexOfLastactivities - rowsPerPageactivities;
  const activitiesPigination = activities.slice(indexOfFirstactivities, indexOfLastactivities);

  const totalPagesactivities = Math.ceil(activities.length / rowsPerPageactivities);

  const handleNextPageactivities = () => {
    if (currentPageactivities < totalPagesactivities) {
      setCurrentPage(currentPageactivities + 1);
    }
  };

  const handlePrevPageactivities = () => {
    if (currentPageactivities > 1) {
      setCurrentPage(currentPageactivities - 1);
    }
  };

  // Make array for Pigination  of courses table
  const [currentPageCourses, setCurrentPageCourses] = useState(1);
  const rowsPerPageCourses = 5;

  const indexOfLastCourses = currentPageCourses * rowsPerPageCourses;
  const indexOfFirstCourses = indexOfLastCourses - rowsPerPageCourses;
  const CoursesPigination = filteredCourses.slice(indexOfFirstCourses, indexOfLastCourses);

  const totalPagesCourses = Math.ceil(filteredCourses.length / rowsPerPageCourses);

  const handleNextPageCourses = () => {
    if (currentPageCourses < totalPagesCourses) {
      setCurrentPageCourses(currentPageCourses + 1);
    }
  };

  const handlePrevPageCourses = () => {
    if (currentPageCourses > 1) {
      setCurrentPageCourses(currentPageCourses - 1);
    }
  };
  // Actvity type icon
  const ActivityType = (type) => {
    switch (type) {
      case 'pdf':
        return PdfImage;
      case 'game':
        return GameImage;
      case 'image':
        return ImageImage;
      case 'audio':
        return AudioImage;
      case 'video':
        return VideoImage;
      case 'url':
        return urlImage;
      case 'multiActivity':
        return MultiAcrivitylImage;
      default:
        return PdfImage;
    }
  };
  // Groupes of courses
  const [GroupesCourses, setGroupesCourses] = useState(true);
  // Onboarding
  const [{ run, steps }, setState] = useState({
    run: true,
    steps: [
      {
        content: <h2>Upload image for any question.</h2>,
        placement: 'right',
        target: '#Uploadimages',
      },
      {
        content: <div><h2>From here you can create new course.</h2>
          <Link className='border py-1 px-3 bg-gray-100 rounded mt-2 hover:bg-blue-200' to="/Dashboard/CreateCourse"  >Click Here to start creating course</Link></div>,
        placement: 'top',
        target: '#createCourse',
      },

    ]
  })
  return (
    <div className='w-full'>
      <MetaTags title='Courses' description='Gamizign Courses page--' />
      <div className='flex px-5 py-2 mb-2 relative ContentHeadingBg'>
        <h3 className="text-xl">Gamizign</h3>
        <LoginHeading />
      </div>
      <div className="m-7 bg-white">
      {!loading && courses.length === 0  &&<Joyride callback={() => { }} 
          run={run} steps={steps} scrollToFirstStep showSkipButton
          styles={{
            buttonNext: { backgroundColor: '#E5F2E3', borderRadius: '5px',color:'black', },
            buttonBack: { backgroundColor: '#E5F2E3',  borderRadius: '5px', color:'black', }
          }} />}
        {/* PopUp for Course Delete */}
        <Dialog className="sm:ms-56" open={DeleteDialog} onClose={DeleteDialogClose}>
          <DialogContent>
            <Alert severity='warning'>
              Are you sure you want to delete the {`${deleteType === 'course' ? 'course' : 'activity'}`} <b>{`${deleteType === 'course' ? CourseName : ActivityName}`}</b> ?
            </Alert>
          </DialogContent>
          {deleteType === 'course' ?
            <div className='flex justify-center'>
              <Button onClick={handleDeleteCourse} color="primary">
                Yes
              </Button>
              <Button onClick={DeleteDialogClose} color="primary">
                No
              </Button>
            </div>
            :
            <div className='flex justify-center'>
              <Button onClick={deleteActivities} color="primary">
                Yes
              </Button>
              <Button onClick={DeleteDialogClose} color="primary">
                No
              </Button>
            </div>
          }
        </Dialog>
        <div className='flex justify-center static'><img className='absolute top-6' src={GamizignHIcon} alt="" /></div>
        <div className="px-7 pt-10">
          <div className="flex py-2 relative px-3" style={{ borderBottom: "2px gray solid" }}>
            {loading ? <Skeleton animation='wave' variant="rounded" width={100} height={30} /> : <p><b>Name</b></p>}

            {loading ? <div className="flex gap-3 absolute right-3"><Skeleton animation='wave' variant="rounded" width={100} height={30} /></div> :
              <div className="flex gap-3 absolute right-3">
                {/* <img src={Addfolder} alt="1" /> */}
                <Tooltip title="Grid View"> <img className="cursor-pointer" src={currentImageGrid} alt='Grid View' onClick={toggleImageView} /></Tooltip>
                <Tooltip title="Table View"><img className="cursor-pointer" src={currentImageGrid === GridIconOff ? ListIconOn : ListIconOff} alt="3" onClick={toggleImageView} /></Tooltip>
              </div>}
          </div>
        </div>
        {/* Grid view courses */}
        <div className={`${currentImageGrid === GridIconOn ? 'hidden' : ''}`}>
          <div className="px-7 pt-3 sm:flex gap-5">
            {loading ? <Skeleton animation='wave' variant="rounded" width={200} height={40} /> : <span><input id="searchInput" value={searchTerm} onChange={handleSearch} className="px-2 lg:px-5 sm:px-5 lg:w-80 py-2 border  rounded " type="text" placeholder="Search Course Name" style={{ width: '' }} /></span>}

            {loading ? <Skeleton className="mt-2 sm:mt-0 className='cursor-pointer'" animation='wave' variant="rounded" width={220} height={40} /> :
              <div className="flex mt-2 sm:mt-1 gap-3">
                <div className="flex"><img className="cursor-pointer m-auto" src={coursesCheckbox} onClick={toggleCeoursesCheckbox} alt="" /> <span className="ps-1 mt-1 sm:mt-1.5"><b>Courses</b></span></div>
                <div className="flex "><img className="cursor-pointer m-auto" src={coursesCheckbox === RadioOffIcon ? RadioOnIcon : RadioOffIcon} onClick={toggleCeoursesCheckbox} alt="" /> <span className="ps-1 mt-1 sm:mt-1.5"><b>Activities</b></span></div>
                <div className="flex sm:mt-1"><img className="cursor-pointer" src={GroupesCourses ? CheckBoxOn : CheckBoxOff} alt="" onClick={() => { setGroupesCourses(prev => !prev); }} style={{ height: '30px' }} /> <span className="ps-1 mt-1"><b>Groups</b></span></div>
              </div>}
          </div>
          <div>
            {/*bot modal course unlock */}
            <Dialog className="sm:ms-56" open={botmodalIsOpen} onClose={BotcloseModal}>
              <DialogContent>
                {!CourseStatus == true ? (
                  <h1 className="text-2xl">Are you sure you want to <b>unlock</b> the course <b>{CourseName}</b>?</h1>
                ) : (
                  <h1 className="text-2xl">Are you sure you want to <b>lock</b> the course <b>{CourseName}</b>?</h1>
                )}
              </DialogContent>
              <div className='flex justify-center'>
                <Button onClick={toggleCourseStatus} color="primary">
                  Yes
                </Button>
                <Button onClick={BotcloseModal} color="primary">
                  No
                </Button>
              </div>
            </Dialog>
            {loading ? (
              <CoursesSkeleton />
            ) : (
              <div className={` mt-10 ${coursesCheckbox === RadioOffIcon ? 'hidden' : ''}`}>
                <div className={`${GroupesCourses ? '' : 'hidden'}`}>
                  <GroupeCourses filteredCourses={filteredCourses} setCourseName={setCourseName} openBotModal={openBotModal} DeleteDialogOpen={DeleteDialogOpen}
                    setCourseIndex={setCourseIndex} setCourseStatus={setCourseStatus} getUniqueStudentCount={getUniqueStudentCount} handleHideCourse={handleHideCourse} setCourseId={setCourseId} />
                </div>
                <div className={`grid grid-cols-1 sm:grid sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-5 gap-5 2xl:px-40 sm:px-5 px-5 mt-5`}>
                  {filteredCourses.length > 0 ? (
                    filteredCourses.map((course, CourseIndex) => (
                      <div key={CourseIndex} className={`${GroupesCourses ? 'hidden' : ''}`}>
                        {course.view ? (
                          <div>
                            <p className="flex justify-center pt-20 pb-3 sm:text-sm"><b>{course.name}</b></p>
                            <p className="flex justify-center"><Tooltip title="Show Course"><img className="cursor-pointer" onClick={() => { setCourseName(course.name); handleHideCourse(CourseIndex) }} src={course.view ? CourseNotActive : CourseActive} alt="" /></Tooltip></p>
                          </div>
                        ) : (
                          <div>
                            <div className={`border relative ${course.view ? 'hidden' : ''}`} style={{ borderRadius: '20px', height: "250px", backgroundColor: ActivityCourseColors[CourseIndex % ActivityCourseColors.length] }}>
                              <div className="" style={{
                                backgroundImage: course.background ? `url(${process.env.REACT_APP_ENDPOINT}/uploads/${course.background})` : 'none',
                                backgroundSize: 'cover', borderRadius: '20px', height: "200px", backgroundPosition: 'center', backgroundRepeat: 'no-repeat',
                              }}></div>
                              <div className="bg-slate-600 text-white  absolute inset-x-0 bottom-0 shadow-xl" style={{ borderRadius: '20px', height: "" }}>
                                <div className="flex justify-center" >
                                  <Tooltip title={getUniqueStudentCount(course) === course.students.length ? 'All Players Played' : course.unlock ? 'Lock Course' : 'Unlock Course'}><img className="cursor-pointer" onClick={() => { openBotModal(); setCourseName(course.name); setCourseIndex(CourseIndex); setCourseStatus(course.unlock) }}
                                    src={getUniqueStudentCount(course) === course.students.length ? CourseDoneIcon : course.unlock ? CourseUnLock : CourseLock} alt="" style={{ marginTop: "-25px" }} /></Tooltip>
                                </div>
                                <Tooltip title="Analysis"><Link to={`/Course?course_id=${course.course_id}`}>
                                  <p className="flex justify-center pt-5 pb-3 sm:text-sm text-center"><b>{course.name}</b></p>
                                  <p className="flex justify-center mt-3 sm:text-sm"></p>
                                </Link></Tooltip>
                              </div>
                            </div>
                            <div className="flex gap-9 justify-center mt-2 border py-2" style={{ borderRadius: "10px" }}>
                              <Tooltip title="Hide Course"><img className="cursor-pointer" onClick={() => { setCourseName(course.name); handleHideCourse(CourseIndex) }} src={course.view ? CourseNotActive : CourseActive} alt="" /></Tooltip>
                              <Tooltip title="Edit"><img src={EditQuestoin} alt="" /></Tooltip>
                              <Tooltip title="Delete"><img className="cursor-pointer" src={DeletQuestoin} alt="" onClick={() => { DeleteDialogOpen('course'); setCourseName(course.name); setCourseId(course.course_id) }} /></Tooltip>
                            </div>
                            <div className="flex gap-5 justify-center border py-2" style={{ borderRadius: "10px" }}>
                              <Tooltip title="Players"><span className="relative"><img src={LearnerTotal} alt="" /><span className={`absolute inset-0 top-0.5 ${getUniqueStudentCount(course) <= 9 ? 'left-2.5' : 'left-1'}`}>{getUniqueStudentCount(course)}</span> </span></Tooltip>
                              <p>Learners</p>
                              <Tooltip title="Enrolled Students"><span className="relative"><img src={LearnerDone} alt="" /><span className={`absolute inset-0 top-0.5 ${course.students.length <= 9 ? 'left-2.5' : 'left-1'}`}>
                                {course.students.length}
                              </span> </span></Tooltip>
                            </div>
                          </div>)}
                      </div>
                    ))
                  ) : (
                    <div className="text-gray-500">No courses available.</div>
                  )}
                </div>
              </div>
            )}
          </div>
          {!loading && <div className={` mt-10 ${coursesCheckbox === RadioOffIcon ? '' : 'hidden'}`}>
            <div className={`${GroupesCourses ? '' : 'hidden'}`}>
              <GroupActivities activities={activities} DeleteDialogOpen={DeleteDialogOpen} setActivity_id={setActivity_id} setActivityName={setActivityName} ActivityType={ActivityType} />
            </div>
            <div className="grid grid-cols-1 px-5 sm:grid sm:grid-cols-2 md:grid-cols-3  sm:px-5 xl:grid-cols-5 gap-5 2xl:px-40 mt-5 ">
              {/* Activities of courses  Grid view*/}
              {activities.length > 0 ? (activities.map((activity, activityIndex) => (
                <div key={activityIndex} className={`${GroupesCourses ? 'hidden' : ''}`}>
                  <div className="border relative" style={{ borderRadius: '20px', height: "250px", backgroundColor: ActivityCourseColors[activityIndex % ActivityCourseColors.length] }}>
                    <div className="" style={{
                      backgroundImage: activity.background ? `url(${process.env.REACT_APP_ENDPOINT}/uploads/${activity.background})` : 'none',
                      backgroundSize: 'cover', borderRadius: '20px', height: "200px", backgroundPosition: 'center', backgroundRepeat: 'no-repeat',
                    }}></div>
                    <div className={`flex justify-center mt-3 absolute inset-x-0 top-0`}><Tooltip title={`Activity Type ${activity.activityType}`}><img src={ActivityType(activity.activityType)} alt="ActivityTYpe" /></Tooltip></div>
                    <div className="bg-gray-900 text-white  absolute inset-x-0 bottom-0 shadow-xl" style={{ borderRadius: '20px', height: "" }}>
                      <div className="flex justify-center">
                        <Tooltip title={activity.analyticsList.length === activity.lerners ? 'All Players Played' : activity.unlock ? 'Unlocked Activity' : ' Locked Activity'}><img className="cursor-pointer"
                          src={activity.analyticsList.length === activity.lerners ? CourseDoneIcon : activity.unlock ? CourseUnLock : CourseLock} alt="" style={{ marginTop: "-25px" }} /></Tooltip>
                      </div>
                      <p className="flex justify-center text-center	 pt-2 text-sm"><b>{activity.name}</b></p>
                      <p className="flex justify-center text-center	 mt-2 text-sm"><b>{activity.courseName}</b></p>
                      <p className="flex justify-center mt-2 text-sm"><b>{activity.stageName}</b></p>
                    </div>
                  </div>
                  <div className="flex gap-7 justify-center mt-2 border py-2" style={{ borderRadius: "10px" }}>
                    {/* <img src={CourseNotActive} alt="" /> */}
                    {/* <img src={EditQuestoin} alt="" /> */}
                    <Tooltip title='Delete'><img className="cursor-pointer" src={DeletQuestoin} alt="" onClick={() => { setActivityName(activity.name); setActivity_id(activity.activity_id); DeleteDialogOpen('activity') }} /></Tooltip>
                  </div>
                  <div className="flex gap-5 justify-center border py-2" style={{ borderRadius: "10px" }}>
                    <Tooltip title='Players'><span className="relative"><img src={LearnerTotal} alt="" /><span className={`absolute inset-0 top-0.5 ${activity.analyticsList.length <= 9 ? 'left-2.5' : 'left-1'}`}>{activity.analyticsList.length}</span> </span></Tooltip>
                    <p>Learners</p>
                    <Tooltip title='Total Enrolment'> <span className="relative"><img src={LearnerDone} alt="" /><span className={`absolute inset-0 top-0.5 ${activity.lerners <= 9 ? 'left-2.5' : 'left-1'}`}>{activity.lerners}</span> </span></Tooltip>
                  </div>
                </div>
              ))) : (<div className="text-gray-500">No activity available.</div>)}
            </div>
          </div>}
        </div>
        {/* List view courses */}
        <div className={`${currentImageGrid === GridIconOff ? 'hidden' : ''}`}>
          <div className="px-7 pt-3 sm:flex gap-5">
            <div>
              {loading ? <Skeleton animation='wave' variant="rounded" width={200} height={40} /> : <span><input id="searchInput" value={searchTerm} onChange={handleSearch} className="px-2 sm:px-5 py-2 border  rounded lg:w-80" type="text" placeholder="Search Course Name" style={{ width: '' }} /></span>}
            </div>
            {loading ? <Skeleton className="mt-2 sm:mt-0 className='cursor-pointer'" animation='wave' variant="rounded" width={180} height={40} /> :
              <div className="flex mt-2 sm:mt-1 gap-3">
                <div className="flex "><img className='cursor-pointer m-auto' src={coursesCheckbox} onClick={toggleCeoursesCheckbox} alt="" /> <span className="ps-1 m-auto sm:mt-1.5"><b>Courses</b></span></div>
                <div className="flex "><img className='cursor-pointer m-auto' src={coursesCheckbox === RadioOffIcon ? RadioOnIcon : RadioOffIcon} alt="" onClick={toggleCeoursesCheckbox} /> <span className="ps-1 m-auto sm:mt-1.5"><b>Activities</b></span></div>
              </div>}
          </div>
          <div className="gap-4  mt-5">
            {/* courses List view*/}
            <div className={`${coursesCheckbox === RadioOffIcon ? 'hidden' : ''}`}>
              {loading ? (
                <LoadingComponent />
              ) :
                (
                  <div>
                    <div className={`relative mx-2 sm:mx-7 overflow-x-auto max-w-80 sm:max-w-full`}>
                      <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                        <thead class="text-gray-700 uppercase dark:bg-gray-700 dark:text-gray-400">
                          <tr>
                            <th scope="col" class="">
                            </th>
                            <th scope="col" class="px-3 py-3">
                              Learners
                            </th>
                            <th scope="col" class="px-3 py-3">
                              Completed
                            </th>
                            <th scope="col" class="px-3 py-3">
                              Date Created
                            </th>
                            <th scope="col" class="">
                            </th>
                          </tr>
                        </thead>
                        <tbody>

                          {CoursesPigination.length > 0 ?
                            CoursesPigination.map((course, CourseIndex) => (
                              <tr class="even:bg-white even:dark:bg-gray-900 odd:bg-gray-50 odd:dark:bg-gray-800 border-b dark:border-gray-700">
                                <th scope="row" class="px-3 py-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                  {course.name}
                                </th>
                                <td class="px-3 py-6">
                                  {course.students.length}
                                </td>
                                <td class="px-3 py-6">
                                  {getUniqueStudentCount(course)}
                                </td>
                                <td class="px-3 py-6">
                                  {course.dateCreated}
                                </td>
                                <td class="px-3 py-6">
                                  <div className="flex justify-center">
                                    <div className="flex justify-center gap-2  p-2" style={{ width: '120px', borderRadius: '8px' }}>
                                      <Tooltip title={course.view ? "Show Course" : 'Hide Course'}><Link to=''><img onClick={() => { setCourseName(course.name); handleHideCourse(CourseIndex) }} src={course.view ? CourseNotActive : CourseActive} alt="pdf" /></Link></Tooltip>
                                      <Tooltip title='Analysis'><Link to={`/Course?course_id=${course.course_id}`}>
                                        <img src={EditQuestoin} alt="edit" />
                                      </Link></Tooltip>
                                      <Tooltip title='Delete'><span className="cursor-pointer" onClick={() => { DeleteDialogOpen('course'); setCourseName(course.name); setCourseId(course.course_id) }}><img src={DeletQuestoin} alt="delete" /></span></Tooltip>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            ))

                            : (<div className="text-gray-500">No Course available.</div>)}

                        </tbody>
                      </table>
                    </div>
                    {/* courses pigination Next and prev page*/}
                    <div className={`flex justify-center gap-5 mt-4 ${coursesCheckbox === RadioOffIcon ? 'hidden' : ''}`}>
                      <button onClick={handlePrevPageCourses} className="px-4 py-2 BackBtn hover:bg-blue-200 rounded disabled:opacity-50 flex" disabled={currentPageCourses === 1}>
                        <img className="mt-1 mr-2" src={BackIcon} alt="" /> Prev
                      </button>
                      <span className="px-4 py-2">{currentPageCourses} of {totalPagesCourses}</span>
                      <button onClick={handleNextPageCourses} className="px-4 py-2 BackBtn hover:bg-blue-200 rounded disabled:opacity-50 flex" disabled={currentPageCourses === totalPagesCourses}>
                        Next <img className="mt-1 ms-2" src={NextIcon} alt="" />
                      </button>
                    </div>
                  </div>
                )}
            </div>
            {/* Activities of courses List view*/}
            <div className={`${coursesCheckbox === RadioOffIcon ? '' : 'hidden'}`}>
              {loading ? (
                <LoadingComponent />
              ) :
                (
                  <div>
                    <div className={`relative  ms-2 sm:mx-7 overflow-x-auto max-w-80 sm:max-w-full`}>
                      <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                        <thead class="text-gray-700 uppercase dark:bg-gray-700 dark:text-gray-400">
                          <tr>
                            <th scope="col" class="">
                            </th>
                            <th scope="col" class="px-3 py-3">
                              Course
                            </th>
                            <th scope="col" class="px-3 py-3">
                              Stage
                            </th>
                            <th scope="col" class="px-3 py-3">
                              Learners
                            </th>
                            <th scope="col" class="px-3 py-3">
                              Completed
                            </th>
                            <th scope="col" class="px-3 py-3">
                              Created By
                            </th>
                            <th scope="col" class="px-3 py-3">
                              Date Created
                            </th>
                            <th scope="col" class="">
                            </th>
                          </tr>
                        </thead>
                        <tbody>

                          {activitiesPigination.length > 0 ?
                            activitiesPigination.map((activity, activityIndex) => (
                              <tr class="even:bg-white even:dark:bg-gray-900 odd:bg-gray-50 odd:dark:bg-gray-800 border-b dark:border-gray-700">
                                <th scope="row" class="px-3 py-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                  {activity.name}
                                </th>
                                <td class="px-3 py-6">
                                  {activity.courseName}
                                </td>
                                <td class="px-3 py-6">
                                  {activity.stageName}
                                </td>
                                <td class="px-3 py-6">
                                  {activity.lerners}
                                </td>
                                <td class="px-3 py-6">
                                  {activity.analyticsList.length}
                                </td>
                                <td class="px-3 py-6">
                                  {activity.createdBy}
                                </td>
                                <td class="px-3 py-6">
                                  {activity.dateCreated}
                                </td>
                                <td class="px-3 py-6">
                                  <div className="flex justify-center">
                                    <div className="flex justify-center gap-2  p-2" style={{ width: '120px', borderRadius: '8px' }}>
                                      <Tooltip title='Delete'><span className="cursor-pointer" onClick={() => { setActivityName(activity.name); setActivity_id(activity.activity_id); DeleteDialogOpen('activity') }}><img src={DeletQuestoin} alt="delete" /></span></Tooltip>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            ))

                            : (<div className="text-gray-500">No Activities available.</div>)}

                        </tbody>
                      </table>
                    </div>
                    {/* Activiies Pigination Next and prev page*/}
                    <div className={`flex justify-center gap-5 mt-4 ${coursesCheckbox === RadioOffIcon ? '' : 'hidden'}`}>
                      <button onClick={handlePrevPageactivities} className="px-4 py-2 BackBtn hover:bg-blue-200 rounded disabled:opacity-50 flex" disabled={currentPageactivities === 1}>
                        <img className="mt-1 mr-2" src={BackIcon} alt="" /> Prev
                      </button>
                      <span className="px-4 py-2">{currentPageactivities} of {totalPagesactivities}</span>
                      <button onClick={handleNextPageactivities} className="px-4 py-2 BackBtn hover:bg-blue-200 rounded disabled:opacity-50 flex" disabled={currentPageactivities === totalPagesactivities}>
                        Next <img className="mt-1 ms-2" src={NextIcon} alt="" />
                      </button>
                    </div>
                  </div>
                )}
            </div>
          </div>
        </div>
        <div className="flex justify-center">
          {loading ? <Skeleton animation="wave" variant="rounded" width={200} height={50} /> :
            <Link to='/Dashboard/CreateCourse'> <button id="createCourse" className=" border py-2 px-6 my-10 font-bold flex BackBtn hover:bg-blue-200" style={{ borderRadius: '7px' }}>
              <img src={CreateNewQuestion} alt="AddNewQ" style={{ width: '30px', paddingRight: '5px' }} />
              Create New Course</button> </Link>}
        </div>
      </div>
    </div>
  )
};

export default Gamizign;
