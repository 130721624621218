import React, { useState, useEffect, useRef } from "react";

// import QuestiontypeBtnIcon from '../../../../../../assets/icons/ActivityContentIcons/NewCourse/QuestionListCard/question level bar.png'
import CreateManualQuestionIcon from '../../../../../../assets/icons/ActivityContentIcons/NewCourse/QuestionListCard/Add New Manual Question icon.png'
import BackIcon from '../../../../../../assets/icons/ActivityContentIcons/NewCourse/QuestionListCard/back icon.png'
import NextIcon from '../../../../../../assets/icons/ActivityContentIcons/NewCourse/QuestionListCard/next icon.png'
import Image from '../../../../../../assets/icons/ActivityContentIcons/NewCourse/Patch4-MultiActivity/image icon _ multi activity.png'
import Text from '../../../../../../assets/icons/ActivityContentIcons/NewCourse/Patch4-MultiActivity/assay icon _ multi activity.png'
import RankOrProccess from '../../../../../../assets/icons/ActivityContentIcons/NewCourse/Patch4-MultiActivity/rank or process icon _ multi activity.png'
import MCQ from '../../../../../../assets/icons/ActivityContentIcons/NewCourse/Patch4-MultiActivity/MSQ.png'
// import RobotOpenEyeChat from '../../../../../../assets/icons/ActivityContentIcons/NewCourse/QuestionListCard/bot with open eyes _ chat bubble.png'
// pop-up one
import QuestiontypeTriangL3lePic from '../../../../../../assets/icons/ActivityContentIcons/NewCourse/page 18/bloom level 3rd level 140-148.png'
import { Link, useLocation, useNavigate } from "react-router-dom";
import Modal from 'react-modal'
// model 2 bot 
import SubmiBtntIcon from '../../../../../../assets/icons/ActivityContentIcons/NewCourse/Creating/Submit icon.png';
import LoginHeading from "../../../../../LoginHeading/LoginHeading";
import QuestionComponent from "../../QuestionList/ReuseableQuestionCard";
import { Alert, Button, Dialog, DialogContent, Tooltip } from "@mui/material";
import MetaTags from "../../../../../GlobalLoading/MetaData";
import Joyride from "react-joyride";


const Customizing = () => {

    const { questions: initialQuestions } = useLocation().state;
    const {courses, courseType, course, stageIndex, courseFormatDate, selectedFile, stratingDate, finishingDate } = useLocation().state;
    const navigate = useNavigate();
    const fileInputRefs = {
        question: useRef(null),
        answer1: useRef(null),
        answer2: useRef(null),
        answer3: useRef(null),
        answer4: useRef(null)
    };

    // Define the questions state and setQuestions function, initialized with the obtained array
    const [questions, setQuestions] = useState(initialQuestions || []);
    const [SelecteQuestionType, SetSelecteQuestionType] = useState(null);
    const [IsError, setIsError] = useState(false);
    const [ErrorMessage, SetErrorMessage] = useState('');
    const closeDialog = () => {
        setIsError(false)
    }


    const openModal = () => {
        // setModalIsOpen(true);
    };

    const closeModal = () => {
        // setModalIsOpen(false);
    };
    // bot model 
    const [botmodalIsOpen, setBotmodalIsOpen] = useState(false);

    // const openBotModal = () => {
    //     setBotmodalIsOpen(true);
    // };

    const BotcloseModal = () => {
        setBotmodalIsOpen(false);
    };

    const removeQuestion = (index) => {
        // Create a new copy of the questions array
        const updatedQuestions = [...questions];
        // Remove the question at the specified index from the copied array
        updatedQuestions.splice(index, 1);
        // Update the state with the modified array
        setQuestions(updatedQuestions);
    };
    // validation for add , coopy or save question
    const validation = (lastQuestion) => {
        if (lastQuestion.questionType === 'essay'
            ? (lastQuestion.question !== '' || lastQuestion.questionImage) && (lastQuestion.answer1 !== '' || lastQuestion.answer1Image)
            : (lastQuestion.question !== '' || lastQuestion.questionImage) &&
            (lastQuestion.answer1 !== '' || lastQuestion.answer1Image) &&
            (lastQuestion.answer2 !== '' || lastQuestion.answer2Image) &&
            (lastQuestion.answer3 !== '' || lastQuestion.answer3Image) &&
            (lastQuestion.answer4 !== '' || lastQuestion.answer4Image)) {
            return true;
        } else { return false };

    }

    const addNewQuestion = (questionType) => {
        SetSelecteQuestionType(null)
        // Check if any question object in the questions array has empty question and answers
        const lastQuestion = questions[questions.length - 1];
        if (validation(lastQuestion)) {
            setQuestions(prevQuestions => [
                ...prevQuestions,
                {
                    question: "",
                    answer1: "",
                    answer2: "",
                    answer3: "",
                    answer4: "",
                    rank: questionType === 'reorder' ? true : false,
                    answer_exact: questionType === 'essay' ? true : false,
                    answer1Image: null,
                    answer2Image: null,
                    answer3Image: null,
                    answer4Image: null,
                    questionImage: null,
                    bloom_level: "Remembering",
                    slide_no: "manual",
                    difficulty: "easy",
                    questionList_id: null,
                    questionType: questionType.toString()
                }
            ]);

        } else {
            setIsError(true);
            SetErrorMessage('Kindly fill all the fields of the last question before adding new one question!');
        }
    };


    const [isHovering, setIsHovering] = useState(false);


    const handleMouseOver = () => {
        setIsHovering(true);
    };

    const handleMouseOut = () => {
        setIsHovering(false);
    };

    const saveList = () => {
        const lastQuestion = questions[questions.length - 1];
        if (validation(lastQuestion)) {
            navigate('/Dashboard/saveQuestions', { state: {courses:courses, courseType: courseType, course: course, questions: questions, selectedFile: selectedFile, stageIndex: stageIndex, courseFormatDate: courseFormatDate, stratingDate: stratingDate, finishingDate: finishingDate } });

        } else {
            setIsError(true);
            SetErrorMessage('Kindly fill all the fields of the last question before submitting!');
        }
    }
    useEffect(() => {
        // This effect will run every time 'questions' state changes
    }, [questions]); // Dependency array containing 'questions'


    // copy question
    const copyQuestion = (index) => {
        const UpdatedCopyquestions = [...questions];
        // Create a deep copy of the selected question
        const lastQuestion = JSON.parse(JSON.stringify(UpdatedCopyquestions[index]));
        console.log(lastQuestion);

        if (validation(lastQuestion)) {
            // Add the deep copied question to the list
            setQuestions(prevQuestions => [
                ...prevQuestions,
                lastQuestion
            ]);
        } else {
            setIsError(true);
            SetErrorMessage('You can not copy an empty question!');
        }
    };

    // Back to convert siles or pages into questions page
    const GoBackSlides = () => {
        if (selectedFile.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') {
            // PPTX file type, navigate to one link
            navigate('/Dashboard/creatingPptx', { state: {courses:courses, courseType: courseType, course: course, selectedFile: selectedFile, stageIndex: stageIndex, courseFormatDate: courseFormatDate, stratingDate: stratingDate, finishingDate: finishingDate } });
        } else if (selectedFile.type === 'application/pdf') {
            // PDF file type, navigate to another link
            navigate('/Dashboard/creatingPdf', { state: {courses:courses, courseType: courseType, course: course, selectedFile: selectedFile, stageIndex: stageIndex, courseFormatDate: courseFormatDate, stratingDate: stratingDate, finishingDate: finishingDate } });
        } else {
            console.error('Unsupported file type');
        }
    }
    // onboarding
    const [Onboarding, setOnboarding] = useState(courses.length);
    const [{ run, steps }, setState] = useState({
        run: true,
        steps: [
            {
                content: <h2>Upload image for any question.</h2>,
                placement: 'right',
                target: '#Uploadimages',
            },
            {
                content: <h2>Generated question from your file.</h2>,
                placement: 'left',
                target: '#GenQ',
            },
            {
                content: <h2>Upload image for any question.</h2>,
                placement: 'top',
                target: '#Uploadimage',
            },
            {
                content: <h2>Here you can customize question , change question or any answer of any question.</h2>,
                placement: 'top',
                target: '#EditQuestion',
            },
            {
                content: <h2>You can change question difficullty Level .</h2>,
                placement: 'top',
                target: '#QDifficulltyLevel',
                title: 'Difficullty Level'
            },
            {
                content: <h2>You can change question bloom Level .</h2>,
                placement: 'top',
                target: '#QBloomLevel',
                title: 'Bloom Level'
            },
            {
                content: <h2>Make a copy of question  .</h2>,
                placement: 'top',
                target: '#QCopy',
            },
            {
                content: <h2>Delete question.</h2>,
                placement: 'top',
                target: '#QDelete',
            },
            {
                content: <h2>Add new question.</h2>,
                placement: 'top',
                target: '#QAdd',
            },
            {
                content: <h2>Click next button to continue creating course.</h2>,
                placement: 'top',
                target: '#save',
            },

        ]
    })



    return (
        <div className='w-full'>
            <MetaTags title='Customize Questions List' description='Gamizign Customize Questions List page--' />
            <div className='flex relative TITLE px-5 py-2 mb-2 ContentHeadingBg'>
                <h3 className="text-xl">Create New Activity</h3>
                <LoginHeading objects={setOnboarding}/>
            </div>
            <div className="sm:m-7 my-7 bg-white relative">
            {Onboarding <= 1  && <Joyride callback={() => { }} continuous showProgress
                    run={run} steps={steps} hideCloseButton scrollToFirstStep showSkipButton
                    styles={{
                        buttonNext: { backgroundColor: '#E5F2E3', borderRadius: '5px',color:'black', },
                        buttonBack: { backgroundColor: '#E5F2E3',  borderRadius: '5px', color:'black', }
                      }} />}
                {/* Errot empty question dialog */}
                <Dialog className="lg:ms-56" open={IsError} onClose={closeDialog}>
                    <DialogContent>
                        <Alert severity='error'>{ErrorMessage}</Alert>
                    </DialogContent>
                    <div className='flex justify-center'>
                        <Button onClick={() => { closeDialog() }} color="primary">
                            Okay
                        </Button>
                    </div>
                </Dialog>
                {/* <img className="AI-BOT absolute bottom-0 right-1 cursor-pointer" onClick={openBotModal} src={RobotOpenEyeChat} alt="robot" /> */}
                {/* progress line */}
                <div>
                    <ol className="flex items-center pt-5 px-2 sm:px-10 w-full text-sm font-medium text-center text-gray-500 dark:text-gray-400 sm:text-base">
                        <li className="flex w-full items-center text-green-600 dark:text-blue-500 sm:after:content-[''] after:w-full after:border-green-200 after:border sm:after:inline-block   dark:after:border-gray-700">
                            <span className="flex items-center after:text-gray-200 dark:after:text-gray-500">
                                <svg className="w-3.5 h-3.5 sm:w-4 sm:h-4 me-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                </svg>
                                <b>Adding</b>
                            </span>
                        </li>
                        <li className="flex w-full items-center text-green-600 after:content-[''] after:w-full after:border-green-200 after:border sm:after:inline-block   dark:after:border-gray-700">
                            <span className="flex items-center after:text-gray-200 dark:after:text-gray-500">
                                <svg className="w-3.5 h-3.5 sm:w-4 sm:h-4 me-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                </svg>
                                <b>Creating</b>
                            </span>
                        </li>
                        <li className="flex w-full items-center text-pink-300 after:content-[''] after:w-full after:border-blue-200 after:border sm:after:inline-block   dark:after:border-gray-700">
                            <span className="flex items-center after:text-gray-200 dark:after:text-gray-500">
                                <b>Customizing</b>
                            </span>
                        </li>
                        <li className="flex items-center">
                            <b>Saving</b>
                        </li>
                    </ol>
                </div>
                <div className="grid justify-items-center mt-10 sm:mx-20 xl:mx-40">
                    <div id="GenQ">
                    <h1 className="text-3xl">Gamizign AI Generated</h1>
                    <div className="flex mt-5">
                        <button className="border bg-gray-100 p-1 px-2 rounded border-gray-400"><b>{questions.length}</b> Questions</button>
                        <p className="text-sky-600 p-1">{selectedFile.name} </p>
                    </div>
                    </div>
                    {questions.map((question, index) => (
                        <div className="QUESTION1 w-full mt-5" key={Math.random()} id={Math.random()}>
                            <QuestionComponent
                                question={question}
                                questionIndex={index}
                                onDelete={() => removeQuestion(index)}
                                copyQuestion={() => copyQuestion(index)} />
                        </div>
                    ))}
                    {/*Add new/ select Question type */}
                    {SelecteQuestionType &&
                        <div className="mt-5 justify-center flex gap-3">
                            <Tooltip title='MCQs Type'><img className="transition-transform transform hover:scale-110 cursor-pointer" onClick={() => { addNewQuestion('mcqs') }} src={MCQ} alt="" /></Tooltip>
                            <Tooltip title='Image Type'><img className="transition-transform transform hover:scale-110 cursor-pointer" onClick={() => { addNewQuestion('image'); }} src={Image} alt="" /></Tooltip>
                            <Tooltip title='Essay Type'><img className="transition-transform transform hover:scale-110 cursor-pointer" onClick={() => { addNewQuestion('essay') }} src={Text} alt="" /></Tooltip>
                            <Tooltip title='Rank Type'><img className="transition-transform transform hover:scale-110 cursor-pointer" onClick={() => { addNewQuestion('reorder') }} src={RankOrProccess} alt="" /></Tooltip>
                        </div>}

                    <div className="flex justify-center">
                        <Tooltip title='Add New Question'>
                            <button id="QAdd" className=" border py-2 px-6 my-10 font-bold flex BackBtn hover:bg-blue-200" onClick={() => { SetSelecteQuestionType(true) }} style={{ borderRadius: '7px' }}>
                                <img src={CreateManualQuestionIcon} alt="AddNewQ border" style={{ width: '30px', paddingRight: '5px' }} />
                                Create</button>
                        </Tooltip>
                    </div>
                    <div className='MANUAL&SUBMIT flex text-center my-10 items-center justify-center gap-3'>
                        <button onClick={GoBackSlides} className='BackBtn flex p-2 px-7 hover:bg-blue-200'><b> Back </b><img className='ml-5 mt-1' src={BackIcon} alt="Back" /></button>
                        <button id="save" type='submit' className='BackBtn flex p-2 px-7 hover:bg-blue-200' onClick={saveList} ><img className='mr-5 mt-1' src={NextIcon} alt="Next" /><b> Next</b></button>
                    </div>
                </div>
                <div>
                    <Modal
                        // isOpen={modalIsOpen}
                        onRequestClose={closeModal}
                        className="Modal"
                    // overlayClassName="Overlay"
                    >
                        {/* Modal Content */}
                        <div className="flex mt-20 justify-center ps-12">
                            <div className="flex mt-14  drop-shadow-xl bg-white" style={{ width: "600px", height: "500px", borderRadius: "20px 20px" }}>
                                <div className="border-end  justify-center bg-gray-300 px-5 pt-9" style={{ borderRadius: "20px 0px 0px 20px" }} >
                                    <img style={{ width: '150px', height: '100px', }} src={QuestiontypeTriangL3lePic} alt="" />
                                </div>
                                <div className="p-5 pt-7">
                                    <div>
                                        <h1 className="text-red-200 font-bold"> Applying-Application Level</h1>
                                        <p className="text-blue-900 mt-2">Abstract idea concrete situation to solve problem</p>
                                    </div>
                                    <div className="mt-5">
                                        <h1 className="text-red-200 font-bold">Keywords</h1>
                                        <li className="text-blue-900">select,solve,utilize,model,identify</li>
                                    </div>
                                    <div className="mt-5">
                                        <h1 className="text-red-200 font-bold">New activity suggestion</h1>
                                        <li className="text-blue-900 mt-2">
                                            Identify general characteristics (started and/or implied) of the main characters.
                                        </li>
                                        <li className="text-blue-900 mt-2">
                                            Distinguish what could happen from what couldn't happen in the story in rael life.
                                        </li>
                                        <li className="text-blue-900 mt-2">
                                            Select parts of the story that were the funniest,saddest,happiest, and most unbelievable.
                                        </li>
                                        <li className="text-blue-900 mt-2">
                                            Differentiate fact from opinion.
                                        </li>
                                        <li className="text-blue-900 mt-2">
                                            Compare and/or contract two of the main characters.
                                        </li>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </Modal>

                    {/*bot modal  */}


                    <Modal
                        isOpen={botmodalIsOpen}
                        onRequestClose={BotcloseModal}
                        className="Modal"
                    // overlayClassName="Overlay"
                    >
                        {/* Modal Content */}
                        <div className="flex mt-20 justify-end pe-40">
                            <div className="flex mt-14  drop-shadow-xl bg-white" style={{ width: "600px", height: "250px", borderRadius: "20px 20px" }}>
                                <div className="border-end  justify-center bg-gray-300 px-5 pt-14" style={{ borderRadius: "20px 0px 0px 20px" }} >
                                    <img style={{ width: '100px', height: '100px', }} src={QuestiontypeTriangL3lePic} alt="" />
                                </div>
                                <div className="p-5 pt-7">
                                    <div>
                                        <h1 className="text-red-200 font-bold">Pick Level</h1>
                                        <p className="text-blue-900 mt-2"><li>Applying-Application Level</li></p>
                                    </div>
                                    <div className="mt-5">
                                        <h1 className="text-red-200 font-bold">Choose Keywords</h1>
                                        <li className="text-blue-900">apply. <b>build,</b>choose, consrtuct, develop, interview, <br />
                                            make usee of, orgnize, experiment with, plan,<br />
                                            <b>select,solve,</b> utilize,model, <b>identify</b>
                                        </li>
                                    </div>
                                    <div className="flex justify-end">
                                        <button className="flex border p-2 px-3 bg-gray-100 rounded" onClick={BotcloseModal}><img className="pe-4 mt-1" src={SubmiBtntIcon} alt="post" /><b>Submit</b></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal>
                </div>
            </div>
            <style >{`
        #steps-range::-webkit-slider-thumb {
          WebkitAppearance: none;
          appearance: none;
          width: var(--thumb-width);
          height: var(--thumb-height);
          border: none;
          background-color: var(--thumb-color);
          clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
          border-radius: var(--thumb-radius);
          cursor: pointer;
        }

        #steps-range::-moz-range-thumb {
          width: var(--thumb-width);
          height: var(--thumb-height);
          border: none;
          background-color: var(#b6dff7);
          clip-path: polygon(#DBE6F5;
          border-radius: var(--thumb-radius);
          cursor: pointer;
        }
      `}</style>
        </div>
    )
};

export default Customizing;