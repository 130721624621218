import React, { useEffect, useState } from "react";
import LoginHeading from "../../../../../../LoginHeading/LoginHeading";
import GamizignHIcon from '../../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Gamizign head icon.png'
import PdfImage from '../../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/HomeWork/PDF icon hover off color.png'
import GameImage from '../../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/HomeWork/game icon hover off color.png'
import VideoImage from '../../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/HomeWork/video icon hover off color.png'
import AudioImage from '../../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/HomeWork/Audio icon hover off color.png'
import ImageImage from '../../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/HomeWork/image icon hover off color.png'
import UrlImage from '../../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/HomeWork/URL icon hover off color.png'
import HideIcon from '../../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/HomeWork/Next icon.png'
import BackIcon from '../../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/HomeWork/back iconB.png'
import DeletIcon from '../../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/HomeWork/delete icon.png'
import EditIcon from '../../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/HomeWork/edit icon.png'
import EyeIcon from '../../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/HomeWork/course active icon.png'
import StarIconOn from '../../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/HomeWork/star for rank icon on.png'
import StarIconOff from '../../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/HomeWork/star for rank off icon.png'
import RadioOnIcon from '../../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/HomeWork/radio button on.png'
import RadioOffIcon from '../../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/HomeWork/radio button off.png'
import { Link, useLocation, useNavigate } from "react-router-dom";
import LoadingComponent from "../../../../../../GlobalLoading/GlobalLoading";
import MetaTags from "../../../../../../GlobalLoading/MetaData";

// Function to combine students and activity answers
const addStudentInfoToActivityAnswers = (students, activityAnswers) => {
    return activityAnswers
        .map(answer => {
            const matchingStudent = students.find(student => student.student_id === answer.student_id);
            if (matchingStudent) {
                return {
                    ...answer,
                    firstName: matchingStudent.firstName,
                    lastName: matchingStudent.lastName
                };
            }
            // If no matching student, exclude the activity answer
            return null;
        })
        .filter(answer => answer !== null); // Filter out any null values
};;

const ActivityEvaluation = () => {
    const { activity, Course_name, Students, course_id } = useLocation().state;
    const [ActivityAnswers, setActivityAnswers] = useState(null);
    const [updatedActivityAnswers, setUpdatedActivityAnswers] = useState([]);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const getActivityAnswers = async () => {
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            };

            try {
                setLoading(true);

                const response = await fetch(`${process.env.REACT_APP_ENDPOINT}/activities/${activity.activity_id}/answers/`, requestOptions);
                const data = await response.json();
                setLoading(false);
                setActivityAnswers(data);
            } catch (error) {
                console.error('Error fetching courses:', error);
                // Handle the error if needed
            } finally {
                setLoading(false);
            }
        };
        getActivityAnswers();
    }, []);
    useEffect(() => {
        if (!loading) {
            const updatedAnswers = addStudentInfoToActivityAnswers(Students, ActivityAnswers, activity);
            setUpdatedActivityAnswers(updatedAnswers);
            setFilteredLearners(updatedAnswers);
        }
    }, [loading, ActivityAnswers, Students]);


    const [activityType, setActivityType] = useState(null)
    const AddingActivityType = () => {
        switch (activity.activityType.toLowerCase()) {
            case "pdf":
                setActivityType(PdfImage);
                break
            case 'image':
                setActivityType(ImageImage);
                break
            case 'video':
                setActivityType(VideoImage);
                break
            case 'audio':
                setActivityType(AudioImage);
                break
            case 'game':
                setActivityType(GameImage);
                break
            case 'url':
                setActivityType(UrlImage);
                break
            default:
                setActivityType(ImageImage);
        }

    };
    useEffect(() => {
        AddingActivityType();
    }, [activity]);
    const [activityDescription, setActivityDescription] = useState(false)
    const ActivityDescription = () => {
        setActivityDescription((prevImage) => (prevImage === false ? true : false));
    };
    const [modelAnswer, setModelAnswer] = useState(false)
    const ModelAnswer = () => {
        setModelAnswer((prevImage) => (prevImage === false ? true : false));
    };
    // Search learners
    const [filteredLearners, setFilteredLearners] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');


    const handleSearch = (e) => {
        setSearchTerm(e.target.value);

        const lowercasedSearchTerm = e.target.value.toLowerCase();

        const filteredLists = updatedActivityAnswers.filter((courses) => {
            return courses.firstName.toLowerCase().includes(lowercasedSearchTerm);
        });

        setFilteredLearners(filteredLists);
    };
    // View Activity File
    const [ViewActivityFile, setViewActivityFile] = useState(false)
    const ViewActivityFileHandle = (activityType, url) => {
        setViewActivityFile((prevImage) => (prevImage === false ? true : false));
        if (activityType === 'url') {
            window.open(url, '_blank');
        }
    };

    const navigate = useNavigate();
    const sendDatLernerpage = (learner) => {
        navigate(`/Course/LearnerEvaluation?course_id=${course_id}`, { state: { activity: activity, Course_name: Course_name, Students: Students, course_id: course_id, learner: learner } })
    };

    return (
        <div className='w-full'>
            <MetaTags title='Activity Evaluation' description='Gamizign Activity Evaluation page--' />
            <div className='flex relative px-5 py-2 mb-2 ContentHeadingBg'>
                <h3 className="text-xl">Students Homework</h3>
                <LoginHeading />
            </div>
            <div className="sm:m-7 my-7 mx-3 bg-white border-4 pb-5">
                <div className='flex justify-center static'><img className='absolute top-6' src={GamizignHIcon} alt="" /></div>
                <div className="p-5  bg-gray-50 border-b font-bold text-xl">{Course_name} / {activity.name}</div>
                <div className="sm:ms-14 mx-3 grid sm:grid-cols-2 mt-10">
                    <label htmlFor="Name of Course"><b>Title</b>
                        <span className="border py-3 px-5 rounded w-80 ms-2">{activity.name}</span>
                    </label>
                    <label className="mt-5 sm:m-0" htmlFor="Starting Date"> <b>Date Created </b>
                        <span className="text-blue-600 ms-2">{activity.dateCreated}</span>
                    </label>
                </div>
                <div className="sm:mx-20 mx-3 mt-5">
                    <div className="sm:flex">
                        <span className="mt-7">1. View activity</span>
                        <span className="ms-5 cursor-pointer"><img onClick={()=>ViewActivityFileHandle(activity.activityType , activity.url)} src={activityType} alt="" /></span>
                        {ViewActivityFile &&
                            <div> {activity.activityType != 'url' && <div className="overflow-x-auto max-w-80 sm:max-w-full">
                                <iframe width='500px' src={`${process.env.REACT_APP_ENDPOINT}/uploads/${activity.activity_file_name}`} frameborder="0"></iframe>
                                <Link className="font-bold cursor-pointer mt-2 BackBtn flex p-2 px-7 hover:bg-blue-200" target="_blank" to={`${process.env.REACT_APP_ENDPOINT}/uploads/${activity.activity_file_name}`}>View Full </Link>
                            </div>}
                            </div>
                        }
                    </div>
                    {/* activity question/description */}
                    <div className="flex mt-5">
                        <label className="mt-2.5 sm:w-56" htmlFor="">2. The activity description </label>
                        <div className="flex-1 text-center">
                            {activityDescription === false ? (
                                <button onClick={ActivityDescription} className='BackBtn ms-5 flex p-2 px-7 hover:bg-blue-200'>show<img className='ml-5 mt-1 rotate-90' src={HideIcon} alt="Back" /></button>
                            ) : (
                                <button onClick={ActivityDescription} className='bg-green-50 ms-5 rounded border flex p-2 px-7 hover:bg-blue-200'>Hide<img className='ml-5 mt-1 rotate-90' src={BackIcon} alt="Back" /></button>
                            )}
                        </div>
                    </div>
                    {activityDescription &&
                        <div>
                            <div className="QUESTIONS-COUNT flex-initial w-100 py-2 flex justify-end">
                                <div className="image-radio flex m-2 hover:cursor-pointer" name='Task' value={activity.activity_case}>
                                    <span>
                                        <img
                                            src={activity.activity_case === 'Task' ? RadioOnIcon : RadioOffIcon}
                                            alt="Task"
                                            className="radio-image mt-1"
                                        />
                                    </span>
                                    <span className={`ps-2 ${activity.activity_case === 'Task' ? 'font-bold' : 'font-light'}`}>Task</span>
                                </div>
                                <div className="image-radio flex m-2 hover:cursor-pointer" name='Project' value={activity.activity_case}>
                                    <span>
                                        <img
                                            src={activity.activity_case === 'Project' ? RadioOnIcon : RadioOffIcon}
                                            alt="Project"
                                            className="radio-image mt-1"
                                        />
                                    </span>
                                    <span className={`ps-2 ${activity.activity_case === 'Project' ? 'font-bold' : 'font-light'}`}>Project</span>
                                </div>
                                <div className="image-radio flex m-2 hover:cursor-pointer" name='Case' value={activity.activity_case}>
                                    <span>
                                        <img
                                            src={activity.activity_case === 'Case' ? RadioOnIcon : RadioOffIcon}
                                            alt="Case"
                                            className="radio-image mt-1"
                                        />
                                    </span>
                                    <span className={`ps-2 ${activity.activity_case === 'Case' ? 'font-bold' : 'font-light'}`}>Case</span>
                                </div>
                            </div>
                            <textarea className='w-full border p-5' value={activity.description} disabled id="ActivityAnswer" name="ActivityAnswer" placeholder='Text ....' rows="4" cols="95" ></textarea>
                        </div>
                    }
                    {/* Model Answer */}
                    <div className="flex mt-5">
                        <label className="mt-2.5 sm:w-56" htmlFor="">3. Model answer </label>
                        <div className="flex-1 text-center">
                            {modelAnswer === false ? (
                                <button onClick={ModelAnswer} className='BackBtn ms-5 flex p-2 px-7 hover:bg-blue-200'>show<img className='ml-5 mt-1 rotate-90' src={HideIcon} alt="Back" /></button>
                            ) : (
                                <button onClick={ModelAnswer} className='bg-green-50 ms-5 rounded border flex p-2 px-7 hover:bg-blue-200'>Hide<img className='ml-5 mt-1 rotate-90' src={BackIcon} alt="Back" /></button>
                            )}
                        </div>
                    </div>
                    {modelAnswer &&
                        <div>
                            <div className="QUESTIONS-COUNT flex-initial w-100 py-2 flex justify-end">
                                <div className="image-radio flex m-2 hover:cursor-pointer" name='is exactly' value={activity.answer_exact}>
                                    <span>
                                        <img
                                            src={activity.answer_exact === 'is exactly' ? RadioOnIcon : RadioOffIcon}
                                            alt="Task"
                                            className="radio-image mt-1"
                                        />
                                    </span>
                                    <span className={`ps-2 ${activity.answer_exact === 'is exactly' ? 'font-bold' : 'font-light'}`}>is exactly</span>
                                </div>
                                <div className="image-radio flex m-2 hover:cursor-pointer" name='contain' value={activity.answer_exact}>
                                    <span>
                                        <img
                                            src={activity.answer_exact === 'contain' ? RadioOnIcon : RadioOffIcon}
                                            alt="Project"
                                            className="radio-image mt-1"
                                        />
                                    </span>
                                    <span className={`ps-2 ${activity.answer_exact === 'contain' ? 'font-bold' : 'font-light'}`}>contain</span>
                                </div>
                            </div>
                            <textarea className='w-full border p-5' value={activity.answer_requirement} disabled id="ActivityAnswer" name="ActivityAnswer" placeholder='Text ....' rows="4" cols="95" ></textarea>
                        </div>
                    }
                    {/* Students Answers */}
                    <div className="flex mt-5">
                        <label className="sm:w-56 mt-2.5" htmlFor="">4. Student answer</label>
                        <div className="flex flex-wrap gap-5">
                            <button className="p-2 bg-gray-100 border rounded">{ActivityAnswers?.length > 0 ? ActivityAnswers.length : 0}/{Students.length} done</button>
                            <button className="p-2 bg-gray-100 border rounded">{ActivityAnswers?.length > 0 ? Students.length - ActivityAnswers.length : Students.length}/{Students.length} Need your check</button>
                            {/* <button className="p-2 bg-gray-100 border rounded">10/25 Un completed</button> */}
                        </div>

                    </div>
                    {/* Learners table */}
                    <div className="border-y-4 py-5 my-10">
                        <div class="relative overflow-x-auto max-w-80 sm:max-w-full">
                            <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                <thead class="text-gray-700 uppercase dark:bg-gray-700 dark:text-gray-400">
                                    <tr>
                                        <th scope="col" class="">
                                            <input type="text" placeholder="Search Learner Name" className="border rounded p-2 ms-5 outline-none w-60" value={searchTerm} onChange={handleSearch} name="" id="" />
                                        </th>
                                        <th scope="col" class="px-3 py-3">
                                            <b>Respond Date</b>
                                        </th>
                                        <th scope="col" class="px-3 py-3">

                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loading ? (
                                       <LoadingComponent/>
                                    ) :
                                        (filteredLearners && Array.isArray(filteredLearners)) ?
                                            filteredLearners.map((Learner, index) => (
                                                <tr class="even:bg-white even:dark:bg-gray-900 odd:bg-gray-50 odd:dark:bg-gray-800 border-b dark:border-gray-700">
                                                    <th scope="row" class="px-3 py-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                        <p className="cursor-pointer" onClick={() => sendDatLernerpage(Learner)}>{Learner.firstName} {Learner.lastName}</p>
                                                    </th>
                                                    <td class="px-3 py-6">
                                                        {Learner.answerTime.replace(/[-,]/g, '/')}
                                                    </td>
                                                    <td class="px-3 py-6">
                                                        <div className={`flex gap-2 ${Learner.evaluation === null ? '' : 'hidden'}`}>
                                                            <img src={StarIconOff} alt="" />
                                                            <img src={StarIconOff} alt="" />
                                                            <img src={StarIconOff} alt="" />
                                                            <img src={StarIconOff} alt="" />
                                                            <img src={StarIconOff} alt="" />
                                                        </div>
                                                        <div className={`flex gap-2 ${Learner.evaluation === '1' ? '' : 'hidden'}`}>
                                                            <img src={StarIconOn} alt="" />
                                                            <img src={StarIconOff} alt="" />
                                                            <img src={StarIconOff} alt="" />
                                                            <img src={StarIconOff} alt="" />
                                                            <img src={StarIconOff} alt="" />
                                                        </div>
                                                        <div className={`flex gap-2 ${Learner.evaluation === '2' ? '' : 'hidden'}`}>
                                                            <img src={StarIconOn} alt="" />
                                                            <img src={StarIconOn} alt="" />
                                                            <img src={StarIconOff} alt="" />
                                                            <img src={StarIconOff} alt="" />
                                                            <img src={StarIconOff} alt="" />
                                                        </div>
                                                        <div className={`flex gap-2 ${Learner.evaluation === '3' ? '' : 'hidden'}`}>
                                                            <img src={StarIconOn} alt="" />
                                                            <img src={StarIconOn} alt="" />
                                                            <img src={StarIconOn} alt="" />
                                                            <img src={StarIconOff} alt="" />
                                                            <img src={StarIconOff} alt="" />
                                                        </div>
                                                        <div className={`flex gap-2 ${Learner.evaluation === '4' ? '' : 'hidden'}`}>
                                                            <img src={StarIconOn} alt="" />
                                                            <img src={StarIconOn} alt="" />
                                                            <img src={StarIconOn} alt="" />
                                                            <img src={StarIconOn} alt="" />
                                                            <img src={StarIconOff} alt="" />
                                                        </div>
                                                        <div className={`flex gap-2 ${Learner.evaluation === '5' ? '' : 'hidden'}`}>
                                                            <img src={StarIconOn} alt="" />
                                                            <img src={StarIconOn} alt="" />
                                                            <img src={StarIconOn} alt="" />
                                                            <img src={StarIconOn} alt="" />
                                                            <img src={StarIconOn} alt="" />
                                                        </div>
                                                    </td>
                                                </tr>
                                            )

                                            ) : (<div className="text-gray-500 font-bold text-2xl flex justify-center">No one player Submitted answers.</div>)}

                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
                <div className="flex justify-center">
                    <Link to={`/Course/HomeWork?course_id=${course_id}`}><button className='BackBtn flex p-2 px-7 hover:bg-blue-200'><b> Back </b><img className='ml-5 mt-1' src={BackIcon} alt="Back" /></button></Link>
                </div>
            </div>
        </div>
    )
};
export default ActivityEvaluation;