import React, { useEffect, useState } from "react";
import QListDashboardIcon from '../../../../../../assets/icons/ActivityContentIcons/QuestionListsPatch5/Question Lists dashboard icon.png'
import PdfICon from '../../../../../../assets/icons/ActivityContentIcons/QuestionListsPatch5/pdf icon.png'
import GMZLogo from '../../../../../../assets/icons/ActivityContentIcons/QuestionListsPatch5/Gamizign-logo.png'

import { useLocation } from "react-router-dom";
import LoginHeading from "../../../../../LoginHeading/LoginHeading";
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { Skeleton } from "@mui/material";
import MetaTags from "../../../../../GlobalLoading/MetaData";
import Joyride from "react-joyride";
import axiosInstance from "../../../../../../axios";
pdfMake.vfs = pdfFonts.pdfMake.vfs;


const PdfDownloadQuestionList = () => {
  const location = useLocation();
  const {Qlist} = location.state || "";
  const queryParams = new URLSearchParams(location.search);
  const questionList_id = queryParams.get('questionList_id');
  const [selectedQuestions, setSelectedQuestions] = useState(null);
    // OnBoarding
    const [Onboarding, setOnboarding] = useState(Qlist);
    const [{ run, steps }, setState] = useState({
      run: true,
      steps: [
          {
              content: <h2>Kindly drag and drop</h2>,
              placement: 'left',
              target: '#im',
          },
          {
              content: <h2>Question list name that you want download.</h2>,
              placement: 'bottom',
              target: '#QLname',
          },
          {
              content: <h2>Download Questions with all answers.</h2>,
              placement: 'top',
              target: '#GenQuiz',
          },
          {
              content: <h2>Here you can download question with right answers only.</h2>,
              placement: 'top',
              target: '#GenRight',
          },
  
      ]
  })
  
  useEffect(() => {

    const fetchData = async () => {
      try {
        const response = await axiosInstance.get(`${process.env.REACT_APP_ENDPOINT}/question-lists/${questionList_id}`);
        // const data = await response.json();

        setSelectedQuestions(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [questionList_id]);
  if (!selectedQuestions) {
    return <div>
      <div>
        <div className='flex relative px-5 py-2 mb-2 ContentHeadingBg'>
          <h3 className="text-xl">Question Lists</h3>
          <LoginHeading objects={setOnboarding} />
        </div>
        <div className="sm:m-7 my-7 bg-white">
          <div className='flex justify-center static'><img className='absolute top-6' src={QListDashboardIcon} alt="" /></div>
          <div className="px-5 py-10">
            <div className="sm:flex py-2 relative px-3" style={{ borderBottom: "2px gray solid" }}>
              <Skeleton animation="wave" variant="rounded" width={100} height={30} />
              <div className="flex gap-3 sm:absolute right-3">
                <p className="mt-5 sm:mt-0"><Skeleton animation="wave" variant="rounded" width={200} height={30} /></p>
              </div>
            </div>
            {/* Course Chapters Here */}
            <div className="my-2 bg-gray-100 w-full pt-10" style={{ borderRadius: '10px', border: '1px gray solid', height: '500px' }}>
              <div className="  sm:flex justify-center sm:mt-40 gap-5" >
                <div className="flex justify-center mt-10 px-2 sm:mt-0">
                  <Skeleton animation="wave" variant="rounded" width={250} height={60} />
                </div>
                <div className="flex justify-center mt-10 px-2 sm:mt-0">
                  <Skeleton animation="wave" variant="rounded" width={250} height={60} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>;
  }
  const generatePDFQuiz = () => {
    // Check if selectedQuestions is defined and contains the questions array
    if (!selectedQuestions || !selectedQuestions.questions) {
      console.error('Selected questions data is not available.');
      return;
    }

    const formatQuestionOrAnswer = (text, level) => ({
      margin: [level * 10, 0, 0, 2], // Adjust margin based on level (0 for question, 1 for answer)
      text: text
    });

    const docDefinition = {
      content: [
        { text: selectedQuestions.name, style: 'header' },
        { text: `Created By: ${selectedQuestions.createdBy}`, style: 'createdDate' },
        { text: `Date Created: ${selectedQuestions.dateCreated}`, style: 'createdDate' },
        { text: '\n' }, // Add some space
        { text: `Multiple Choice`, style: 'MultipleChoice' },
        ...selectedQuestions.questions.flatMap((question, index) => [
          formatQuestionOrAnswer(`${index + 1}. ${question.question}`, 1),
          formatQuestionOrAnswer(`a) ${question.answer1 || ''}`, 2),
          formatQuestionOrAnswer(`b) ${question.answer2 || ''}`, 2),
          formatQuestionOrAnswer(`c) ${question.answer3 || ''}`, 2),
          formatQuestionOrAnswer(`d) ${question.answer4 || ''}`, 2),
          { text: '\n' } // Add some space after each question
        ])
      ],
      styles: {
        header: {
          fontSize: 18,
          bold: true
        },
        createdDate: {
          fontSize: 10,
          bold: true
        },
        MultipleChoice: {
          fontSize: 14,
          bold: true,
          margin: [0, 0, 0, 10]
        },
        question: {
          fontSize: 14,
          margin: [10, 10, 0, 5]
        },
        answer: {
          fontSize: 15,
          margin: [20, 0, 0, 2]
        }
      },
      background: function (currentPage, pageSize) {
        return [
          {
            image: GMZLogo,
            width: 150,
            height: 150,
            opacity: 0.5,
            alignment: 'center',
            zIndex: 10,
            absolutePosition: { x: 0, y: 300 },
            margin: [0, 0, 0, 0]
          },
          // ...Array(20).fill().map((_, i) => (
            {
            text: 'The AI ultimate gamification platform',
            fontSize: 30,
            color: 'gray',
            opacity: 0.2,
            bold: true,
            alignment: 'center',
            absolutePosition: { x: 0, y: 430 },
            margin: [0, 20, 0, 0],
          }
        // )),
        ];
      }



    };

    // Generate PDF
    pdfMake.createPdf(docDefinition).download(`${selectedQuestions.name} Quiz.pdf`);
  };
  // Generate and download only Right answer form
  const generatePDFRightAnswers = () => {
    if (!selectedQuestions || !selectedQuestions.questions) {
      console.error('Selected questions data is not available.');
      return;
    }

    const docDefinition = {
      content: [
        { text: selectedQuestions.name, style: 'header' },
        { text: `Created By: ${selectedQuestions.createdBy}`, style: 'CreatedDate' },
        { text: `Date Created: ${selectedQuestions.dateCreated}`, style: 'CreatedDate' },
        { text: '\n' },
        {
          table: {
            body: [
              ['Sr#', 'Question', 'Answer'],
              ...selectedQuestions.questions.map((question, index) => [
                index + 1,
                question.question,
                question.answer1 || '',
              ])
            ]
          }
        }
      ],
      styles: {
        header: {
          fontSize: 18,
          bold: true
        },
        CreatedDate: {
          fontSize: 10,
          bold: true
        }
      },
      background: function (currentPage, pageSize) {
        return [
          {
            image: GMZLogo,
            width: 150,
            height: 150,
            opacity: 0.5,
            alignment: 'center',
            zIndex: 10,
            absolutePosition: { x: 0, y: 300 },
            margin: [0, 0, 0, 0]
          },
          // ...Array(20).fill().map((_, i) => (
            {
            text: 'The AI ultimate gamification platform',
            fontSize: 30,
            color: 'gray',
            opacity: 0.2,
            bold: true,
            alignment: 'center',
            absolutePosition: { x: 0, y: 430 },
            margin: [0, 20, 0, 0]
          }
        // )),
        ];
      }
    };

    pdfMake.createPdf(docDefinition).download(`${selectedQuestions.name} RightAnswers.pdf`);
  };



  return (
    <div className='w-full'>
      <MetaTags title={`Download PDF of the ${selectedQuestions?.name}`} description='Gamizign Download QuestionS List page--' />
      <div className='flex relative px-5 py-2 mb-2 ContentHeadingBg'>
        <h3 className="text-xl">Question Lists</h3>
        <LoginHeading objects={setOnboarding} ActualValue={Qlist} />
      </div>
      <div className="sm:m-7 my-7 bg-white">
      {selectedQuestions && Onboarding === 1  &&<Joyride callback={() => { }} continuous showProgress
                    run={run} steps={steps} hideCloseButton scrollToFirstStep showSkipButton
                    styles={{
                        buttonNext: { backgroundColor: '#E5F2E3', borderRadius: '5px',color:'black', },
                        buttonBack: { backgroundColor: '#E5F2E3',  borderRadius: '5px', color:'black', }
                      }} />}
        <div className='flex justify-center static'><img className='absolute top-6' src={QListDashboardIcon} alt="" /></div>
        <div className="px-5 py-10">
          <div className="sm:flex py-2 relative px-3" style={{ borderBottom: "2px gray solid" }}>
            <p><b>Name</b></p>
            <div id="QLname" className="flex gap-3 sm:absolute right-3">
              <p><b>{selectedQuestions.name}</b></p>
            </div>
          </div>
          {/* Course Chapters Here */}
          <div className="my-2 bg-gray-100 w-full pt-10" style={{ borderRadius: '10px', border: '1px gray solid', height: '500px' }}>
            <div className="  sm:flex justify-center sm:mt-40 gap-5" >
              {/* Right answers here */}
              <div className="flex flex justify-center">
                <button id="GenQuiz" onClick={() => { generatePDFQuiz() }} className="flex border p-5 bg-white font-bold" style={{ borderRadius: '11px', width: '250px' }}>
                  <img className="mr-3" src={PdfICon} alt="quiz" />Quiz form</button>
              </div>
              <div className="flex justify-center mt-10 px-2 sm:mt-0">
                <button id="GenRight" onClick={generatePDFRightAnswers} className="flex border p-5 bg-white font-bold" style={{ borderRadius: '11px', width: '250px' }}>
                  <img className="mr-3" src={PdfICon} alt="rightAnswers" />Right Answers form</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default PdfDownloadQuestionList;
