import React, { useEffect, useState } from "react";

import QListDashboardIcon from '../../../../../assets/icons/ActivityContentIcons/QuestionListsPatch5/Question Lists dashboard icon.png'
import Addfolder from '../../../../../assets/icons/ActivityContentIcons/QuestionListsPatch5/create group icon.png'
import GridIconOn from '../../../../../assets/icons/ActivityContentIcons/QuestionListsPatch5/grid icon on.png'
import GridIconOff from '../../../../../assets/icons/ActivityContentIcons/QuestionListsPatch5/grid icon off.png'
import ListIconOn from '../../../../../assets/icons/ActivityContentIcons/QuestionListsPatch5/list icon on.png'
import ListIconOff from '../../../../../assets/icons/ActivityContentIcons/QuestionListsPatch5/list icon off.png'
import QuestionVolume from '../../../../../assets/icons/ActivityContentIcons/QuestionListsPatch5/questions volume icon.png'
import GradVolume from '../../../../../assets/icons/ActivityContentIcons/QuestionListsPatch5/grade volume icon.png'
import PdfICon from '../../../../../assets/icons/ActivityContentIcons/QuestionListsPatch5/pdf icon.png'
import EditQuestoin from '../../../../../assets/icons/ActivityContentIcons/QuestionListsPatch5/edit icon.png'
import DeletQuestoin from '../../../../../assets/icons/ActivityContentIcons/QuestionListsPatch5/delete icon.png'
import NextIcon from '../../../../../assets/icons/ActivityContentIcons/QuestionListsPatch5/Next icon.png'
import BackIcon from '../../../../../assets/icons/ActivityContentIcons/QuestionListsPatch5/back iconB.png'
import CheckBoxOn from '../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/PPTX check box on.png'
import CheckBoxOff from '../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/PPTX check box off.png'
import CreateNewQuestion from '../../../../../assets/icons/ActivityContentIcons/New Course icon.png'
import AlertIcon from '../../../../../assets/icons/Alert-icon.png'
import { Link, Navigate, unstable_HistoryRouter, useNavigate } from "react-router-dom";
import Modal from 'react-modal'
import LoginHeading from "../../../../LoginHeading/LoginHeading";
import { CircularProgress, Skeleton, Tooltip } from "@mui/material";
import { Button, Dialog, Alert, DialogContent } from '@mui/material';
import LoadingComponent from "../../../../GlobalLoading/GlobalLoading";
import QuestionsListsSkeleton from "./QuestionsListsSkeleton";
import MetaTags from "../../../../GlobalLoading/MetaData";
import GroupQuestionLists from "./GroupQestionsLists";
import Joyride from "react-joyride";
import axiosInstance from "../../../../../axios";



const QuestionList = () => {
    const [currentImageGrid, setCurrentImageGrid] = useState(GridIconOff);
    const toggleImage = () => {
        setCurrentImageGrid((prevImage) => (prevImage === GridIconOff ? GridIconOn : GridIconOff));
    };
    const [questionsLists, setQuestionsLists] = useState(null);
    const [loading, setLoading] = useState(true);
    const [resp, setResp] = useState('ok');
    const [progress, setProgress] = useState(false);
    const [dynamicMessage, setDynamicMessage] = useState(null);
    var instructor_id = parseInt(localStorage.getItem("instructor_id"));

    useEffect(() => {
        const getQuestionsLists = async () => {
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            };
            try {
                setLoading(true);
                const response = await axiosInstance.get(`${process.env.REACT_APP_ENDPOINT}/instructors/${instructor_id}/question-lists`)
                    setLoading(false);
                    setQuestionsLists(response.data);
                    setFilteredQuestionLists(response.data);
            } catch (error) {
                console.error('Error fetching courses:', error);
                // Handle the error if needed
            } finally {
                setLoading(false);
            }

        }
        getQuestionsLists();

    }, []);

    const navigate = useNavigate();
    const [filteredQuestionLists, setFilteredQuestionLists] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);

        const lowercasedSearchTerm = e.target.value.toLowerCase();

        const filteredLists = questionsLists.filter((questionsList) => {
            return questionsList.name.toLowerCase().includes(lowercasedSearchTerm);
        });

        setFilteredQuestionLists(filteredLists);
    };
    // color Palette
    // const QuestionsListsColors = ['#ACCCE5', '#BCE2BB', '#DDE6C0', '#FFF3C6', '#FEE7C3', '#FCDCBD', '#F6C1C1', '#DFBFDD'];
    const QuestionsListsColors = ['#DBE6F5', '#E5F2E3', '#F5F9E6', '#FEFAE2', '#FEF7E2', '#FEF1DD', '#FDE4DC', '#EFE1EE'];

    // Delete Question list
    const [QuestionListId, setQuestionListId] = useState();
    const handleDeleteQuestionList = async () => {
        setResp(null);
        // const updatedQuestionLists = filteredQuestionLists.filter(questionsList => questionsList.questionList_id !== QuestionListId);
        // setFilteredQuestionLists(updatedQuestionLists);
        // setCoursebotmodalIsOpen(false);
        setProgress(true);
        var req = {
            "questionList_id": QuestionListId,
            "instructor_id": instructor_id,
        };
        fetch(`${process.env.REACT_APP_ENDPOINT}/questionLists/${QuestionListId}?instructor_id=${instructor_id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${sessionStorage.getItem('token')}`,
            },
            body: JSON.stringify(req),
        })
            .then(response => {
                if (response.ok) {
                    response.json().then(data => { setResp(data) });
                    const updatedQuestionLists = filteredQuestionLists.filter(questionsList => questionsList.questionList_id !== QuestionListId);
                    setFilteredQuestionLists(updatedQuestionLists);
                    setCoursebotmodalIsOpen(true);
                    setDynamicMessage('Question list has been deleted successfully')
                }
                else {
                    response.json().then(data => { setResp(data) })
                    setDynamicMessage('You can not delete the question list')
                    setCoursebotmodalIsOpen(true);
                    throw new Error(`Failed to delete questionlist ${QuestionListId}`);
                }
            })
            .catch(error => {
                console.error('Error deleting questionlist:', error);
            });
        setProgress(false);
    };
    // Delete Course PopUp
    const [QuestionListName, setQuestionListName] = useState('');
    const [CoursebotmodalIsOpen, setCoursebotmodalIsOpen] = useState(false);
    const openCurseBotModal = () => {
        setCoursebotmodalIsOpen(true);
    };
    const CourseBotcloseModal = () => {
        setCoursebotmodalIsOpen(false);
    };
    // Make array for Pigination 
    const [currentPage, setCurrentPage] = useState(1);
    const rowsPerPage = 5;

    const indexOfLastquestionsLits = currentPage * rowsPerPage;
    const indexOfFirstquestionsLits = indexOfLastquestionsLits - rowsPerPage;
    const questionsLitsPigination = filteredQuestionLists.slice(indexOfFirstquestionsLits, indexOfLastquestionsLits);

    const totalPages = Math.ceil(filteredQuestionLists.length / rowsPerPage);

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };
    // Dwnload Question list
    const DownloadQlist = (questionList_id, type) =>{
        if (type === 'download') {
            
            navigate(`/Dashboard/DownloadQuestionsList?questionList_id=${questionList_id}` ,{state:{ Qlist:questionsLists?.length}})
        }else{
            navigate(`/Dashboard/EditQuestionsList?questionList_id=${questionList_id}` ,{state:{ Qlist:questionsLists?.length}})
        }
    }
    // Groping Question lists as per maintopic
    const [GroupQlists, setGroupQlists] = useState(true);
    const [Onboarding, setOnboarding] = useState(null);
    const ungroupQlists = () =>{
    if (filteredQuestionLists.length === 1) {
        setGroupQlists(false);
    }}
    useEffect(()=>{
        ungroupQlists();
        setOnboarding(questionsLists?.length);
    },[questionsLists])
      // Onboarding
      const steps = questionsLists?.length === 0  ?  [{
        content: <h2>Upload image for any question.</h2>,
        placement: 'right',
        target: '#Uploa',
      },
      {
        content: <div><h2>From here you can create new question list.</h2>
          <Link className='border py-1 px-3 bg-gray-100 rounded mt-2 hover:bg-blue-200' to="/Dashboard/CreateCourse"  >Click Here to start creating course</Link></div>,
        placement: 'top',
        target: '#createQlist',
      }, ] : [
        {
            content: <h2>Upload image for any question.</h2>,
            placement: 'right',
            target: '#Uploa',
          },
          {
            content: <h2>See Question lists cards.</h2>,
            placement: 'top',
            target: '#grid',
          },
          {
            content: <h2>View Questions lists in table.</h2>,
            placement: 'top',
            target: '#table',
          },
          {
            content: <h2>Search question lits by any name,tag,grade,...etc..</h2>,
            placement: 'top',
            target: '#searchInput',
          },
          {
            content: <h2>Show questions lists grops by Main Topic English Arts, Math, and much more ....</h2>,
            placement: 'top',
            target: '#group',
          },
          {
            content: <h2>Your Question lists are here.</h2>,
            placement: 'top',
            target: '#Qlists',
          },
          {
            content: <h2>Total questions in Question list.</h2>,
            placement: 'top',
            target: '#QLength',
          },
          {
            content: <h2>Download Question list , question with all mcqs option and also you can download question and right answers.</h2>,
            placement: 'top',
            target: '#QLDownload',
          },
          {
            content: <h2>Edit Question list , add new or delete question change question bloom level difficulty level and much more.....</h2>,
            placement: 'top',
            target: '#QLEdit',
          },
          {
            content: <h2>Here you can delete question list permanent from your acccout.</h2>,
            placement: 'top',
            target: '#QLDelete',
          },
          {
            content:<h2>From here you can create new question list.</h2>,
            placement: 'top',
            target: '#createQlist',
          },
    ]
  const [{ run,}, setState] = useState({ run: true,})
    return (
        <div className='w-full'>
            <MetaTags title='Questions Lists' description='Questions Lists page--' />
            <div className='flex relative px-5 py-2 mb-2 ContentHeadingBg'>
                <h3 className="text-xl">Question Lists</h3>
                <LoginHeading objects={setOnboarding} />
            </div>
            <div className="m-7 bg-white">
            {!loading && (Onboarding === 0 || Onboarding === 1)  && <Joyride callback={() => { }}
                    run={run} steps={steps} scrollToFirstStep showSkipButton showProgress hideCloseButton continuous
                    styles={{
                        buttonNext: { backgroundColor: '#E5F2E3', borderRadius: '5px',color:'black', },
                        buttonBack: { backgroundColor: '#E5F2E3',  borderRadius: '5px', color:'black', }
                      }} />}
                {/* PopUp Delete QuestionsList */}
                <Dialog open={CoursebotmodalIsOpen} onClose={CourseBotcloseModal}>
                    <DialogContent>
                        <Alert severity='warning'>
                            {!resp && <div className="flex justify-center z-10"><CircularProgress /></div>}
                            <h1 className={`sm:text-2xl `}>{dynamicMessage} <b>{QuestionListName}</b>{dynamicMessage === 'Are you sure you want to Delete the questions list' ? '?' : '.'}</h1>
                            {dynamicMessage === 'You can not delete the question list' && <p className="flex justify-center">{resp?.detail}</p>}                        </Alert>
                    </DialogContent>
                    {dynamicMessage === 'Are you sure you want to Delete the questions list' ?
                        <div className='flex justify-center'>
                            <Button onClick={() => { setResp('ok'); handleDeleteQuestionList() }} color="primary">
                                Yes
                            </Button>
                            <Button onClick={() => { setResp('ok'); CourseBotcloseModal() }} color="primary">
                                No
                            </Button>
                        </div>
                        : dynamicMessage === 'You can not delete the question list' ?
                            <div className='flex justify-center'>
                                <Button onClick={() => { navigate(`/Dashboard/refQuestionList?QuestionListId=${QuestionListId}`); setResp('ok'); CourseBotcloseModal() }} color="primary">
                                    Change
                                </Button>
                                <Button onClick={() => { setResp('ok'); CourseBotcloseModal() }} color="primary">
                                    Okay
                                </Button>
                            </div>
                            : <div className='flex justify-center'>
                                <Button onClick={() => { setResp('ok'); CourseBotcloseModal() }} color="primary">
                                    Okay
                                </Button>

                            </div>
                    }
                </Dialog>
                <div className='flex justify-center static'><img className='absolute top-6' src={QListDashboardIcon} alt="" /></div>
                <div className="px-7 pt-10">
                    <div className="flex py-2 relative px-3" style={{ borderBottom: "2px gray solid" }}>
                        {loading ? <Skeleton animation='wave' variant="rounded" width={100} height={30} /> : <p><b>Name</b></p>}

                        {loading ? <div className="flex gap-3 absolute right-3"><Skeleton animation='wave' variant="rounded" width={100} height={30} /></div> :
                            <div className="flex gap-3 absolute right-3">
                                {/* <img src={Addfolder} alt="1" /> */}
                                <Tooltip title="Grid View"> <img id="grid" className="cursor-pointer" src={currentImageGrid} alt='Grid View' onClick={toggleImage} /></Tooltip>
                                <Tooltip title="Table View"><img id="table" className="cursor-pointer" src={currentImageGrid === GridIconOff ? ListIconOn : ListIconOff} alt="3" onClick={toggleImage} /></Tooltip>
                            </div>}
                    </div>
                    <div>
                        {loading ? <Skeleton className="mt-3" animation='wave' variant="rounded" width={200} height={30} /> :
                            <div className="sm:flex gap-5">
                                <input id="searchInput" value={searchTerm} onChange={handleSearch} className="sm:px-5 ps-2 py-2 border mt-3 rounded sm:w-80" type="text" placeholder="Search by any name,tag,grade,...etc." />
                                {currentImageGrid === GridIconOff &&<div id="group" className="flex mt-2 sm:mt-5"><img className="cursor-pointer" src={GroupQlists ? CheckBoxOn : CheckBoxOff} onClick={()=>{setGroupQlists(prev => !prev)}} style={{ height: '30px' }} alt="group" /><span className="ps-1 mt-1"><b>Groups</b></span></div>}
                            </div>
                        }
                    </div>
                    {/* <div className={`mt-5 ${currentImageGrid === GridIconOn ? 'hidden' : ''}`}><input type="checkbox" /> Show Groups</div> */}
                </div>

                <div>
                    {loading ? (
                        <QuestionsListsSkeleton />
                    ) :
                        ( 
                        <div className={`${currentImageGrid === GridIconOn ? 'hidden' : ''}`}>
                            {GroupQlists ? <div>
                                <GroupQuestionLists filteredQuestionLists={filteredQuestionLists} setDynamicMessage={setDynamicMessage} openCurseBotModal={openCurseBotModal}
                                setQuestionListName={setQuestionListName} setQuestionListId={setQuestionListId} DownloadQlist={DownloadQlist} />
                            </div> :
                        <div id="Qlists" className={`flex flex-wrap gap-4 px-5 justify-center grid grid-cols-1 lg:grid-cols-3 sm:grid-cols-2`}>
                            {filteredQuestionLists.length > 0 ? (
                                filteredQuestionLists.map((questionsList, QuestionsListsIndex) => (
                                    <div className="bg-gray-100 flex px-5 justify-center my-3 hover:scale-95 cursor-pointer shadow-xl" style={{ backgroundColor: QuestionsListsColors[QuestionsListsIndex % QuestionsListsColors.length], borderRadius: '10px', }}>
                                        <div className="py-3 relative">
                                            <h1 className="font-bold  sm:px-6 py-1">{questionsList.name}</h1>
                                            <div className="flex justify-center my-2 gap-8">
                                                <p id="QLength" className="flex"><span><img className="mt-1.5 mr-2" src={QuestionVolume} alt="q" /></span>{questionsList.questions.length}</p>
                                                <p className="flex"><span><img className="mt-1.5 mr-2" src={GradVolume} alt="q" /></span> </p>
                                            </div>
                                            <div className="flex justify-center">
                                                <div className="flex justify-center gap-2 bg-white p-2 border absolute -bottom-6" style={{ width: '120px', borderRadius: '8px' }}>
                                                    <Tooltip title="Download">
                                                        <img id="QLDownload" onClick={()=>{DownloadQlist(questionsList.questionList_id, 'download')}} src={PdfICon} alt="pdf" />
                                                        </Tooltip>
                                                    <Tooltip title="Edit">
                                                        <img id="QLEdit" onClick={()=>{DownloadQlist(questionsList.questionList_id, 'edit')}} src={EditQuestoin} alt="edit" />
                                                    </Tooltip>
                                                    <Tooltip title="Delete"> <img id="QLDelete" className="cursor-pointer" src={DeletQuestoin} alt="delete" onClick={() => { setDynamicMessage('Are you sure you want to Delete the questions list'); openCurseBotModal(); setQuestionListName(questionsList.name); setQuestionListId(questionsList.questionList_id) }} /></Tooltip>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <div className="text-gray-500">No questions lists available.</div>)}
                        </div>}
                        </div>

                        )
                    }
                </div>

                {/*Question Lists List View  */}
                <div className={`px-2 sm:px-5 ${currentImageGrid === GridIconOff ? 'hidden' : ''}`}>


                    <div class="relative overflow-x-auto max-w-80 sm:max-w-full">
                        <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                            <thead class="text-gray-700 uppercase dark:bg-gray-700 dark:text-gray-400">
                                <tr>
                                    <th scope="col" class="">
                                    </th>
                                    <th scope="col" class="px-3 py-3">
                                        Created By
                                    </th>
                                    <th scope="col" class="px-3 py-3">
                                        Date Created
                                    </th>
                                    <th scope="col" class="px-3 py-3">
                                        Last Modified
                                    </th>
                                    <th scope="col" class="px-3 py-3">
                                        Last Modified By
                                    </th>
                                    <th scope="col" class="">
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {loading ? (
                                    <LoadingComponent />
                                ) :
                                    (questionsLitsPigination && Array.isArray(questionsLitsPigination)) ?
                                        questionsLitsPigination.map((questionsList, index) => (
                                            <tr class="even:bg-white even:dark:bg-gray-900 odd:bg-gray-50 odd:dark:bg-gray-800 border-b dark:border-gray-700">
                                                <th scope="row" class="px-3 py-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                    {questionsList.name}
                                                </th>
                                                <td class="px-3 py-6">
                                                    {questionsList.createdBy}
                                                </td>
                                                <td class="px-3 py-6">
                                                    {questionsList.dateCreated}
                                                </td>
                                                <td class="px-3 py-6">
                                                    {questionsList.lastModified}
                                                </td>
                                                <td class="px-3 py-6">
                                                    {questionsList.createdBy}
                                                </td>
                                                <td class="px-3 py-6">
                                                    <div className="flex justify-center">
                                                        <div className="flex justify-center gap-2  p-2" style={{ width: '120px', borderRadius: '8px' }}>
                                                            <Tooltip title="Download"><Link to={`/Dashboard/DownloadQuestionsList?questionList_id=${questionsList.questionList_id}`}><img src={PdfICon} alt="pdf" /></Link></Tooltip>
                                                            <Tooltip title="Edit"><Link to={`/Dashboard/EditQuestionsList?questionList_id=${questionsList.questionList_id}`}>
                                                                <img src={EditQuestoin} alt="edit" />
                                                            </Link></Tooltip>
                                                            <Tooltip title="Delete"><span className="cursor-pointer" onClick={() => { setDynamicMessage('Are you sure you want to Delete the questions list'); openCurseBotModal(); setQuestionListName(questionsList.name); setQuestionListId(questionsList.questionList_id) }}><img src={DeletQuestoin} alt="delete" /></span></Tooltip>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        )

                                        ) : (<div className="text-gray-500">No questions lists available.</div>)}

                            </tbody>
                        </table>
                    </div>
                    <div className="flex justify-center gap-5 mt-4">
                        <button onClick={handlePrevPage} className="px-4 py-2 BackBtn hover:bg-blue-200 rounded disabled:opacity-50 flex" disabled={currentPage === 1}>
                            <img className="mt-1 mr-2" src={BackIcon} alt="" /> Prev
                        </button>
                        <span className="px-4 py-2">{currentPage} of {totalPages}</span>
                        <button onClick={handleNextPage} className="px-4 py-2 BackBtn hover:bg-blue-200 rounded disabled:opacity-50 flex" disabled={currentPage === totalPages}>
                            Next <img className="mt-1 ms-2" src={NextIcon} alt="" />
                        </button>
                    </div>
                </div>
                <div className="flex justify-center">
                    {loading ? <Skeleton animation='wave' variant="rounded" width={150} height={40} /> :
                        <Link to='/Dashboard/CreateCourse'>  <button id="createQlist" className=" border py-2 px-6 my-10 font-bold flex BackBtn hover:bg-blue-200" style={{ borderRadius: '7px' }}>
                            <img src={CreateNewQuestion} alt="AddNewQ" style={{ width: '30px', paddingRight: '5px' }} />
                            Create</button></Link>}
                </div>
            </div>
        </div>
    )
};

export default QuestionList;