import React, { useEffect, useRef } from "react";
import LoginHeading from "../../../../LoginHeading/LoginHeading";
import CheckBoxOn from '../../../../../assets/icons/ActivityContentIcons/The-Gamizign-World/PPTX check box on.png'
import CheckBoxOff from '../../../../../assets/icons/ActivityContentIcons/The-Gamizign-World/PPTX check box off.png'
import Learneres from '../../../../../assets/icons/ActivityContentIcons/The-Gamizign-World/Users icon off.png'
import SearchIcon from '../../../../../assets/icons/ActivityContentIcons/The-Gamizign-World/magnifer icon.png'
import NextIcon from '../../../../../assets/icons/ActivityContentIcons/The-Gamizign-World/Next icon.png'
import BackIcon from '../../../../../assets/icons/ActivityContentIcons/The-Gamizign-World/back iconB.png'
import LearnersIcon from '../../../../../assets/icons/ActivityContentIcons/The-Gamizign-World/Learners insight icon.png'
import SkillsIcon from '../../../../../assets/icons/ActivityContentIcons/The-Gamizign-World/Skills insight icon.png'
import KnowlwdgeIcon from '../../../../../assets/icons/ActivityContentIcons/The-Gamizign-World/Knowledge insight icon.png'
import CompetenceIcon from '../../../../../assets/icons/ActivityContentIcons/The-Gamizign-World/Competence insight icon.png'
import AccuracyIcon from '../../../../../assets/icons/ActivityContentIcons/The-Gamizign-World/Accuracy insight icon.png'
import { useState } from "react";
import ReactApexChart from "react-apexcharts";
import PlotyChart from "./Components/BoxPlotChart";
import AnalyticsTable from "./Components/PlayersAnalytics";
import QuadrantChart from "./Components/QuadrantChart";
import LoadingComponent from "../../../../GlobalLoading/GlobalLoading";
import { Box, Card, Skeleton } from "@mui/material";
import MetaTags from "../../../../GlobalLoading/MetaData";
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import Joyride from "react-joyride";
import { Link } from "react-router-dom";
import axiosInstance from "../../../../../axios";





const GamizignWorld = ({setMode, mode, Authentication}) => {
    const [skills, setSkills] = useState(CheckBoxOn)
    const skillsHandle = () => {
        setSkills((prevImage) => (prevImage === CheckBoxOn ? CheckBoxOff : CheckBoxOn))
    };
    const [knowledge, setKnowledge] = useState(CheckBoxOn)
    const knowledgeHandle = () => {
        setKnowledge((prevImage) => (prevImage === CheckBoxOn ? CheckBoxOff : CheckBoxOn))
    };
    const [competence, setCompetence] = useState(CheckBoxOn)
    const CompetenceHandle = () => {
        setCompetence((prevImage) => (prevImage === CheckBoxOn ? CheckBoxOff : CheckBoxOn))
    };
    // getting data fron endpoint
    var token = sessionStorage.getItem("token");
    const [courses, setCourses] = useState([]);
    const [SingleCourseAnalytics, setSingleCourseAnalytics] = useState(null);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const getCourses = async () => {
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${token}`,
                },
            };

            var instructor_id = parseInt(localStorage.getItem("instructor_id"));
            

            try {
                console.log(token);
                setLoading(true);

                // const response = await fetch(`${process.env.REACT_APP_ENDPOINT}/instructors/${instructor_id}/courses`, requestOptions);
                const response = await axiosInstance.get(`${process.env.REACT_APP_ENDPOINT}/instructors/${instructor_id}/courses`);
                // const data = await response.json();

                setCourses(response.data);
                setSelectedCourse(response.data);
                setOnboarding(response.data.length)
            } catch (error) {
                console.error('Error fetching courses:', error);
                // Handle the error if needed
            } finally {
                setLoading(false);
            }
        };

        getCourses();
    }, []);
    // handler course change 
    const [Highlight, setHighlight] = useState(null);
    const [selectedCourse, setSelectedCourse] = useState([]);
    const handleCourseChange = (e) => {
        setStudentId(null);
        setCurrentPage(1);
        const selectedIndex = parseInt(e.target.value);
        if (!isNaN(selectedIndex) && selectedIndex !== -1) {
            setSelectedCourse(courses[selectedIndex]);
            setHighlight(e.target.value);
            const getCourseAnalytics = async () => {
                const requestOptions = {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                };

                try {
                    setLoading(true);

                    const response = await axiosInstance.get(`${process.env.REACT_APP_ENDPOINT}/analytics/courses/${(courses[selectedIndex].course_id)}`);
                    // const data = await response.json();
                    setSingleCourseAnalytics(response.data);
                } catch (error) {
                    console.error('Error fetching courses:', error);
                } finally {
                    setLoading(false);
                }
            };
            getCourseAnalytics();
        } else {
            setSelectedCourse(courses);
            setHighlight(null);
            setSingleCourseAnalytics(null);
        }
    };

    // Function to extract all activities from filteredCourses and set them in state
    const [students, setStudents] = useState([]);
    const [filtestudents, setfiltestudents] = useState([]);
    const [analyticsData, setAnalyticsData] = useState([]);

    const extractStudents = () => {
        let allStudents = [];
        if (Array.isArray(selectedCourse)) {
            // If selectedCourse is an array
            allStudents = selectedCourse.flatMap(course => course.students);
        }
        else if (typeof selectedCourse === 'object') {
            // If selectedCourse is an object
            allStudents = Object.values(selectedCourse.students).flatMap(students => students);
        }
        // Convert array to Set to filter out duplicate students by student_id
        const uniqueStudents = Array.from(new Set(allStudents.map(student => student.student_id)))
            .map(student_id => allStudents.find(student => student.student_id === student_id));
        // Sort the unique students by firstName in ascending order
        // uniqueStudents.sort((a, b) => a.firstName.localeCompare(b.firstName));
        // uniqueStudents.sort((a, b) => a.firstName.localeCompare(b.firstName));
        setStudents(uniqueStudents);
        setfiltestudents(uniqueStudents);
    };

    useEffect(() => {
        extractStudents();
    }, [selectedCourse]);

    // order name wise learneres
    const orderLearners = () => {
        const ordernames = [...filtestudents]
        ordernames.sort((a, b) => a.firstName.localeCompare(b.firstName));
        setStudents(ordernames);

    };

    //   for searche students
    const [searchTerm, setSearchTerm] = useState('');


    const handleSearch = (e) => {
        setSearchTerm(e.target.value);

        const lowercasedSearchTerm = e.target.value.toLowerCase();

        const filteredLists = filtestudents.filter((students) => {
            return students.firstName.toLowerCase().includes(lowercasedSearchTerm);
        });

        setStudents(filteredLists);
        setCurrentPage(1)
    };

    const highlightText = (text, highlight) => {
        if (!highlight) return text;
        const regex = new RegExp(`(${highlight})`, 'gi');
        return text.split(regex).map((part, index) =>
            part.toLowerCase() === highlight.toLowerCase() ? (
                <span key={index} className="bg-yellow-300 text-xl font-bold">{part}</span>
            ) : (
                part
            )
        );
    };



    // Calculate cards' values
    let totalStudents = 0;
    let totalScore = 0;
    let totalSessions = 0;
    let CompleteSessions = 0;
    let correctAnswers = 0;
    let incorrectAnswers = 0;
    let allPlayers = 0;
    let totalTime = 0;

    const uniqueStudentIds = new Set();

    if (selectedCourse) {
        if (Array.isArray(selectedCourse)) {
            selectedCourse.forEach(course => {
                totalStudents += course.students.length;
                if (course.stages) {
                    course.stages.forEach(stage => {
                        stage.activities.forEach(activity => {
                            activity.analyticsList.forEach(analytics => {
                                if (!uniqueStudentIds.has(analytics.student_id)) {
                                    uniqueStudentIds.add(analytics.student_id);
                                    allPlayers++;
                                }
                                totalScore += analytics.score;
                                totalSessions += analytics.sessions;
                                CompleteSessions += analytics.gamesWon;
                                correctAnswers += analytics.correctAnswers;
                                incorrectAnswers += analytics.incorrectAnswers;
                                totalTime += analytics.totalTime;
                            });
                        });
                    });
                }
            });
        } else {
            // totalStudents += selectedCourse.students.length;
            // selectedCourse.stages.forEach(stage => {
            //     stage.activities.forEach(activity => {
            //         activity.analyticsList.forEach(analytics => {
            //             if (!uniqueStudentIds.has(analytics.student_id)) {
            //                 uniqueStudentIds.add(analytics.student_id);
            //                 allPlayers++;
            //             }
            //             totalScore += analytics.score;
            //             totalSessions += analytics.sessions;
            //             CompleteSessions += analytics.gamesWon;
            //             correctAnswers += analytics.correctAnswers;
            //             incorrectAnswers += analytics.incorrectAnswers;
            //             totalTime += analytics.totalTime;
            //         });
            //     });
            // });
        }
    }


    // Learners chart
    class ApexChart extends React.Component {
        constructor(props) {
            super(props);

            this.state = {

                series: [76],
                options: {
                    chart: {
                        type: 'radialBar',
                        offsetY: -20,
                        sparkline: {
                            enabled: true
                        }
                    },
                    plotOptions: {
                        radialBar: {
                            startAngle: -90,
                            endAngle: 90,
                            track: {
                                background: "#DBE6F5",
                                strokeWidth: '80%',
                                margin: 5, // margin is in pixels
                                dropShadow: {
                                    enabled: true,
                                    top: 2,
                                    left: 0,
                                    color: '#999',
                                    opacity: 1,
                                    blur: 2
                                }
                            },
                            dataLabels: {
                                name: {
                                    show: false
                                },
                                value: {
                                    offsetY: -2,
                                    fontSize: '22px'
                                }
                            }
                        }
                    },
                    grid: {
                        padding: {
                            top: -10
                        }
                    },
                    fill: {
                        type: 'gradient',
                        gradient: {
                            shade: 'light',
                            shadeIntensity: 0.4,
                            inverseColors: false,
                            opacityFrom: 1,
                            opacityTo: 1,
                            stops: [0, 50, 53, 91]
                        },
                        colors: ['#ACCCE5'],
                    },
                    labels: ['Average Results'],
                },


            };
        }



        render() {
            return (
                <div>
                    <div id="chart">
                        <ReactApexChart options={this.state.options} series={this.state.series} type="radialBar" />
                    </div>
                    <div id="html-dist"></div>
                </div>
            );
        }
    }
    // Skills chart
    class SkillsChart extends React.Component {
        constructor(props) {
            super(props);

            this.state = {

                series: [46],
                options: {
                    chart: {
                        type: 'radialBar',
                        offsetY: -20,
                        sparkline: {
                            enabled: true
                        }
                    },
                    plotOptions: {
                        radialBar: {
                            startAngle: -90,
                            endAngle: 90,
                            track: {
                                background: "#E5F2E3",
                                strokeWidth: '97%',
                                margin: 5, // margin is in pixels
                                dropShadow: {
                                    enabled: true,
                                    top: 2,
                                    left: 0,
                                    color: '#999',
                                    opacity: 1,
                                    blur: 2
                                }
                            },
                            dataLabels: {
                                name: {
                                    show: false
                                },
                                value: {
                                    offsetY: -2,
                                    fontSize: '22px'
                                }
                            }
                        }
                    },
                    grid: {
                        padding: {
                            top: -10
                        }
                    },
                    fill: {
                        type: 'gradient',
                        gradient: {
                            shade: 'light',
                            shadeIntensity: 0.4,
                            inverseColors: false,
                            opacityFrom: 1,
                            opacityTo: 1,
                            stops: [0, 50, 53, 91]
                        },
                        colors: ['#BCE2BB'],
                    },
                    labels: ['Average Results'],
                },


            };
        }



        render() {
            return (
                <div>
                    <div id="chart">
                        <ReactApexChart options={this.state.options} series={this.state.series} type="radialBar" />
                    </div>
                    <div id="html-dist"></div>
                </div>
            );
        }
    }
    // Knowledge chart
    class KnowledgeChart extends React.Component {
        constructor(props) {
            super(props);

            this.state = {

                series: [-15],
                options: {
                    chart: {
                        type: 'radialBar',
                        offsetY: -20,
                        sparkline: {
                            enabled: true
                        }
                    },
                    plotOptions: {
                        radialBar: {
                            startAngle: -90,
                            endAngle: 90,
                            track: {
                                background: "#FEFAE2",
                                strokeWidth: '97%',
                                margin: 5, // margin is in pixels
                                dropShadow: {
                                    enabled: true,
                                    top: 2,
                                    left: 0,
                                    color: '#999',
                                    opacity: 1,
                                    blur: 2
                                }
                            },
                            dataLabels: {
                                name: {
                                    show: false
                                },
                                value: {
                                    offsetY: -2,
                                    fontSize: '22px'
                                }
                            }
                        }
                    },
                    grid: {
                        padding: {
                            top: -10
                        }
                    },
                    fill: {
                        type: 'gradient',
                        gradient: {
                            shade: 'light',
                            shadeIntensity: 0.4,
                            inverseColors: false,
                            opacityFrom: 1,
                            opacityTo: 1,
                            stops: [0, 50, 53, 91]
                        },
                        colors: ['#FFF3C6'],
                    },
                    labels: ['Average Results'],
                },


            };
        }



        render() {
            return (
                <div>
                    <div id="chart">
                        <ReactApexChart options={this.state.options} series={this.state.series} type="radialBar" />
                    </div>
                    <div id="html-dist"></div>
                </div>
            );
        }
    }
    // Competence chart
    class CompetenceChart extends React.Component {
        constructor(props) {
            super(props);

            this.state = {

                series: [93],
                options: {
                    chart: {
                        type: 'radialBar',
                        offsetY: -20,
                        sparkline: {
                            enabled: true
                        }
                    },
                    plotOptions: {
                        radialBar: {
                            startAngle: -90,
                            endAngle: 90,
                            track: {
                                background: "#FDE4DC",
                                strokeWidth: '97%',
                                margin: 5, // margin is in pixels
                                dropShadow: {
                                    enabled: true,
                                    top: 2,
                                    left: 0,
                                    color: '#999',
                                    opacity: 1,
                                    blur: 2
                                }
                            },
                            dataLabels: {
                                name: {
                                    show: false
                                },
                                value: {
                                    offsetY: -2,
                                    fontSize: '22px'
                                }
                            }
                        }
                    },
                    grid: {
                        padding: {
                            top: -10
                        }
                    },
                    fill: {
                        type: 'gradient',
                        gradient: {
                            shade: 'light',
                            shadeIntensity: 0.4,
                            inverseColors: false,
                            opacityFrom: 1,
                            opacityTo: 1,
                            stops: [0, 50, 53, 91]
                        },
                        colors: ['#F6C1C1'],
                    },
                    labels: ['Average Results'],
                },


            };
        }



        render() {
            return (
                <div>
                    <div id="chart">
                        <ReactApexChart options={this.state.options} series={this.state.series} type="radialBar" />
                    </div>
                    <div id="html-dist"></div>
                </div>
            );
        }
    }
    // Accuracy chart
    class AccuracyChart extends React.Component {
        constructor(props) {
            super(props);

            this.state = {

                series: [25],
                options: {
                    chart: {
                        type: 'radialBar',
                        offsetY: -20,
                        sparkline: {
                            enabled: true
                        }
                    },
                    plotOptions: {
                        radialBar: {
                            startAngle: -90,
                            endAngle: 90,
                            track: {
                                background: "#EFE1EE",
                                strokeWidth: '97%',
                                margin: 5, // margin is in pixels
                                dropShadow: {
                                    enabled: true,
                                    top: 2,
                                    left: 0,
                                    color: '#999',
                                    opacity: 1,
                                    blur: 2
                                }
                            },
                            dataLabels: {
                                name: {
                                    show: false
                                },
                                value: {
                                    offsetY: -2,
                                    fontSize: '22px'
                                }
                            }
                        }
                    },
                    grid: {
                        padding: {
                            top: -10
                        }
                    },
                    fill: {
                        type: 'gradient',
                        gradient: {
                            shade: 'light',
                            shadeIntensity: 0.4,
                            inverseColors: false,
                            opacityFrom: 1,
                            opacityTo: 1,
                            stops: [0, 50, 53, 91]
                        },
                        colors: ['#DFBFDD'],
                    },
                    labels: ['Average Results'],
                },


            };
        }



        render() {
            return (
                <div>
                    <div id="chart">
                        <ReactApexChart options={this.state.options} series={this.state.series} type="radialBar" />
                    </div>
                    <div id="html-dist"></div>
                </div>
            );
        }
    }
    // All courses Accuracy chart
    // const colors = ['#ACCCE5', '#BCE2BB', '#DDE6C0', '#FFF3C6', '#FEE7C3', '#FCDCBD', '#F6C1C1', '#DFBFDD'];
    const colors = ['#1F77B4', '#2CA02C', '#D62728', '#FF7F0E', '#9467BD', '#8C564B', '#E377C2', '#7F7F7F'];


    // New quadrant chart 
    const [studentId, setStudentId] = useState(null); // Global variable for student_id

    // getting data from endpoint as per single or all course selection bases

    useEffect(() => {
        const fetchData = async (student_id) => {
            try {
                let url = `${process.env.REACT_APP_ENDPOINT}/analytics/courses/students/${student_id}`;
                if (typeof selectedCourse === 'object' && !Array.isArray(selectedCourse)) {
                    url += `?course_id=${selectedCourse.course_id}`;
                }
                const response = await axiosInstance.get(url);
                if (!response.status === 200) {
                    throw new Error('Network response was not ok');
                }
                // const data = await response.json();
                return response.data;
            } catch (error) {
                console.error('Error fetching data:', error);
                return null;
            }
        };

        const fetchAnalyticsData = async () => {
            const results = await Promise.all(filtestudents.map(student => fetchData(student.student_id)));
            setAnalyticsData(results);
        };

        fetchAnalyticsData();
    }, [selectedCourse, filtestudents]);


    // combine analytic and students array 
    const mergedArray = analyticsData.map((item, index) => ({
        ...item,
        ...students[index]
    }));
    // Assending and desending plyers as per score, session, accuracy and avg.time
    const [sortOrder, setSortOrder] = useState({ field: '', order: 'asc' });
    const [CombineStudents, setCombineStudents] = useState([]);
    useEffect(() => {
        setCombineStudents(mergedArray);
    }, [analyticsData])

    const sortMergedArray = (field) => {
        const order = sortOrder.field === field && sortOrder.order === 'asc' ? 'desc' : 'asc';

        const sortedArray = [...mergedArray].sort((a, b) => {
            let valueA, valueB;

            if (field === 'accuracy') {
                if (a.correctAnswers > 0) {
                    valueA = ((a.correctAnswers / (a.correctAnswers + a.incorrectAnswers)) * 100);
                    valueB = ((b.correctAnswers / (b.correctAnswers + b.incorrectAnswers)) * 100);
                } else { valueA = 0; valueB = 0; }
            } else if (field === 'Avg.time') {
                if (a.totalTime > 0) {
                    valueA = (a.totalTime / a.sessions);
                    valueB = (b.totalTime / b.sessions);
                } else { valueA = 0; valueB = 0; }
            } else {
                valueA = a[field];
                valueB = b[field];
            }

            if (typeof valueA === 'string') {
                return order === 'asc' ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
            } else {
                return order === 'asc' ? valueA - valueB : valueB - valueA;
            }
        });

        setSortOrder({ field, order });
        setCombineStudents(sortedArray);
    };
    //   Pigination for Learners Table
    const [currentPage, setCurrentPage] = useState(1);
    const rowsPerPage = 5;

    const indexOfLastStudent = currentPage * rowsPerPage;
    const indexOfFirstStudent = indexOfLastStudent - rowsPerPage;
    const currentStudents = CombineStudents.slice(indexOfFirstStudent, indexOfLastStudent);
    const totalPages = Math.ceil(CombineStudents.length / rowsPerPage);

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };
    // OnBoarding
      const [Onboarding, setOnboarding] = useState(null);
      const steps = Onboarding === 0  ?   [
        {
            content: <h2>this is just for making first step ovelap</h2>,
            placement:'center',
            target:'Bodyjhh',
            title:'Welcome'
          },
        {
          content: <h2>Welcome to the AI ultimate gamification platform.</h2>,
          // locale:{skip: <strong>SKIP</strong>},
          placement:'center',
          target:'Body',
          title:'Welcome'
        },
        {
          content: <h2>Here get immediate insights about your courses know whom is at risk whom on the top.</h2>,
          placement:'right',
          target:'#step-1',
          title:'Gamizign Dashboard'
        },
        {
          content: <h2>Here you create list of questions either multiple choice, essay, rank and much more question types.</h2>,
          placement:'right',
          target:'#step-3',
          title:'Your Questions lists'
        },
        {
          content: <h2>Analyze your courses and see how many learners and there activities in details.</h2>,
          placement:'right',
          target:'#step-4',
          title:'Your Courses'
        },
        {
          content: <h2>Hands on detailed view ratios, best players, accuracy of there answers and much more.</h2>,
          placement:'right',
          target:'#step-5',
          title:'Insights'
        },
        {
          content: <div><h2>Let's start by creating a new course.</h2>
          <Link className='border py-1 px-3 bg-gray-100 rounded mt-2 hover:bg-blue-200' to="/Dashboard/CreateCourse"  >Click Here to start creating course</Link></div>,
          placement:'right',
          target:'#step-2',
          title:'Create New Course'
        },
      ] :  [

        {
          content: <h2>Here get immediate insights about your courses know whom is at risk whom on the top.</h2>,
          placement:'right',
          target:'#Curren',
          title:'Gamizign Dashboard'
        },
        {
          content: <h2>Here are your created courses.</h2>,
          placement:'right',
          target:'#CurrentCourses',
          title:'Your Courses'
        },
        {
          content: <h2>Selcet duation you want see players accuracy and much more bases on duration.</h2>,
          placement:'right',
          target:'#CourseDuration',
          title:'Courses duration'
        },
        {
          content: <h2>Here are Players , accuracy, Competence and Average time per course.</h2>,
          placement:'bottom',
          target:'#Analysis',
        },
        {
          content: <h2>Accuracy per course chart.</h2>,
          placement:'top',
          target:'#Accuracy',
        },
        {
          content: <h2>Here are you can see who is performing good who is in risk and much more.</h2>,
          placement:'top',
          target:'#Quadrant',
        },
        {
          content: <h2>Enrolled student here you cane see accuracy, sessions, game wone and much more about student .</h2>,
          placement:'top',
          target:'#PlyersTable',
        },
        {
          content: <h2>Find student by his name.</h2>,
          placement:'right',
          target:'#Search',
        },
        {
          content: <h2>View more students.</h2>,
          placement:'top',
          target:'#ViewMore',
        },
        {
          content: <h2>View previews students.</h2>,
          placement:'top',
          target:'#ViewLess',
        },
      ]
      const [{ run }, setState] = useState({ run: true, });



    return (
        <Card>
        <div className={`w-full ${mode === 'light' ? 'BodyBg' :''}`}>
            <MetaTags title='GMZ Dashboard' description='Insights page--' />
            <div className={`flex relative px-5 py-2 mb-2 ${mode === 'light' ? 'ContentHeadingBg' :''}`}>
                <h3 className="text-xl">Dashboard</h3>
                <LoginHeading objects={setOnboarding} setMode={setMode} />
            </div>
            {loading ? (
                <>
                    {/* <LoadingComponent /> */}
                    <div class=" mx-7 ">
                        <div className="grid md:grid-cols-2">
                            <div className="grid md:grid-cols-2">
                                <div className="flex gap-3">
                                    <Skeleton animation='wave' variant="rounded" width={210} height={60} />
                                </div>
                                <div className="flex gap-3 mt-2 sm:mt-0">
                                    <Skeleton animation='wave' variant="rounded" width={150} height={60} />
                                </div>
                            </div>
                            <div className="grid md:grid-cols-3 py-2">
                                <div className="flex gap-3 mt-2 sm:mt-0">
                                    <Skeleton animation='wave' variant="rounded" width={100} height={30} />
                                </div>
                                <div className="flex gap-3 mt-2 sm:mt-0">
                                    <Skeleton animation='wave' variant="rounded" width={120} height={30} />
                                </div>
                                <div className="flex gap-3 mt-2 sm:mt-0">
                                    <Skeleton animation='wave' variant="rounded" width={140} height={30} />
                                </div>
                            </div>

                        </div>
                        {/* Cards */}
                        <div className="grid lg:grid-cols-5 md:grid-cols-3 sm:grid-cols-2 my-5 gap-5">
                            <Skeleton animation='wave' variant="rounded" width={240} height={100} />
                            <Skeleton animation='wave' variant="rounded" width={240} height={100} />
                            <Skeleton animation='wave' variant="rounded" width={240} height={100} />
                            <Skeleton animation='wave' variant="rounded" width={240} height={100} />
                            <Skeleton animation='wave' variant="rounded" width={240} height={100} />

                        </div>
                        {/*courses Accuracy and bubble chart */}
                        <div className="grid lg:grid-cols-2 gap-5">
                            <div><Skeleton animation='wave' variant="rectangular" height={300} className="" /></div>
                            <div><Skeleton animation='wave' variant="rectangular" height={300} className="" /></div>
                        </div>
                        {/* Students table */}
                        <p className="mt-5 text-xl font-bold">Learner Results</p>
                        <div className="my-3 border bg-white py-5 ">
                            <div className="flex justify-between">
                                <div className="flex gap-3">
                                    <span><img src={SearchIcon} alt="Search Icon" className="transform scale-x-[-1] mt-3" /></span>
                                    <Skeleton animation='wave' variant="rounded" width={200} height={40} />
                                </div>
                            </div>
                            {/* Table of students Start */}
                            <div className="relative overflow-x-auto max-w-80 sm:max-w-full">
                                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                    <thead className="text-gray-700 uppercase dark:bg-gray-700 dark:text-gray-400">
                                        <tr>
                                            <th scope="col" className="px-6 py-3">Name</th>
                                            <th scope="col" className="px-6 py-3">E-mail Address</th>
                                            <th scope="col" className="px-6 py-3">Score</th>
                                            <th scope="col" className="px-6 py-3">Accuracy</th>
                                            <th scope="col" className="px-6 py-3">Sessions</th>
                                            <th scope="col" className="px-6 py-3">Average Time</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className='border-b'>
                                            <th scope="col" className="px-6 py-3"><Skeleton animation='wave' variant="rounded" width={200} height={40} /></th>
                                            <th scope="col" className="px-6 py-3"><Skeleton animation='wave' variant="rounded" width={200} height={40} /></th>
                                            <th scope="col" className="px-6 py-3"><Skeleton animation='wave' variant="rounded" width={80} height={40} /></th>
                                            <th scope="col" className="px-6 py-3"><Skeleton animation='wave' variant="rounded" width={80} height={40} /></th>
                                            <th scope="col" className="px-6 py-3"><Skeleton animation='wave' variant="rounded" width={80} height={40} /></th>
                                            <th scope="col" className="px-6 py-3"><Skeleton animation='wave' variant="rounded" width={80} height={40} /></th>
                                        </tr>
                                        <tr className='border-b'>
                                            <th scope="col" className="px-6 py-3"><Skeleton animation='wave' variant="rounded" width={200} height={40} /></th>
                                            <th scope="col" className="px-6 py-3"><Skeleton animation='wave' variant="rounded" width={200} height={40} /></th>
                                            <th scope="col" className="px-6 py-3"><Skeleton animation='wave' variant="rounded" width={80} height={40} /></th>
                                            <th scope="col" className="px-6 py-3"><Skeleton animation='wave' variant="rounded" width={80} height={40} /></th>
                                            <th scope="col" className="px-6 py-3"><Skeleton animation='wave' variant="rounded" width={80} height={40} /></th>
                                            <th scope="col" className="px-6 py-3"><Skeleton animation='wave' variant="rounded" width={80} height={40} /></th>
                                        </tr>
                                        <tr className='border-b'>
                                            <th scope="col" className="px-6 py-3"><Skeleton animation='wave' variant="rounded" width={200} height={40} /></th>
                                            <th scope="col" className="px-6 py-3"><Skeleton animation='wave' variant="rounded" width={200} height={40} /></th>
                                            <th scope="col" className="px-6 py-3"><Skeleton animation='wave' variant="rounded" width={80} height={40} /></th>
                                            <th scope="col" className="px-6 py-3"><Skeleton animation='wave' variant="rounded" width={80} height={40} /></th>
                                            <th scope="col" className="px-6 py-3"><Skeleton animation='wave' variant="rounded" width={80} height={40} /></th>
                                            <th scope="col" className="px-6 py-3"><Skeleton animation='wave' variant="rounded" width={80} height={40} /></th>
                                        </tr>
                                        <tr className='border-b'>
                                            <th scope="col" className="px-6 py-3"><Skeleton animation='wave' variant="rounded" width={200} height={40} /></th>
                                            <th scope="col" className="px-6 py-3"><Skeleton animation='wave' variant="rounded" width={200} height={40} /></th>
                                            <th scope="col" className="px-6 py-3"><Skeleton animation='wave' variant="rounded" width={80} height={40} /></th>
                                            <th scope="col" className="px-6 py-3"><Skeleton animation='wave' variant="rounded" width={80} height={40} /></th>
                                            <th scope="col" className="px-6 py-3"><Skeleton animation='wave' variant="rounded" width={80} height={40} /></th>
                                            <th scope="col" className="px-6 py-3"><Skeleton animation='wave' variant="rounded" width={80} height={40} /></th>
                                        </tr>
                                        <tr className='border-b'>
                                            <th scope="col" className="px-6 py-3"><Skeleton animation='wave' variant="rounded" width={200} height={40} /></th>
                                            <th scope="col" className="px-6 py-3"><Skeleton animation='wave' variant="rounded" width={200} height={40} /></th>
                                            <th scope="col" className="px-6 py-3"><Skeleton animation='wave' variant="rounded" width={80} height={40} /></th>
                                            <th scope="col" className="px-6 py-3"><Skeleton animation='wave' variant="rounded" width={80} height={40} /></th>
                                            <th scope="col" className="px-6 py-3"><Skeleton animation='wave' variant="rounded" width={80} height={40} /></th>
                                            <th scope="col" className="px-6 py-3"><Skeleton animation='wave' variant="rounded" width={80} height={40} /></th>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="flex justify-center gap-5 mt-4">
                                <Skeleton animation='wave' variant="rounded" width={90} height={40} />
                                <Skeleton animation='wave' variant="rounded" width={120} height={40} />
                                <Skeleton animation='wave' variant="rounded" width={90} height={40} />
                            </div>

                        </div>
                    </div>
                </>
            ) : (
                <div class=" mx-7 ">
                        {Onboarding <= 1 &&<Joyride callback={() => { }} continuous showProgress
                            run={run} steps={steps} hideCloseButton scrollToFirstStep showSkipButton  
                            styles={{
                                buttonNext: { backgroundColor: '#E5F2E3', borderRadius: '5px',color:'black', },
                                buttonBack: { backgroundColor: '#E5F2E3',  borderRadius: '5px', color:'black', }
                              }} />}
                        {/* {courses.length === 1 &&<Joyride callback={() => { }} continuous showProgress
                            run={run1} steps={steps1} hideCloseButton scrollToFirstStep showSkipButton  
                            styles={{
                                buttonNext: { backgroundColor: '#E5F2E3', borderRadius: '5px',color:'black', },
                                buttonBack: { backgroundColor: '#E5F2E3',  borderRadius: '5px', color:'black', }
                              }} />} */}
                    <div className="grid md:grid-cols-2 gap-3">
                        <div className="grid md:grid-cols-2 gap-3">
                            <div className="flex gap-3" id="CurrentCourses">
                                <FormControl margin="dense" fullWidth
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                border: '1px solid #CCCCCC',
                                            },
                                            '&.Mui-focused fieldset': {
                                                border: '2px solid #DBE6F5',
                                            },
                                            '&:hover fieldset': {
                                                border: '2px solid #DBE6F5',
                                            },
                                        },
                                    }}>
                                    <InputLabel>Courses</InputLabel>
                                    {courses && Array.isArray(courses) && courses.length > 0 ? (<Select
                                        labelId="Courses"
                                        name="Courses"
                                        label="Courses"
                                        onChange={(e) => { handleCourseChange(e) }}
                                        value={courses.indexOf(selectedCourse)}
                                        style={{ borderRadius: '10px', color: mode === 'light' ? '#2E4EB5' :'', background: mode === 'light' ? 'white' :'' , height: '40px' }}
                                    >
                                        <MenuItem selected value={-1}>All Courses</MenuItem>
                                        {courses.map((course, index) => (
                                            <MenuItem key={index} value={index}>
                                                {course.name}
                                            </MenuItem>
                                        ))}
                                    </Select>) : (<p>No Course available</p>)}
                                </FormControl>
                            </div>
                            <div className="flex gap-3" id="CourseDuration">
                                <FormControl margin="dense" fullWidth
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                border: '1px solid #CCCCCC',
                                            },
                                            '&.Mui-focused fieldset': {
                                                border: '2px solid #DBE6F5',
                                            },
                                            '&:hover fieldset': {
                                                border: '2px solid #DBE6F5',
                                            },
                                        },
                                    }}>
                                    <InputLabel>Duration</InputLabel>
                                    <Select
                                        labelId="Duration"
                                        name="Duration"
                                        label="Duration"
                                        defaultValue='02 Weeks'
                                        style={{ borderRadius: '10px', color: mode === 'light' ? '#2E4EB5' :'', background: mode === 'light' ? 'white' :'', height: '40px' }}
                                    >
                                        <MenuItem selected value='02 Weeks'>02 Weeks</MenuItem>
                                        <MenuItem value='03 Weeks'>03 Weeks</MenuItem>
                                        <MenuItem value='01 Month'>01 Month</MenuItem>

                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        <div className="grid md:grid-cols-3 py-2">
                            <div className="flex gap-3">
                                <img className="cursor-pointer" onClick={skillsHandle} src={skills} width={30} alt="skill" />
                                <span className="font-bold mt-1">Skills</span>
                            </div>
                            <div className="flex gap-3">
                                <img className="cursor-pointer" src={knowledge} onClick={knowledgeHandle} width={30} alt="Knowledge" />
                                <span className="font-bold mt-1">Knowledge</span>
                            </div>
                            <div className="flex gap-3">
                                <img className="cursor-pointer" src={competence} onClick={CompetenceHandle} width={30} alt="Competence" />
                                <span className="font-bold mt-1">Competence</span>
                            </div>
                        </div>

                    </div>
                    {/* Cards */}
                    <div id="Analysis" className="grid lg:grid-cols-5 md:grid-cols-3 sm:grid-cols-2 my-5 gap-5">
                        {/* Learners card */}
                        <div className="border bg-blue-100 pt-5 shadow-lg" style={{ background: `${mode === 'light' ? '#ACCCE5' :'gray'}`  }}>
                            <div className=" h-24">
                                <p className="flex justify-center font-bold text-xl">Learners</p>
                                {SingleCourseAnalytics ?
                                    <p className="flex justify-center font-bold text-2xl">{SingleCourseAnalytics.players > 0 ? SingleCourseAnalytics.players : 0}</p>
                                    :
                                    <p className="flex justify-center font-bold text-2xl">{allPlayers}</p>
                                }
                            </div>
                            {/* <div className="border border-bottom bg-white">
                                <div className="flex justify-center bg-blue-50 mx-5 p-5 border -mt-5 gap-5" style={{ background: "#DBE6F5" }}>
                                    <div>
                                        <p>Previous week</p>
                                        <p className="flex justify-center">360</p>
                                    </div>
                                    <img className={"m-auto"} width={40} src={LearnersIcon} alt="" />
                                </div>
                                <div id="ApexChart" className="ApexChart my-5 flex justify-center">
                                    <ApexChart />
                                </div>
                            </div> */}
                        </div>
                        {/* Skills card */}
                        <div className={`border bg-blue-100 pt-5 shadow-lg ${skills === CheckBoxOn ? "" : "hidden"}`} style={{ background:`${mode === 'light' ? '#BCE2BB' :'gray'}` }}>
                            <div className=" h-24">
                                <p className="flex justify-center font-bold text-xl">Skills</p>
                                {SingleCourseAnalytics ?
                                    <p className="flex justify-center font-bold text-2xl">{SingleCourseAnalytics.gamesWon > 0 ? ((SingleCourseAnalytics.gamesWon / SingleCourseAnalytics.sessions) * 100).toFixed(2) : 0.0}%</p>
                                    :
                                    <p className="flex justify-center font-bold text-2xl">{CompleteSessions > 0 ? ((CompleteSessions / totalSessions) * 100).toFixed(2) : 0}%</p>
                                }
                            </div>
                            {/* <div className="border border-bottom bg-white">
                                <div className="flex justify-center bg-blue-50 mx-5 p-5 border -mt-5 gap-5" style={{ background: "#E5F2E3" }}>
                                    <div>
                                        <p>Previous week</p>
                                        <p className="flex justify-center">38%</p>
                                    </div>
                                    <img className={"m-auto"} width={40} src={SkillsIcon} alt="" />
                                </div>
                                <div id="ApexChart" className="ApexChart my-5 flex justify-center">
                                    <SkillsChart />
                                </div>
                            </div> */}
                        </div>
                        {/* Knowledge card */}
                        <div className={`border bg-blue-100 pt-5 shadow-lg ${knowledge === CheckBoxOn ? '' : 'hidden'}`} style={{ background:`${mode === 'light' ? '#FFF3C6' :'gray'}`}}>
                            <div className=" h-24">
                                <p className="flex justify-center font-bold text-xl">Knowledge</p>
                                {SingleCourseAnalytics ?
                                    <p className="flex justify-center font-bold text-2xl">{SingleCourseAnalytics.correctAnswers > 0 ? (((SingleCourseAnalytics.correctAnswers / (SingleCourseAnalytics.correctAnswers + SingleCourseAnalytics.incorrectAnswers)) * 100) / SingleCourseAnalytics.players).toFixed(2) : 0.0}%</p>
                                    :
                                    <p className="flex justify-center font-bold text-2xl">{correctAnswers > 0 ? (((correctAnswers / (correctAnswers + incorrectAnswers)) * 100) / allPlayers).toFixed(2) : 0.0}%</p>
                                }
                            </div>
                            {/* <div className="border border-bottom bg-white">
                                <div className="flex justify-center bg-blue-50 mx-5 p-5 border -mt-5 gap-5" style={{ background: "#FEFAE2" }}>
                                    <div>
                                        <p>Previous week</p>
                                        <p className="flex justify-center">65%</p>
                                    </div>
                                    <img className={"m-auto"} width={40} src={KnowlwdgeIcon} alt="" />
                                </div>
                                <div id="ApexChart" className="ApexChart my-5 flex justify-center">
                                    <KnowledgeChart />
                                </div>
                            </div> */}
                        </div>
                        {/* Competence card */}
                        <div className={`border bg-blue-100 pt-5 shadow-lg ${competence === CheckBoxOn ? '' : 'hidden'}`} style={{ background: `${mode === 'light' ? '#F6C1C1' :'gray'}` }}>
                            <div className=" h-24">
                                <p className="flex justify-center font-bold text-xl">Competence</p>
                                {SingleCourseAnalytics ?
                                    <p className="flex justify-center font-bold text-2xl">{SingleCourseAnalytics.correctAnswers > 0 ? (((SingleCourseAnalytics.correctAnswers / (SingleCourseAnalytics.correctAnswers + SingleCourseAnalytics.incorrectAnswers)) * 100) / SingleCourseAnalytics.sessions).toFixed(2) : 0.0}%</p>
                                    :
                                    <p className="flex justify-center font-bold text-2xl">{correctAnswers > 0 ? (((correctAnswers / (correctAnswers + incorrectAnswers)) * 100) / totalSessions).toFixed(2) : 0.0}%</p>
                                }
                            </div>
                            {/* <div className="border border-bottom bg-white">
                                <div className="flex justify-center bg-blue-50 mx-5 p-5 border -mt-5 gap-5" style={{ background: "#FDE4DC" }}>
                                    <div>
                                        <p>Previous week</p>
                                        <p className="flex justify-center">68%</p>
                                    </div>
                                    <img className={"m-auto"} width={40} src={CompetenceIcon} alt="" />
                                </div>
                                <div id="ApexChart" className="ApexChart my-5 flex justify-center">
                                    <CompetenceChart />
                                </div>
                            </div> */}
                        </div>
                        {/* Accuracy card */}
                        <div className="border bg-blue-100 pt-5 shadow-lg" style={{ background: `${mode === 'light' ? '#DFBFDD' :'gray'}`}}>
                            <div className=" h-24">
                                <p className="flex justify-center font-bold text-xl">Accuracy</p>
                                {SingleCourseAnalytics ?
                                    <p className="flex justify-center font-bold text-2xl">{SingleCourseAnalytics.correctAnswers > 0 ? ((SingleCourseAnalytics.correctAnswers / (SingleCourseAnalytics.correctAnswers + SingleCourseAnalytics.incorrectAnswers)) * 100).toFixed(2) : 0.0}%</p>
                                    :
                                    <p className="flex justify-center font-bold text-2xl">{correctAnswers > 0 ? ((correctAnswers / (correctAnswers + incorrectAnswers)) * 100).toFixed(2) : 0.0}%</p>
                                }
                            </div>
                            {/* <div className="border border-bottom bg-white">
                                <div className="flex justify-center bg-blue-50 mx-5 p-5 border -mt-5 gap-5" style={{ background: "#EFE1EE" }}>
                                    <div>
                                        <p>Previous week</p>
                                        <p className="flex justify-center">71%</p>
                                    </div>
                                    <img className={"m-auto"} width={40} src={AccuracyIcon} alt="" />
                                </div>
                                <div id="ApexChart" className="ApexChart my-5 flex justify-center">
                                    <AccuracyChart />
                                </div>
                            </div> */}
                        </div>
                    </div>
                    {/*courses Accuracy and bubble chart */}
                    <div className="grid lg:grid-cols-2 gap-5">
                    <div id="Accuracy" className={`border ${mode === 'light' ? 'bg-white' :''}`}><PlotyChart coursesData={courses} colors={colors} highlight={Highlight} /></div>
                        <div id="Quadrant" className={`border ${mode === 'light' ? 'bg-white' :''}`}><QuadrantChart students={filtestudents} analyticsData={analyticsData} setStudentId={setStudentId} /></div>

                    </div>
                    {/* Students table */}
                    <p className="mt-5 text-xl font-bold">Learner Results</p>
                    <div className={`my-3 border py-5 ${mode === 'light' ? 'bg-white' :''}`}>
                        <div className="flex justify-between">
                            <div id="Search" className="flex gap-3">
                                <span><img src={SearchIcon} alt="Search Icon" className="transform scale-x-[-1] mt-3" /></span>
                                <input className="outline-none border p-3 w-56" placeholder="Search by Name" type="text" value={searchTerm} onChange={handleSearch} />
                            </div>
                            {/* <div> <span><img src={CheckBoxOn} alt="" /></span></div> */}
                        </div>
                        {/* Table of students Start */}
                        <div id="PlyersTable" className="relative overflow-x-auto max-w-80 sm:max-w-full">
                            <AnalyticsTable mode={mode} currentStudents={currentStudents} studentId={studentId} sortOrder={sortOrder} sortMergedArray={sortMergedArray} />

                        </div>
                        <div className="flex justify-center gap-5 mt-4">
                            <button id="ViewLess" onClick={handlePrevPage} className={`px-4 py-2 hover:bg-blue-200 rounded disabled:opacity-50 flex ${mode === 'light' ? 'BackBtn' :''}`} disabled={currentPage === 1}>
                                <img className="mt-1 mr-2" src={BackIcon} alt="" /> Prev
                            </button>
                            <span className="px-4 py-2">{currentPage} of {totalPages}</span>
                            <button id="ViewMore" onClick={handleNextPage} className={`px-4 py-2 hover:bg-blue-200 rounded disabled:opacity-50 flex ${mode === 'light' ? 'BackBtn' :''}`} disabled={currentPage === totalPages}>
                                Next <img className="mt-1 ms-2" src={NextIcon} alt="" />
                            </button>
                        </div>

                    </div>
                </div>
            )}

        </div>
        </Card>
    )
};

export default GamizignWorld;