import React, { useState, useRef, useEffect } from "react";
import { Link, useLocation, useNavigate } from 'react-router-dom'
import moment from 'moment';
import Modal from 'react-modal';
import DatePicker from "react-datepicker";

import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import CircularProgress from '@mui/material/CircularProgress';

// import './style.css'
import ChooseStage from '../../../../../../assets/icons/ActivityContentIcons/NewCourse/Patch3/Activity-Type/stage icon with the number of activities.png'
import ChooseStageHover from '../../../../../../assets/icons/ActivityContentIcons/NewCourse/Patch3/Activity-Type/stage icon with the number of activities on.png'

import AddStage from '../../../../../../assets/icons/ActivityContentIcons/NewCourse/Patch3/Activity-Type/Add stage icon.png'
// // Chose activity type
import Game from '../../../../../../assets/icons/ActivityContentIcons/NewCourse/Patch3/Activity-Type/game icon hover off color.png'
import GameHoveron from '../../../../../../assets/icons/ActivityContentIcons/NewCourse/Patch3/Activity-Type/game icon hover on.png'
import Online from '../../../../../../assets/icons/ActivityContentIcons/NewCourse/Patch3/Activity-Type/URL icon hover off color.png'
import OnlineHoveron from '../../../../../../assets/icons/ActivityContentIcons/NewCourse/Patch3/Activity-Type/URL icon hover on.png'
import Image from '../../../../../../assets/icons/ActivityContentIcons/NewCourse/Patch3/Activity-Type/image icon hover off color.png'
import ImageHoveron from '../../../../../../assets/icons/ActivityContentIcons/NewCourse/Patch3/Activity-Type/image icon hover on.png'
import PDF from '../../../../../../assets/icons/ActivityContentIcons/NewCourse/Patch3/Activity-Type/PDF icon hover off color.png'
import PDFHoveron from '../../../../../../assets/icons/ActivityContentIcons/NewCourse/Patch3/Activity-Type/PDF icon hover on.png'
import Video from '../../../../../../assets/icons/ActivityContentIcons/NewCourse/Patch3/Activity-Type/video icon hover off color.png'
import VideoHoveron from '../../../../../../assets/icons/ActivityContentIcons/NewCourse/Patch3/Activity-Type/video icon hover on.png'
import Audio from '../../../../../../assets/icons/ActivityContentIcons/NewCourse/Patch3/Activity-Type/Audio icon hover off color.png'
import AudioHoveron from '../../../../../../assets/icons/ActivityContentIcons/NewCourse/Patch3/Activity-Type/Audio icon hover on.png'
import MultiActivityHoverOn from '../../../../../../assets/icons/ActivityContentIcons/NewCourse/Patch3/Activity-Type/multiactivity  icon hover off.png'
import MultiActivityHoverOff from '../../../../../../assets/icons/ActivityContentIcons/NewCourse/Patch3/Activity-Type/multiactivity icon hover on.png'
import BackIcon from '../../../../../../assets/icons/ActivityContentIcons/NewCourse/back iconB.png'
import NextIcon from '../../../../../../assets/icons/ActivityContentIcons/NewCourse/Next icon.png'
// Pdf
import UploadPdf from '../../../../../../assets/icons/ActivityContentIcons/NewCourse/Patch3/Pdf/Upload PDF file.png'
import LoadedPdf from '../../../../../../assets/icons/ActivityContentIcons/NewCourse/Patch3/Pdf/Loaded PDF file.png'
import RadioOffIcon from '../../../../../../assets/icons/ActivityContentIcons/NewCourse/Creating/radio button off.png'
import RadioOnIcon from '../../../../../../assets/icons/ActivityContentIcons/NewCourse/Creating/radio button on.png'
import DragdropOnIcon from '../../../../../../assets/icons/ActivityContentIcons/NewCourse/Patch3/Pdf/Drag & Drop icon on.png'
import DragdropOffIcon from '../../../../../../assets/icons/ActivityContentIcons/NewCourse/Patch3/Pdf/Drag & Drop icon off.png'
import CheckOffIcon from '../../../../../../assets/icons/ActivityContentIcons/NewCourse/Creating/PPTX check box off.png'
import CheckOnIcon from '../../../../../../assets/icons/ActivityContentIcons/NewCourse/Creating/PPTX check box on.png'
import SaveIcon from '../../../../../../assets/icons/ActivityContentIcons/NewCourse/save icon.png'
import AlertIcon from '../../../../../../assets/icons/Alert-icon.png'
import LoginHeading from "../../../../../LoginHeading/LoginHeading";
import CurrentQIcon from '../../../../../../assets/icons/ActivityContentIcons/NewCourse/Adding/Current Questions Lists icon.png'
import MultiActivy from "./MultiActivity/mutiActivity";
import MetaTags from "../../../../../GlobalLoading/MetaData";
import dayjs, { Dayjs } from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { Alert, Button, DialogContent, TextField } from "@mui/material";
import axiosInstance from "../../../../../../axios";



const AddingActivityType = () => {
    const { courses, courseType, course, courseFormatDate, activity, stageIndex, finishingDate, stratingDate } = useLocation().state;
    var [StageIndex, setStageIndex] = useState(course.stages.length - 1);
    const [stageImgSrc, setStageImgSrc] = useState(ChooseStage);
    const [isDisabled, setDisabled] = useState(false);
    const [validateActivity, setValidateActivity] = useState(false);
    const [activity_case, setactivity_case] = useState('Task');
    const [answer_exact, setSelectedOptionStudent] = useState('is exactly');
    const [dateCreated, setDateCreated] = useState(moment().format("DD/MM/YYYY HH:mm:ss"));
    const [startingDate, setStartingDate] = useState(courseFormatDate);
    const [questionsLists, setQuestionsLists] = useState([]);
    const [questionList_Id, setQuestionList_Id] = useState(null);
    const [questionlistName, setQuestionlistName] = useState(null);
    const [isHovering, setIsHovering] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [Score, setScore] = useState(50);
    const [ActivityActionType, setActivityActionType] = useState(1);
    const [showDropdown, setShowDropdown] = useState(false);
    const [FinishingDate, setFinishingDate] = useState(false);
    const [loading, setLoading] = useState(true);
    // Get questin lists from endpoint
    const fetchQuestionsLists = async () => {
        setLoading(true);
        var instructor_id = parseInt(localStorage.getItem("instructor_id"));
        const response = await axiosInstance(`${process.env.REACT_APP_ENDPOINT}/instructors/${instructor_id}/question-lists`)
            setQuestionsLists(response.data);
            setLoading(false);

    }
    useEffect(() => {
        fetchQuestionsLists();
    }, []);
    // push data to multi activity page
    const HanldeCreateMultiActivity = () => {
        navigate('/Dashboard/multiActivities', { state: { courses: courses, courseType: courseType, course: course, courseFormatDate: stageFormatDate, stageIndex: StageIndex, stratingDate, finishingDate: stageFinishingDate } });
    };


    const [addingStageModal, setaddingStageModalIsOpen] = useState(false);
    const [ErrorMessage, setErrorMessage] = useState('');
    const BotcloseModal = () => {
        setaddingStageModalIsOpen(false);
    };
    const openBotModal = () => {
        addStage(course.stages.length);
    };
    const [activityType, setActivityType] = useState('');

    const [activityData, setActivityData] = useState({
        name: `Activity ${course.stages[StageIndex].activities.length + 1}`,
        activityType: activityType,
        instructor_id: localStorage.getItem("instructor_id"),
        activity_file_name: "",
        description: "",
        scoreVolume: Score,
        url: "",
        gameType: null,
        dateCreated: dateCreated,
        createdBy: localStorage.getItem("name"),
        answer_requirement: "",
        answer_exact: answer_exact,
        activity_case: activity_case,
        actionType: ActivityActionType,
        questionList_id: questionList_Id,
        questions: [],
    });
    useEffect(() => {
        setActivityData(prevState => ({
            ...prevState,
            activityType: activityType,
            activity_case: activity_case,
            questionList_id: questionList_Id,
            actionType: ActivityActionType,
            scoreVolume: Score,
        }));
    }, [activityType, activity_case, answer_exact, questionList_Id, ActivityActionType, Score]);

    const handleInputChange = (input, event) => {

        if (input === "name") {
            setActivityData(prevState => ({
                ...prevState,
                name: event.target.value
            }));
            activityData.name = event.target.value;
        }
        if (input === "description") {
            setActivityData(prevState => ({
                ...prevState,
                description: event.target.value
            }));
        }
        if (input === "answer_requirement") {
            setActivityActionType(1)
            setQuestionlistName(null)
            setQuestionList_Id(null)
            setActivityData(prevState => ({
                ...prevState,
                answer_requirement: event.target.value
            }));
        }
        if (input === "url") {
            const inputValue = event.target.value;
            const urlRegex = /^https?:\/\//;

            if (urlRegex.test(inputValue) || inputValue === '') {
                setActivityData(prevState => ({
                    ...prevState,
                    url: event.target.value
                }));
                setValidationError('');
            } else {
                setValidationError('URL must start with "http://".');
            }

        }
    };


    const handleOptionChanges = (e) => {
        setActivityData((prevData) => ({
            ...prevData,
            activity_case: e.target.value,
        }));
    };

    const handleOptionChangeStudent = (e) => {
        setActivityData((prevData) => ({
            ...prevData,
            answer_exact: e.target.value,
        }));
    };

    const handleSaveActivity = () => {
        if (validateActivityInput()) {
            if (activityData.activityType !== "game" && activityData.activityType !== "url") {
                setUploading(true);
                uploadFile(uploadedImage)
                    .then(fileName => {
                        activityData.activity_file_name = fileName; // Assign the file name to activity.activity_file_name
                        // course.stages[StageIndex].activities.push(activityData);
                        resetActivityType();
                        navigate(`/Dashboard/gameDone`, { state: { courses: courses, courseType: courseType, course: course, activity: activityData, stageIndex: StageIndex, courseFormatDate: stageFormatDate, stratingDate: stratingDate, finishingDate: stageFinishingDate } });
                    })
                    .catch(error => {
                        console.error(error);
                    });
            } else {
                // course.stages[StageIndex].activities.push(activityData);
                navigate(`/Dashboard/gameDone`, { state: { courses: courses, courseType: courseType, course: course, activity: activityData, stageIndex: StageIndex, courseFormatDate: stageFormatDate, stratingDate: stratingDate, finishingDate: stageFinishingDate } });
                resetActivityType();
                // setCurrentImage(CheckOffIcon);
                // setQuestionlistName(null);

            }

        }
    };
    const uploadFile = async (file) => {
        return new Promise((resolve, reject) => {

            // Prepare the request
            const url = `${process.env.REACT_APP_ENDPOINT}/attachment?type=activity`;
            const formData = new FormData();
            formData.append('file', file);

            // Send the request
            axiosInstance.post(url, formData)
                .then(function (response) {
                    if (response.ok) {
                        return response.data; // Extract the file name from the response
                    } else {
                        setUploading(false);
                        throw new Error('Error uploading file');
                    }
                })
                .then(data => {
                    setUploading(false);
                    resolve(data.message); // Resolve with the file name
                })
                .catch(error => {
                    setUploading(false);
                    reject(error);
                });
        });
    };
    const [stages, setStages] = useState(course.stages) || [
        {
            name: 1,
            activities: [],
        },
    ];
    const addStage = (stageIndex) => {
        if (stages[stageIndex - 1].activities && stages[stageIndex - 1].activities.length !== 0) {
            const newStage = {
                name: `stage ${stages.length + 1}`,
                startingDate: stratingDate.toLocaleString('en-GB').split(',')[0].trim(),
                // finishingDate: (stageFinishingDate.$d ? stageFinishingDate.$d : stageFinishingDate).toLocaleString('en-GB').split(',')[0].trim(),
                finishingDate: new Date(new Date(stratingDate).setMonth(new Date(stratingDate).getMonth() + 1)).toLocaleString('en-GB').split(',')[0].trim(),
                activities: [],
            };
            course.stages.push(newStage);
            setstageFormatDate(stratingDate);
            setStageFinishingDate(dayjs(stratingDate).add(1, 'month'));
            // setStages((prevStages) => [...prevStages]);
            // setDate(new Date());
            // stageIndex+=1;
            setStageIndex(course.stages.length - 1);
        } else {
            setErrorMessage('Kindly fill current stage with activities before adding a new stage!')
            setaddingStageModalIsOpen(true);
        }
    };

    const validateActivityInput = () => {
        switch (activityType) {
            case 'pdf':
            case 'image':
            case 'video':
            case 'audio':
                {
                    if (!uploadedImage) {
                        setValidationError(`Kindly upload ${activityType} file `);
                        return false;
                    }
                    else {
                        setValidationError('');
                    }
                    if (!activityData.description) {
                        setdescriptionError('Kindly fill in the activity description');
                        return false;
                    }
                    else {
                        setdescriptionError('');
                    }
                    if (!isDisabled && activityData.answer_requirement === "" && questionlistName === null) {
                        setanswerRequirementError('Kindly fill in the activity answer requirement');
                        return false;
                    }
                    else {
                        setanswerRequirementError('');
                        return true;
                    }
                }
            case 'url':
                {
                    if (activityData.url === "") {
                        setValidationError(`Kindly fill in the activity ${activityType}`);
                        return false;
                    } else {
                        setValidationError('');
                    }
                    if (!activityData.description) {
                        setdescriptionError('Kindly fill in the activity description');
                        return false;
                    } else {
                        setdescriptionError('');
                    }
                    if (!isDisabled && activityData.answer_requirement === "") {
                        setanswerRequirementError('Kindly fill in the activity answer requirement');
                        return false;
                    }
                    else {
                        setanswerRequirementError('');
                        return true;
                    }
                }

            default:

        }
    }
    const addActivityToStage = (stageIndex) => {
        setStageIndex(stageIndex);
    };
    // ................................stage and activity ......................
    const [journeyCourseName, seJourneyCourseName] = useState(course.stages.length + 1);
    const [date, setDate] = useState(courseFormatDate);
    const [stageFormatDate, setstageFormatDate] = useState(course.stages[StageIndex].startingDate == null ? courseFormatDate : date);
    const [stageFinishingDate, setStageFinishingDate] = useState(finishingDate);
    const [selectedImage, setSelectedImage] = useState();
    const navigate = useNavigate();

    const CourseNameHandler = (event) => {
        seJourneyCourseName(event.target.value);
        course.stages[StageIndex].name = event.target.value;
    };
    // Stage date change starting
    const handleStageDateChange = (selectedDate) => {
        setDate(selectedDate);
        setstageFormatDate(selectedDate);
        setShowDropdown(false);
        setStageFinishingDate((selectedDate).add(1, 'month'));
        course.stages[StageIndex].startingDate = (selectedDate.$d).toLocaleString('en-GB').split(',')[0].trim();
    };
    // Stage date change finishing
    const handleFinishingDateChange = (selectedDate) => {
        setStageFinishingDate(selectedDate);
        setFinishingDate(false);
        course.stages[StageIndex].finishingDate = (selectedDate.$d).toLocaleString('en-GB').split(',')[0].trim();
    };
    // activity date change
    const handleDateChange = (selectedDate) => {
        setDate(selectedDate);
        setShowDropdown(false);
        // setstageFormatDate(selectedDate);
    };
    // image required
    const fileInputRef = useRef(null);
    const [imageSrc, setImageSrc] = useState(UploadPdf);
    const [uploadedImage, setUploadedImage] = useState('');
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setSelectedImage(file);
    };
    // Student Answer Option

    // Activity Discription Option
    const [selectedOption, setSelectedOption] = useState('Task');
    const handleOptionChange = (value) => {
        setSelectedOption(value);
    };
    // check on and off
    const [currentImage, setCurrentImage] = useState(CheckOffIcon);
    const toggleImage = () => {
        setCurrentImage((prevImage) => (prevImage === CheckOffIcon ? CheckOnIcon : CheckOffIcon));
        setDisabled(!isDisabled);

        if (currentImage === CheckOffIcon) {
            setActivityActionType(0)
            setQuestionlistName(null)
            setQuestionList_Id(null)
        }
    };

    // texarea Student Answer
    const [StudentAnswer, setStudentAnswer] = useState('')
    // texarea Description
    const [Description, setDecription] = useState('');

    const [validationError, setValidationError] = useState('');
    const [descriptionError, setdescriptionError] = useState('');
    const [answerRequirementError, setanswerRequirementError] = useState('');


    const resetActivityType = () => {
        setUploadedImage('');
        setActivityType('');
        setValidationError('');
        setdescriptionError('');
        setImageSrc(UploadPdf);
        setanswerRequirementError('');
        setDisabled(false);
        setCurrentImage(CheckOffIcon);
        setQuestionlistName(null);
        setActivityActionType(1)
    }

    // date input validations
    const getCurrentDate = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }
    const [keephover, setKeephover] = useState('');

    const get_activity_type = (type) => {
        setActivityType(type);
    };

    const renderOptions = () => {

        const handleImageClick = () => {
            fileInputRef.current.click();
        };

        const handleFileChange = (event) => {
            const selectedFile = event.target.files[0];
            if (selectedFile.type === 'video/mp4' || selectedFile.type === 'video/webm') {
                if (selectedFile.size <= 4000000) {
                    setUploadedImage(selectedFile);
                    setTimeout(() => {
                        setImageSrc(LoadedPdf);
                    }, 200);
                } else {
                    setErrorMessage('please select file that has size less than 4 Mb!');
                    setaddingStageModalIsOpen(true);
                    console.log('please select file that has size less than 4 mb');
                }
            } else {
                setUploadedImage(selectedFile);
                setTimeout(() => {
                    setImageSrc(LoadedPdf);
                }, 200);
            }
        };
        if (!uploading) {
            switch (activityType) {
                case 'pdf':
                    // PDF code is here 
                    return (
                        <div className='w-full'>
                            <div className='flex relative px-5 py-2 mb-2 ContentHeadingBg'>
                                <h3 className="text-xl">Create New Activity</h3>
                                <LoginHeading />
                            </div>
                            <div className="m-7 bg-white static">
                                <div className='flex justify-center '><img className='PdfImage' src={PDF} alt="" /></div>
                                <div className="xl:mx-40 mx-5 my-10">
                                    <div className="md:flex relative">
                                        <TextField
                                            label="Title"
                                            name="Title"
                                            type="text"
                                            defaultValue={`Activity`}
                                            onChange={(e) => handleInputChange("name", e)}
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    '& fieldset': {
                                                        border: '2px solid #F3F6F6',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        border: '2px solid #DBE6F5',
                                                    },
                                                    '&:hover fieldset': {
                                                        border: '2px solid #DBE6F5',
                                                    },
                                                },
                                            }}
                                            InputProps={{
                                                style: {
                                                    borderRadius: "5px",
                                                    background: `${activityData.name ? '#F3F6F6' : 'white'}`
                                                }
                                            }}
                                        />
                                        <div className='my-2 sm:my-0 md:absolute  md:right-0'>
                                            {/* date picker MUI */}
                                            <div className="relative">
                                                <button
                                                    className="flex items-center justify-center -mt-1 rounded-full focus:outline-none"
                                                    onMouseEnter={() => setShowDropdown(true)}
                                                    onMouseLeave={() => setShowDropdown(false)}
                                                >
                                                    <label htmlFor="date">Starting Date: </label>  <DatePicker disabled className='text-sky-600 border-2 hover:border-blue-100 p-2 sm:mx-5 rounded bg-gray-100' minDate={new Date()} selected={date.$d ? date.$d : date} onChange={(date) => handleDateChange(date)} dateFormat={"dd/MM/yyyy"} />
                                                </button>
                                                {showDropdown && (
                                                    <div className="absolute text-black right-0 z-10  bg-white border border-gray-200 rounded shadow-lg top-full" onMouseEnter={() => setShowDropdown(true)} onMouseLeave={() => setShowDropdown(false)}>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <DemoContainer components={['DateCalendar', 'DateCalendar']}>
                                                                <DemoItem>
                                                                    <DateCalendar
                                                                        onChange={(newValue) => handleDateChange(newValue)}
                                                                        minDate={dayjs(stageFormatDate)}
                                                                        value={dayjs(date)}
                                                                        dateFormat={"dd/MM/yyyy"}
                                                                    />

                                                                </DemoItem>
                                                            </DemoContainer>
                                                        </LocalizationProvider>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    {/* Image Requirements */}
                                    <div className="my-5 md:ms-12">
                                        <div className='flex'>
                                            <span className='mt-3'>
                                                <b>1.</b>
                                            </span>
                                            <div>
                                                <img className='ms-5 cursor-pointer' src={imageSrc} alt="Upload PDF" onClick={handleImageClick} />
                                                <input type="file" ref={fileInputRef} style={{ display: 'none' }}
                                                    onChange={handleFileChange}
                                                    // onChange={(event) => { handleFileInputChange(event); handleFileChange(event); }}
                                                    name="fileInput1"
                                                    accept=".pdf" />
                                                {validationError && (<p className="text-red-500">{validationError}</p>)}
                                            </div>
                                            <div className='ms-5 mt-5'>
                                                <span>{uploadedImage ? (<p><b>{uploadedImage.name}</b></p>) : null}</span>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Write the activity question*/}
                                    <div className="my-5 md:ms-12">
                                        <div className='md:flex relative my-2'>
                                            <div className='mt-4'><b>2. Write the activity question\description</b></div>
                                            <div className='md:absolute  right-0'>
                                                <div className="QUESTIONS-COUNT flex-initial w-100 py-2 flex">
                                                    <div className="image-radio flex m-2 hover:cursor-pointer" disabled={isDisabled} name='Task' value={activity_case} onClick={(e) => { handleOptionChanges(e); setActivityData((prevData) => ({ ...prevData, activity_case: 'Task', })); }}>
                                                        <span>
                                                            <img
                                                                src={activityData.activity_case === 'Task' ? RadioOnIcon : RadioOffIcon}
                                                                alt="Task"
                                                                className="radio-image mt-1"
                                                            />
                                                        </span>
                                                        <span className={`ps-2 ${activityData.activity_case === 'Task' ? 'font-bold' : 'font-light'}`}>Task</span>
                                                    </div>
                                                    <div className="image-radio flex m-2 hover:cursor-pointer" disabled={isDisabled} name='Project' value={activity_case} onClick={(e) => { handleOptionChanges(e); setActivityData((prevData) => ({ ...prevData, activity_case: 'Project', })); }}>
                                                        <span>
                                                            <img
                                                                src={activityData.activity_case === 'Project' ? RadioOnIcon : RadioOffIcon}
                                                                alt="Project"
                                                                className="radio-image mt-1"
                                                            />
                                                        </span>
                                                        <span className={`ps-2 ${activityData.activity_case === 'Project' ? 'font-bold' : 'font-light'}`}>Project</span>
                                                    </div>
                                                    <div className="image-radio flex m-2 hover:cursor-pointer" disabled={isDisabled} name='Case' value={activity_case} onClick={(e) => { handleOptionChanges(e); setActivityData((prevData) => ({ ...prevData, activity_case: 'Case', })); }}>
                                                        <span>
                                                            <img
                                                                src={activityData.activity_case === 'Case' ? RadioOnIcon : RadioOffIcon}
                                                                alt="Case"
                                                                className="radio-image mt-1"
                                                            />
                                                        </span>
                                                        <span className={`ps-2 ${activityData.activity_case === 'Case' ? 'font-bold' : 'font-light'}`}>Case</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='bg-gray-200 px-1'>
                                            <TextField
                                                margin="dense"
                                                label="Activity question\description"
                                                name="Activity question\description"
                                                type="text"
                                                fullWidth
                                                placeholder={`View the ${activityType}.. then answer the following question`}
                                                onChange={(e) => handleInputChange("description", e)}
                                                sx={{
                                                    '& .MuiOutlinedInput-root': {
                                                        '& fieldset': {
                                                            border: `${descriptionError ? '2px solid #FDE4DC' : '2px solid #F3F6F6'}`,
                                                        },
                                                        '&.Mui-focused fieldset': {
                                                            border: '2px solid #DBE6F5',
                                                        },
                                                        '&:hover fieldset': {
                                                            border: '2px solid #DBE6F5',
                                                        },
                                                    },
                                                }}
                                                InputProps={{
                                                    style: {
                                                        borderRadius: "0px",
                                                        background: `${activityData.description ? '#F3F6F6' : 'white'}`,
                                                        height: '100px'
                                                    }
                                                }}
                                            />
                                            {descriptionError && (<p className="text-red-500">{descriptionError}</p>)}
                                        </div>
                                    </div>
                                    {/* Student answer requirement or drage and drop or selecte exist question list */}
                                    <div className='md:ms-12'>
                                        <div className='md:flex relative'>
                                            <p className='mt-4'><b>3. Student answer requirement</b></p>
                                            <div className='md:absolute  left-56 '>
                                                <div className="QUESTIONS-COUNT flex-initial w-100 py-2 flex">
                                                    <div className="image-radio flex m-2 hover:cursor-pointer" name='is exactly' value={answer_exact} onClick={(e) => { handleOptionChangeStudent(e); setActivityData((prevData) => ({ ...prevData, answer_exact: 'is exactly' })); }}>
                                                        <span>
                                                            <img
                                                                src={activityData.answer_exact === 'is exactly' ? RadioOnIcon : RadioOffIcon}
                                                                alt="Task"
                                                                className="radio-image mt-1"
                                                            />
                                                        </span>
                                                        <span className={`ps-2 ${activityData.answer_exact === 'is exactly' ? 'font-bold' : 'font-light'}`}>is exactly</span>
                                                    </div>
                                                    <div className="image-radio flex m-2 hover:cursor-pointer" name='contain' value={answer_exact} onClick={(e) => { handleOptionChangeStudent(e); setActivityData((prevData) => ({ ...prevData, answer_exact: 'contain' })); }}>
                                                        <span>
                                                            <img
                                                                src={activityData.answer_exact === 'contain' ? RadioOnIcon : RadioOffIcon}
                                                                alt="Project"
                                                                className="radio-image mt-1"
                                                            />
                                                        </span>
                                                        <span className={`ps-2 ${activityData.answer_exact === 'contain' ? 'font-bold' : 'font-light'}`}>contain</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='md:flex  gap-5 my-3'>
                                            <div className='bg-gray-200 px-1 w-full'>
                                                <TextField
                                                    margin="dense"
                                                    label="Activity answer"
                                                    name="Activity answer"
                                                    type="text"
                                                    fullWidth
                                                    disabled={isDisabled || questionlistName !== null}
                                                    placeholder='Type your answer ....'
                                                    onChange={(e) => handleInputChange("answer_requirement", e)}
                                                    sx={{
                                                        '& .MuiOutlinedInput-root': {
                                                            '& fieldset': {
                                                                border: `${answerRequirementError ? '2px solid #FDE4DC' : '2px solid #F3F6F6'}`,
                                                            },
                                                            '&.Mui-focused fieldset': {
                                                                border: '2px solid #DBE6F5',
                                                            },
                                                            '&:hover fieldset': {
                                                                border: '2px solid #DBE6F5',
                                                            },
                                                        },
                                                    }}
                                                    InputProps={{
                                                        style: {
                                                            borderRadius: "0px",
                                                            background: `${activityData.answer_requirement ? '#F3F6F6' : 'white'}`,
                                                            height: '100px'
                                                        }
                                                    }}
                                                />
                                                {answerRequirementError && (<p className="text-red-500">{answerRequirementError}</p>)}
                                            </div>
                                            <p className='mt-10 flex justify-center'><b>Or</b></p>
                                            <div className='md:flex'>
                                                <div className='mt-4 flex justify-center'>
                                                    <img width={200} className="m-auto" src={(currentImage === CheckOnIcon ? DragdropOnIcon : DragdropOffIcon || questionlistName === null ? DragdropOffIcon : DragdropOnIcon)} alt="drop" />
                                                </div>
                                                <span className='my-10 ms-2 flex justify-center'>
                                                    <img
                                                        src={currentImage}
                                                        className="m-auto"
                                                        alt="Toggle"
                                                        onClick={toggleImage}
                                                        style={{ cursor: 'pointer', width: '50px' }} />
                                                </span>
                                            </div>
                                            <p className='mt-10 flex justify-center'><b>Or</b></p>
                                            {/* Selecte Exist question list list */}
                                            <div>
                                                <div className="flex justify-center mt-7">
                                                    <button name="currentQuestion"
                                                        className="border flex px-4 md:w-60 h-10 py-2 h-auto CurentQListBtn"
                                                        onClick={() => setIsHovering(true)}
                                                        onMouseLeave={() => setIsHovering(false)}
                                                    // disabled={isDisabled}
                                                    >

                                                        <img src={CurrentQIcon} alt="Icon" />
                                                        <span className="mt-1 ms-3">{`${questionlistName ? questionlistName : "Current Questions Lists"}`}</span>
                                                        {isHovering && (
                                                            <div className="origin-top-right mt-10 overflow-y-auto overflow-x-auto max-h-64 max-w-40 md:max-w-60 absolute py-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                                                                <div className="list-container max-h-100 py-30 w-fit">
                                                                    {loading ? <div className="flex justify-center px-10 sm:px-20"><CircularProgress className="text-blue-800" /></div> : <ul>
                                                                        {questionsLists.length > 0 ? (questionsLists.map((item, index) => (
                                                                            <li className={`hover:bg-gray-100 p-2 ${item.mainTopic == course.mainTopic ? '' : 'hidden'}`} id={index} onClick={() => { setQuestionList_Id(item.questionList_id); setQuestionlistName(item.name); setIsHovering(false); setDisabled(!isDisabled); setActivityActionType(2) }} key={item.questionsList_id}>{item.name}</li>
                                                                        ))) : (<>No Questions lists are available for mainTopic <b>'{course.mainTopic}'</b>.</>)}
                                                                    </ul>}
                                                                </div>
                                                            </div>

                                                        )}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Choose activity score range */}
                                    {/* <div className="my-5 md:ms-12">
                                        <div className='md:flex my-2'>
                                            <div className='mt-4'><b>4. Select Score for Activity</b></div>
                                        </div>
                                        <div className="relative flex my-5">
                                            <div className="md:absolute  md:left-0">50</div>
                                            <div className="md:absolute  md:left-60">100</div>
                                            <div className="md:absolute  md:right-60">150</div>
                                            <div className="md:absolute ps-5 md:right-0">200</div>
                                        </div>
                                        <div className='bg-gray-100 px-1 pt-2'>
                                            <input className="w-full"  id="default-range" type="range" min='50' max='200' step='50' defaultValue='50'
                                            onClick={(e)=>{setScore(e.target.value)}}
                                            class="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"></input>
                                        </div>
                                    </div> */}
                                </div>
                                <div className="flex  justify-center gap-5 mt-10">
                                    {/* <Link to='/Dashboard/activityType'> */}
                                    <button className="border flex px-7 py-2 rounded BackBtn hover:bg-blue-200" onClick={resetActivityType}><b>Back</b><img className="mt-1 ms-2" src={BackIcon} alt="back" /></button>
                                    {/* </Link> */}
                                    {/* <Link to='/Dashboard/ImportContent'> */}
                                    <button type='submit' className='BackBtn flex p-2 px-7 hover:bg-blue-200' onClick={() => { handleSaveActivity(); }} ><img className='mr-5 mt-1' src={SaveIcon} alt="Next" /><b> Save</b></button>
                                    {/* </Link> */}

                                </div>
                            </div>
                        </div>
                    );
                // Audio code 
                case 'audio':
                    return (
                        <div className='w-full'>
                            <div className='flex relative px-5 py-2 mb-2 ContentHeadingBg'>
                                <h3 className="text-xl">Create New Activity</h3>
                                <LoginHeading />
                            </div>
                            <div className="m-7 bg-white static">
                                <div className='flex justify-center '><img className='PdfImage' src={Audio} alt="" /></div>
                                <div className="xl:mx-40 mx-5 my-10">
                                    <div className="md:flex relative">
                                        <TextField
                                            label="Title"
                                            name="Title"
                                            type="text"
                                            defaultValue={`Activity`}
                                            onChange={(e) => handleInputChange("name", e)}
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    '& fieldset': {
                                                        border: '2px solid #F3F6F6',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        border: '2px solid #DBE6F5',
                                                    },
                                                    '&:hover fieldset': {
                                                        border: '2px solid #DBE6F5',
                                                    },
                                                },
                                            }}
                                            InputProps={{
                                                style: {
                                                    borderRadius: "5px",
                                                    background: `${activityData.name ? '#F3F6F6' : 'white'}`
                                                }
                                            }} />
                                        <div className='my-2 sm:my-0 md:absolute  md:right-0'>
                                            {/* date picker MUI */}
                                            <div className="relative">
                                                <button
                                                    className="flex items-center justify-center -mt-1 rounded-full focus:outline-none"
                                                    onMouseEnter={() => setShowDropdown(true)}
                                                    onMouseLeave={() => setShowDropdown(false)}
                                                >
                                                    <label htmlFor="date">Starting Date: </label>  <DatePicker disabled className='text-sky-600 border-2 hover:border-blue-100 p-2 mx-5 rounded bg-gray-100' minDate={new Date()} selected={date.$d ? date.$d : date} onChange={(date) => handleDateChange(date)} dateFormat={"dd/MM/yyyy"} />
                                                </button>
                                                {showDropdown && (
                                                    <div className="absolute text-black right-0 z-10  bg-white border border-gray-200 rounded shadow-lg top-full" onMouseEnter={() => setShowDropdown(true)} onMouseLeave={() => setShowDropdown(false)}>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <DemoContainer components={['DateCalendar', 'DateCalendar']}>
                                                                <DemoItem>
                                                                    <DateCalendar
                                                                        onChange={(newValue) => handleDateChange(newValue)}
                                                                        minDate={dayjs(stageFormatDate)}
                                                                        value={dayjs(date)}
                                                                        dateFormat={"dd/MM/yyyy"}
                                                                    />

                                                                </DemoItem>
                                                            </DemoContainer>
                                                        </LocalizationProvider>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    {/* Image Requirements */}
                                    <div className="my-5 md:ms-12">
                                        <div className='flex'>
                                            <span className='mt-3'>
                                                <b>1.</b>
                                            </span>
                                            <div>
                                                <img className='ms-5 cursor-pointer' src={imageSrc} alt="Upload PDF" onClick={handleImageClick} />
                                                <input type="file" ref={fileInputRef} style={{ display: 'none' }}
                                                    onChange={handleFileChange}
                                                    accept="audio/*" />
                                                {validationError && (<p className="text-red-500">{validationError}</p>)}
                                            </div>
                                            <div className='ms-5'>
                                                <div className='flex'>
                                                    <span>{uploadedImage ? (<p><b>{uploadedImage.name}</b></p>) : null}</span>
                                                </div>
                                                {/* <p>Audio Requirements:</p>
                                                    <p>Audio size less than 1 mb by MP3 extension</p> */}
                                            </div>

                                        </div>
                                    </div>
                                    {/* Choose activity type */}
                                    <div className="my-5 md:ms-12">
                                        <div className='md:flex relative my-2'>
                                            <div className='mt-4'><b>2. Write the activity question\description</b></div>
                                            <div className='md:absolute  right-0'>
                                                <div className="QUESTIONS-COUNT flex-initial w-100 py-2 flex">
                                                    <div className="image-radio flex m-2 hover:cursor-pointer" disabled={isDisabled} name='Task' value={activity_case} onClick={(e) => { handleOptionChanges(e); setActivityData((prevData) => ({ ...prevData, activity_case: 'Task', })); }}>
                                                        <span>
                                                            <img
                                                                src={activityData.activity_case === 'Task' ? RadioOnIcon : RadioOffIcon}
                                                                alt="Task"
                                                                className="radio-image mt-1"
                                                            />
                                                        </span>
                                                        <span className={`ps-2 ${activityData.activity_case === 'Task' ? 'font-bold' : 'font-light'}`}>Task</span>
                                                    </div>
                                                    <div className="image-radio flex m-2 hover:cursor-pointer" disabled={isDisabled} name='Project' value={activity_case} onClick={(e) => { handleOptionChanges(e); setActivityData((prevData) => ({ ...prevData, activity_case: 'Project', })); }}>
                                                        <span>
                                                            <img
                                                                src={activityData.activity_case === 'Project' ? RadioOnIcon : RadioOffIcon}
                                                                alt="Project"
                                                                className="radio-image mt-1"
                                                            />
                                                        </span>
                                                        <span className={`ps-2 ${activityData.activity_case === 'Project' ? 'font-bold' : 'font-light'}`}>Project</span>
                                                    </div>
                                                    <div className="image-radio flex m-2 hover:cursor-pointer" disabled={isDisabled} name='Case' value={activity_case} onClick={(e) => { handleOptionChanges(e); setActivityData((prevData) => ({ ...prevData, activity_case: 'Case', })); }}>
                                                        <span>
                                                            <img
                                                                src={activityData.activity_case === 'Case' ? RadioOnIcon : RadioOffIcon}
                                                                alt="Case"
                                                                className="radio-image mt-1"
                                                            />
                                                        </span>
                                                        <span className={`ps-2 ${activityData.activity_case === 'Case' ? 'font-bold' : 'font-light'}`}>Case</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='bg-gray-200 px-1'>
                                            <TextField
                                                margin="dense"
                                                label="Activity question\description"
                                                name="Activity question\description"
                                                type="text"
                                                fullWidth
                                                placeholder={`View the ${activityType}.. then answer the following question`}
                                                onChange={(e) => handleInputChange("description", e)}
                                                sx={{
                                                    '& .MuiOutlinedInput-root': {
                                                        '& fieldset': {
                                                            border: `${descriptionError ? '2px solid #FDE4DC' : '2px solid #F3F6F6'}`,
                                                        },
                                                        '&.Mui-focused fieldset': {
                                                            border: '2px solid #DBE6F5',
                                                        },
                                                        '&:hover fieldset': {
                                                            border: '2px solid #DBE6F5',
                                                        },
                                                    },
                                                }}
                                                InputProps={{
                                                    style: {
                                                        borderRadius: "0px",
                                                        background: `${activityData.description ? '#F3F6F6' : 'white'}`,
                                                        height: '100px'
                                                    }
                                                }}
                                            />
                                            {descriptionError && (<p className="text-red-500">{descriptionError}</p>)}
                                        </div>
                                    </div>
                                    {/* Student answer requirement or drage and drop or selecte exist question list */}
                                    <div className='md:ms-12'>
                                        <div className='md:flex relative'>
                                            <p className='mt-4'><b>3. Student answer requirement</b></p>
                                            <div className='md:absolute  left-56 '>
                                                <div className="QUESTIONS-COUNT flex-initial w-100 py-2 flex">
                                                    <div className="image-radio flex m-2 hover:cursor-pointer" name='is exactly' value={answer_exact} onClick={(e) => { handleOptionChangeStudent(e); setActivityData((prevData) => ({ ...prevData, answer_exact: 'is exactly' })); }}>
                                                        <span>
                                                            <img
                                                                src={activityData.answer_exact === 'is exactly' ? RadioOnIcon : RadioOffIcon}
                                                                alt="Task"
                                                                className="radio-image mt-1"
                                                            />
                                                        </span>
                                                        <span className={`ps-2 ${activityData.answer_exact === 'is exactly' ? 'font-bold' : 'font-light'}`}>is exactly</span>
                                                    </div>
                                                    <div className="image-radio flex m-2 hover:cursor-pointer" name='contain' value={answer_exact} onClick={(e) => { handleOptionChangeStudent(e); setActivityData((prevData) => ({ ...prevData, answer_exact: 'contain' })); }}>
                                                        <span>
                                                            <img
                                                                src={activityData.answer_exact === 'contain' ? RadioOnIcon : RadioOffIcon}
                                                                alt="Project"
                                                                className="radio-image mt-1"
                                                            />
                                                        </span>
                                                        <span className={`ps-2 ${activityData.answer_exact === 'contain' ? 'font-bold' : 'font-light'}`}>contain</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='md:flex  gap-5 my-3'>
                                            <div className='bg-gray-200 px-1 w-full'>
                                                <TextField
                                                    margin="dense"
                                                    label="Activity answer"
                                                    name="Activity answer"
                                                    type="text"
                                                    fullWidth
                                                    disabled={isDisabled || questionlistName !== null}
                                                    placeholder='Type your answer ....'
                                                    onChange={(e) => handleInputChange("answer_requirement", e)}
                                                    sx={{
                                                        '& .MuiOutlinedInput-root': {
                                                            '& fieldset': {
                                                                border: `${answerRequirementError ? '2px solid #FDE4DC' : '2px solid #F3F6F6'}`,
                                                            },
                                                            '&.Mui-focused fieldset': {
                                                                border: '2px solid #DBE6F5',
                                                            },
                                                            '&:hover fieldset': {
                                                                border: '2px solid #DBE6F5',
                                                            },
                                                        },
                                                    }}
                                                    InputProps={{
                                                        style: {
                                                            borderRadius: "0px",
                                                            background: `${activityData.answer_requirement ? '#F3F6F6' : 'white'}`,
                                                            height: '100px'
                                                        }
                                                    }}
                                                />
                                                {answerRequirementError && (<p className="text-red-500">{answerRequirementError}</p>)}
                                            </div>
                                            <p className='mt-10 flex justify-center'><b>Or</b></p>
                                            <div className='md:flex'>
                                                <div className='mt-4 flex justify-center'>
                                                    <img width={200} className="m-auto" src={(currentImage === CheckOnIcon ? DragdropOnIcon : DragdropOffIcon || questionlistName === null ? DragdropOffIcon : DragdropOnIcon)} alt="drop" />
                                                </div>
                                                <span className='my-10 ms-2 flex justify-center'>
                                                    <img
                                                        src={currentImage}
                                                        className="m-auto"
                                                        alt="Toggle"
                                                        onClick={toggleImage}
                                                        style={{ cursor: 'pointer', width: '50px' }} />
                                                </span>
                                            </div>
                                            <p className='mt-10 flex justify-center'><b>Or</b></p>
                                            {/* Selecte Exist question list list */}
                                            <div>
                                                <div className="flex justify-center mt-7">
                                                    <button name="currentQuestion"
                                                        className="border flex px-4 md:w-60 h-10 py-2 h-auto CurentQListBtn"
                                                        onClick={() => setIsHovering(true)}
                                                        onMouseLeave={() => setIsHovering(false)}
                                                    // disabled={isDisabled}
                                                    >

                                                        <img src={CurrentQIcon} alt="Icon" />
                                                        <span className="mt-1 ms-3">{`${questionlistName ? questionlistName : "Current Questions Lists"}`}</span>
                                                        {isHovering && (
                                                            <div className="origin-top-right mt-10 overflow-y-auto overflow-x-auto max-h-64 max-w-40 md:max-w-60 absolute py-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                                                                <div className="list-container max-h-100 py-30 w-fit">
                                                                {loading ? <div className="flex justify-center px-10 sm:px-20"><CircularProgress className="text-blue-800" /></div> : <ul>
                                                                        {questionsLists.length > 0 ? (questionsLists.map((item, index) => (
                                                                            <li className={`hover:bg-gray-100 p-2 ${item.mainTopic == course.mainTopic ? '' : 'hidden'}`} id={index} onClick={() => { setQuestionList_Id(item.questionList_id); setQuestionlistName(item.name); setIsHovering(false); setDisabled(!isDisabled); setActivityActionType(2) }} key={item.questionsList_id}>{item.name}</li>
                                                                        ))) : (<>No Questions lists are available for mainTopic <b>'{course.mainTopic}'</b>.</>)}
                                                                    </ul>}
                                                                </div>
                                                            </div>

                                                        )}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex  justify-center gap-5 pb-5 mt-10">
                                    {/* <Link to='/Dashboard/activityType'> */}
                                    <button className="border flex px-7 py-2 rounded BackBtn hover:bg-blue-200" onClick={resetActivityType}><b>Back</b><img className="mt-1 ms-2" src={BackIcon} alt="back" /></button>
                                    {/* </Link> */}
                                    {/* <Link to='/Dashboard/ImportContent'> */}
                                    <button type='submit' className='BackBtn flex p-2 px-7 hover:bg-blue-200' onClick={handleSaveActivity}><img className='mr-5 mt-1' src={SaveIcon} alt="Next" /><b> Save</b></button>
                                    {/* </Link> */}
                                </div>
                            </div>
                        </div>
                    );
                // Video code 
                case 'video':
                    return (
                        <div className='w-full'>
                            <div className='flex relative px-5 py-2 mb-2 ContentHeadingBg'><h3 className="text-xl">Create New Activity</h3>
                                <LoginHeading />
                            </div>
                            <div className="m-7 bg-white static">
                                <div className='flex justify-center '><img className='PdfImage' src={Video} alt="" /></div>
                                <div className="xl:mx-40 mx-5 my-10">
                                    <div className="md:flex relative">
                                        <TextField
                                            label="Title"
                                            name="Title"
                                            type="text"
                                            defaultValue={`Activity`}
                                            onChange={(e) => handleInputChange("name", e)}
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    '& fieldset': {
                                                        border: '2px solid #F3F6F6',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        border: '2px solid #DBE6F5',
                                                    },
                                                    '&:hover fieldset': {
                                                        border: '2px solid #DBE6F5',
                                                    },
                                                },
                                            }}
                                            InputProps={{
                                                style: {
                                                    borderRadius: "5px",
                                                    background: `${activityData.name ? '#F3F6F6' : 'white'}`
                                                }
                                            }} />
                                        <div className='my-2 sm:my-0 md:absolute  md:right-0'>
                                            {/* date picker MUI */}
                                            <div className="relative">
                                                <button
                                                    className="flex items-center justify-center -mt-1 rounded-full focus:outline-none"
                                                    onMouseEnter={() => setShowDropdown(true)}
                                                    onMouseLeave={() => setShowDropdown(false)}
                                                >
                                                    <label htmlFor="date">Starting Date: </label>  <DatePicker disabled className='text-sky-600 border-2 hover:border-blue-100 p-2 mx-5 rounded bg-gray-100' minDate={new Date()} selected={date.$d ? date.$d : date} onChange={(date) => handleDateChange(date)} dateFormat={"dd/MM/yyyy"} />
                                                </button>
                                                {showDropdown && (
                                                    <div className="absolute text-black right-0 z-10  bg-white border border-gray-200 rounded shadow-lg top-full" onMouseEnter={() => setShowDropdown(true)} onMouseLeave={() => setShowDropdown(false)}>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <DemoContainer components={['DateCalendar', 'DateCalendar']}>
                                                                <DemoItem>
                                                                    <DateCalendar
                                                                        onChange={(newValue) => handleDateChange(newValue)}
                                                                        minDate={dayjs(stageFormatDate)}
                                                                        value={dayjs(date)}
                                                                        dateFormat={"dd/MM/yyyy"}
                                                                    />

                                                                </DemoItem>
                                                            </DemoContainer>
                                                        </LocalizationProvider>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    {/* Image Requirements */}
                                    <div className="my-5 md:ms-12">
                                        <div className='flex'>
                                            <span className='mt-3'>
                                                <b>1.</b>
                                            </span>
                                            <div>
                                                <img className='ms-5 cursor-pointer' src={imageSrc} alt="Upload PDF" onClick={handleImageClick} />
                                                <input type="file" ref={fileInputRef} style={{ display: 'none' }}
                                                    onChange={handleFileChange}
                                                    accept=".mp4, .webm" />
                                                {validationError && (<p className="text-red-500">{validationError}</p>)}
                                            </div>
                                            <div className='ms-5'>
                                                {uploadedImage ? (<p><b>{uploadedImage.name}</b></p>) : null}
                                                {/* <p>Video Requirements:</p>
                                                <p>Video size less than 2 mb with ratio 2:3 or 3:2 MP4 extension</p> */}
                                            </div>

                                        </div>
                                    </div>
                                    {/* Choose activity type */}
                                    <div className="my-5 md:ms-12">
                                        <div className='md:flex relative my-2'>
                                            <div className='mt-4'><b>2. Write the activity question\description</b></div>
                                            <div className='md:absolute  right-0'>
                                                <div className="QUESTIONS-COUNT flex-initial w-100 py-2 flex">
                                                    <div className="image-radio flex m-2 hover:cursor-pointer" disabled={isDisabled} name='Task' value={activity_case} onClick={(e) => { handleOptionChanges(e); setActivityData((prevData) => ({ ...prevData, activity_case: 'Task', })); }}>
                                                        <span>
                                                            <img
                                                                src={activityData.activity_case === 'Task' ? RadioOnIcon : RadioOffIcon}
                                                                alt="Task"
                                                                className="radio-image mt-1"
                                                            />
                                                        </span>
                                                        <span className={`ps-2 ${activityData.activity_case === 'Task' ? 'font-bold' : 'font-light'}`}>Task</span>
                                                    </div>
                                                    <div className="image-radio flex m-2 hover:cursor-pointer" disabled={isDisabled} name='Project' value={activity_case} onClick={(e) => { handleOptionChanges(e); setActivityData((prevData) => ({ ...prevData, activity_case: 'Project', })); }}>
                                                        <span>
                                                            <img
                                                                src={activityData.activity_case === 'Project' ? RadioOnIcon : RadioOffIcon}
                                                                alt="Project"
                                                                className="radio-image mt-1"
                                                            />
                                                        </span>
                                                        <span className={`ps-2 ${activityData.activity_case === 'Project' ? 'font-bold' : 'font-light'}`}>Project</span>
                                                    </div>
                                                    <div className="image-radio flex m-2 hover:cursor-pointer" disabled={isDisabled} name='Case' value={activity_case} onClick={(e) => { handleOptionChanges(e); setActivityData((prevData) => ({ ...prevData, activity_case: 'Case', })); }}>
                                                        <span>
                                                            <img
                                                                src={activityData.activity_case === 'Case' ? RadioOnIcon : RadioOffIcon}
                                                                alt="Case"
                                                                className="radio-image mt-1"
                                                            />
                                                        </span>
                                                        <span className={`ps-2 ${activityData.activity_case === 'Case' ? 'font-bold' : 'font-light'}`}>Case</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='bg-gray-200 px-1'>
                                            <TextField
                                                margin="dense"
                                                label="Activity question\description"
                                                name="Activity question\description"
                                                type="text"
                                                fullWidth
                                                placeholder={`View the ${activityType}.. then answer the following question`}
                                                onChange={(e) => handleInputChange("description", e)}
                                                sx={{
                                                    '& .MuiOutlinedInput-root': {
                                                        '& fieldset': {
                                                            border: `${descriptionError ? '2px solid #FDE4DC' : '2px solid #F3F6F6'}`,
                                                        },
                                                        '&.Mui-focused fieldset': {
                                                            border: '2px solid #DBE6F5',
                                                        },
                                                        '&:hover fieldset': {
                                                            border: '2px solid #DBE6F5',
                                                        },
                                                    },
                                                }}
                                                InputProps={{
                                                    style: {
                                                        borderRadius: "0px",
                                                        background: `${activityData.description ? '#F3F6F6' : 'white'}`,
                                                        height: '100px'
                                                    }
                                                }}
                                            />
                                            {descriptionError && (<p className="text-red-500">{descriptionError}</p>)}
                                        </div>
                                    </div>
                                    {/* Student answer requirement or drage and drop or selecte exist question list */}
                                    <div className='md:ms-12'>
                                        <div className='md:flex relative'>
                                            <p className='mt-4'><b>3. Student answer requirement</b></p>
                                            <div className='md:absolute  left-56 '>
                                                <div className="QUESTIONS-COUNT flex-initial w-100 py-2 flex">
                                                    <div className="image-radio flex m-2 hover:cursor-pointer" name='is exactly' value={answer_exact} onClick={(e) => { handleOptionChangeStudent(e); setActivityData((prevData) => ({ ...prevData, answer_exact: 'is exactly' })); }}>
                                                        <span>
                                                            <img
                                                                src={activityData.answer_exact === 'is exactly' ? RadioOnIcon : RadioOffIcon}
                                                                alt="Task"
                                                                className="radio-image mt-1"
                                                            />
                                                        </span>
                                                        <span className={`ps-2 ${activityData.answer_exact === 'is exactly' ? 'font-bold' : 'font-light'}`}>is exactly</span>
                                                    </div>
                                                    <div className="image-radio flex m-2 hover:cursor-pointer" name='contain' value={answer_exact} onClick={(e) => { handleOptionChangeStudent(e); setActivityData((prevData) => ({ ...prevData, answer_exact: 'contain' })); }}>
                                                        <span>
                                                            <img
                                                                src={activityData.answer_exact === 'contain' ? RadioOnIcon : RadioOffIcon}
                                                                alt="Project"
                                                                className="radio-image mt-1"
                                                            />
                                                        </span>
                                                        <span className={`ps-2 ${activityData.answer_exact === 'contain' ? 'font-bold' : 'font-light'}`}>contain</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='md:flex  gap-5 my-3'>
                                            <div className='bg-gray-200 px-1 w-full'>
                                                <TextField
                                                    margin="dense"
                                                    label="Activity answer"
                                                    name="Activity answer"
                                                    type="text"
                                                    fullWidth
                                                    disabled={isDisabled || questionlistName !== null}
                                                    placeholder='Type your answer ....'
                                                    onChange={(e) => handleInputChange("answer_requirement", e)}
                                                    sx={{
                                                        '& .MuiOutlinedInput-root': {
                                                            '& fieldset': {
                                                                border: `${answerRequirementError ? '2px solid #FDE4DC' : '2px solid #F3F6F6'}`,
                                                            },
                                                            '&.Mui-focused fieldset': {
                                                                border: '2px solid #DBE6F5',
                                                            },
                                                            '&:hover fieldset': {
                                                                border: '2px solid #DBE6F5',
                                                            },
                                                        },
                                                    }}
                                                    InputProps={{
                                                        style: {
                                                            borderRadius: "0px",
                                                            background: `${activityData.answer_requirement ? '#F3F6F6' : 'white'}`,
                                                            height: '100px'
                                                        }
                                                    }}
                                                />
                                                {answerRequirementError && (<p className="text-red-500">{answerRequirementError}</p>)}
                                            </div>
                                            <p className='mt-10 flex justify-center'><b>Or</b></p>
                                            <div className='md:flex'>
                                                <div className='mt-4 flex justify-center'>
                                                    <img width={200} className="m-auto" src={(currentImage === CheckOnIcon ? DragdropOnIcon : DragdropOffIcon || questionlistName === null ? DragdropOffIcon : DragdropOnIcon)} alt="drop" />
                                                </div>
                                                <span className='my-10 ms-2 flex justify-center'>
                                                    <img
                                                        src={currentImage}
                                                        className="m-auto"
                                                        alt="Toggle"
                                                        onClick={toggleImage}
                                                        style={{ cursor: 'pointer', width: '50px' }} />
                                                </span>
                                            </div>
                                            <p className='mt-10 flex justify-center'><b>Or</b></p>
                                            {/* Selecte Exist question list list */}
                                            <div>
                                                <div className="flex justify-center mt-7">
                                                    <button name="currentQuestion"
                                                        className="border flex px-4 md:w-60 h-10 py-2 h-auto CurentQListBtn"
                                                        onClick={() => setIsHovering(true)}
                                                        onMouseLeave={() => setIsHovering(false)}
                                                    // disabled={isDisabled}
                                                    >

                                                        <img src={CurrentQIcon} alt="Icon" />
                                                        <span className="mt-1 ms-3">{`${questionlistName ? questionlistName : "Current Questions Lists"}`}</span>
                                                        {isHovering && (
                                                            <div className="origin-top-right mt-10 overflow-y-auto overflow-x-auto max-h-64 max-w-40 md:max-w-60 absolute py-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                                                                <div className="list-container max-h-100 py-30 w-fit">
                                                                {loading ? <div className="flex justify-center px-10 sm:px-20"><CircularProgress className="text-blue-800" /></div> : <ul>
                                                                        {questionsLists.length > 0 ? (questionsLists.map((item, index) => (
                                                                            <li className={`hover:bg-gray-100 p-2 ${item.mainTopic == course.mainTopic ? '' : 'hidden'}`} id={index} onClick={() => { setQuestionList_Id(item.questionList_id); setQuestionlistName(item.name); setIsHovering(false); setDisabled(!isDisabled); setActivityActionType(2) }} key={item.questionsList_id}>{item.name}</li>
                                                                        ))) : (<>No Questions lists are available for mainTopic <b>'{course.mainTopic}'</b>.</>)}
                                                                    </ul>}
                                                                </div>
                                                            </div>

                                                        )}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex  justify-center gap-5 pb-5 mt-10">
                                    {/* <Link to='/Dashboard/activityType'> */}
                                    <button className="border flex px-7 py-2 rounded BackBtn hover:bg-blue-200" onClick={resetActivityType}><b>Back</b><img className="mt-1 ms-2" src={BackIcon} alt="back" /></button>
                                    {/* </Link> */}
                                    {/* <Link to='/Dashboard/ImportContent'> */}
                                    <button type='submit' className='BackBtn flex p-2 px-7 hover:bg-blue-200' onClick={handleSaveActivity}><img className='mr-5 mt-1' src={SaveIcon} alt="Next" /><b> Save</b></button>
                                    {/* </Link> */}
                                </div>
                            </div>
                        </div>
                    );
                // Image code here
                case 'image':
                    return (
                        <div className='w-full'>
                            <div className='flex relative px-5 py-2 mb-2 ContentHeadingBg'><h3 className="text-xl">Create New Activity</h3>
                                <LoginHeading />
                            </div>
                            <div className="m-7 bg-white static">
                                <div className='flex justify-center '><img className='PdfImage' src={Image} alt="" /></div>
                                <div className="xl:mx-40 mx-5 my-10">
                                    <div className="md:flex relative">
                                        <TextField
                                            label="Title"
                                            name="Title"
                                            type="text"
                                            defaultValue={`Activity`}
                                            onChange={(e) => handleInputChange("name", e)}
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    '& fieldset': {
                                                        border: '2px solid #F3F6F6',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        border: '2px solid #DBE6F5',
                                                    },
                                                    '&:hover fieldset': {
                                                        border: '2px solid #DBE6F5',
                                                    },
                                                },
                                            }}
                                            InputProps={{
                                                style: {
                                                    borderRadius: "5px",
                                                    background: `${activityData.name ? '#F3F6F6' : 'white'}`
                                                }
                                            }} />
                                        <div className='my-2 sm:my-0 md:absolute  md:right-0'>
                                            {/* date picker MUI */}
                                            <div className="relative">
                                                <button
                                                    className="flex items-center justify-center -mt-1 rounded-full focus:outline-none"
                                                    onMouseEnter={() => setShowDropdown(true)}
                                                    onMouseLeave={() => setShowDropdown(false)}
                                                >
                                                    <label htmlFor="date">Starting Date: </label>  <DatePicker disabled className='text-sky-600 border-2 hover:border-blue-100 p-2 mx-5 rounded bg-gray-100' minDate={new Date()} selected={date.$d ? date.$d : date} onChange={(date) => handleDateChange(date)} dateFormat={"dd/MM/yyyy"} />
                                                </button>
                                                {showDropdown && (
                                                    <div className="absolute text-black right-0 z-10  bg-white border border-gray-200 rounded shadow-lg top-full" onMouseEnter={() => setShowDropdown(true)} onMouseLeave={() => setShowDropdown(false)}>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <DemoContainer components={['DateCalendar', 'DateCalendar']}>
                                                                <DemoItem>
                                                                    <DateCalendar
                                                                        onChange={(newValue) => handleDateChange(newValue)}
                                                                        minDate={dayjs(stageFormatDate)}
                                                                        value={dayjs(date)}
                                                                        dateFormat={"dd/MM/yyyy"}
                                                                    />

                                                                </DemoItem>
                                                            </DemoContainer>
                                                        </LocalizationProvider>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    {/* Image Requirements */}
                                    <div className="my-5 md:ms-12">
                                        <div className='flex'>
                                            <span className='mt-3'>
                                                <b>1.</b>
                                            </span>
                                            <div>
                                                <img className='ms-5 cursor-pointer' src={imageSrc} alt="Upload PDF" onClick={handleImageClick} />
                                                <input type="file" ref={fileInputRef} style={{ display: 'none' }}
                                                    onChange={handleFileChange}
                                                    accept="image/*" />
                                                {validationError && (<p className="text-red-500">{validationError}</p>)}
                                            </div>
                                            <div className='ms-5'>
                                                {uploadedImage ? (<p><b>{uploadedImage.name}</b></p>) : null}
                                                {/* <p>Image Requirements:</p>
                                                    <p>Image size less than 1 mb with ratio 2:3 by JPG or PNG extension</p> */}
                                            </div>

                                        </div>
                                    </div>
                                    {/* Choose activity type */}
                                    <div className="my-5 md:ms-12">
                                        <div className='md:flex relative my-2'>
                                            <div className='mt-4'><b>2. Write the activity question\description</b></div>
                                            <div className='md:absolute  right-0'>
                                                <div className="QUESTIONS-COUNT flex-initial w-100 py-2 flex">
                                                    <div className="image-radio flex m-2 hover:cursor-pointer" disabled={isDisabled} name='Task' value={activity_case} onClick={(e) => { handleOptionChanges(e); setActivityData((prevData) => ({ ...prevData, activity_case: 'Task', })); }}>
                                                        <span>
                                                            <img
                                                                src={activityData.activity_case === 'Task' ? RadioOnIcon : RadioOffIcon}
                                                                alt="Task"
                                                                className="radio-image mt-1"
                                                            />
                                                        </span>
                                                        <span className={`ps-2 ${activityData.activity_case === 'Task' ? 'font-bold' : 'font-light'}`}>Task</span>
                                                    </div>
                                                    <div className="image-radio flex m-2 hover:cursor-pointer" disabled={isDisabled} name='Project' value={activity_case} onClick={(e) => { handleOptionChanges(e); setActivityData((prevData) => ({ ...prevData, activity_case: 'Project', })); }}>
                                                        <span>
                                                            <img
                                                                src={activityData.activity_case === 'Project' ? RadioOnIcon : RadioOffIcon}
                                                                alt="Project"
                                                                className="radio-image mt-1"
                                                            />
                                                        </span>
                                                        <span className={`ps-2 ${activityData.activity_case === 'Project' ? 'font-bold' : 'font-light'}`}>Project</span>
                                                    </div>
                                                    <div className="image-radio flex m-2 hover:cursor-pointer" disabled={isDisabled} name='Case' value={activity_case} onClick={(e) => { handleOptionChanges(e); setActivityData((prevData) => ({ ...prevData, activity_case: 'Case', })); }}>
                                                        <span>
                                                            <img
                                                                src={activityData.activity_case === 'Case' ? RadioOnIcon : RadioOffIcon}
                                                                alt="Case"
                                                                className="radio-image mt-1"
                                                            />
                                                        </span>
                                                        <span className={`ps-2 ${activityData.activity_case === 'Case' ? 'font-bold' : 'font-light'}`}>Case</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='bg-gray-200 px-1'>
                                            <TextField
                                                margin="dense"
                                                label="Activity question\description"
                                                name="Activity question\description"
                                                type="text"
                                                fullWidth
                                                placeholder={`View the ${activityType}.. then answer the following question`}
                                                onChange={(e) => handleInputChange("description", e)}
                                                sx={{
                                                    '& .MuiOutlinedInput-root': {
                                                        '& fieldset': {
                                                            border: `${descriptionError ? '2px solid #FDE4DC' : '2px solid #F3F6F6'}`,
                                                        },
                                                        '&.Mui-focused fieldset': {
                                                            border: '2px solid #DBE6F5',
                                                        },
                                                        '&:hover fieldset': {
                                                            border: '2px solid #DBE6F5',
                                                        },
                                                    },
                                                }}
                                                InputProps={{
                                                    style: {
                                                        borderRadius: "0px",
                                                        background: `${activityData.description ? '#F3F6F6' : 'white'}`,
                                                        height: '100px'
                                                    }
                                                }}
                                            />
                                            {descriptionError && (<p className="text-red-500">{descriptionError}</p>)}
                                        </div>
                                    </div>
                                    {/* Student answer requirement or drage and drop or selecte exist question list */}
                                    <div className='md:ms-12'>
                                        <div className='md:flex relative'>
                                            <p className='mt-4'><b>3. Student answer requirement</b></p>
                                            <div className='md:absolute  left-56 '>
                                                <div className="QUESTIONS-COUNT flex-initial w-100 py-2 flex">
                                                    <div className="image-radio flex m-2 hover:cursor-pointer" name='is exactly' value={answer_exact} onClick={(e) => { handleOptionChangeStudent(e); setActivityData((prevData) => ({ ...prevData, answer_exact: 'is exactly' })); }}>
                                                        <span>
                                                            <img
                                                                src={activityData.answer_exact === 'is exactly' ? RadioOnIcon : RadioOffIcon}
                                                                alt="Task"
                                                                className="radio-image mt-1"
                                                            />
                                                        </span>
                                                        <span className={`ps-2 ${activityData.answer_exact === 'is exactly' ? 'font-bold' : 'font-light'}`}>is exactly</span>
                                                    </div>
                                                    <div className="image-radio flex m-2 hover:cursor-pointer" name='contain' value={answer_exact} onClick={(e) => { handleOptionChangeStudent(e); setActivityData((prevData) => ({ ...prevData, answer_exact: 'contain' })); }}>
                                                        <span>
                                                            <img
                                                                src={activityData.answer_exact === 'contain' ? RadioOnIcon : RadioOffIcon}
                                                                alt="Project"
                                                                className="radio-image mt-1"
                                                            />
                                                        </span>
                                                        <span className={`ps-2 ${activityData.answer_exact === 'contain' ? 'font-bold' : 'font-light'}`}>contain</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='md:flex gap-5 my-3 '>
                                            <div className='bg-gray-200 px-1 w-full'>
                                                <TextField
                                                    margin="dense"
                                                    label="Activity answer"
                                                    name="Activity answer"
                                                    type="text"
                                                    fullWidth
                                                    disabled={isDisabled || questionlistName !== null}
                                                    placeholder='Type your answer ....'
                                                    onChange={(e) => handleInputChange("answer_requirement", e)}
                                                    sx={{
                                                        '& .MuiOutlinedInput-root': {
                                                            '& fieldset': {
                                                                border: `${answerRequirementError ? '2px solid #FDE4DC' : '2px solid #F3F6F6'}`,
                                                            },
                                                            '&.Mui-focused fieldset': {
                                                                border: '2px solid #DBE6F5',
                                                            },
                                                            '&:hover fieldset': {
                                                                border: '2px solid #DBE6F5',
                                                            },
                                                        },
                                                    }}
                                                    InputProps={{
                                                        style: {
                                                            borderRadius: "0px",
                                                            background: `${activityData.answer_requirement ? '#F3F6F6' : 'white'}`,
                                                            height: '100px'
                                                        }
                                                    }}
                                                />
                                                {answerRequirementError && (<p className="text-red-500">{answerRequirementError}</p>)}
                                            </div>
                                            <p className='mt-10 flex justify-center'><b>Or</b></p>
                                            <div className='md:flex'>
                                                <div className='mt-4 flex justify-center'>
                                                    <img width={200} className="m-auto" src={(currentImage === CheckOnIcon ? DragdropOnIcon : DragdropOffIcon || questionlistName === null ? DragdropOffIcon : DragdropOnIcon)} alt="drop" />
                                                </div>
                                                <span className='my-10 ms-2 flex justify-center'>
                                                    <img
                                                        src={currentImage}
                                                        className="m-auto"
                                                        alt="Toggle"
                                                        onClick={toggleImage}
                                                        style={{ cursor: 'pointer', width: '50px' }} />
                                                </span>
                                            </div>
                                            <p className='mt-10 flex justify-center'><b>Or</b></p>
                                            {/* Selecte Exist question list list */}
                                            <div>
                                                <div className="flex justify-center mt-7">
                                                    <button name="currentQuestion"
                                                        className="border flex px-4 md:w-60 h-10 py-2 h-auto CurentQListBtn"
                                                        onClick={() => setIsHovering(true)}
                                                        onMouseLeave={() => setIsHovering(false)}
                                                    // disabled={isDisabled}
                                                    >

                                                        <img src={CurrentQIcon} alt="Icon" />
                                                        <span className="mt-1 ms-3">{`${questionlistName ? questionlistName : "Current Questions Lists"}`}</span>
                                                        {isHovering && (
                                                            <div className="origin-top-right mt-10 overflow-y-auto overflow-x-auto max-h-64 max-w-40 md:max-w-60 absolute py-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                                                                <div className="list-container max-h-100 py-30 w-fit">
                                                                {loading ? <div className="flex justify-center px-10 sm:px-20"><CircularProgress className="text-blue-800" /></div> : <ul>
                                                                        {questionsLists.length > 0 ? (questionsLists.map((item, index) => (
                                                                            <li className={`hover:bg-gray-100 p-2 ${item.mainTopic == course.mainTopic ? '' : 'hidden'}`} id={index} onClick={() => { setQuestionList_Id(item.questionList_id); setQuestionlistName(item.name); setIsHovering(false); setDisabled(!isDisabled); setActivityActionType(2) }} key={item.questionsList_id}>{item.name}</li>
                                                                        ))) : (<>No Questions lists are available for mainTopic <b>'{course.mainTopic}'</b>.</>)}
                                                                    </ul>}
                                                                </div>
                                                            </div>

                                                        )}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex  justify-center gap-5 pb-5 mt-10">
                                    {/* <Link to='/Dashboard/activityType'> */}
                                    <button className="border flex px-7 py-2 rounded BackBtn hover:bg-blue-200" onClick={resetActivityType}><b>Back</b><img className="mt-1 ms-2" src={BackIcon} alt="back" /></button>
                                    {/* </Link> */}
                                    {/* <Link to='/Dashboard/ImportContent'> */}
                                    <button type='submit' className='BackBtn flex p-2 px-7 hover:bg-blue-200' onClick={handleSaveActivity}><img className='mr-5 mt-1' src={SaveIcon} alt="Next" /><b> Save</b></button>
                                    {/* </Link> */}
                                </div>
                            </div>
                        </div>
                    );
                // URL code
                case 'url':
                    return (
                        <div className='w-full'>
                            <div className='flex relative px-5 py-2 mb-2 ContentHeadingBg'><h3 className="text-xl">Create New Activity</h3>
                                <LoginHeading />
                            </div>
                            <div className="m-7 bg-white static">
                                <div className='flex justify-center '><img className='PdfImage' src={Online} alt="" /></div>
                                <div className="xl:mx-40 mx-3 my-10">
                                    <div className="sm:flex relative">
                                        <TextField
                                            label="Title"
                                            name="Title"
                                            type="text"
                                            defaultValue={`Activity`}
                                            onChange={(e) => handleInputChange("name", e)}
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    '& fieldset': {
                                                        border: '2px solid #F3F6F6',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        border: '2px solid #DBE6F5',
                                                    },
                                                    '&:hover fieldset': {
                                                        border: '2px solid #DBE6F5',
                                                    },
                                                },
                                            }}
                                            InputProps={{
                                                style: {
                                                    borderRadius: "5px",
                                                    background: `${activityData.name ? '#F3F6F6' : 'white'}`
                                                }
                                            }} />
                                        <div className='my-2 sm:my-0 sm:absolute  sm:right-0'>
                                            {/* date picker MUI */}
                                            <div className="relative">
                                                <button
                                                    className="flex items-center justify-center -mt-1 rounded-full focus:outline-none"
                                                    onMouseEnter={() => setShowDropdown(true)}
                                                    onMouseLeave={() => setShowDropdown(false)}
                                                >
                                                    <label htmlFor="date">Starting Date: </label>  <DatePicker disabled className='text-sky-600 border-2 hover:border-blue-100 p-2 mx-5 rounded bg-gray-100' minDate={new Date()} selected={date.$d ? date.$d : date} onChange={(date) => handleDateChange(date)} dateFormat={"dd/MM/yyyy"} />
                                                </button>
                                                {showDropdown && (
                                                    <div className="absolute text-black right-0 z-10  bg-white border border-gray-200 rounded shadow-lg top-full" onMouseEnter={() => setShowDropdown(true)} onMouseLeave={() => setShowDropdown(false)}>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <DemoContainer components={['DateCalendar', 'DateCalendar']}>
                                                                <DemoItem>
                                                                    <DateCalendar
                                                                        onChange={(newValue) => handleDateChange(newValue)}
                                                                        minDate={dayjs(stageFormatDate)}
                                                                        value={dayjs(date)}
                                                                        dateFormat={"dd/MM/yyyy"}
                                                                    />

                                                                </DemoItem>
                                                            </DemoContainer>
                                                        </LocalizationProvider>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    {/* Image Requirements */}
                                    <div className="my-5 md:ms-12">
                                        <span className='my-2'><b>1. Paste your URL link</b></span>
                                        <div className='bg-gray-200 px-1'>
                                            <TextField
                                                margin="dense"
                                                label="URL"
                                                name="URL"
                                                type="text"
                                                fullWidth
                                                placeholder='http://...........'
                                                onChange={(e) => handleInputChange("url", e)}
                                                sx={{
                                                    '& .MuiOutlinedInput-root': {
                                                        '& fieldset': {
                                                            border: `${validationError ? '2px solid #FDE4DC' : '2px solid #F3F6F6'}`,
                                                        },
                                                        '&.Mui-focused fieldset': {
                                                            border: '2px solid #DBE6F5',
                                                        },
                                                        '&:hover fieldset': {
                                                            border: '2px solid #DBE6F5',
                                                        },
                                                    },
                                                }}
                                                InputProps={{
                                                    style: {
                                                        borderRadius: "0px",
                                                        background: `${activityData.url ? '#F3F6F6' : 'white'}`,
                                                    }
                                                }}
                                            />
                                            {validationError && (<p className="text-red-500">{validationError}</p>)}
                                        </div>
                                    </div>
                                    {/* Choose activity type */}
                                    <div className="my-5 md:ms-12">
                                        <div className='md:flex relative my-2'>
                                            <div className='mt-4'><b>2. Write the activity question\description</b></div>
                                            <div className='md:absolute  right-0'>
                                                <div className="QUESTIONS-COUNT flex-initial w-100 py-2 flex">
                                                    <div className="image-radio flex m-2 hover:cursor-pointer" disabled={isDisabled} name='Task' value={activity_case} onClick={(e) => { handleOptionChanges(e); setActivityData((prevData) => ({ ...prevData, activity_case: 'Task', })); }}>
                                                        <span>
                                                            <img
                                                                src={activityData.activity_case === 'Task' ? RadioOnIcon : RadioOffIcon}
                                                                alt="Task"
                                                                className="radio-image mt-1"
                                                            />
                                                        </span>
                                                        <span className={`ps-2 ${activityData.activity_case === 'Task' ? 'font-bold' : 'font-light'}`}>Task</span>
                                                    </div>
                                                    <div className="image-radio flex m-2 hover:cursor-pointer" disabled={isDisabled} name='Project' value={activity_case} onClick={(e) => { handleOptionChanges(e); setActivityData((prevData) => ({ ...prevData, activity_case: 'Project', })); }}>
                                                        <span>
                                                            <img
                                                                src={activityData.activity_case === 'Project' ? RadioOnIcon : RadioOffIcon}
                                                                alt="Project"
                                                                className="radio-image mt-1"
                                                            />
                                                        </span>
                                                        <span className={`ps-2 ${activityData.activity_case === 'Project' ? 'font-bold' : 'font-light'}`}>Project</span>
                                                    </div>
                                                    <div className="image-radio flex m-2 hover:cursor-pointer" disabled={isDisabled} name='Case' value={activity_case} onClick={(e) => { handleOptionChanges(e); setActivityData((prevData) => ({ ...prevData, activity_case: 'Case', })); }}>
                                                        <span>
                                                            <img
                                                                src={activityData.activity_case === 'Case' ? RadioOnIcon : RadioOffIcon}
                                                                alt="Case"
                                                                className="radio-image mt-1"
                                                            />
                                                        </span>
                                                        <span className={`ps-2 ${activityData.activity_case === 'Case' ? 'font-bold' : 'font-light'}`}>Case</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='bg-gray-200 px-1'>
                                            <TextField
                                                margin="dense"
                                                label="Activity question\description"
                                                name="Activity question\description"
                                                type="text"
                                                fullWidth
                                                placeholder={`View the ${activityType}.. then answer the following question`}
                                                onChange={(e) => handleInputChange("description", e)}
                                                sx={{
                                                    '& .MuiOutlinedInput-root': {
                                                        '& fieldset': {
                                                            border: `${descriptionError ? '2px solid #FDE4DC' : '2px solid #F3F6F6'}`,
                                                        },
                                                        '&.Mui-focused fieldset': {
                                                            border: '2px solid #DBE6F5',
                                                        },
                                                        '&:hover fieldset': {
                                                            border: '2px solid #DBE6F5',
                                                        },
                                                    },
                                                }}
                                                InputProps={{
                                                    style: {
                                                        borderRadius: "0px",
                                                        background: `${activityData.description ? '#F3F6F6' : 'white'}`,
                                                        height: '100px'
                                                    }
                                                }}
                                            />
                                            {descriptionError && (<p className="text-red-500">{descriptionError}</p>)}
                                        </div>
                                    </div>
                                    {/* Student answer requirement or drage and drop or selecte exist question list */}
                                    <div className='md:ms-12'>
                                        <div className='md:flex relative'>
                                            <p className='mt-4'><b>3. Student answer requirement</b></p>
                                            <div className='md:absolute  left-56 '>
                                                <div className="QUESTIONS-COUNT flex-initial w-100 py-2 flex">
                                                    <div className="image-radio flex m-2 hover:cursor-pointer" name='is exactly' value={answer_exact} onClick={(e) => { handleOptionChangeStudent(e); setActivityData((prevData) => ({ ...prevData, answer_exact: 'is exactly' })); }}>
                                                        <span>
                                                            <img
                                                                src={activityData.answer_exact === 'is exactly' ? RadioOnIcon : RadioOffIcon}
                                                                alt="Task"
                                                                className="radio-image mt-1"
                                                            />
                                                        </span>
                                                        <span className={`ps-2 ${activityData.answer_exact === 'is exactly' ? 'font-bold' : 'font-light'}`}>is exactly</span>
                                                    </div>
                                                    <div className="image-radio flex m-2 hover:cursor-pointer" name='contain' value={answer_exact} onClick={(e) => { handleOptionChangeStudent(e); setActivityData((prevData) => ({ ...prevData, answer_exact: 'contain' })); }}>
                                                        <span>
                                                            <img
                                                                src={activityData.answer_exact === 'contain' ? RadioOnIcon : RadioOffIcon}
                                                                alt="Project"
                                                                className="radio-image mt-1"
                                                            />
                                                        </span>
                                                        <span className={`ps-2 ${activityData.answer_exact === 'contain' ? 'font-bold' : 'font-light'}`}>contain</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='md:flex  gap-5 my-3'>
                                            <div className='bg-gray-200 px-1 w-full'>
                                                <TextField
                                                    margin="dense"
                                                    label="Activity answer"
                                                    name="Activity answer"
                                                    type="text"
                                                    fullWidth
                                                    disabled={isDisabled || questionlistName !== null}
                                                    placeholder='Type your answer ....'
                                                    onChange={(e) => handleInputChange("answer_requirement", e)}
                                                    sx={{
                                                        '& .MuiOutlinedInput-root': {
                                                            '& fieldset': {
                                                                border: `${answerRequirementError ? '2px solid #FDE4DC' : '2px solid #F3F6F6'}`,
                                                            },
                                                            '&.Mui-focused fieldset': {
                                                                border: '2px solid #DBE6F5',
                                                            },
                                                            '&:hover fieldset': {
                                                                border: '2px solid #DBE6F5',
                                                            },
                                                        },
                                                    }}
                                                    InputProps={{
                                                        style: {
                                                            borderRadius: "0px",
                                                            background: `${activityData.answer_requirement ? '#F3F6F6' : 'white'}`,
                                                            height: '100px'
                                                        }
                                                    }}
                                                />
                                                {answerRequirementError && (<p className="text-red-500">{answerRequirementError}</p>)}
                                            </div>
                                            <p className='mt-10 flex justify-center'><b>Or</b></p>
                                            <div className='md:flex'>
                                                <div className='mt-4 flex justify-center'>
                                                    <img width={200} className="m-auto" src={(currentImage === CheckOnIcon ? DragdropOnIcon : DragdropOffIcon || questionlistName === null ? DragdropOffIcon : DragdropOnIcon)} alt="drop" />
                                                </div>
                                                <div className='my-10 ms-2 flex justify-center'>
                                                    <img
                                                        src={currentImage}
                                                        className="m-auto"
                                                        alt="Toggle"
                                                        onClick={toggleImage}
                                                        style={{ cursor: 'pointer', width: '50px' }} />
                                                </div>
                                            </div>
                                            <p className='mt-10 flex justify-center'><b>Or</b></p>
                                            {/* Selecte Exist question list list */}
                                            <div>
                                                <div className="flex justify-center mt-7">
                                                    <button name="currentQuestion"
                                                        className="border flex px-4 md:w-60 h-10 py-2 h-auto CurentQListBtn"
                                                        onClick={() => setIsHovering(true)}
                                                        onMouseLeave={() => setIsHovering(false)}
                                                    // disabled={isDisabled}
                                                    >

                                                        <img src={CurrentQIcon} alt="Icon" />
                                                        <span className="mt-1 ms-3">{`${questionlistName ? questionlistName : "Current Questions Lists"}`}</span>
                                                        {isHovering && (
                                                            <div className="origin-top-right mt-10 overflow-y-auto overflow-x-auto max-h-64 max-w-40 md:max-w-60 absolute py-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                                                                <div className="list-container max-h-100 py-30 w-fit">
                                                                {loading ? <div className="flex justify-center px-10 sm:px-20"><CircularProgress className="text-blue-800" /></div> : <ul>
                                                                        {questionsLists.length > 0 ? (questionsLists.map((item, index) => (
                                                                            <li className={`hover:bg-gray-100 p-2 ${item.mainTopic == course.mainTopic ? '' : 'hidden'}`} id={index} onClick={() => { setQuestionList_Id(item.questionList_id); setQuestionlistName(item.name); setIsHovering(false); setDisabled(!isDisabled); setActivityActionType(2) }} key={item.questionsList_id}>{item.name}</li>
                                                                        ))) : (<>No Questions lists are available for mainTopic <b>'{course.mainTopic}'</b>.</>)}
                                                                    </ul>}
                                                                </div>
                                                            </div>

                                                        )}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex  justify-center gap-5 pb-5 mt-10">
                                    {/* <Link to='/Dashboard/activityType'> */}
                                    <button className="border flex px-7 py-2 rounded BackBtn hover:bg-blue-200" onClick={resetActivityType}><b>Back</b><img className="mt-1 ms-2" src={BackIcon} alt="back" /></button>
                                    {/* </Link> */}
                                    {/* <Link to='/Dashboard/ImportContent'> */}
                                    <button type='submit' className='BackBtn flex p-2 px-7 hover:bg-blue-200' onClick={handleSaveActivity}><img className='mr-5 mt-1' src={SaveIcon} alt="Next" /><b> Save</b></button>
                                    {/* </Link> */}
                                </div>
                            </div>
                        </div>
                    );
                case 'multiActivity': return (<div><MultiActivy resetActivityTypemain={resetActivityType} /></div>)
                default:
                    return null;
            }
        }
        else {
            return (
                <>
                    <Dialog open={uploading}>
                        <DialogTitle> Uploading the {activityType} file </DialogTitle>
                        <CircularProgress className="text-blue-800 cursor-progress m-auto my-10" />
                    </Dialog>
                </>
            );
        }
    };

    const navigateToContentImport = () => {
        navigate('/Dashboard/adding', { state: { courses: courses, courseType: courseType, course: course, stageIndex: StageIndex, courseFormatDate: stageFormatDate, stratingDate: stratingDate, finishingDate: stageFinishingDate } });
    };

    return (
        // Main component
        <div>
            <Dialog className="lg:ms-56" open={addingStageModal} onClose={BotcloseModal}>
                <DialogContent>
                    <Alert severity='error'>{ErrorMessage}</Alert>
                </DialogContent>
                <div className='flex justify-center'>
                    <Button onClick={() => { BotcloseModal() }} color="primary">
                        Okay
                    </Button>
                </div>
            </Dialog>
            {activityType ? (
                <div>

                    {renderOptions()}
                    {/* Add a close or reset button here if needed */}
                </div>
            ) : (
                <div className='w-full'>
                    <MetaTags title='Choose Activity Type' description='Gamizign Choose Activity Type page--' />
                    <div className='flex relative px-5 py-2 mb-2 ContentHeadingBg'><h3 className="text-xl">Create New Activity</h3>
                        <LoginHeading />
                    </div>
                    <div className="m-7 bg-white">
                        {/* Progress Line */}
                        <div>
                            <ol className="flex items-center pt-5 px-2 sm:px-10 w-full text-sm font-medium text-center text-gray-500 dark:text-gray-400 sm:text-base">
                                <li className="flex w-full items-center text-pink-300 dark:text-blue-500 sm:after:content-[''] after:w-full after:border-blue-200 after:border sm:after:inline-block   dark:after:border-gray-700">
                                    <span className="flex items-center after:text-gray-200 dark:after:text-gray-500">
                                        <b>Adding</b>
                                    </span>
                                </li>
                                <li className="flex w-full items-center after:content-[''] after:w-full after:border-blue-200 after:border sm:after:inline-block   dark:after:border-gray-700">
                                    <span className="flex items-center after:text-gray-200 dark:after:text-gray-500">
                                        <b>Creating</b>
                                    </span>
                                </li>
                                <li className="flex w-full items-center after:content-[''] after:w-full after:border-blue-200 after:border sm:after:inline-block   dark:after:border-gray-700">
                                    <span className="flex items-center after:text-gray-200 dark:after:text-gray-500">
                                        <b>Customizing</b>
                                    </span>
                                </li>
                                <li className="flex items-center">
                                    <b>Saving</b>
                                </li>
                            </ol>
                        </div>
                        <div className="xl:mx-40 mx-5 mb-20 mt-10">
                            {/* course name */}
                            <div className="my-5">
                                <TextField
                                    fullWidth
                                    label="Course Title"
                                    name="Course Title"
                                    type="text"
                                    defaultValue={course.name}
                                    onChange={(e) => { course.name = e.target.value }}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                border: '2px solid #F3F6F6',
                                            },
                                            '&.Mui-focused fieldset': {
                                                border: '2px solid #DBE6F5',
                                            },
                                            '&:hover fieldset': {
                                                border: '2px solid #DBE6F5',
                                            },
                                        },
                                    }}
                                    InputProps={{
                                        style: {
                                            borderRadius: "5px",
                                            background: `${course.name ? '#F3F6F6' : 'white'}`,
                                            height: '50px',
                                        }
                                    }}
                                />
                            </div>
                            <div className="sm:flex relative">
                                <TextField
                                    label="Stage Title"
                                    name="Stage Title"
                                    type="text"
                                    value={course.stages[StageIndex].name}
                                    onChange={CourseNameHandler}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                border: '2px solid #F3F6F6',
                                            },
                                            '&.Mui-focused fieldset': {
                                                border: '2px solid #DBE6F5',
                                            },
                                            '&:hover fieldset': {
                                                border: '2px solid #DBE6F5',
                                            },
                                        },
                                    }}
                                    InputProps={{
                                        style: {
                                            borderRadius: "5px",
                                            background: `${journeyCourseName ? '#F3F6F6' : 'white'}`,
                                        }
                                    }}
                                />
                                <div className="">
                                    <div className='my-5 sm:my-0 sm:absolute  sm:right-0 md:flex gap-3'>
                                        {/* date picker MUI stage starting */}
                                        <div className="relative">
                                            <button
                                                className="flex items-center justify-center -mt-1 rounded-full focus:outline-none"
                                                onMouseEnter={() => setShowDropdown(true)}
                                                onMouseLeave={() => setShowDropdown(false)}
                                            >
                                                <label htmlFor="date">Starting Date: </label>
                                                <DatePicker disabled className='text-sky-600 border-2 hover:border-blue-100 p-2 sm:mx-5 rounded bg-gray-100' minDate={new Date()} selected={stageFormatDate.$d ? stageFormatDate.$d : stageFormatDate} onChange={(date) => handleDateChange(date)} dateFormat={"dd/MM/yyyy"} />
                                            </button>
                                            {showDropdown && (
                                                <div className="absolute text-black right-0 z-10  bg-white border border-gray-200 rounded shadow-lg top-full" onMouseEnter={() => setShowDropdown(true)} onMouseLeave={() => setShowDropdown(false)}>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DemoContainer components={['Starting DateCalendar']}>
                                                            <DemoItem label="Starting Date">
                                                                <DateCalendar
                                                                    onChange={(newValue) => handleStageDateChange(newValue)}
                                                                    minDate={dayjs(courseFormatDate)}
                                                                    value={dayjs(stageFormatDate)}
                                                                    dateFormat={"dd/MM/yyyy"}
                                                                />

                                                            </DemoItem>
                                                        </DemoContainer>
                                                    </LocalizationProvider>
                                                </div>
                                            )}
                                        </div>
                                        {/* stage finishing date */}
                                        <div className="relative mt-5 md:mt-0">
                                            <button
                                                className="flex items-center justify-center -mt-1 rounded-full focus:outline-none"
                                                onMouseEnter={() => setFinishingDate(true)}
                                                onMouseLeave={() => setFinishingDate(false)}
                                            >

                                                <label htmlFor="date">Finishing Date: </label>
                                                <DatePicker disabled className='text-sky-600 border-2 hover:border-blue-100 p-2 sm:mx-5 rounded bg-gray-100' minDate={stageFormatDate} selected={stageFinishingDate.$d ? stageFinishingDate.$d : stageFinishingDate} onChange={(date) => handleDateChange(date)} dateFormat={"dd/MM/yyyy"} />
                                            </button>
                                            {FinishingDate && (
                                                <div className="absolute text-black right-0 z-10  bg-white border border-gray-200 rounded shadow-lg top-full" onMouseEnter={() => setFinishingDate(true)} onMouseLeave={() => setFinishingDate(false)}>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DemoContainer components={['Finishing DateCalendar']}>
                                                            <DemoItem label="Finishing Date">
                                                                <DateCalendar
                                                                    onChange={(newValue) => handleFinishingDateChange(newValue)}
                                                                    // minDate={dayjs(stageFormatDate)}
                                                                    minDate={dayjs(stageFormatDate).add(1, 'week')}
                                                                    value={dayjs(stageFinishingDate)}
                                                                    // defaultValue={dayjs(stageFormatDate).add(1, 'month')}
                                                                    dateFormat={"dd/MM/yyyy"}
                                                                />

                                                            </DemoItem>
                                                        </DemoContainer>
                                                    </LocalizationProvider>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Choose stage */}
                            <div className="my-5 mx-2 sm:mx-12">
                                <p>1. Choose stage</p>
                                {/* ................................stage and activity ...................... */}
                                <div className="flex justify-center grid grid-cols-3 sm:grid-cols-5 sm:ms-10">
                                    {stages.map((stage, stageIndex) => (
                                        <div className="flex justify-center" key={stageIndex}>
                                            <div className={`relative  mt-5 hover:scale-110 ${StageIndex === stageIndex ? "scale-110" : ""}  cursor-pointer`} onClick={() => setStageIndex(stageIndex)}>
                                                <img className="" src={StageIndex === stageIndex ? ChooseStageHover : stageImgSrc} alt="" />
                                                <span className="text-3xl absolute bottom-8 start-9 font-bold"><p>{stageIndex + 1}</p></span>
                                                <ul className="@apply absolute bottom-0 left-0 h-16 w-16" style={{ top: "57%", left: "13%" }}>
                                                    <li>{stages[stageIndex].activities.length !== 0 ? stages[stageIndex].activities.length : ""} </li>
                                                </ul>
                                            </div>
                                        </div>
                                    ))}
                                    <div className="mt-5 flex"><span className="mt-6 font-bold">...</span><img className="cursor-pointer hover:scale-110" src={AddStage} onClick={() => { openBotModal(); }} alt="" /></div>
                                </div>
                            </div>
                            {/* Choose activity type */}
                            <div className="my-5 sm:mx-12">
                                <p>2. Choose activity type</p>
                                <div className="relative sm:ms-10 mt-5 flex flex-wrap gap-4">
                                    <div className={`hover:font-bold transition-transform transform hover:scale-110 ${keephover === 'game' ? "scale-110 font-bold" : ""} cursor-pointer`} onMouseEnter={() => setKeephover('game')} onMouseLeave={() => setKeephover('')} onClick={() => { setKeephover('game'); navigateToContentImport(); }}>
                                        <img className="" src={keephover === 'game' ? GameHoveron : Game} alt="" />
                                        <span className="ms-5 text-sky-600">Game</span>
                                    </div>
                                    <div className={`hover:font-bold transition-transform transform hover:scale-110 ${keephover === 'url' ? "scale-110 font-bold" : ""} cursor-pointer`} onMouseEnter={() => setKeephover('url')} onMouseLeave={() => setKeephover('')} onClick={() => { setActivityType('url'); setKeephover('url') }}>
                                        <img className="" src={keephover === 'url' ? OnlineHoveron : Online} alt="" />
                                        <span className="ms-5 text-sky-600">Online</span>
                                    </div>
                                    <div className={`hover:font-bold transition-transform transform hover:scale-110 ${keephover === 'image' ? "scale-110 font-bold" : ""} cursor-pointer`} onMouseEnter={() => setKeephover('image')} onMouseLeave={() => setKeephover('')} onClick={() => { setActivityType('image'); setKeephover('image') }}>
                                        <img className="" src={keephover === 'image' ? ImageHoveron : Image} alt="" />
                                        <span className="ms-5 text-sky-600">Image</span>
                                    </div>
                                    <div className={`hover:font-bold transition-transform transform hover:scale-110 ${keephover === 'pdf' ? "scale-110 font-bold" : ""} cursor-pointer`} onMouseEnter={() => setKeephover('pdf')} onMouseLeave={() => setKeephover('')} onClick={() => { setActivityType('pdf'); setKeephover('pdf') }}>
                                        <img className="" src={keephover === 'pdf' ? PDFHoveron : PDF} alt="" />
                                        <span className="ms-6 text-sky-600"> PDF </span>
                                    </div>
                                    <div className={`hover:font-bold transition-transform transform hover:scale-110 ${keephover === 'video' ? "scale-110 font-bold" : ""} cursor-pointer`} onMouseEnter={() => setKeephover('video')} onMouseLeave={() => setKeephover('')} onClick={() => { setActivityType('video'); setKeephover('video') }}>
                                        <img className="" src={keephover === 'video' ? VideoHoveron : Video} alt="" />
                                        <span className="ms-6 text-sky-600">Video</span>
                                    </div>
                                    <div className={`hover:font-bold transition-transform transform hover:scale-110 ${keephover === 'audio' ? "scale-110 font-bold" : ""} cursor-pointer`} onMouseEnter={() => setKeephover('audio')} onMouseLeave={() => setKeephover('')} onClick={() => { setActivityType('audio'); setKeephover('audio') }}>
                                        <img className="" src={keephover === 'audio' ? AudioHoveron : Audio} alt="" />
                                        <span className="ms-6 text-sky-600">Audio</span>
                                    </div>
                                    {/* <Link to='/Dashboard/multiActivities'> */}
                                    <div className='hover:font-bold transition-transform transform hover:scale-110 cursor-pointer' onMouseEnter={() => setKeephover('multiActivity')} onMouseLeave={() => setKeephover('')} onClick={() => { setActivityType('multiActivity'); setKeephover('multiActivity'); HanldeCreateMultiActivity() }}>
                                        <img className="" src={keephover === 'multiActivity' ? MultiActivityHoverOff : MultiActivityHoverOn} alt="" />
                                        <span className=" text-sky-600">Multi Activity</span>
                                    </div>
                                    {/* </Link> */}
                                </div>
                            </div>
                        </div>
                        <div className="flex  justify-center gap-5 pb-5 mt-10">
                            <button onClick={() => navigate(-1)} className="border flex px-7 py-2 rounded BackBtn hover:bg-blue-200"><b>Back</b><img className="mt-1 ms-2" src={BackIcon} alt="back" /></button>
                            <button type='submit' onClick={navigateToContentImport} className='BackBtn flex p-2 px-7 hover:bg-blue-200' ><img className='mr-5 mt-1' src={NextIcon} alt="Next" /><b> Next</b></button>
                        </div>
                    </div>
                </div>

            )}
        </div>
    );
};

export default AddingActivityType;
