import React, { useState, useEffect } from 'react';
import { Drawer, List, ListItem, ListItemText, ListItemIcon, Divider, CssBaseline, AppBar, Toolbar, Typography, Hidden, IconButton, Box } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ReusableListCard from './ReusableListCard';

import SchoolIcon from '@mui/icons-material/School';
import ClassIcon from '@mui/icons-material/Class';
import PeopleIcon from '@mui/icons-material/People';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import axios from 'axios';
import AdminLoginHeading from './LoginHeading/AdminLoginHeading';
import { Link } from 'react-router-dom';
import LoadingComponent from './GlobalLoading/GlobalLoading';
import Joyride from 'react-joyride';
import axiosInstance from '../axios';

const drawerWidth = 240;

const Admin = ({setMode, mode}) => {
    const [mobileOpen, setMobileOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [NetworkResp, setNetworkResp] = useState(false);
    const [RespMessage, setRespMessage] = useState('');
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [data, setData] = useState([]);
    const [classes, setClasses] = useState([]);
    const [instructors, setInstructors] = useState([]);
    const [students, setStudents] = useState([]);
    const [rewards, setRewards] = useState([]);
    const [DeleteId, setDeleteId] = useState('');
    const [NewRecord, setNewRecord] = useState(null);
    var org = localStorage.getItem("organization");
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };
    const className = classes?.find(item => item.class_id == NewRecord?.class_id)?.class_name;

    const getInstructors = async () => {
        setLoading(true);
       const resp = await axiosInstance.get(`${process.env.REACT_APP_ENDPOINT}/instructorInfo/?organization_id=${org}`);
            setInstructors(resp.data);
            setData(resp.data);
            setLoading(false);
    };

    const getClasses = async () => {
        setLoading(true);
        const resp = await axiosInstance.get(`${process.env.REACT_APP_ENDPOINT}/organization/${org}/classes/`);
            setClasses(resp.data.classes);
            setLoading(false);
    };

    const getStudents = async () => {
        setLoading(true);
        const resp = await axiosInstance.get(`${process.env.REACT_APP_ENDPOINT}/instructor/students/${org}`);
            setStudents(resp.data);
            setLoading(false);
    };

    const getRewards = async () => {
        setLoading(true);
        const resp = await axiosInstance.get(`${process.env.REACT_APP_ENDPOINT}/organizations/${org}/rewards`);
            setRewards(resp.data);
            setLoading(false);
    }

    useEffect(() => {
        if(!NetworkResp){

            getClasses();
            getStudents();
            getRewards();
            getInstructors();
            getInstructors();
        }
    }, [NetworkResp]);
    useEffect(() => {
        if (selectedIndex === 0) {
            setData(instructors);
            setDeleteId('instructor_id');
            setOnboarding(instructors.length);
        } else if (selectedIndex === 1) {
            setData(classes);
            setDeleteId('class_id');
            setOnboarding(classes.length);
        } else if (selectedIndex === 2) {
            setData(students);
            setDeleteId('student_id');
            setOnboarding(students.length);
        } else if (selectedIndex === 3) {
            setData(rewards);
            setDeleteId('reward_id');
            setOnboarding(rewards.length);
        }
    }, [selectedIndex, instructors, classes, students, rewards]);

    const handleListItemClick = (event, index) => {
        setSelectedIndex(index);
        setMobileOpen(false);
    };

    // Add/create inst, class, students or reward
    const handleAddItem = async (newItem) => {
        if (Array.isArray(newItem)) {
            const addedItem = newItem[0];
            setData([...data, addedItem]);
        } else {
            setData([...data, newItem]);
        };
        let endpoint = '';
        const class_id = Array.isArray(newItem) ? newItem[0].class_id : 2;
        const admin_id = Array.isArray(newItem) ? newItem[0].admin_id : 2;
        switch (selectedIndex) {
            case 0:
                endpoint = `${process.env.REACT_APP_ENDPOINT}/instructors/`;
                break;
            case 1:
                endpoint = `${process.env.REACT_APP_ENDPOINT}/organizations/${org}/classes/`;
                break;
            case 2:
                endpoint = `${process.env.REACT_APP_ENDPOINT}/organizations/${org}/classes/${class_id}/students/?admin_id=${admin_id}`;
                break;
            case 3:
                endpoint = `${process.env.REACT_APP_ENDPOINT}/organizations/${org}/rewards`;
                break;
            default:
                console.error('Invalid selectedIndex');
                return;
        }

        try {
            const response = await axiosInstance.post(endpoint, newItem)
            if (response.status === 200) {
                if (selectedIndex === 2) {
                    setNewRecord(response.data[0]);
                }
                setRespMessage('Created successfully');
                setNetworkResp(true);
            } else {
                console.log('request status is', response.status);
            }
        } catch (error) {
            console.error('Error adding item:', error);
            setRespMessage('An error occurred while Creating');
            setNetworkResp(true);
        }
        console.log(newItem);
    };

    // update/put inst, class, students or reward
    const handleUpdateItem = async (newItem) => {
        let endpoint;

        switch (selectedIndex) {
            case 0:
                endpoint = `${process.env.REACT_APP_ENDPOINT}/organizations/${org}/instructors/${newItem.instructor_id}`;
                break;
            case 1:
                endpoint = `${process.env.REACT_APP_ENDPOINT}/organizations/${newItem.organization_id}/classes/`;
                break;
            case 2:
                endpoint = `${process.env.REACT_APP_ENDPOINT}/organizations/${newItem.organization_id}/classes/${newItem.class_id}/students/`;
                break;
            case 3:
                endpoint = `${process.env.REACT_APP_ENDPOINT}/organizations/${newItem.organization_id}/rewards`;
                break;
            default:
                console.error("Invalid selectedIndex");
                return;
        }
        try {
            const response = await axiosInstance.put(endpoint, newItem);
            setRespMessage('Updated successfully');
            setNetworkResp(true);
        } catch (error) {
            console.error("Error updating data:", error);
            setRespMessage('An error occurred while Updating');
            setNetworkResp(true);
        }
    };
    // Delete inst, class, students or reward
    const handleDelete = async (id) => {
        const updatedData = data.filter(item => item[DeleteId] !== id);
        setData(updatedData);
        if (selectedIndex === 0) {
            setInstructors(updatedData);
        } else if (selectedIndex === 1) {
            setClasses(updatedData);
        } else if (selectedIndex === 2) {
            setStudents(updatedData);
        } else if (selectedIndex === 3) {
            setRewards(updatedData);
        }
    
        let endpoint = '';
    
        switch (selectedIndex) {
            case 0:
                endpoint = `${process.env.REACT_APP_ENDPOINT}/organizations/${org}/instructors/${id}`;
                break;
            case 1:
                endpoint = `${process.env.REACT_APP_ENDPOINT}/organizations/${org}/classes/?class_id=${id}`;
                break;
            case 2:
                endpoint = `${process.env.REACT_APP_ENDPOINT}/students/${id}`;
                break;
            case 3:
                endpoint = `${process.env.REACT_APP_ENDPOINT}/organizations/1/rewards?reward_id=${id}`;
                break;
            default:
                console.error('Invalid selectedIndex value');
                return;
        }
    
        try {
            const response = await axiosInstance.delete(endpoint);
            if (response.status === 200) {
                console.log('Item successfully deleted');
            } else {
                console.error(`Failed to delete item. Status: ${response.status}`);
            }
        } catch (error) {
            console.error('Error deleting item:', error);
        }
    };    


    const drawer = (
        <div>
            <Box
                component="div"
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: 64,
                    backgroundColor: 'primary.main',
                    color: 'white',
                    fontSize: 20,
                }}
            >
                <img src="/path/to/logo.png" alt="Logo" style={{ height: '100%' }} />
            </Box>
            <Divider />
            <List>
                {[
                    { text: 'Instructors', icon: <SchoolIcon /> },
                    { text: 'Classes', icon: <ClassIcon /> },
                    { text: 'Students', icon: <PeopleIcon /> },
                    // { text: 'Rewards', icon: <EmojiEventsIcon /> }
                ].map((item, index) => (
                    <ListItem id={`step-${index +1}`}
                        button
                        key={item.text}
                        selected={selectedIndex === index}
                        onClick={(event) => handleListItemClick(event, index)}
                    >
                        <ListItemIcon>
                            {item.icon}
                        </ListItemIcon>
                        <ListItemText primary={item.text} />
                    </ListItem>
                ))}
            </List>
        </div>

    );
        // OnBoarding
        const [Onboarding, setOnboarding] = useState(null);
        const steps = Onboarding === 0  ?   [
          {
            content: <h2>this is just for making first step ovelap</h2>,
            placement:'center',
            target:'Bodyjhh',
            title:'Welcome'
          },
          {
            content: <h2>Welcome to the AI ultimate gamification platform.</h2>,
            placement:'center',
            target:'Body',
            title:'Welcome'
          },
          {
            content: <h2>Start by creating a new instructor.</h2>,
            placement:'right',
            target:'#step-1',
            title:'Instructors'
          },
          {
            content: <h2>Then we create a class.</h2>,
            placement:'right',
            target:'#step-2',
            title:'Classes'
          },
          {
            content: <h2>Then we create a student account.</h2>,
            placement:'right',
            target:'#step-3',
            title:'Students'
          },
          {
            content: <h2>Let's start adding a new {selectedIndex === 0 ? 'Instructor' : selectedIndex === 1 ? 'Class' : selectedIndex === 2 ? 'Student' : 'Reward'} click here to start then provide info of new {selectedIndex === 0 ? 'Instructor' : selectedIndex === 1 ? 'Class' : selectedIndex === 2 ? 'Student' : 'Reward'} that you want to add then click to add button and wait while adding completed.</h2>,
            placement:'right',
            target:'#AddNew',
            title: selectedIndex === 0 ? 'Add Instructor' : selectedIndex === 1 ? 'Add Class' : selectedIndex === 2 ? 'Add Student' : 'Add Reward',
          },
          {
            content: <h2>Here you can add students in bulk quantity through uploadeing csv file , click here and see sample of file and then upload.</h2>,
            placement:'right',
            target:'#AddNewCsv',
            title: 'Upload Csv file',
          },
        ] :  [
            {
              content: <h2>Welcome to the AI ultimate gamification platform.</h2>,
              // locale:{skip: <strong>SKIP</strong>},
              placement:'center',
              target:'#step-111',
              title:'Welcome'
            },
            {
              content: <h2>Here you can change info of the {selectedIndex === 0 ? 'Instructor' : selectedIndex === 1 ? 'Class' : selectedIndex === 2 ? 'Student' : 'Reward'}.</h2>,
              placement:'top',
              target:'#Edit',
              title:'Update'
            },
            {
              content: <h2>Click here to delete/remove {selectedIndex === 0 ? 'Instructor' : selectedIndex === 1 ? 'Class' : selectedIndex === 2 ? 'Student' : 'Reward'} from you organization.</h2>,
              placement:'top',
              target:'#Delete',
              title:'Delete'
            },
            {
              content: <h2>Here you can see and add delete also can update Instructor.</h2>,
              placement:'right',
              target:'#step-1',
              title:'Instructors'
            },
            {
              content: <h2>Here you can see and add delete also can update Class.</h2>,
              placement:'right',
              target:'#step-2',
              title:'Classes'
            },
            {
              content: <h2>Here you can see and add delete also can update Student.</h2>,
              placement:'right',
              target:'#step-3',
              title:'Students'
            },
            {
              content: <h2>Let's start adding a new {selectedIndex === 0 ? 'Instructor' : selectedIndex === 1 ? 'Class' : selectedIndex === 2 ? 'Student' : 'Reward'} click here to start then provide info of new {selectedIndex === 0 ? 'Instructor' : selectedIndex === 1 ? 'Class' : selectedIndex === 2 ? 'Student' : 'Reward'} that you want to add then click to add button and wait while adding completed.</h2>,
              placement:'right',
              target:'#AddNew',
              title: selectedIndex === 0 ? 'Add Instructor' : selectedIndex === 1 ? 'Add Class' : selectedIndex === 2 ? 'Add Student' : 'Add Reward',
            },
            {
              content: <h2>Here you can add students in bulk quantity through uploadeing csv file , click here and see sample of file and then upload.</h2>,
              placement:'right',
              target:'#AddNewCsv',
              title: 'Upload Csv file',
            },
          ]
        const [{ run }, setState] = useState({ run: true, });

    return (
        <div style={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar sx={{ bgcolor: "#ebf8ff" }} position="fixed" style={{ zIndex: 1201 }}>
                <Toolbar>
                    <IconButton
                        color="text-gray-700"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap className='text-gray-700 flex'>
                        Gamizign Admin Dashboard
                    <AdminLoginHeading objects={setOnboarding} setMode={setMode} />
                    </Typography>
                </Toolbar>
            </AppBar>
            <Box
                component="nav"
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                aria-label="mailbox folders"
            >
                <Hidden smUp implementation="css">
                    <Drawer
                        variant="temporary"
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                        sx={{
                            display: { xs: 'block', sm: 'none' },
                            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                        }}
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
                <Hidden xsDown implementation="css">
                    <Drawer
                        variant="permanent"
                        sx={{
                            display: { xs: 'none', sm: 'block' },
                            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                        }}
                        open
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
            </Box>


            <main style={{ flexGrow: 1, padding: '80px 24px 24px', width: `calc(100% - ${drawerWidth}px)` }}>
            {!loading && Onboarding <= 1  &&<Joyride callback={() => { }} continuous showProgress
                            run={run} steps={steps} hideCloseButton scrollToFirstStep showSkipButton  
                            styles={{
                                buttonNext: { backgroundColor: '#E5F2E3', borderRadius: '5px',color:'black', },
                                buttonBack: { backgroundColor: '#E5F2E3',  borderRadius: '5px', color:'black', }
                              }} />}
                {loading ?
                    <LoadingComponent/>
                    :
                    <ReusableListCard
                        title={selectedIndex === 0 ? 'Instructor' : selectedIndex === 1 ? 'Class' : selectedIndex === 2 ? 'Student' : 'Reward'}
                        titleCard={selectedIndex === 0 ? 'Instructors' : selectedIndex === 1 ? 'Classes' : selectedIndex === 2 ? 'Students' : 'Rewards'}
                        items={data}
                        onAdd={handleAddItem}
                        selectedIndex={selectedIndex}
                        classes={classes}
                        handleUpdateItem={handleUpdateItem}
                        NetworkResp={NetworkResp}
                        RespMessage={RespMessage}
                        setNetworkResp={setNetworkResp}
                        setRespMessage={setRespMessage}
                        handleDelete={handleDelete}
                        NewRecord={NewRecord}
                        setNewRecord={setNewRecord}
                        className ={className}
                    />
                }
            </main>
        </div>
    );
};

export default Admin;
