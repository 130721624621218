import React, { useEffect, useState } from "react";
import LoginHeading from "../../../../LoginHeading/LoginHeading";
import EditIcon from '../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/edit icon.png'
import cheboxoff from '../../../../../assets/icons/ActivityContentIcons/Insights-page/PPTX check box off.png'
import cheboxoOn from '../../../../../assets/icons/ActivityContentIcons/Insights-page/PPTX check box on.png'
import NextIcon from '../../../../../assets/icons/ActivityContentIcons/Insights-page/Next icon.png'
import BackIcon from '../../../../../assets/icons/ActivityContentIcons/Insights-page/back iconB.png'
import LoadingComponent from "../../../../GlobalLoading/GlobalLoading";
import { FormControl, InputLabel, MenuItem, Select, Skeleton } from "@mui/material";
import MetaTags from "../../../../GlobalLoading/MetaData";
import axiosInstance from "../../../../../axios";

const Insights = () => {

    const [courses, setCourses] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const getCourses = async () => {
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            };

            var instructor_id = parseInt(localStorage.getItem("instructor_id"));

            try {
                setLoading(true);

                const response = await axiosInstance.get(`${process.env.REACT_APP_ENDPOINT}/instructors/${instructor_id}/courses`);

                setCourses(response.data);
                setFilteredCourses(response.data);
                setSelectedCourse(response.data);
                // setLoading(false);
            } catch (error) {
                console.error('Error fetching courses:', error);
                // Handle the error if needed
            } finally {
                setLoading(false);
            }
        };

        getCourses();
    }, []);
    const [selectedCourse, setSelectedCourse] = useState(null);
    // useEffect(() => {
    //     if (courses.length > 0) {
    //       setSelectedCourse(courses[0]);
    //     }
    //   }, [courses])
    const handleCourseChange = (e) => {
        setCurrentPage(1);
        const selectedIndex = parseInt(e.target.value);
        if (!isNaN(selectedIndex) && selectedIndex !== -1) {
            setSelectedCourse(courses[selectedIndex]);
        } else {
            setSelectedCourse(courses);
        }
    };
    // Search Courses
    const [filteredCourses, setFilteredCourses] = useState([]);



    // for seporate activies
    const [activities, setActivities] = useState([])
    const seporateActivities = () => {
        if (selectedCourse) {
            if (Array.isArray(selectedCourse)) {
                const allActivities = selectedCourse.flatMap(course =>
                    course.stages.flatMap(stage =>
                        stage.activities.map(activity => ({ ...activity, stageName: stage.name, courseName: course.name }))
                    )
                );
                setActivities(allActivities);

            }
            else {
                if (selectedCourse) {
                    const allActivities = selectedCourse.stages.flatMap(stage =>
                        // course.stages.flatMap(stage =>
                        stage.activities.map(activity => ({ ...activity, stageName: stage.name, courseName: selectedCourse.name }))
                        // )
                    );
                    setActivities(allActivities);

                };
            }

        };
    };
    useEffect(() => {
        seporateActivities();
    }, [selectedCourse]);
    // Make pigination for activies
    const [currentPage, setCurrentPage] = useState(1);
    const rowsPerPage = 5;

    const indexOfLastActivity = currentPage * rowsPerPage;
    const indexOfFirstActivity = indexOfLastActivity - rowsPerPage;
    const ActivityPigination = activities.slice(indexOfFirstActivity, indexOfLastActivity);

    const totalPages = Math.ceil(activities.length / rowsPerPage);

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };
    // Calculate cards' values
    let totalStudents = 0;
    let totalScore = 0;
    let totalSessions = 0;
    let CompleteSessions = 0;
    let correctAnswers = 0;
    let incorrectAnswers = 0;
    let allPlayers = 0;
    let totalTime = 0;

    const uniqueStudentIds = new Set();

    if (selectedCourse) {
        if (Array.isArray(selectedCourse)) {
            selectedCourse.forEach(course => {
                totalStudents += course.students.length;
                if (course.stages) {
                    course.stages.forEach(stage => {
                        stage.activities.forEach(activity => {
                            activity.analyticsList.forEach(analytics => {
                                if (!uniqueStudentIds.has(analytics.student_id)) {
                                    uniqueStudentIds.add(analytics.student_id);
                                    allPlayers++;
                                }
                                totalScore += analytics.score;
                                totalSessions += analytics.sessions;
                                CompleteSessions += analytics.gamesWon;
                                correctAnswers += analytics.correctAnswers;
                                incorrectAnswers += analytics.incorrectAnswers;
                                totalTime += analytics.totalTime;
                            });
                        });
                    });
                }
            });
        } else {
            totalStudents += selectedCourse.students.length;
            selectedCourse.stages.forEach(stage => {
                stage.activities.forEach(activity => {
                    activity.analyticsList.forEach(analytics => {
                        if (!uniqueStudentIds.has(analytics.student_id)) {
                            uniqueStudentIds.add(analytics.student_id);
                            allPlayers++;
                        }
                        totalScore += analytics.score;
                        totalSessions += analytics.sessions;
                        CompleteSessions += analytics.gamesWon;
                        correctAnswers += analytics.correctAnswers;
                        incorrectAnswers += analytics.incorrectAnswers;
                        totalTime += analytics.totalTime;
                    });
                });
            });
        }
    }

    // Formate time duration for avrage time
    function formatTime(seconds) {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = seconds % 60;

        return `${String(hours).padStart(2, '0')}h:${String(minutes).padStart(2, '0')}m:${String(
            Math.floor(remainingSeconds)
        ).padStart(2, '0')}s`;
    }

    const [cards, setCards] = useState(false)
    const [avgScore, setAvgScore] = useState(cheboxoOn)
    const [totalSession, setTotalSession] = useState(cheboxoOn)
    const [completSessions, setCompletSessions] = useState(cheboxoOn)
    const [avgDuration, setAvgDuration] = useState(cheboxoff)
    const [accuracy, setAccuracy] = useState(cheboxoff)
    const [scoreToplayers, setScoreToplayers] = useState(cheboxoff)
    const [completSessionToPlaters, setCompletSessionToPlaters] = useState(cheboxoff)
    const [durationToPlayers, setDurationToPlayers] = useState(cheboxoff)
    const [accuracyToPlayers, setAccuracyToPlayers] = useState(cheboxoff)
    return (
        <div className='w-full'>
            <MetaTags title='Insights' description='Insights page--' />
            <div className='flex relative px-5 py-2 mb-2 ContentHeadingBg'>
                <h3 className="text-xl">Insights</h3>
                <LoginHeading />
            </div>
            {/* {loading ? ( */}
            {/* <LoadingComponent/> */}
            {/* // ) : ( */}
            <div className="m-7 bg-white  border-2">
                <div className="sm:flex bg-gray-100 p-5 border-b-2 relative">
                    <p className="flex justify-center sm:flex-none my-2 sm:my-0">Courses Trends</p>
                    <div className="md:absolute inset-x-72 sm:gap-5 sm:ms-3">
                        {loading ? <Skeleton className="border" animation='wave' variant="rounded" width={250} height={30} /> :
                            <FormControl
                                fullWidth
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            border: '1px solid #CCCCCC',
                                        },
                                        '&.Mui-focused fieldset': {
                                            border: '2px solid #DBE6F5',
                                        },
                                        '&:hover fieldset': {
                                            border: '2px solid #DBE6F5',
                                        },
                                    },
                                }}>
                                <InputLabel>Courses</InputLabel>
                                {courses && Array.isArray(courses) && courses.length > 0 ? (<Select
                                    labelId="Courses"
                                    name="Courses"
                                    label="Courses"
                                    onChange={(e) => { handleCourseChange(e) }}
                                    value={courses.indexOf(selectedCourse)}
                                    style={{ borderRadius: '10px', color: '#2E4EB5', background: 'white', height: '40px', }}
                                >
                                    <MenuItem selected value={-1}>All Courses</MenuItem>
                                    {courses.map((course, index) => (
                                        <MenuItem key={index} value={index}>
                                            {course.name}
                                        </MenuItem>
                                    ))}
                                </Select>) : (<p>No Course available</p>)}
                            </FormControl>}
                    </div>
                    <div className="sm:absolute right-5 flex justify-center">
                        {/* <img src="" alt="download" /> */}
                        {loading ? <Skeleton className="border" animation='wave' variant="circular" width={30} height={30} /> :
                            <div className="relative" onMouseEnter={() => setCards(true)} onMouseLeave={() => setCards(false)}>
                                {/* Button with dropdown toggle */}
                                <button
                                    className="flex items-center justify-center w-10 h-10 -mt-1 hover:bg-gray-300 rounded-full focus:outline-none"

                                >
                                    <svg className="w-2.5 h-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4" />
                                    </svg>
                                </button>

                                {/* Dropdown menu */}
                                {cards && (
                                    <div className="sm:absolute right-0 z-10 w-64 py-2 bg-white border border-gray-200 rounded shadow-lg top-full">
                                        {/* Option 1 */}
                                        <button className="flex items-center w-full px-4 py-1 hover:bg-gray-100 focus:outline-none">
                                            <img width={20} src={avgScore} alt="" onClick={() => setAvgScore((prevImage) => (prevImage === cheboxoOn ? cheboxoff : cheboxoOn))} />
                                            Avg Score
                                        </button>

                                        {/* Option 2 */}
                                        <button className="flex items-center w-full px-4 py-1 hover:bg-gray-100 focus:outline-none">
                                            <img width={20} src={totalSession} alt="" onClick={() => setTotalSession((prevImage) => (prevImage === cheboxoOn ? cheboxoff : cheboxoOn))} />
                                            Total Sessions
                                        </button>

                                        {/* Option 3 */}
                                        <button className="flex items-center w-full px-4 py-1 hover:bg-gray-100 focus:outline-none">
                                            <img width={20} src={completSessions} alt="" onClick={() => setCompletSessions((prevImage) => (prevImage === cheboxoOn ? cheboxoff : cheboxoOn))} />
                                            Complete Sessions
                                        </button>
                                        {/* Option 4 */}
                                        <button className="flex items-center w-full px-4 py-1 hover:bg-gray-100 focus:outline-none">
                                            <img width={20} src={avgDuration} alt="" onClick={() => setAvgDuration((prevImage) => (prevImage === cheboxoOn ? cheboxoff : cheboxoOn))} />
                                            Avg.Duration
                                        </button>
                                        {/* Option 5 */}
                                        <button className="flex items-center w-full px-4 py-1 hover:bg-gray-100 focus:outline-none">
                                            <img width={20} src={accuracy} alt="" onClick={() => setAccuracy((prevImage) => (prevImage === cheboxoOn ? cheboxoff : cheboxoOn))} />
                                            Accuracy
                                        </button>
                                        {/* Option 6 */}
                                        <button className="flex items-center w-full px-4 py-1 hover:bg-gray-100 focus:outline-none">
                                            <img width={20} src={scoreToplayers} alt="" onClick={() => setScoreToplayers((prevImage) => (prevImage === cheboxoOn ? cheboxoff : cheboxoOn))} />
                                            Score to Players Ratio
                                        </button>
                                        {/* Option 7 */}
                                        <button className="flex  w-full px-4 py-1 hover:bg-gray-100 focus:outline-none">
                                            <img width={20} src={completSessionToPlaters} alt="" onClick={() => setCompletSessionToPlaters((prevImage) => (prevImage === cheboxoOn ? cheboxoff : cheboxoOn))} />
                                            Complete Sessions to Players Ratio
                                        </button>
                                        {/* Option 8 */}
                                        <button className="flex items-center w-full px-4 py-1 hover:bg-gray-100 focus:outline-none">
                                            <img width={20} src={durationToPlayers} alt="" onClick={() => setDurationToPlayers((prevImage) => (prevImage === cheboxoOn ? cheboxoff : cheboxoOn))} />
                                            Duration to Playres Ratio
                                        </button>
                                        {/* Option 9 */}
                                        <button className="flex items-center w-full px-4 py-1 hover:bg-gray-100 focus:outline-none">
                                            <img width={20} src={accuracyToPlayers} alt="" onClick={() => setAccuracyToPlayers((prevImage) => (prevImage === cheboxoOn ? cheboxoff : cheboxoOn))} />
                                            Accuracy to Players Ratio
                                        </button>
                                    </div>
                                )}
                            </div>}
                    </div>
                </div>
                {/* Course OverView */}
                {loading ? <div className="flex justify-center flex-wrap gap-4 px-5 mt-5">
                    <Skeleton className="border" animation='wave' variant="rounded" width={250} height={95} />
                    <Skeleton className="border" animation='wave' variant="rounded" width={250} height={95} />
                    <Skeleton className="border" animation='wave' variant="rounded" width={250} height={95} />
                    <Skeleton className="border" animation='wave' variant="rounded" width={250} height={95} />
                </div> :
                    <div className={`flex justify-center flex-wrap gap-4 px-5 mt-5 ${totalSessions === 0 ? "hidden" : ""}`}>
                        {/* <div className={`grid grid-cols-4 gap-4 px-5 mt-5 ${Array.isArray(selectedCourse) || (selectedCourse && selectedCourse.students && selectedCourse.students.length === 0) ? "hidden" : ""}`}> */}
                        <div className="max-w-sm w-64 rounded overflow-hidden shadow-lg" style={{ backgroundColor: '#ACCCE5' }}>
                            <div className="px-4 py-4">
                                <div className="font-bold text-xl mb-1 flex justify-center">{allPlayers}</div>
                                <p className="text-gray-700 text-base flex justify-center">
                                    Players
                                </p>
                            </div>
                        </div>
                        <div className={`max-w-sm w-64 rounded overflow-hidden shadow-lg ${avgScore === cheboxoff ? "hidden" : ""}`} style={{ backgroundColor: '#BCE2BB' }}>
                            <div className="px-4 py-4">
                                <div className="font-bold text-xl mb-1 flex justify-center">{(totalScore / totalSessions).toFixed(2)}</div>
                                <p className="text-gray-700 text-base flex justify-center">
                                    Avg Score
                                </p>
                            </div>
                        </div>
                        <div className={`max-w-sm w-64 rounded overflow-hidden shadow-lg ${totalSession === cheboxoff ? "hidden" : ""}`} style={{ backgroundColor: '#FFF3C6' }}>
                            <div className="px-4 py-4">
                                <div className="font-bold text-xl mb-1 flex justify-center">{totalSessions}</div>
                                <p className="text-gray-700 text-base flex justify-center">
                                    Total Sessions
                                </p>
                            </div>
                        </div>
                        <div className={`max-w-sm w-64 rounded overflow-hidden shadow-lg ${completSessions === cheboxoff ? "hidden" : ""}`} style={{ backgroundColor: '#F6C1C1' }}>
                            <div className="px-4 py-4">
                                <div className="font-bold text-xl mb-1 flex justify-center">{CompleteSessions}</div>
                                <p className="text-gray-700 text-base flex justify-center">
                                    Complete Sessions
                                </p>
                            </div>
                        </div>
                        <div className={`max-w-sm w-64 rounded overflow-hidden shadow-lg ${avgDuration === cheboxoff ? "hidden" : ""}`} style={{ backgroundColor: '#DFBFDD' }}>
                            <div className="px-4 py-4">
                                <div className="font-bold text-xl mb-1 flex justify-center">{formatTime(totalTime / totalSessions)}</div>
                                <p className="text-gray-700 text-base flex justify-center">
                                    Avg. Duration
                                </p>
                            </div>
                        </div>
                        <div className={`max-w-sm w-64 rounded overflow-hidden shadow-lg ${accuracy === cheboxoff ? "hidden" : ""}`} style={{ backgroundColor: '#ACCCE5' }}>
                            <div className="px-4 py-4 ">
                                <div className="font-bold text-xl mb-1 flex justify-center">{correctAnswers > 0 ? (((correctAnswers / (correctAnswers + incorrectAnswers)) * 100).toFixed(2)) : 0.0}%</div>
                                <p className="text-gray-700 text-base flex justify-center">
                                    Accuracy
                                </p>
                            </div>
                        </div>
                        {/* Default hidden cards */}
                        <div className={`max-w-sm w-64 rounded overflow-hidden shadow-lg ${scoreToplayers === cheboxoff ? "hidden" : ""}`} style={{ backgroundColor: '#BCE2BB' }}>
                            <div className="px-4 py-4 ">
                                <div className="font-bold text-xl mb-1 flex justify-center">{(totalScore / allPlayers).toFixed(2)}</div>
                                <p className="text-gray-700 text-base flex justify-center">
                                    Scoretoplayer
                                </p>
                            </div>
                        </div>
                        <div className={`max-w-sm w-64 rounded overflow-hidden shadow-lg ${completSessionToPlaters === cheboxoff ? "hidden" : ""}`} style={{ backgroundColor: '#FFF3C6' }}>
                            <div className="px-4 py-4 ">
                                <div className="font-bold text-xl mb-1 flex justify-center">{(totalSessions / allPlayers).toFixed(2)}</div>
                                <p className="text-gray-700 text-base flex justify-center">
                                    Sessionstoplayer
                                </p>
                            </div>
                        </div>
                        <div className={`max-w-sm w-64 rounded overflow-hidden shadow-lg ${durationToPlayers === cheboxoff ? "hidden" : ""}`} style={{ backgroundColor: '#F6C1C1' }}>
                            <div className="px-4 py-4 ">
                                <div className="font-bold text-xl mb-1 flex justify-center">{formatTime((totalTime / allPlayers) / allPlayers)}</div>
                                <p className="text-gray-700 text-base flex justify-center">
                                    Durationtoplayer
                                </p>
                            </div>
                        </div>
                        <div className={`max-w-sm w-64 rounded overflow-hidden shadow-lg ${accuracyToPlayers === cheboxoff ? "hidden" : ""}`} style={{ backgroundColor: '#DFBFDD' }}>
                            <div className="px-4 py-4 ">
                                <div className="font-bold text-xl mb-1 flex justify-center">{correctAnswers > 0 ? (((correctAnswers / (correctAnswers + incorrectAnswers)) * 100) / allPlayers).toFixed(2) : 0.0}%</div>
                                <p className="text-gray-700 text-base flex justify-center">
                                    Accuracytoplayer
                                </p>
                            </div>
                        </div>
                    </div>}
                {/* Activities of Course*/}
                <div className="p-5 border-y bg-gray-100 mt-10">
                    <h1>Activities Analysis</h1>
                </div>
                <div class="relative px-5 mt-5 overflow-x-auto max-w-80 sm:max-w-full">
                    <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                        <thead class="text-gray-700 px-5 uppercase dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                <th scope="col" class="px-3 py-3">
                                    Name
                                </th>
                                <th scope="col" class="px-3 py-3">
                                    Course
                                </th>
                                <th scope="col" class="px-3 py-3">
                                    Activity Type
                                </th>
                                <th scope="col" class="px-3 py-3">
                                    Sessions
                                </th>
                                <th scope="col" class="px-3 py-3">
                                    Players
                                </th>
                                <th scope="col" class="px-3 py-3">
                                    Accuracy
                                </th>
                                <th scope="col" class="px-3 py-3">
                                    Created By
                                </th>
                                <th scope="col" class="px-3 py-3">
                                    Date Created
                                </th>
                                <th scope="col" class="px-3 py-3">
                                    Last Modified
                                </th>
                            </tr>
                        </thead>
                        {loading ?
                            <tbody>
                                <tr className='border-b'>
                                    <th scope="col" className="px-6 py-3"><Skeleton animation='wave' variant="rounded" width={100} height={40} /></th>
                                    <th scope="col" className="px-6 py-3"><Skeleton animation='wave' variant="rounded" width={100} height={40} /></th>
                                    <th scope="col" className="px-6 py-3"><Skeleton animation='wave' variant="rounded" width={40} height={40} /></th>
                                    <th scope="col" className="px-6 py-3"><Skeleton animation='wave' variant="rounded" width={40} height={40} /></th>
                                    <th scope="col" className="px-6 py-3"><Skeleton animation='wave' variant="rounded" width={40} height={40} /></th>
                                    <th scope="col" className="px-6 py-3"><Skeleton animation='wave' variant="rounded" width={40} height={40} /></th>
                                    <th scope="col" className="px-6 py-3"><Skeleton animation='wave' variant="rounded" width={40} height={40} /></th>
                                    <th scope="col" className="px-6 py-3"><Skeleton animation='wave' variant="rounded" width={100} height={40} /></th>
                                    <th scope="col" className="px-6 py-3"><Skeleton animation='wave' variant="rounded" width={100} height={40} /></th>
                                </tr>
                                <tr className='border-b'>
                                    <th scope="col" className="px-6 py-3"><Skeleton animation='wave' variant="rounded" width={100} height={40} /></th>
                                    <th scope="col" className="px-6 py-3"><Skeleton animation='wave' variant="rounded" width={100} height={40} /></th>
                                    <th scope="col" className="px-6 py-3"><Skeleton animation='wave' variant="rounded" width={40} height={40} /></th>
                                    <th scope="col" className="px-6 py-3"><Skeleton animation='wave' variant="rounded" width={40} height={40} /></th>
                                    <th scope="col" className="px-6 py-3"><Skeleton animation='wave' variant="rounded" width={40} height={40} /></th>
                                    <th scope="col" className="px-6 py-3"><Skeleton animation='wave' variant="rounded" width={40} height={40} /></th>
                                    <th scope="col" className="px-6 py-3"><Skeleton animation='wave' variant="rounded" width={40} height={40} /></th>
                                    <th scope="col" className="px-6 py-3"><Skeleton animation='wave' variant="rounded" width={100} height={40} /></th>
                                    <th scope="col" className="px-6 py-3"><Skeleton animation='wave' variant="rounded" width={100} height={40} /></th>
                                </tr>
                                <tr className='border-b'>
                                    <th scope="col" className="px-6 py-3"><Skeleton animation='wave' variant="rounded" width={100} height={40} /></th>
                                    <th scope="col" className="px-6 py-3"><Skeleton animation='wave' variant="rounded" width={100} height={40} /></th>
                                    <th scope="col" className="px-6 py-3"><Skeleton animation='wave' variant="rounded" width={40} height={40} /></th>
                                    <th scope="col" className="px-6 py-3"><Skeleton animation='wave' variant="rounded" width={40} height={40} /></th>
                                    <th scope="col" className="px-6 py-3"><Skeleton animation='wave' variant="rounded" width={40} height={40} /></th>
                                    <th scope="col" className="px-6 py-3"><Skeleton animation='wave' variant="rounded" width={40} height={40} /></th>
                                    <th scope="col" className="px-6 py-3"><Skeleton animation='wave' variant="rounded" width={40} height={40} /></th>
                                    <th scope="col" className="px-6 py-3"><Skeleton animation='wave' variant="rounded" width={100} height={40} /></th>
                                    <th scope="col" className="px-6 py-3"><Skeleton animation='wave' variant="rounded" width={100} height={40} /></th>
                                </tr>
                                <tr className='border-b'>
                                    <th scope="col" className="px-6 py-3"><Skeleton animation='wave' variant="rounded" width={100} height={40} /></th>
                                    <th scope="col" className="px-6 py-3"><Skeleton animation='wave' variant="rounded" width={100} height={40} /></th>
                                    <th scope="col" className="px-6 py-3"><Skeleton animation='wave' variant="rounded" width={40} height={40} /></th>
                                    <th scope="col" className="px-6 py-3"><Skeleton animation='wave' variant="rounded" width={40} height={40} /></th>
                                    <th scope="col" className="px-6 py-3"><Skeleton animation='wave' variant="rounded" width={40} height={40} /></th>
                                    <th scope="col" className="px-6 py-3"><Skeleton animation='wave' variant="rounded" width={40} height={40} /></th>
                                    <th scope="col" className="px-6 py-3"><Skeleton animation='wave' variant="rounded" width={40} height={40} /></th>
                                    <th scope="col" className="px-6 py-3"><Skeleton animation='wave' variant="rounded" width={100} height={40} /></th>
                                    <th scope="col" className="px-6 py-3"><Skeleton animation='wave' variant="rounded" width={100} height={40} /></th>
                                </tr>
                                <tr className='border-b'>
                                    <th scope="col" className="px-6 py-3"><Skeleton animation='wave' variant="rounded" width={100} height={40} /></th>
                                    <th scope="col" className="px-6 py-3"><Skeleton animation='wave' variant="rounded" width={100} height={40} /></th>
                                    <th scope="col" className="px-6 py-3"><Skeleton animation='wave' variant="rounded" width={40} height={40} /></th>
                                    <th scope="col" className="px-6 py-3"><Skeleton animation='wave' variant="rounded" width={40} height={40} /></th>
                                    <th scope="col" className="px-6 py-3"><Skeleton animation='wave' variant="rounded" width={40} height={40} /></th>
                                    <th scope="col" className="px-6 py-3"><Skeleton animation='wave' variant="rounded" width={40} height={40} /></th>
                                    <th scope="col" className="px-6 py-3"><Skeleton animation='wave' variant="rounded" width={40} height={40} /></th>
                                    <th scope="col" className="px-6 py-3"><Skeleton animation='wave' variant="rounded" width={100} height={40} /></th>
                                    <th scope="col" className="px-6 py-3"><Skeleton animation='wave' variant="rounded" width={100} height={40} /></th>
                                </tr>
                            </tbody> :
                            <tbody>

                                {ActivityPigination && Array.isArray(ActivityPigination) ?
                                    ActivityPigination.map((activity, index) => (
                                        <tr class="even:bg-white px-5 even:dark:bg-gray-900 odd:bg-gray-50 odd:dark:bg-gray-800 border-b dark:border-gray-700">
                                            <th scope="row" class="px-3 py-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                {activity.name}
                                            </th>
                                            <td class="px-3 py-6">
                                                {activity.courseName}
                                            </td>
                                            <td class="px-3 py-6">
                                                {activity.activityType}
                                            </td>
                                            <td class="px-3 py-6">
                                                {activity.analyticsList.map(analytics => analytics.sessions).reduce((acc, val) => acc + val, 0)}
                                            </td>
                                            <td class="px-3 py-6">
                                                {activity.analyticsList.length}
                                            </td>
                                            <td class="px-3 py-6">
                                                {(activity.analyticsList.length > 0 && activity.analyticsList.some(analytics => analytics.correctAnswers !== undefined && analytics.correctAnswers > 0)) ?
                                                    ((activity.analyticsList.map(analytics => analytics.correctAnswers).reduce((acc, val) => acc + val, 0) /
                                                        (activity.analyticsList.map(analytics => analytics.incorrectAnswers).reduce((acc, val) => acc + val, 0) + activity.analyticsList.map(analytics => analytics.correctAnswers).reduce((acc, val) => acc + val, 0))
                                                        * 100).toFixed(2)) + "%"
                                                    : "0.00%"
                                                }
                                            </td>
                                            <td class="px-3 py-6">
                                                {activity.createdBy}
                                            </td>
                                            <td class="px-3 py-6">
                                                {activity.dateCreated}
                                            </td>
                                            <td class="px-3 py-6">
                                                {activity.dateCreated}
                                            </td>
                                        </tr>
                                    ))

                                    : (<div className="text-gray-500">No Activity available.</div>)}

                            </tbody>}
                    </table>
                </div>
                {loading ?
                    <div className="flex justify-center gap-5 mt-4">
                        <Skeleton animation='wave' variant="rounded" width={90} height={40} />
                        <Skeleton animation='wave' variant="rounded" width={120} height={40} />
                        <Skeleton animation='wave' variant="rounded" width={90} height={40} />
                    </div> :
                    <div className="flex justify-center gap-5 pb-5 mt-4">
                        <button onClick={handlePrevPage} className="px-4 py-2 BackBtn hover:bg-blue-200 rounded disabled:opacity-50 flex" disabled={currentPage === 1}>
                            <img className="mt-1 mr-2" src={BackIcon} alt="" /> Prev
                        </button>
                        <span className="px-4 py-2">{currentPage} of {totalPages}</span>
                        <button onClick={handleNextPage} className="px-4 py-2 BackBtn hover:bg-blue-200 rounded disabled:opacity-50 flex" disabled={currentPage === totalPages}>
                            Next <img className="mt-1 ms-2" src={NextIcon} alt="" />
                        </button>
                    </div>}
            </div>

        </div>
    )
};

export default Insights;