import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import QListDashboardIcon from '../../../../../assets/icons/ActivityContentIcons/QuestionListsPatch5/Question Lists dashboard icon.png'
import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import LoginHeading from "../../../../LoginHeading/LoginHeading";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PdfImage from '../../../../../assets/icons/ActivityContentIcons//Gamizign_Courses/PDF icon hover off color.png';
import GameImage from '../../../../../assets/icons/ActivityContentIcons//Gamizign_Courses/game icon hover off color.png';
import VideoImage from '../../../../../assets/icons/ActivityContentIcons//Gamizign_Courses/video icon hover off color.png';
import ImageImage from '../../../../../assets/icons/ActivityContentIcons//Gamizign_Courses/image icon hover off color.png';
import AudioImage from '../../../../../assets/icons/ActivityContentIcons//Gamizign_Courses/Audio icon hover off color.png';
import SaveIcon from '../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/ViewCourse/save icon.png';
import UrlImage from '../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/HomeWork/URL icon hover off color.png'
import MultiActivityImage from '../../../../../assets/icons/ActivityContentIcons/NewCourse/Patch3/Activity-Type/multiactivity  icon hover off.png'
import LoadingComponent from "../../../../GlobalLoading/GlobalLoading";
import MetaTags from "../../../../GlobalLoading/MetaData";
import axiosInstance from "../../../../../axios";


const EditReferencedQList = () => {
    const [activities, setActivities] = useState([]);
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const queryParams = new URLSearchParams(location.search);
    const questionList_id = queryParams.get('QuestionListId');
    const [questionsLists, setQuestionsLists] = useState([]);
    const [SinglequestionsList, setSinglequestionsList] = useState(null);
    const [QListID, setQListID] = useState(null);

    const StageColors = ['#ACCCE5', '#BCE2BB', '#DDE6C0', '#FFF3C6', '#FEE7C3', '#FCDCBD', '#F6C1C1', '#DFBFDD'];
    const ActivityColors = ['#DBE6F5', '#E5F2E3', '#F5F9E6', '#FEFAE2', '#FEF7E2', '#FEF1DD', '#FDE4DC', '#EFE1EE'];


    useEffect(() => {
        const getQuestionsLists = async () => {
            var instructor_id = parseInt(localStorage.getItem("instructor_id"));
            try {
                const responseQlist = await axiosInstance.get(`${process.env.REACT_APP_ENDPOINT}/instructors/${instructor_id}/question-lists`);
                setQuestionsLists(responseQlist.data);
            }
            catch (error) {
                console.log("Error getting questions lists");
            }
        }
        const getActivities = async () => {
            setLoading(true);
            try {
                const responseAcrivities = await axiosInstance.get(`${process.env.REACT_APP_ENDPOINT}/activities/questionsList/${questionList_id}`);
                    setLoading(false);
                    setActivities(responseAcrivities.data);
            }
            catch (error) {
                console.error('Error fetching activities:', error);

            }

        }
        const getSingleQuestionsList = async () => {
            try {
                const responseQlist = await axiosInstance.get(`${process.env.REACT_APP_ENDPOINT}/question-lists/${questionList_id}`);
                setSinglequestionsList(responseQlist.data);
            }
            catch (error) {
                console.log("Error getting questions lists");
            }
        }
        getActivities();
        getQuestionsLists();
        getSingleQuestionsList();
    }, []);
    const navigate = useNavigate();
    const changeQuestionList = async (activity, activity_id) => {
        try {
            const response = await axiosInstance.post(`${process.env.REACT_APP_ENDPOINT}/activity/${activity.activity_id}`,activity);
            if (response.status === 200) {
                if (activities.length > 1) {
                    const updatesActivities = activities.filter(activity => activity.activity_id !== activity_id);
                    setActivities(updatesActivities);
                } else {
                    navigate(`/Dashboard/questionList`);
                }
            } else {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };
    // Actvity type icon
    const ActivityType = (type) => {
        switch (type) {
            case 'pdf':
                return PdfImage;
            case 'game':
                return GameImage;
            case 'image':
                return ImageImage;
            case 'audio':
                return AudioImage;
            case 'video':
                return VideoImage;
            case 'url':
                return UrlImage;
            case 'multiActivity':
                return MultiActivityImage;
            default:
                return PdfImage;
        }
    };

    return (
        <div className='w-full'>
            <MetaTags title='Change Questions List' description='Gamizign Change QuestionS List page--' />
            <div className='flex relative px-5 py-2 mb-2 ContentHeadingBg'>
                <h3 className="text-xl">Question Lists</h3>
                <LoginHeading />
            </div>
            <div className="m-7 bg-white">
                <div className='flex justify-center static'><img className='absolute top-6' src={QListDashboardIcon} alt="" /></div>
                <div className='h-10'>
                </div>
                {loading ? (
                    <LoadingComponent />
                ) : (
                    <div className="mx-3 my-5">
                        <div >
                            <div className="sm:mx-20 mx-3 py-5">
                                <Accordion className="grow" defaultExpanded>
                                    <div style={{ backgroundColor: '#ACCCE5' }}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel3-content"
                                            id="panel3-header"
                                            sx={{ justifyItems: 'center', display: 'flex' }}
                                        >
                                            <Typography sx={{ fontSize: '20px' }}>{SinglequestionsList?.name}</Typography>
                                        </AccordionSummary>
                                    </div>
                                    {/* Activities Of stage */}
                                    <div>
                                        <AccordionDetails>
                                            {activities.length > 0 ? (
                                                activities.map((activity, activityIndex) => (
                                                    <div className=" -mt-4 py-2 w-full ms-3.5" key={activityIndex}>
                                                        <Accordion className="grow">
                                                            <div className="mb-0.5" style={{ backgroundColor: ActivityColors[activityIndex % ActivityColors.length] }}>
                                                                <AccordionSummary
                                                                    expandIcon={<ExpandMoreIcon />}
                                                                    aria-controls="panel1-content"
                                                                    id="panel1-header"
                                                                >
                                                                    <div className="flex relative w-full gap-5">
                                                                        <span><img width={30} src={ActivityType(activity.activityType)} alt="" /></span>
                                                                        <Typography sx={{ color: 'text.secondary', width: '20%' }}>{activity.name}</Typography>
                                                                    </div>
                                                                </AccordionSummary>
                                                            </div>
                                                            <AccordionDetails>
                                                                <div>
                                                                    <FormControl margin="dense" fullWidth
                                                                        sx={{
                                                                            '& .MuiOutlinedInput-root': {
                                                                                '& fieldset': {
                                                                                    border: '2px solid #F3F6F6',
                                                                                },
                                                                                '&.Mui-focused fieldset': {
                                                                                    border: '2px solid #DBE6F5',
                                                                                },
                                                                                '&:hover fieldset': {
                                                                                    border: '2px solid #DBE6F5',
                                                                                },
                                                                            },
                                                                        }}>
                                                                        <InputLabel>Questions list</InputLabel>
                                                                        <Select
                                                                            labelId="Questions list"
                                                                            name="Questions list"
                                                                            label="Questions list"
                                                                            value={activity.questionList_id}
                                                                            onChange={(e) => { setQListID(e.target.value); activity.questionList_id = e.target.value }}
                                                                            style={{ borderRadius: '10px', color: '#2E4EB5', background: '#F3F6F6' }}
                                                                        >
                                                                            {questionsLists.map((questionsList, index) => (
                                                                                <MenuItem sx={{ display: `${questionsList.mainTopic === SinglequestionsList.mainTopic ? '' : 'none'}` }}
                                                                                  key={questionsList} value={questionsList.questionList_id}>
                                                                                    {questionsList.name}
                                                                                </MenuItem>
                                                                            ))}
                                                                        </Select>
                                                                    </FormControl>
                                                                    <div className="flex justify-center mt-10">
                                                                        <button onClick={() => changeQuestionList(activity, activity.activity_id)} className='BackBtn flex p-2 px-7 hover:bg-blue-200'><b> Save Activity </b><img className='ml-5 mt-1' src={SaveIcon} alt="Back" /></button>
                                                                    </div>
                                                                </div>
                                                            </AccordionDetails>
                                                        </Accordion>
                                                    </div>
                                                ))
                                            ) : (<p className='flex justify-center'>No activity in  <b> {SinglequestionsList?.name} </b>  question list</p>)
                                            }
                                        </AccordionDetails>
                                    </div>
                                    <AccordionActions>
                                    </AccordionActions>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                )
                }
            </div>
        </div>
    );
};

export default EditReferencedQList;