import React from "react";
import {Navigate, Outlet  } from "react-router-dom";

const AdminRouteProtection = () =>{
    const admin_id = localStorage.getItem("admin_id");
    return(
        
        admin_id?
                <Outlet/>
                :
                <Navigate to='/'/>

    )
};
export default AdminRouteProtection;