import React, { useEffect } from "react";
import { Tooltip } from "@mui/material";
import CourseLock from '../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/course locked icon.png'
import CourseUnLock from '../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/course open icon.png'
import CourseActive from '../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/course active icon.png'
import CourseNotActive from '../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/course not active icon.png'
import LearnerTotal from '../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/total learners number icon.png'
import LearnerDone from '../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/learners done number icon.png'
import DeletQuestoin from '../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/delete icon.png'
import CourseDoneIcon from '../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/course done icon.png'

const GroupActivities = ({ activities, DeleteDialogOpen, setActivity_id, setActivityName, ActivityType }) => {
    const ActivityCourseColors = ['#DBE6F5', '#E5F2E3', '#F5F9E6', '#FEFAE2', '#FEF7E2', '#FEF1DD', '#FDE4DC', '#EFE1EE'];
    // Initialize arrays for each mainTopic
    const languageActivities = [];
    const Unknown = [];
    const mathActivities = [];
    const scienceActivities = [];
    const socialActivities = [];    

    // Iterate through filteredCourses and sort into respective arrays
    if (activities && activities.length > 0) {
        activities.forEach(activity => {
            switch (activity.mainTopic) {
                case 'Language Arts':
                    languageActivities.push(activity);
                    break;
                case 'Math':
                    mathActivities.push(activity);
                    break;
                case 'Science':
                    scienceActivities.push(activity);
                    break;
                case 'Social':
                    socialActivities.push(activity);
                    break;
                default:
                    Unknown.push(activity);
                    // Handle case if mainTopic does not match any expected value
                    console.warn(`Unexpected mainTopic: ${activity.mainTopic}`);
                    break;
            }
        });
    }
    return (
        <div>
            {/* Language Arts/English Courses' Activities */}
            <div>
                <p className="border-b-2 mx-7 px-2 text-2xl flex justify-center sm:justify-start font-bold">Language arts</p>
                <div className="grid grid-cols-1 px-5 sm:grid sm:grid-cols-2 md:grid-cols-3  sm:px-5 xl:grid-cols-5 gap-5 2xl:px-40 mt-5">
                    {languageActivities.length > 0 ? (languageActivities.map((activity, activityIndex) => (
                        <div key={activityIndex}>
                            <div className="border relative" style={{ borderRadius: '20px', height: "250px", backgroundColor: ActivityCourseColors[activityIndex % ActivityCourseColors.length] }}>
                                <div className="" style={{
                                    backgroundImage: activity.background ? `url(${process.env.REACT_APP_ENDPOINT}/uploads/${activity.background})` : 'none',
                                    backgroundSize: 'cover', borderRadius: '20px', height: "200px", backgroundPosition: 'center', backgroundRepeat: 'no-repeat',
                                }}></div>
                                <div className={`flex justify-center mt-3 absolute inset-x-0 top-0`}><Tooltip title={`Activity Type ${activity.activityType}`}><img src={ActivityType(activity.activityType)} alt="ActivityTYpe" /></Tooltip></div>
                                <div className="bg-gray-900 text-white  absolute inset-x-0 bottom-0 shadow-xl" style={{ borderRadius: '20px', height: "100px" }}>
                                    <div className="flex justify-center">
                                        <Tooltip title={activity.analyticsList.length === activity.lerners ? 'All Players Played' : activity.unlock ? 'Unlocked Activity' : ' Locked Activity'}><img className="cursor-pointer"
                                            src={activity.analyticsList.length === activity.lerners ? CourseDoneIcon : activity.unlock ? CourseUnLock : CourseLock} alt="" style={{ marginTop: "-25px" }} /></Tooltip>
                                    </div>
                                    <p className="flex justify-center text-center	 pt-2 text-sm"><b>{activity.name}</b></p>
                                    <p className="flex justify-center text-center	 mt-2 text-sm"><b>{activity.courseName}</b></p>
                                    <p className="flex justify-center mt-2 text-sm"><b>{activity.stageName}</b></p>
                                </div>
                            </div>
                            <div className="flex gap-7 justify-center mt-2 border py-2" style={{ borderRadius: "10px" }}>
                                {/* <img src={CourseNotActive} alt="" /> */}
                                {/* <img src={EditQuestoin} alt="" /> */}
                                <Tooltip title='Delete'><img className="cursor-pointer" src={DeletQuestoin} alt="" onClick={() => { setActivityName(activity.name); setActivity_id(activity.activity_id); DeleteDialogOpen('activity') }} /></Tooltip>
                            </div>
                            <div className="flex gap-5 justify-center border py-2" style={{ borderRadius: "10px" }}>
                                <Tooltip title='Players'><span className="relative"><img src={LearnerTotal} alt="" /><span className={`absolute inset-0 top-0.5 ${activity.analyticsList.length <= 9 ? 'left-2.5' : 'left-1'}`}>{activity.analyticsList.length}</span> </span></Tooltip>
                                <p>Learners</p>
                                <Tooltip title='Total Enrolment'> <span className="relative"><img src={LearnerDone} alt="" /><span className={`absolute inset-0 top-0.5 ${activity.lerners <= 9 ? 'left-2.5' : 'left-1'}`}>{activity.lerners}</span> </span></Tooltip>
                            </div>
                        </div>
                    ))) : (<div className="text-gray-500">No activities available in this subject.</div>)}
                </div>
            </div>
            {/* Mathematics Courses' Activities */}
            <div>
                <p className="border-b-2 mx-7 px-2 text-2xl pt-10 flex justify-center sm:justify-start font-bold">Math</p>
                <div className="grid grid-cols-1 px-5 sm:grid sm:grid-cols-2 md:grid-cols-3  sm:px-5 xl:grid-cols-5 gap-5 2xl:px-40 mt-5">
                    {mathActivities.length > 0 ? (mathActivities.map((activity, activityIndex) => (
                        <div key={activityIndex}>
                            <div className="border relative" style={{ borderRadius: '20px', height: "250px", backgroundColor: ActivityCourseColors[activityIndex % ActivityCourseColors.length] }}>
                                <div className="" style={{
                                    backgroundImage: activity.background ? `url(${process.env.REACT_APP_ENDPOINT}/uploads/${activity.background})` : 'none',
                                    backgroundSize: 'cover', borderRadius: '20px', height: "200px", backgroundPosition: 'center', backgroundRepeat: 'no-repeat',
                                }}></div>
                                <div className={`flex justify-center mt-3 absolute inset-x-0 top-0`}><Tooltip title={`Activity Type ${activity.activityType}`}><img src={ActivityType(activity.activityType)} alt="ActivityTYpe" /></Tooltip></div>
                                <div className="bg-gray-900 text-white  absolute inset-x-0 bottom-0 shadow-xl" style={{ borderRadius: '20px', height: "100px" }}>
                                    <div className="flex justify-center">
                                        <Tooltip title={activity.analyticsList.length === activity.lerners ? 'All Players Played' : activity.unlock ? 'Unlocked Activity' : ' Locked Activity'}><img className="cursor-pointer"
                                            src={activity.analyticsList.length === activity.lerners ? CourseDoneIcon : activity.unlock ? CourseUnLock : CourseLock} alt="" style={{ marginTop: "-25px" }} /></Tooltip>
                                    </div>
                                    <p className="flex justify-center text-center	 pt-2 text-sm"><b>{activity.name}</b></p>
                                    <p className="flex justify-center text-center	 mt-2 text-sm"><b>{activity.courseName}</b></p>
                                    <p className="flex justify-center mt-2 text-sm"><b>{activity.stageName}</b></p>
                                </div>
                            </div>
                            <div className="flex gap-7 justify-center mt-2 border py-2" style={{ borderRadius: "10px" }}>
                                {/* <img src={CourseNotActive} alt="" /> */}
                                {/* <img src={EditQuestoin} alt="" /> */}
                                <Tooltip title='Delete'><img className="cursor-pointer" src={DeletQuestoin} alt="" onClick={() => { setActivityName(activity.name); setActivity_id(activity.activity_id); DeleteDialogOpen('activity') }} /></Tooltip>
                            </div>
                            <div className="flex gap-5 justify-center border py-2" style={{ borderRadius: "10px" }}>
                                <Tooltip title='Players'><span className="relative"><img src={LearnerTotal} alt="" /><span className={`absolute inset-0 top-0.5 ${activity.analyticsList.length <= 9 ? 'left-2.5' : 'left-1'}`}>{activity.analyticsList.length}</span> </span></Tooltip>
                                <p>Learners</p>
                                <Tooltip title='Total Enrolment'> <span className="relative"><img src={LearnerDone} alt="" /><span className={`absolute inset-0 top-0.5 ${activity.lerners <= 9 ? 'left-2.5' : 'left-1'}`}>{activity.lerners}</span> </span></Tooltip>
                            </div>
                        </div>
                    ))) : (<div className="text-gray-500">No activities available in this subject.</div>)}
                </div>
            </div>
            {/* Science Courses' Activities */}
            <div>
                <p className="border-b-2 mx-7 px-2 text-2xl pt-10 flex justify-center sm:justify-start font-bold">Science</p>
                <div className="grid grid-cols-1 px-5 sm:grid sm:grid-cols-2 md:grid-cols-3  sm:px-5 xl:grid-cols-5 gap-5 2xl:px-40 mt-5">
                    {scienceActivities.length > 0 ? (scienceActivities.map((activity, activityIndex) => (
                        <div key={activityIndex}>
                            <div className="border relative" style={{ borderRadius: '20px', height: "250px", backgroundColor: ActivityCourseColors[activityIndex % ActivityCourseColors.length] }}>
                                <div className="" style={{
                                    backgroundImage: activity.background ? `url(${process.env.REACT_APP_ENDPOINT}/uploads/${activity.background})` : 'none',
                                    backgroundSize: 'cover', borderRadius: '20px', height: "200px", backgroundPosition: 'center', backgroundRepeat: 'no-repeat',
                                }}></div>
                                <div className={`flex justify-center mt-3 absolute inset-x-0 top-0`}><Tooltip title={`Activity Type ${activity.activityType}`}><img src={ActivityType(activity.activityType)} alt="ActivityTYpe" /></Tooltip></div>
                                <div className="bg-gray-900 text-white  absolute inset-x-0 bottom-0 shadow-xl" style={{ borderRadius: '20px', height: "100px" }}>
                                    <div className="flex justify-center">
                                        <Tooltip title={activity.analyticsList.length === activity.lerners ? 'All Players Played' : activity.unlock ? 'Unlocked Activity' : ' Locked Activity'}><img className="cursor-pointer"
                                            src={activity.analyticsList.length === activity.lerners ? CourseDoneIcon : activity.unlock ? CourseUnLock : CourseLock} alt="" style={{ marginTop: "-25px" }} /></Tooltip>
                                    </div>
                                    <p className="flex justify-center text-center	 pt-2 text-sm"><b>{activity.name}</b></p>
                                    <p className="flex justify-center text-center	 mt-2 text-sm"><b>{activity.courseName}</b></p>
                                    <p className="flex justify-center mt-2 text-sm"><b>{activity.stageName}</b></p>
                                </div>
                            </div>
                            <div className="flex gap-7 justify-center mt-2 border py-2" style={{ borderRadius: "10px" }}>
                                {/* <img src={CourseNotActive} alt="" /> */}
                                {/* <img src={EditQuestoin} alt="" /> */}
                                <Tooltip title='Delete'><img className="cursor-pointer" src={DeletQuestoin} alt="" onClick={() => { setActivityName(activity.name); setActivity_id(activity.activity_id); DeleteDialogOpen('activity') }} /></Tooltip>
                            </div>
                            <div className="flex gap-5 justify-center border py-2" style={{ borderRadius: "10px" }}>
                                <Tooltip title='Players'><span className="relative"><img src={LearnerTotal} alt="" /><span className={`absolute inset-0 top-0.5 ${activity.analyticsList.length <= 9 ? 'left-2.5' : 'left-1'}`}>{activity.analyticsList.length}</span> </span></Tooltip>
                                <p>Learners</p>
                                <Tooltip title='Total Enrolment'> <span className="relative"><img src={LearnerDone} alt="" /><span className={`absolute inset-0 top-0.5 ${activity.lerners <= 9 ? 'left-2.5' : 'left-1'}`}>{activity.lerners}</span> </span></Tooltip>
                            </div>
                        </div>
                    ))) : (<div className="text-gray-500">No activities available in this subject.</div>)}
                </div>
            </div>
            {/* Social Study Courses' Activities */}
            <div>
                <p className="border-b-2 mx-7 px-2 text-2xl pt-10 flex justify-center sm:justify-start font-bold">Social</p>
                <div className="grid grid-cols-1 px-5 sm:grid sm:grid-cols-2 md:grid-cols-3  sm:px-5 xl:grid-cols-5 gap-5 2xl:px-40 mt-5">
                    {socialActivities.length > 0 ? (socialActivities.map((activity, activityIndex) => (
                        <div key={activityIndex}>
                            <div className="border relative" style={{ borderRadius: '20px', height: "250px", backgroundColor: ActivityCourseColors[activityIndex % ActivityCourseColors.length] }}>
                                <div className="" style={{
                                    backgroundImage: activity.background ? `url(${process.env.REACT_APP_ENDPOINT}/uploads/${activity.background})` : 'none',
                                    backgroundSize: 'cover', borderRadius: '20px', height: "200px", backgroundPosition: 'center', backgroundRepeat: 'no-repeat',
                                }}></div>
                                <div className={`flex justify-center mt-3 absolute inset-x-0 top-0`}><Tooltip title={`Activity Type ${activity.activityType}`}><img src={ActivityType(activity.activityType)} alt="ActivityTYpe" /></Tooltip></div>
                                <div className="bg-gray-900 text-white  absolute inset-x-0 bottom-0 shadow-xl" style={{ borderRadius: '20px', height: "100px" }}>
                                    <div className="flex justify-center">
                                        <Tooltip title={activity.analyticsList.length === activity.lerners ? 'All Players Played' : activity.unlock ? 'Unlocked Activity' : ' Locked Activity'}><img className="cursor-pointer"
                                            src={activity.analyticsList.length === activity.lerners ? CourseDoneIcon : activity.unlock ? CourseUnLock : CourseLock} alt="" style={{ marginTop: "-25px" }} /></Tooltip>
                                    </div>
                                    <p className="flex justify-center text-center	 pt-2 text-sm"><b>{activity.name}</b></p>
                                    <p className="flex justify-center text-center	 mt-2 text-sm"><b>{activity.courseName}</b></p>
                                    <p className="flex justify-center mt-2 text-sm"><b>{activity.stageName}</b></p>
                                </div>
                            </div>
                            <div className="flex gap-7 justify-center mt-2 border py-2" style={{ borderRadius: "10px" }}>
                                {/* <img src={CourseNotActive} alt="" /> */}
                                {/* <img src={EditQuestoin} alt="" /> */}
                                <Tooltip title='Delete'><img className="cursor-pointer" src={DeletQuestoin} alt="" onClick={() => { setActivityName(activity.name); setActivity_id(activity.activity_id); DeleteDialogOpen('activity') }} /></Tooltip>
                            </div>
                            <div className="flex gap-5 justify-center border py-2" style={{ borderRadius: "10px" }}>
                                <Tooltip title='Players'><span className="relative"><img src={LearnerTotal} alt="" /><span className={`absolute inset-0 top-0.5 ${activity.analyticsList.length <= 9 ? 'left-2.5' : 'left-1'}`}>{activity.analyticsList.length}</span> </span></Tooltip>
                                <p>Learners</p>
                                <Tooltip title='Total Enrolment'> <span className="relative"><img src={LearnerDone} alt="" /><span className={`absolute inset-0 top-0.5 ${activity.lerners <= 9 ? 'left-2.5' : 'left-1'}`}>{activity.lerners}</span> </span></Tooltip>
                            </div>
                        </div>
                    ))) : (<div className="text-gray-500">No activities available in this subject.</div>)}
                </div>
            </div>
            {/* Without topic Courses' Activities */}
            <div>
                <p className="border-b-2 mx-7 px-2 text-2xl pt-10 flex justify-center sm:justify-start font-bold">No topic Activities</p>
                <div className="grid grid-cols-1 px-5 sm:grid sm:grid-cols-2 md:grid-cols-3  sm:px-5 xl:grid-cols-5 gap-5 2xl:px-40 mt-5">
                    {Unknown.length > 0 ? (Unknown.map((activity, activityIndex) => (
                        <div key={activityIndex}>
                            <div className="border relative" style={{ borderRadius: '20px', height: "250px", backgroundColor: ActivityCourseColors[activityIndex % ActivityCourseColors.length] }}>
                                <div className="" style={{
                                    backgroundImage: activity.background ? `url(${process.env.REACT_APP_ENDPOINT}/uploads/${activity.background})` : 'none',
                                    backgroundSize: 'cover', borderRadius: '20px', height: "200px", backgroundPosition: 'center', backgroundRepeat: 'no-repeat',
                                }}></div>
                                <div className={`flex justify-center mt-3 absolute inset-x-0 top-0`}><Tooltip title={`Activity Type ${activity.activityType}`}><img src={ActivityType(activity.activityType)} alt="ActivityTYpe" /></Tooltip></div>
                                <div className="bg-gray-900 text-white  absolute inset-x-0 bottom-0 shadow-xl" style={{ borderRadius: '20px', height: "100px" }}>
                                    <div className="flex justify-center">
                                        <Tooltip title={activity.analyticsList.length === activity.lerners ? 'All Players Played' : activity.unlock ? 'Unlocked Activity' : ' Locked Activity'}><img className="cursor-pointer"
                                            src={activity.analyticsList.length === activity.lerners ? CourseDoneIcon : activity.unlock ? CourseUnLock : CourseLock} alt="" style={{ marginTop: "-25px" }} /></Tooltip>
                                    </div>
                                    <p className="flex justify-center text-center	 pt-2 text-sm"><b>{activity.name}</b></p>
                                    <p className="flex justify-center text-center	 mt-2 text-sm"><b>{activity.courseName}</b></p>
                                    <p className="flex justify-center mt-2 text-sm"><b>{activity.stageName}</b></p>
                                </div>
                            </div>
                            <div className="flex gap-7 justify-center mt-2 border py-2" style={{ borderRadius: "10px" }}>
                                {/* <img src={CourseNotActive} alt="" /> */}
                                {/* <img src={EditQuestoin} alt="" /> */}
                                <Tooltip title='Delete'><img className="cursor-pointer" src={DeletQuestoin} alt="" onClick={() => { setActivityName(activity.name); setActivity_id(activity.activity_id); DeleteDialogOpen('activity') }} /></Tooltip>
                            </div>
                            <div className="flex gap-5 justify-center border py-2" style={{ borderRadius: "10px" }}>
                                <Tooltip title='Players'><span className="relative"><img src={LearnerTotal} alt="" /><span className={`absolute inset-0 top-0.5 ${activity.analyticsList.length <= 9 ? 'left-2.5' : 'left-1'}`}>{activity.analyticsList.length}</span> </span></Tooltip>
                                <p>Learners</p>
                                <Tooltip title='Total Enrolment'> <span className="relative"><img src={LearnerDone} alt="" /><span className={`absolute inset-0 top-0.5 ${activity.lerners <= 9 ? 'left-2.5' : 'left-1'}`}>{activity.lerners}</span> </span></Tooltip>
                            </div>
                        </div>
                    ))) : (<div className="text-gray-500">No activities available in this subject.</div>)}
                </div>
            </div>
        </div>
    )
};

export default GroupActivities;